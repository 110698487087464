import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { listadoFacturacionHistorica, listadoFlujosFuturos, listadoLineaCredito, listadoReport, listadoSolicitud, listadoSolicitudCteEmpresa, listadoUtilidades } from 'src/app/models/utils/utils.model';
import { environment } from '../../../environments/environment';
import { ChartType } from 'angular-google-charts';

interface TotalesCategoriasString {
  anio: string | number
  TotalSem1: TotalesString,
  TotalSem2: TotalesString,
  TotalAnio: TotalesString,
  TotalProm: TotalesString,
}

interface TotalesString {
  ventas: number | string,
  compras: number | string,
  dif: number | string,
  posterga: number | string,
  meses?: number,
  mesesPosterga?: number | string,
  margen: number | string,
}

interface TotalesCategorias {
  anio: string | number
  TotalSem1: Totales,
  TotalSem2: Totales,
  TotalAnio: Totales,
  TotalProm: Totales,
}

interface Totales {
  ventas: number,
  compras: number,
  dif: number,
  posterga: number,
  meses?: number,
  mesesPosterga?: number,
  margen: number,
}

@Injectable({
  providedIn: 'root'
})

export class UtilsService {

  private refreshStepSubject: Subject<any> = new Subject();
  public refreshStep$ = this.refreshStepSubject.asObservable();
  public updateReportSubject: Subject<any> = new Subject();
  public updateReportSubjectObs$ = this.updateReportSubject.asObservable();
  public objectKeys = Object.keys;

  private camposLineaDeCredito = [
    'Sociedad',
    'LineadeNegocio',
    'Sucursal',
    'LCSolicitada',
    'TipoSeguro',
    'Tipodeclienteasegurado',
    'AprobaciondesegurodecreditoMontoenUF',
    'CantidaddeCuotasPlazoVenta',
    'Vendedor',
    'NumeroTickets',
    'Periodicidad'
  ];

  private camposRenegociacion = [
    'Sociedad',
    'LineadeNegocio',
    'Sucursal',
    'TotalDeuda',
    'CantidaddeCuotas',
    'Renegociacion',
    'TasaMensual',
    'Pie',
    'ValorCuota',
    'MontoCuotasconInteres',
    'MontoTotalInteres',
    'Vendedor',
    'DebeFirmarleydelos30dias',
    'Plazodelaleydelos30dias',
    'NumeroTickets',
    'Periodicidad',
    'PorcentajeRenegociacion',
    'PorcentajePie',
    'PorcentajeValorCuota',
    'PorcentajeTotalDeuda',
  ];

  private camposVentaNueva = [
    'Sociedad',
    'LineadeNegocio',
    'Tipodeequipo',
    'Sucursal',
    'ValorEquipoNeto',
    'AfectoaIVA',
    'ValorEquipoIVAincluido',
    'IVAalIVA',
    'Financiamientoexterno',
    'PagoContado',
    'Financiamientointerno',
    'Retoma',
    'Pie',
    'AplicaInteres',
    'MontoafinanciarSalfa',
    'TasaMensual',
    'CantidaddeCuotas',
    'DebeFirmarleydelos30dias',
    'ValorCuota',
    'Plazodelaleydelos30dias',
    'MontoCuotasconInteres',
    'MontoTotalInteres',
    'Vendedor',
    'NumeroTickets',
    'Periodicidad',
    'PorcentajePie',
    'PorcentajeValorCuota',
    'PorcentajeFinanciamientoexterno',
    'PorcentajeFinanciamientointerno',
    'PorcentajeIVAalIVA',
    'PorcentajePagoContado',
    'PorcentajeMontoafinanciarSalfa',
    'PorcentajeValorEquipoIVAincluido',
    'TipoSeguro',
    'Tipodeclienteasegurado',
    'AprobaciondesegurodecreditoMontoenUF',
  ];


  getFieldsForm(tipoSolicitud: string) {
    switch(tipoSolicitud) {
      case 'ventaNueva':
        return this.camposVentaNueva;
      case 'renegociacion':
        return this.camposRenegociacion;
      default:
        return this.camposLineaDeCredito;

    }
  }

  getConfigForm(tipoSolicitud: string) {
    switch(tipoSolicitud) {
      case 'ventaNueva':
        return [
          'PorcentajePie',
          'PorcentajeValorCuota',
          'ValorCuota',
          'MontoCuotasconInteres',
          'MontoTotalInteres',
          'Pie',
          'ValorEquipoIVAincluido',
          'Financiamientointerno',
          'MontoafinanciarSalfa',
          'PorcentajeFinanciamientoexterno',
          'PorcentajeFinanciamientointerno',
          'PorcentajeIVAalIVA',
          'PorcentajePagoContado',
          'PorcentajeMontoafinanciarSalfa',
          'PorcentajeValorEquipoIVAincluido',
          'Tipodeclienteasegurado'
        ];
      case 'renegociacion':
        return [
          'PorcentajeRenegociacion',
          'PorcentajePie',
          'PorcentajeTotalDeuda',
          'PorcentajeValorCuota',
          'Renegociacion',
          'ValorCuota',
          'MontoCuotasconInteres',
          'MontoTotalInteres',
        ];
      default:
        return ['Tipodeclienteasegurado'];

    }
  }

  getDoubleFields(control: string) {
    const obj: any = {
      'Sociedad': ['Sociedad', 'SociedadVal'],
      'CantidaddeCuotasPlazoVenta': ['#', 'CantidaddeCuotasPlazoVenta', 'Periodicidad', 'Periodicidad'],
      'CantidaddeCuotas': ['#', 'CantidaddeCuotas', 'Periodicidad', 'Periodicidad'],
      'TotalDeuda': ['$', 'TotalDeuda', '%', 'PorcentajeTotalDeuda'],
      'Renegociacion': ['$', 'Renegociacion', '%', 'PorcentajeRenegociacion'],
      'Pie': ['$', 'Pie', '%', 'PorcentajePie'],
      'ValorCuota': ['$', 'ValorCuota', '%', 'PorcentajeValorCuota'],
      'ValorEquipoIVAincluido': ['$', 'ValorEquipoIVAincluido', '%', 'PorcentajeValorEquipoIVAincluido'],
      'Financiamientoexterno': ['$', 'Financiamientoexterno', '%', 'PorcentajeFinanciamientoexterno'],
      'Financiamientointerno': ['$', 'Financiamientointerno', '%', 'PorcentajeFinanciamientointerno'],
      'IVAalIVA': ['$', 'IVAalIVA', '%', 'PorcentajeIVAalIVA'],
      'PagoContado': ['$', 'PagoContado', '%', 'PorcentajePagoContado'],
      'MontoafinanciarSalfa': ['$', 'MontoafinanciarSalfa', '%', 'PorcentajeMontoafinanciarSalfa'],
      'Periodicidad': ['', '', ''],
      'PorcentajeRenegociacion': ['', '', ''],
      'PorcentajeValorCuota': ['', '', ''],
      'PorcentajePie': ['', '', ''],
      'PorcentajeTotalDeuda': ['', '', ''],
      'PorcentajeFinanciamientoexterno': ['', '', ''],
      'PorcentajeFinanciamientointerno': ['', '', ''],
      'PorcentajeIVAalIVA': ['', '', ''],
      'PorcentajePagoContado': ['', '', ''],
      'PorcentajeMontoafinanciarSalfa': ['', '', ''],
      'PorcentajeValorEquipoIVAincluido': ['', '', ''],
    }
    return obj[control] || [];
  }

  formatDate(date: string, mounth?: boolean): string {
    if (date) {
      const time = date.length > 14 ? date.slice(-8) : '00:00:00';
      let fecha = date.substring(0, 10);
      fecha = fecha.substr(2, 1) === '-' ? this.splitDate(fecha, mounth) : fecha;
      return fecha + ' ' + time;
    }
    return ''
  }

  dateIsValid(date: any) {
    return date instanceof Date && !isNaN(date.getTime());
  }  

  formatDateHMS(date: string): string {
    if(date){
      const fecha = new Date(date);
      const dia = fecha.getDate().toString().padStart(2, '0');
      const mes = (fecha.getMonth() + 1).toString().padStart(2, '0');
      const anio = fecha.getFullYear().toString();
      const hora = fecha.toLocaleTimeString('es-ES', {hour12: false}).substring(0, 8);
      return this.dateIsValid(fecha) ? `${dia}-${mes}-${anio} ${hora}` : '';
    } else {
      return '';
    }
  }

  splitDate(date: string, mounth?: boolean): string {
    const d = date.split('-');
    return [d[2], (mounth? (Number(d[1]) - 1) < 10? '0' + (Number(d[1]) - 1 - 1): (Number(d[1]) - 1) 
    : d[1]), d[0]].join('-');
  }

  getNested(obj: any, ...args: any) {
    return args.reduce((obj: any, level: any) => obj && obj[level], obj)
  }

  setStep(step: any) {
    this.refreshStepSubject.next(step);
  }

  setGroupHeader(group: string) {
    switch (group) {
      case 'listadoCTE':
        return listadoSolicitudCteEmpresa;
      case 'listadoReport':
        return listadoReport;
      case 'listadoUtilidades':
        return listadoUtilidades;
      case 'listadoFacturacionHistorica':
        return listadoFacturacionHistorica;
      case 'listadoFlujosFuturos':
        return listadoFlujosFuturos;
      case 'listadolineaDeCredito':
        return listadoLineaCredito;
      default:
        return listadoSolicitud;
    }
  }

  validateAllFormFields(formGroup: UntypedFormGroup): void {

    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      }
    });
  }

  getMonth(month: number): string {
    return month < 10 ? '0' + month : month.toString();
  }

  setRiesgo(riesgo: number): string {
    switch (true) {
      case (riesgo <= 199):
        return 'Alto';
      case (riesgo >= 200 && riesgo <= 399):
        return 'Medio Alto';
      case (riesgo >= 400 && riesgo <= 599):
        return 'Medio';
      case (riesgo >= 600 && riesgo <= 799):
        return 'Medio Bajo';
      default:
        return 'Bajo';
    }
  }

  valorCampoPersonalizado(informacionCliente: any, key: string) {
    return informacionCliente?.SolicitudDeCredito?.Reportes?.camposPersonalizados?.Atributos
      .find((e: any) => e.nameAttribute === key)?.value || '';
  }

  obtenerMorosidadSocios(informacionCliente: any, key: string): string {
    if (informacionCliente?.SolicitudDeCredito?.Socios.length > 0) {
      return informacionCliente?.SolicitudDeCredito?.Socios.reduce((a: any, b: any) => {
        return a + Number(b.Reportes?.basicReportInformeComercial?.AntecedentesComerciales?.
          Resumen?.[key]) || 0;
      }, 0);
    }
    return '';
  }

  changeSpanExtra(entrada: string): void{
    const spanExtra: any = document.getElementById('span-menutoggle');
    if(spanExtra)
      spanExtra.textContent = entrada;
  }

  ordernarDatosPeriodoMesDesc(array: any[]): any[] {
    array?.sort(function(a, b) {
      if (a.periodo < b.periodo) {
        return 1;
      } else if (a.periodo > b.periodo) {
        return -1;
      } else {
        if (a.mes < b.mes) {
          return 1;
        } else if (a.mes > b.mes) {
          return -1;
        } else {
          return 0;
        }
      }
    });
    return array;
  }

  crearCompraVentaFichaCapital(libros: any[]): any {
    const librosOrdenados = this.ordernarDatosPeriodoMesDesc(libros || []);
    const fechaActual = new Date();

    const dateDesde = new Date(fechaActual.getTime());
    dateDesde.setMonth(fechaActual.getMonth() - 12);

    const notasCredito: string[] =	['60','61','112'];
    let totalMontoCompras = 0;
    let totalMontoVentas = 0;
    let totalMontoComprasNC = 0;
    let totalMontoVentasNC = 0;
    let totalRegistrosCompras = 0;
    let totalRegistrosVentas = 0;
    let hasData = false;

    librosOrdenados.forEach(item => {
      if(this.validaMesAnioActual(item.mes, item.periodo)){
        const itemDate = new Date(item.periodo, item.mes - 1, 1);
        if(itemDate <= fechaActual && itemDate >= dateDesde){
          if(item?.totalizadores && Object.keys(item?.totalizadores).length > 0){
            totalMontoCompras += Number(item?.totalizadores?.montoTotalCompra || 0);
            totalMontoVentas += Number(item?.totalizadores?.montoTotalVenta || 0);
            totalRegistrosCompras += Number(item?.totalizadores?.totalRegistrosCompras || 0);
            totalRegistrosVentas += Number(item?.totalizadores?.totalRegistrosVentas || 0);
            hasData = true;
          } else if((item?.registros?.compra && item?.registros?.compra.length > 0) || (item?.registros?.venta && item?.registros?.venta.length > 0)) {
            if(item?.registros?.compra){
              item?.registros?.compra.forEach((element: any) => {
                totalRegistrosCompras += 1;
                if(!notasCredito.includes(element?.TipoDoc)){
                  totalMontoCompras += Number(element?.MontoTotal || 0);
                  hasData = true;
                } else {
                  totalMontoComprasNC += Number(element?.MontoTotal || 0);
                  hasData = true;
                }
              })
            }
            if(item?.registros?.venta){
              item?.registros?.venta.forEach((element: any) => {
                totalRegistrosVentas += 1;
                if(!notasCredito.includes(element?.TipoDoc)){
                  totalMontoVentas += Number(element?.Montototal || 0);
                  hasData = true;
                } else {
                  totalMontoVentasNC += Number(element?.Montototal || 0);
                  hasData = true;
                }
              })
            }
          }
        }
      }
    });
    return {
      totalCompras: Number(totalMontoCompras) - Number(totalMontoComprasNC),
      totalVentas: Number(totalMontoVentas) - Number(totalMontoVentasNC),
      totalRegistrosCompras: totalRegistrosCompras,
      totalRegistrosVentas: totalRegistrosVentas,
      hasData: hasData
    };
  }

  identificarInformeComercial(datos: any): string {
    if(datos?.AntecedentesSocietarios && datos?.AntecedentesSociedades && datos?.Ejecutivos && datos?.AntecedentesComerciales){
      return '11';
    } else {
      return '10';
    }
  }

  mapeoPoderJudicial(reporteJudicial: any): any{
    if(reporteJudicial?.reporte){
      const datos = reporteJudicial?.reporte["Totalizadores Poder Judicial"];
      const reporte = {
        poderJudicialReporte: {
          poderJudicial: {
            Totales: datos
          }
        }
      };
      reporteJudicial.reporteMapeado = reporte;
    }
    if(reporteJudicial?.reporte?.Reporte){
      const datos = reporteJudicial?.reporte?.Reporte;
      const reporte = {
        poderJudicialReporte: {
          poderJudicial: datos
        }
      };

      reporteJudicial.ReporteFull = reporte;
    }
    return reporteJudicial;
  }

  validaObjetos(reporte: any): void{
    if(reporte?.cteVentasBalancesRentas?.reporte && Object.keys(reporte?.cteVentasBalancesRentas?.reporte).length === 0){
      reporte.cteVentasBalancesRentas.reporte = null;
    }
    if(reporte?.basicReport?.reporte && Object.keys(reporte?.basicReport?.reporte).length === 0){
      reporte.basicReport.reporte = null;
    }
    if(reporte?.deudaSbif?.reporte && Object.keys(reporte?.deudaSbif?.reporte).length === 0){
      reporte.deudaSbif.reporte = null;
    }
    if(reporte?.boletinConcursal?.reporte && Object.keys(reporte?.boletinConcursal?.reporte).length === 0){
      reporte.boletinConcursal.reporte = null;
    }
    if(reporte?.reporteEmpresaDia?.reporte && Object.keys(reporte?.reporteEmpresaDia?.reporte).length === 0){
      reporte.reporteEmpresaDia.reporte = null;
    }
    return reporte;
  }

  getReporteBasico(objReporteBasico: any): any {
    return this.ifReporteBasico(objReporteBasico);
  }

  setRiesgoIndustrialCalculado(objReporteBasico: any): any {
    const objeto: any = {
      riesgoIndustriaCalculado: "",
      riesgoIndustriaCalculadoValue: 100
    }

    if (!objReporteBasico?.Riesgo) {
      return objeto;
    }

    switch (objReporteBasico?.Riesgo.RiesgoIndustria) {
      case "Alto":
        objeto.riesgoIndustriaCalculado = "Alto";
        objeto.riesgoIndustriaCalculadoValue = 10;
        return objeto;
      case "Medio":
        objeto.riesgoIndustriaCalculado = "Medio";
        objeto.riesgoIndustriaCalculadoValue = 30;
        return objeto;
      case "Medio Alto":
        objeto.riesgoIndustriaCalculado = "Medio Alto";
        objeto.riesgoIndustriaCalculadoValue = 50;
        return objeto;
      case "Medio Bajo":
        objeto.riesgoIndustriaCalculado = "Medio Bajo";
        objeto.riesgoIndustriaCalculadoValue = 70;
        return objeto;
      case "Bajo":
        objeto.riesgoIndustriaCalculado = "Bajo";
        objeto.riesgoIndustriaCalculadoValue = 90;
        return objeto;
      default:
        objeto.riesgoIndustriaCalculado = "";
        objeto.riesgoIndustriaCalculadoValue = 100;
        return objeto;
    }
  }

  setChartData(objReporteBasico: any): any {
    if (
      !objReporteBasico?.InformacionSectorialyDeMercado
      || !objReporteBasico?.InformacionSectorialyDeMercado.TotalVentasHistoricoUF
      || !objReporteBasico?.InformacionSectorialyDeMercado.TotalVentasHistoricoUF.ano1
      || !objReporteBasico?.InformacionSectorialyDeMercado.TotalVentasHistoricoUF.periodo_1
    ) {
      return {};
    }
    return {
      graficaSinInformacion: true,
      data: [
        [String(objReporteBasico?.InformacionSectorialyDeMercado.TotalVentasHistoricoUF.ano1), (objReporteBasico?.InformacionSectorialyDeMercado.TotalVentasHistoricoUF.periodo_1) / 1000000],
        [String(objReporteBasico?.InformacionSectorialyDeMercado.TotalVentasHistoricoUF.ano2), (objReporteBasico?.InformacionSectorialyDeMercado.TotalVentasHistoricoUF.periodo_2) / 1000000],
        [String(objReporteBasico?.InformacionSectorialyDeMercado.TotalVentasHistoricoUF.ano3), (objReporteBasico?.InformacionSectorialyDeMercado.TotalVentasHistoricoUF.periodo_3) / 1000000],
        [String(objReporteBasico?.InformacionSectorialyDeMercado.TotalVentasHistoricoUF.ano4), (objReporteBasico?.InformacionSectorialyDeMercado.TotalVentasHistoricoUF.periodo_4) / 1000000],
        [String(objReporteBasico?.InformacionSectorialyDeMercado.TotalVentasHistoricoUF.ano5), (objReporteBasico?.InformacionSectorialyDeMercado.TotalVentasHistoricoUF.periodo_5) / 1000000],
      ]
    }
  }

  setGrafico(objReporteBasico: any): any {
    const objTemp = objReporteBasico?.Riesgo?.ClasificacionHistorica
      .sort((a: any, b: any) => (new Date(a.Fecha).getTime() > new Date(b.Fecha).getTime() ? 1 : -1))
      .slice(-12);

    const categorias = objTemp?.map((element: any) => {
      const fechaStr = element.Fecha;
      let fecha;

      fecha = new Date(fechaStr);

      if (isNaN(fecha.getTime())) {
        const [year, month, day] = fechaStr.split('-').map(Number);
        fecha = new Date(Date.UTC(year, month - 1, day));
      }

      if (isNaN(fecha.getTime())) {
        //console.error(`Fecha inválida: ${fechaStr}`);
        return null;
      }

      const mes = fecha.getUTCMonth() + 1;
      return `${fecha.getUTCFullYear()}/${mes}`;
    });

    const values = objTemp?.map((element: any) => {
      return Number(element.Valor)
    });

    const graph = {
      xAxis: [{
        categories: categorias
      }],
      title: {
        text: ''
      },
      yAxis: [{
        labels: {
          format: '{value}',
          style: {
            color: '#000'
          }
        },
        title: {
          text: '',
          style: {
            color: '#000'
          }
        }
      }],
      series: [{
        name: 'Puntajes',
        type: 'line',
        data: values,
        color: '#f00'
      }]
    };

    return graph;
  }

  ifReporteBasico(objReporteBasico: any): any {
    const objRiesgoIndustrialCalculado = this.setRiesgoIndustrialCalculado(objReporteBasico);
    const chartData = this.setChartData(objReporteBasico);

    let graph = null;
    if (objReporteBasico?.Riesgo?.ClasificacionHistorica && objReporteBasico?.Riesgo?.ClasificacionHistorica.length > 0) {
      graph = this.setGrafico(objReporteBasico);
    }
    
    let showInformacionSectorialyDeMercado = false;
    if (
      objReporteBasico?.InformacionSectorialyDeMercado
      && objReporteBasico?.InformacionSectorialyDeMercado.TotalVentasHistoricoUF
      && objReporteBasico?.InformacionSectorialyDeMercado.TotalVentasHistoricoUF.ano1) {
      showInformacionSectorialyDeMercado = true;
    }

    return {
      objRiesgoIndustrialCalculado: objRiesgoIndustrialCalculado,
      chartData: chartData,
      graph: graph,
      showInformacionSectorialyDeMercado: showInformacionSectorialyDeMercado,
    }

  }

  calcularMallaSocietaria(objReporteFinanciero: any, objReporteBasico: any, informeComercial: any): string {
    return objReporteFinanciero && objReporteFinanciero.CarpetaTributariaElectronicaReporte.Reporte.socios.length > 0 ?
      'CTE' : objReporteFinanciero && objReporteFinanciero.CarpetaTributariaElectronicaReporte.Reporte.socios.length > 0 &&
        objReporteBasico && informeComercial === '11' ? 'CTE' : objReporteBasico && informeComercial === '11' ?
        'Informe comercial' : 'Sin información';
  }

  setInformacionBalance(f22: any[]): any[] {
    const objF22 = f22 || [];

    if (objF22.length > 0) {
      return objF22.sort((a: any, b: any) => {
        return b.periodo - a.periodo;
      }).slice(0, 3);
    }
    return [];
  }

  formatDateDeuda(date: string): string {
    return date.split('-').reverse().join('-');
  }

  getResponse(response: any[]): any[] {
    if (response && response.length > 0) {
      return response.map((e: any) => {
        return {
          castigadadirecta: e.castigadadirecta,
          castigadaindirecta: e.castigadaindirecta,
          credcontingente: e.credcontingente,
          diracreedor: [e.dirconsumo, e.diracreedor],
          dircomercial: e.dircomercial,
          dirhipotecaria: e.dirhipotecaria,
          dirinversion: e.dirinversion,
          dirmorosa: e.dirmorosa,
          diroperacionpacto: e.diroperacionpacto,
          dirven180mayor3year: e.dirven180mayor3year,
          dirvencida: e.dirvencida,
          dirvigente: e.dirvigente,
          indirectaven: e.indirectaven,
          indirectavig: e.indirectavig,
          leasingmor: e.leasingmor,
          leasingvig: e.leasingvig,
          lineacredito: e.lineacredito,
          monexvencida: e.monexvencida,
          monexvigente: e.monexvigente,
          periodo: this.formatDateDeuda(e.periodo)
        }
      }).sort((a, b) => new Date(b.periodo).getTime() - new Date(a.periodo).getTime()) || []
    }
    return [];
  }

  reducirDeudaSbif(obj: any): any {
    const lastPeriodo = obj[0].periodo;
    const periodoDeuda = !isNaN(Number(lastPeriodo.substr(0, 3))) ? lastPeriodo : '';

    let objResumenDeudaSbif: any = {};
    if(obj[0]){
      objResumenDeudaSbif = {
        castigadadirecta: (obj[0]['castigadadirecta']? Number(obj[0]['castigadadirecta']) : 0),
        credcontingente: (obj[0]['credcontingente']? Number(obj[0]['credcontingente']) : 0),
        dirmorosa: (obj[0]['dirmorosa']? Number(obj[0]['dirmorosa']) : 0),
        dirven180mayor3year: (obj[0]['dirven180mayor3year']? Number(obj[0]['dirven180mayor3year']) : 0),
        dirvencida: (obj[0]['dirvencida']? Number(obj[0]['dirvencida']) : 0),
        dirvigente: (obj[0]['dirvigente']? Number(obj[0]['dirvigente']) : 0),
        leasingmor: (obj[0]['leasingmor']? Number(obj[0]['leasingmor']) : 0),
        leasingvig: (obj[0]['leasingvig']? Number(obj[0]['leasingvig']) : 0),
        lineacredito: (obj[0]['lineacredito']? Number(obj[0]['lineacredito']) : 0),
        periodo: lastPeriodo
      }
    }
    return {
      periodoDeuda: periodoDeuda, 
      objResumenDeudaSbif: objResumenDeudaSbif
    }
  }

  obtenerLastDeudaSbif(objReporteDeudaSbif: any): any {
    if (objReporteDeudaSbif && objReporteDeudaSbif?.Sbif?.rowsMeses) {
      const objTemp = this.getResponse(objReporteDeudaSbif?.Sbif?.rowsMeses?.item);
      return this.reducirDeudaSbif(objTemp);
    }
    return {};
  }

  getTotalJudicial(objReporteJudicial: any): number {
    const causas = objReporteJudicial?.poderJudicialReporte?.poderJudicial?.Totales;
    if (!causas) { return 0; }
    const civil = causas.nCausasCivil || 0;
    const cobranza = causas.nCausasCobranza || 0;
    const laboral = causas.nCausasLaboral || 0;
    return Number(civil) + Number(cobranza) + Number(laboral);
  }

  validarValor(valor: any): boolean {
    if(valor || valor == 0){
      return true;
    }
    return false;
  }

  crearObjReporteBasico(objReporteBasico: any): any {
    const objeto: any = {};

    if(objReporteBasico?.InformacionBasica){
      objeto.InformacionBasica = {};
      objeto.InformacionBasica.NombreORazonSocial = objReporteBasico?.InformacionBasica?.NombreORazonSocial || null;
      objeto.InformacionBasica.Direccion = objReporteBasico?.InformacionBasica?.Direccion || null;
      objeto.InformacionBasica.AnoInicioActividades = objReporteBasico?.InformacionBasica?.AnoInicioActividades || null;
      objeto.InformacionBasica.TipoSociedad = objReporteBasico?.InformacionBasica?.TipoSociedad || null;
      objeto.InformacionBasica.FechaReporte = objReporteBasico?.InformacionBasica?.FechaReporte || null;
      objeto.InformacionBasica.TipoReporte = objReporteBasico?.InformacionBasica?.TipoReporte || null;
      objeto.InformacionBasica.Rut = objReporteBasico?.InformacionBasica?.Rut || null;
      objeto.InformacionBasica.TerminoGiro = objReporteBasico?.InformacionBasica?.TerminoGiro || null;
      objeto.InformacionBasica.FechaTerminoGiro = objReporteBasico?.InformacionBasica?.FechaTerminoGiro || null;
    }

    if(objReporteBasico?.Riesgo){
      objeto.Riesgo = {};
      objeto.Riesgo.ActividadEconomica = objReporteBasico?.Riesgo?.ActividadEconomica || null;
      objeto.Riesgo.Industria = objReporteBasico?.Riesgo?.Industria || null;
      objeto.Riesgo.Subsector = objReporteBasico?.Riesgo?.Subsector || null;
      objeto.Riesgo.RiesgoIndustria = objReporteBasico?.Riesgo?.RiesgoIndustria || null;
      objeto.Riesgo.Clasificacion = objReporteBasico?.Riesgo?.Clasificacion || null;
      objeto.Riesgo.ClasificacionHistorica = objReporteBasico?.Riesgo?.ClasificacionHistorica || null;
    }

    if(objReporteBasico?.AntecedentesFinancieros){
      objeto.AntecedentesFinancieros = {};
      objeto.AntecedentesFinancieros.NumeroEmpleados = this.validarValor(objReporteBasico?.AntecedentesFinancieros?.NumeroEmpleados) ? objReporteBasico?.AntecedentesFinancieros?.NumeroEmpleados : null;
      objeto.AntecedentesFinancieros.TamanoEmpresaDescripcion = this.validarValor(objReporteBasico?.AntecedentesFinancieros?.TamanoEmpresaDescripcion) ? objReporteBasico?.AntecedentesFinancieros?.TamanoEmpresaDescripcion : null;
      objeto.AntecedentesFinancieros.TamanoSubsegmento = this.validarValor(objReporteBasico?.AntecedentesFinancieros?.TamanoSubsegmento) ? objReporteBasico?.AntecedentesFinancieros?.TamanoSubsegmento : null;
      objeto.AntecedentesFinancieros.TamanoEmpresaRango = this.validarValor(objReporteBasico?.AntecedentesFinancieros?.TamanoEmpresaRango) ? objReporteBasico?.AntecedentesFinancieros?.TamanoEmpresaRango : null;
      objeto.AntecedentesFinancieros.Patrimonio = this.validarValor(objReporteBasico?.AntecedentesFinancieros?.Patrimonio) ? objReporteBasico?.AntecedentesFinancieros?.Patrimonio : null;
      objeto.AntecedentesFinancieros.NumeroVehiculos = this.validarValor(objReporteBasico?.AntecedentesFinancieros?.NumeroVehiculos) ? objReporteBasico?.AntecedentesFinancieros?.NumeroVehiculos : null;
      objeto.AntecedentesFinancieros.ValorVehiculos = this.validarValor(objReporteBasico?.AntecedentesFinancieros?.ValorVehiculos) ? objReporteBasico?.AntecedentesFinancieros?.ValorVehiculos : null;
      objeto.AntecedentesFinancieros.NumeroBienesRaices = this.validarValor(objReporteBasico?.AntecedentesFinancieros?.NumeroBienesRaices) ? objReporteBasico?.AntecedentesFinancieros?.NumeroBienesRaices : null;
      objeto.AntecedentesFinancieros.ValorBienesRaices = this.validarValor(objReporteBasico?.AntecedentesFinancieros?.ValorBienesRaices) ? objReporteBasico?.AntecedentesFinancieros?.ValorBienesRaices : null;
      objeto.AntecedentesFinancieros.TramoSegunVentas = this.validarValor(objReporteBasico?.AntecedentesFinancieros?.TramoSegunVentas) ? objReporteBasico?.AntecedentesFinancieros?.TramoSegunVentas : null;
    }

    if(objReporteBasico?.AntecedentesSocietarios){
      objeto.AntecedentesSocietarios = {};
      objeto.AntecedentesSocietarios = objReporteBasico?.AntecedentesSocietarios || null;
    }

    if(objReporteBasico?.InformacionSectorialyDeMercado){
      objeto.InformacionSectorialyDeMercado = {};
      objeto.InformacionSectorialyDeMercado.TamanoMercadoNumeroEmpresas = this.validarValor(objReporteBasico?.InformacionSectorialyDeMercado?.TamanoMercadoNumeroEmpresas) ? objReporteBasico?.InformacionSectorialyDeMercado?.TamanoMercadoNumeroEmpresas : null;
      objeto.InformacionSectorialyDeMercado.TotalVentasIndustriaEnUF = this.validarValor(objReporteBasico?.InformacionSectorialyDeMercado?.TotalVentasIndustriaEnUF) ? objReporteBasico?.InformacionSectorialyDeMercado?.TotalVentasIndustriaEnUF : null;
      objeto.InformacionSectorialyDeMercado.ParticipacionRelativa = this.validarValor(objReporteBasico?.InformacionSectorialyDeMercado?.ParticipacionRelativa) ? objReporteBasico?.InformacionSectorialyDeMercado?.ParticipacionRelativa : null;
      objeto.InformacionSectorialyDeMercado.TendenciaMercado = this.validarValor(objReporteBasico?.InformacionSectorialyDeMercado?.TendenciaMercado) ? objReporteBasico?.InformacionSectorialyDeMercado?.TendenciaMercado : null;
      objeto.InformacionSectorialyDeMercado.VariacionVentasCrecimientoAnual = this.validarValor(objReporteBasico?.InformacionSectorialyDeMercado?.VariacionVentasCrecimientoAnual) ? objReporteBasico?.InformacionSectorialyDeMercado?.VariacionVentasCrecimientoAnual : null;
      objeto.InformacionSectorialyDeMercado.NumeroEmpresasEnComuna = this.validarValor(objReporteBasico?.InformacionSectorialyDeMercado?.NumeroEmpresasEnComuna) ? objReporteBasico?.InformacionSectorialyDeMercado?.NumeroEmpresasEnComuna : null;
      objeto.InformacionSectorialyDeMercado.TotalVentasComunaEnUF = this.validarValor(objReporteBasico?.InformacionSectorialyDeMercado?.TotalVentasComunaEnUF) ? objReporteBasico?.InformacionSectorialyDeMercado?.TotalVentasComunaEnUF : null;
      objeto.InformacionSectorialyDeMercado.ParticipacionRelativaComuna = this.validarValor(objReporteBasico?.InformacionSectorialyDeMercado?.ParticipacionRelativaComuna) ? objReporteBasico?.InformacionSectorialyDeMercado?.ParticipacionRelativaComuna : null;

      if(objReporteBasico?.InformacionSectorialyDeMercado?.TotalVentasHistoricoUF){
        objeto.InformacionSectorialyDeMercado.TotalVentasHistoricoUF = {};
        objeto.InformacionSectorialyDeMercado.TotalVentasHistoricoUF.ano1 = this.validarValor(objReporteBasico?.InformacionSectorialyDeMercado?.TotalVentasHistoricoUF?.ano1) ? objReporteBasico?.InformacionSectorialyDeMercado?.TotalVentasHistoricoUF?.ano1 : null;
        objeto.InformacionSectorialyDeMercado.TotalVentasHistoricoUF.periodo_1 = this.validarValor(objReporteBasico?.InformacionSectorialyDeMercado?.TotalVentasHistoricoUF?.periodo_1) ? objReporteBasico?.InformacionSectorialyDeMercado?.TotalVentasHistoricoUF?.periodo_1 : null;
        objeto.InformacionSectorialyDeMercado.TotalVentasHistoricoUF.ano2 = this.validarValor(objReporteBasico?.InformacionSectorialyDeMercado?.TotalVentasHistoricoUF?.ano2) ? objReporteBasico?.InformacionSectorialyDeMercado?.TotalVentasHistoricoUF?.ano2 : null;
        objeto.InformacionSectorialyDeMercado.TotalVentasHistoricoUF.periodo_2 = this.validarValor(objReporteBasico?.InformacionSectorialyDeMercado?.TotalVentasHistoricoUF?.periodo_2) ? objReporteBasico?.InformacionSectorialyDeMercado?.TotalVentasHistoricoUF?.periodo_2 : null;
        objeto.InformacionSectorialyDeMercado.TotalVentasHistoricoUF.ano3 = this.validarValor(objReporteBasico?.InformacionSectorialyDeMercado?.TotalVentasHistoricoUF?.ano3) ? objReporteBasico?.InformacionSectorialyDeMercado?.TotalVentasHistoricoUF?.ano3 : null;
        objeto.InformacionSectorialyDeMercado.TotalVentasHistoricoUF.periodo_3 = this.validarValor(objReporteBasico?.InformacionSectorialyDeMercado?.TotalVentasHistoricoUF?.periodo_3) ? objReporteBasico?.InformacionSectorialyDeMercado?.TotalVentasHistoricoUF?.periodo_3 : null;
        objeto.InformacionSectorialyDeMercado.TotalVentasHistoricoUF.ano4 = this.validarValor(objReporteBasico?.InformacionSectorialyDeMercado?.TotalVentasHistoricoUF?.ano4) ? objReporteBasico?.InformacionSectorialyDeMercado?.TotalVentasHistoricoUF?.ano4 : null;
        objeto.InformacionSectorialyDeMercado.TotalVentasHistoricoUF.periodo_4 = this.validarValor(objReporteBasico?.InformacionSectorialyDeMercado?.TotalVentasHistoricoUF?.periodo_4) ? objReporteBasico?.InformacionSectorialyDeMercado?.TotalVentasHistoricoUF?.periodo_4 : null;
        objeto.InformacionSectorialyDeMercado.TotalVentasHistoricoUF.ano5 = this.validarValor(objReporteBasico?.InformacionSectorialyDeMercado?.TotalVentasHistoricoUF?.ano5) ? objReporteBasico?.InformacionSectorialyDeMercado?.TotalVentasHistoricoUF?.ano5 : null;
        objeto.InformacionSectorialyDeMercado.TotalVentasHistoricoUF.periodo_5 = this.validarValor(objReporteBasico?.InformacionSectorialyDeMercado?.TotalVentasHistoricoUF?.periodo_5) ? objReporteBasico?.InformacionSectorialyDeMercado?.TotalVentasHistoricoUF?.periodo_5 : null;
      }

    }


    if(objReporteBasico?.AntecedentesComerciales){
      objeto.AntecedentesComerciales = {};

      objeto.AntecedentesComerciales.ConsultasAlRut = this.validarValor(objReporteBasico?.AntecedentesComerciales?.ConsultasAlRut) ? objReporteBasico?.AntecedentesComerciales?.ConsultasAlRut : null;
      objeto.AntecedentesComerciales.DetalleMorosidadesYProtestos = this.validarValor(objReporteBasico?.AntecedentesComerciales?.DetalleMorosidadesYProtestos) ? objReporteBasico?.AntecedentesComerciales?.DetalleMorosidadesYProtestos : null;
      objeto.AntecedentesComerciales.InformacionBoletinComercialLaboral = this.validarValor(objReporteBasico?.AntecedentesComerciales?.InformacionBoletinComercialLaboral) ? objReporteBasico?.AntecedentesComerciales?.InformacionBoletinComercialLaboral : null;

      if(objReporteBasico?.AntecedentesComerciales?.Resumen){
        objeto.AntecedentesComerciales.Resumen = {};
        objeto.AntecedentesComerciales.Resumen.TotalImpagosCantidad = this.validarValor(objReporteBasico?.AntecedentesComerciales?.Resumen?.TotalImpagosCantidad) ? objReporteBasico?.AntecedentesComerciales?.Resumen?.TotalImpagosCantidad : null;
        objeto.AntecedentesComerciales.Resumen.TotalImpagosMonto = this.validarValor(objReporteBasico?.AntecedentesComerciales?.Resumen?.TotalImpagosMonto) ? objReporteBasico?.AntecedentesComerciales?.Resumen?.TotalImpagosMonto : null;
        objeto.AntecedentesComerciales.Resumen.ProtestosMonto = this.validarValor(objReporteBasico?.AntecedentesComerciales?.Resumen?.ProtestosMonto) ? objReporteBasico?.AntecedentesComerciales?.Resumen?.ProtestosMonto : null;
        objeto.AntecedentesComerciales.Resumen.ProtestoCantidad = this.validarValor(objReporteBasico?.AntecedentesComerciales?.Resumen?.ProtestoCantidad) ? objReporteBasico?.AntecedentesComerciales?.Resumen?.ProtestoCantidad : null;
        objeto.AntecedentesComerciales.Resumen.MorosidadesMonto = this.validarValor(objReporteBasico?.AntecedentesComerciales?.Resumen?.MorosidadesMonto) ? objReporteBasico?.AntecedentesComerciales?.Resumen?.MorosidadesMonto : null;
        objeto.AntecedentesComerciales.Resumen.MorosidadesCantidad = this.validarValor(objReporteBasico?.AntecedentesComerciales?.Resumen?.MorosidadesCantidad) ? objReporteBasico?.AntecedentesComerciales?.Resumen?.MorosidadesCantidad : null;
        objeto.AntecedentesComerciales.Resumen.InfraccionesLaboralesMonto = this.validarValor(objReporteBasico?.AntecedentesComerciales?.Resumen?.InfraccionesLaboralesMonto) ? objReporteBasico?.AntecedentesComerciales?.Resumen?.InfraccionesLaboralesMonto : null;
        objeto.AntecedentesComerciales.Resumen.InfraccionesLaboralesCantidad = this.validarValor(objReporteBasico?.AntecedentesComerciales?.Resumen?.InfraccionesLaboralesCantidad) ? objReporteBasico?.AntecedentesComerciales?.Resumen?.InfraccionesLaboralesCantidad : null;
      }

    }

    return objeto;
  }

  crearObjReporteFinanciero(objReporteFinanciero: any): any {
    const objeto: any = {};

    if(objReporteFinanciero?.CarpetaTributariaElectronicaReporte){
      objeto.CarpetaTributariaElectronicaReporte = {};

      if(objReporteFinanciero?.CarpetaTributariaElectronicaReporte?.DatosBasicosSolicitud){
        objeto.CarpetaTributariaElectronicaReporte.DatosBasicosSolicitud = {};
        objeto.CarpetaTributariaElectronicaReporte.DatosBasicosSolicitud.FechaReporte = objReporteFinanciero?.CarpetaTributariaElectronicaReporte?.DatosBasicosSolicitud?.FechaReporte || null;
        objeto.CarpetaTributariaElectronicaReporte.DatosBasicosSolicitud.Rut = objReporteFinanciero?.CarpetaTributariaElectronicaReporte?.DatosBasicosSolicitud?.Rut || null;
        objeto.CarpetaTributariaElectronicaReporte.DatosBasicosSolicitud.DV = objReporteFinanciero?.CarpetaTributariaElectronicaReporte?.DatosBasicosSolicitud?.DV || null;
      }

      if(objReporteFinanciero?.CarpetaTributariaElectronicaReporte?.Reporte){
        objeto.CarpetaTributariaElectronicaReporte.Reporte = {};
        objeto.CarpetaTributariaElectronicaReporte.Reporte.representantes = objReporteFinanciero?.CarpetaTributariaElectronicaReporte?.Reporte?.representantes || null;
        objeto.CarpetaTributariaElectronicaReporte.Reporte.nombre = objReporteFinanciero?.CarpetaTributariaElectronicaReporte?.Reporte?.nombre || null;
        objeto.CarpetaTributariaElectronicaReporte.Reporte.socios = objReporteFinanciero?.CarpetaTributariaElectronicaReporte?.Reporte?.socios || null;
      }

    }

    return objeto;
  }

  crearObjReporteBoletinConcursal(objReporteBoletinConcursal: any): any {
    const objeto: any = {};

    if(objReporteBoletinConcursal?.boletinConcursalReporte?.principal){
      objeto.boletinConcursalReporte = {};
      objeto.boletinConcursalReporte.principal = this.objectKeys(objReporteBoletinConcursal.boletinConcursalReporte.principal).length;
    }

    return objeto;
  }

  crearConsolidadoTitular(f22: any, f29: any, stage4Response: any): any {
    if(stage4Response){
      const informeComercial = this.identificarInformeComercial(stage4Response?.basicReport?.reporte); 
      stage4Response.poderJudicial = this.mapeoPoderJudicial(stage4Response?.poderJudicial); // genera el this.reportesSeleccionado.poderJudicial.reporteMapeado
      stage4Response = this.validaObjetos(stage4Response); // deja en null los reportes vacios

      const objReporteFinanciero = stage4Response?.cteVentasBalancesRentas?.reporte;
      const objReporteBasico = stage4Response?.basicReport?.reporte;
      const objReporteDeudaSbif = stage4Response?.deudaSbif?.reporte;
      const objReporteJudicial = stage4Response?.poderJudicial?.reporteMapeado;
      const objReporteBoletinConcursal = stage4Response?.boletinConcursal?.reporte;

      const reporteBasico: any = this.getReporteBasico(objReporteBasico);
      const mallaSocietaria: string = this.calcularMallaSocietaria(objReporteFinanciero, objReporteBasico, informeComercial);
      
      let informacionBalance: any = null;
      if (f22.length > 0) {
        informacionBalance = this.setInformacionBalance(f22);
      }

      let objResumenDeudaSbif: any = null;
      if (objReporteDeudaSbif) {
        objResumenDeudaSbif = this.obtenerLastDeudaSbif(objReporteDeudaSbif);
      }
      
      let totalJudicial: any = null;
      if (objReporteJudicial) {
        totalJudicial = this.getTotalJudicial(objReporteJudicial);
      }

      const chart: any = {
        title: "Ventas expresadas en millones de UF",
        type: ChartType.ColumnChart,
        data: reporteBasico?.chartData?.data || [],
        columns: ['Topping', 'Ventas'],
        options: [],
        width: 490,
        height: 250
      }

      return {
        reporteBasico: reporteBasico,
        mallaSocietaria: mallaSocietaria,
        informacionBalance: informacionBalance,
        objResumenDeudaSbif: objResumenDeudaSbif,
        totalJudicial: totalJudicial,
        chart: chart,
        objReporteBasico: this.crearObjReporteBasico(objReporteBasico),
        objReporteFinanciero: this.crearObjReporteFinanciero(objReporteFinanciero),
        objReporteBoletinConcursal: this.crearObjReporteBoletinConcursal(objReporteBoletinConcursal),
        informeComercial: informeComercial
      }
    }
  }

  obtenerServicesReportes(listaReportesPrincipales: any[], listaReportesDerivados: any): any[] {
    const apiServicesReportes: any = [];

    if (listaReportesPrincipales.length > 0) {
      for (const value of listaReportesPrincipales) {
        apiServicesReportes.push(
          {
            principal: true, 
            reportKey: value, 
            entidad: '', 
            rutDerivado: ''
          }
        );
      }
    }
    if(Object.keys(listaReportesDerivados).length > 0){
        for (const [entidad, valorEntidad] of Object.entries(listaReportesDerivados)) {
          if(Object.keys(listaReportesDerivados[entidad]).length > 0){
            for (const [rut, valor] of Object.entries(listaReportesDerivados[entidad])) {
              if(listaReportesDerivados[entidad][rut].length > 0){
                for (const element of listaReportesDerivados[entidad][rut]){
                  apiServicesReportes.push(
                    {
                      principal: false, 
                      reportKey: element, 
                      entidad: entidad, 
                      rutDerivado: rut
                    }
                  );
                }
              }
            }
            
          }
        }
    }
    return apiServicesReportes;
  }

  mapeoReportesPrincipales(reportes: any[]): any {
    const respuesta: any = {};
    reportes.forEach(element => {
      if(element?.response && element?.service?.principal === true){
        respuesta[element.service.reportKey] = element?.response?.Reporte;
      }
    })
    return respuesta;
  }

  mapeoReportesDerivados(reportes: any[]): any {
    const respuesta: any = {};
    reportes.forEach(element => {
      if(element?.response && element?.service?.principal === false){
        if(!respuesta[element.service.entidad]){
          respuesta[element.service.entidad] = {};  
        }

        if(!respuesta[element.service.entidad][element.service.rutDerivado]){
          respuesta[element.service.entidad][element.service.rutDerivado] = {};  
        }

        respuesta[element.service.entidad][element.service.rutDerivado][element.service.reportKey] = element?.response?.Reporte;
      }
    })
    return respuesta;
  }

  calculosVariacion(entrada: any[]): any{
    entrada.sort((a: any,b: any) => a?.calculado?.periodo - b?.calculado?.periodo);
    const entradaFiltrada = entrada.filter(e => e.calculado);
    
    for(let i = 0; i < entradaFiltrada.length; i++){
      if( i > 0 && Object.keys(entradaFiltrada[i].calculado).length > 0){
        const variacionenActivos = entradaFiltrada[i-1].calculado.totalactivos !== 0 ?
          ((entradaFiltrada[i].calculado.totalactivos - entradaFiltrada[i-1].calculado.totalactivos) / entradaFiltrada[i-1].calculado.totalactivos)*100 : 0;
        entradaFiltrada[i].calculado.variacionenActivos = variacionenActivos;
        
        const variacionenPasivoExigible = entradaFiltrada[i-1].calculado.totalpasivoexigible !== 0 ?
          ((entradaFiltrada[i].calculado.totalpasivoexigible - entradaFiltrada[i-1].calculado.totalpasivoexigible) / entradaFiltrada[i-1].calculado.totalpasivoexigible)*100 : 0;
        entradaFiltrada[i].calculado.variacionenPasivoExigible = variacionenPasivoExigible;

        const variacionenVentas = entradaFiltrada[i-1].calculado.ingresosdelaExplotacion !== 0 ?
          ((entradaFiltrada[i].calculado.ingresosdelaExplotacion - entradaFiltrada[i-1].calculado.ingresosdelaExplotacion) / entradaFiltrada[i-1].calculado.ingresosdelaExplotacion)*100 : 0;
        entradaFiltrada[i].calculado.variacionenVentas = variacionenVentas;

        const variacionenPatrimonio = entradaFiltrada[i-1].calculado.totalpatrimonio !== 0 ?
          ((entradaFiltrada[i].calculado.totalpatrimonio - entradaFiltrada[i-1].calculado.totalpatrimonio) / entradaFiltrada[i-1].calculado.totalpatrimonio)*100 : 0;
        entradaFiltrada[i].calculado.variacionenPatrimonio = variacionenPatrimonio;

      }
    }
    return entradaFiltrada.reverse();

  }

  obtenerPeriodos(entrada: any[]): any[]{
    const arrayAllPeriodos: any[] = [];

    entrada.forEach(element => {
      arrayAllPeriodos.push(element.calculado.periodo);
    });
    
    return this.eliminaPeriodosDuplicados(arrayAllPeriodos);
  }

  eliminaPeriodosDuplicados(array : any[]): any[]{
    const filteredArray = array.filter(function(ele , pos){
      return array.indexOf(ele) == pos;
    })
    filteredArray.sort().reverse();
    return filteredArray;
  }

  obtenerReporteFiltrado(responseApi: any[], periodos: string[]): any[] {
    const reporteFinal : any[] = [];
    periodos.forEach(elementPeriodo => {
      responseApi.forEach(elementResponse => {
        if(elementPeriodo.toString() === elementResponse.calculado.periodo.toString() || periodos.length === 0){
          reporteFinal.push(elementResponse);
        }
      });
    });
    return reporteFinal;
  }

  // Funcion para obtener nombre de mes abreviado a partir de su numero

  obtenerMesAbreviado(numero : number): string{
    const meses = ["en", "feb", "mar", "abr", "may", "jun", "jul", "ag", "sept", "oct", "nov", "dic"];
    if(numero && !isNaN(numero) && numero >= 1  && numero <= 12 ) {
      return meses[numero - 1];
    } else {
      return 'dic';
    }

  }

  // Funcion para obtener nombre de mes a partir de su numero

  obtenerMes(numero : number): string{
    const meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
    if(numero && !isNaN(numero) && numero >= 1  && numero <= 12 ) {
      return meses[numero - 1];
    } else {
      return '-';
    }
  }

  obtenerNumeroDelMes(nombreMes: string): number {
    const meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
    const indice = meses.indexOf(nombreMes);

    if (indice !== -1) {
      return indice + 1;
    } else {
      return -1;
    }
  }

  obtenerEnrolado(contratoServicios: any[]): string{
    let respuesta: string = '-';
    const indexLibroCompraVenta = contratoServicios.findIndex((elemento: any) => elemento.key === 'libroCompraVenta');

    if(indexLibroCompraVenta >= 0){
      const values = contratoServicios[indexLibroCompraVenta].values;
      if(values.length > 0){
        const indexCrear = contratoServicios[indexLibroCompraVenta]?.values.findIndex((elemento: any) => elemento.type === 'crear');
        if(indexCrear >= 0){
          const status = contratoServicios[indexLibroCompraVenta]?.values[indexCrear]?.infoVariables?.passSyncStatus[0]?.status;
          if(status === true){
            respuesta = 'Si';
          } else if (status === false){
            respuesta = 'No';
          } else {
            respuesta = '-';
          }
        } else {
          respuesta = '-';
        }

      }
    }
    return respuesta
  }

  transformacionRangoSii(entrada: string): string {
    if(entrada === "Sin ventas")
      return "1";
    if(entrada === "MICRO 1")
      return "2";
    if(entrada === "MICRO 2")
      return "3";
    if(entrada === "MICRO 3")
      return "4";
    if(entrada === "PEQUEÑA 1")
      return "5";
    if(entrada === "PEQUEÑA 2")
      return "6";
    if(entrada === "PEQUEÑA 3")
      return "7";
    if(entrada === "MEDIANA 1")
      return "8";
    if(entrada === "MEDIANA 2")
      return "9";
    if(entrada === "GRANDE 1")
      return "10";
    if(entrada === "GRANDE 2")
      return "11";
    if(entrada === "GRANDE 3")
      return "12";
    if(entrada === "GRANDE 4")
      return "13";

    return "";
  }

  isFloat(n: any){
    return Number(n) === n && n % 1 !== 0;
  }

  // Array informacionFinancieraTable y totalesInformacionFinanciera para tabla Informacion financiera 

  crearInformacionFinancieraTable(anioActual: number, anioAnterior: number, listaF29: any[]): any {
    const totalesInformacionFinanciera: any = {};
    let informacionFinancieraTable: any = {};

    if(anioActual || anioAnterior){
      const objTemp: any[] = [];
      let objTempActual: any[] = [];
      let objTempAnterior: any[] = [];

      if (anioAnterior || anioActual) {
        listaF29
          .map((item: any) => {
            if (anioAnterior && (String(anioAnterior) === (String(item?.calculado?.periodo)))) {
              objTempAnterior.push({
                mes: this.obtenerMes(Number(item?.calculado?.mes)),
                date: item?.calculado?.periodo + '-' + ((item?.calculado?.mes) < 10 ? '0' + (item?.calculado?.mes) : (item?.calculado?.mes)) + '-' + '02',
                ventas: item.calculado?.ventasNetasMilesPesos || item.calculado?.ventasNetasMilesPesos == 0 ? item.calculado?.ventasNetasMilesPesos : '',
                compras: item.calculado?.comprasNetasMilesPesos || item.calculado?.comprasNetasMilesPesos == 0 ? item.calculado?.comprasNetasMilesPesos : '',
                diferencia: item.calculado?.margenMiles || item.calculado?.margenMiles ? item.calculado?.margenMiles : '',
                fechaPago: item.calculado?.fechaPago || '',
                tipoDeclaracion: item.calculado?.tipoDeclaracion || '',
                posterga: item.calculado?.postergacionIVA || item.calculado?.postergacionIVA == 0 ? item.calculado?.postergacionIVA : '',
                autoGenerado: false,
              });
            } else if (anioActual && (String(anioActual) === (String(item?.calculado?.periodo)))) {
              objTempActual.push({
                mes: this.obtenerMes(Number(item?.calculado?.mes)),
                date: item?.calculado?.periodo + '-' + ((item?.calculado?.mes) < 10 ? '0' + (item?.calculado?.mes) : (item?.calculado?.mes)) + '-' + '02',
                ventas: item.calculado?.ventasNetasMilesPesos || item.calculado?.ventasNetasMilesPesos == 0 ? item.calculado?.ventasNetasMilesPesos : '',
                compras: item.calculado?.comprasNetasMilesPesos || item.calculado?.comprasNetasMilesPesos == 0 ? item.calculado?.comprasNetasMilesPesos : '',
                diferencia: item.calculado?.margenMiles || item.calculado?.margenMiles == 0 ? item.calculado?.margenMiles : '',
                fechaPago: item.calculado?.fechaPago || '',
                tipoDeclaracion: item.calculado?.tipoDeclaracion || '',
                posterga: item.calculado?.postergacionIVA || item.calculado?.postergacionIVA == 0 ? item.calculado?.postergacionIVA : '',
                autoGenerado: false,
              });
            }
          });
        
          if(anioAnterior){
            for (let i = 1; i <= 12; i++) {
              if (objTempAnterior.find((e: any) => e.date === anioAnterior + '-' + ((i) < 10 ? '0' + (i) : (i)) + '-' + '02') === undefined) {
                objTempAnterior.push({
                  mes: this.obtenerMes(Number(i)),
                  date: anioAnterior + '-' + ((i) < 10 ? '0' + (i) : (i)) + '-' + '02',
                  ventas: '',
                  compras: '',
                  diferencia: '',
                  fechaPago: '',
                  tipoDeclaracion: '',
                  posterga: '',
                  autoGenerado: true,
                });
              }
            }
          }

          if(anioActual){
            for (let i = 1; i <= 12; i++) {
              if (objTempActual.find((e: any) => e.date === anioActual + '-' + ((i) < 10 ? '0' + (i) : (i)) + '-' + '02') === undefined) {
                objTempActual.push({
                  mes: this.obtenerMes(Number(i)),
                  date: anioActual + '-' + ((i) < 10 ? '0' + (i) : (i)) + '-' + '02',
                  ventas: '',
                  compras: '',
                  diferencia: '',
                  fechaPago: '',
                  tipoDeclaracion: '',
                  posterga: '',
                  autoGenerado: true,
                });
              }
            }
          }

          const totalesAnterior: TotalesCategoriasString = {
            anio: anioAnterior,
              TotalSem1: {
              ventas: '',
              compras: '',
              dif: '',
              posterga: '',
              margen: '',
            },
              TotalSem2: {
              ventas: '',
              compras: '',
              dif: '',
              posterga: '',
              margen: '',
            },
              TotalAnio: {
              ventas: '',
              compras: '',
              dif: '',
              posterga: '',
              meses: 0,
              margen: '',
            },
              TotalProm: {
              ventas: '',
              compras: '',
              dif: '',
              posterga: '',
              margen: '',
            },
          };
          const totalesActual: TotalesCategoriasString = {
            anio: anioActual,
              TotalSem1: {
              ventas: '',
              compras: '',
              dif: '',
              posterga: '',
              margen: '',
            },
              TotalSem2: {
              ventas: '',
              compras: '',
              dif: '',
              posterga: '',
              margen: '',
            },
              TotalAnio: {
              ventas: '',
              compras: '',
              dif: '',
              posterga: '',
              meses: 0,
              margen: '',
            },
              TotalProm: {
              ventas: '',
              compras: '',
              dif: '',
              posterga: '',
              margen: '',
            },
          };

          objTempAnterior = objTempAnterior.sort((a, b) => (a.date > b.date ? 1 : -1));
          objTempActual = objTempActual.sort((a, b) => (a.date > b.date ? 1 : -1));

          objTempAnterior.forEach((element: any, index: number) => {
            if(index <= 5){
              (element?.ventas && element?.ventas != '') ? (totalesAnterior.TotalSem1.ventas = (Number(totalesAnterior.TotalSem1.ventas) + (Number(element?.ventas) || 0))) : null;
              (element?.compras && element?.compras != '') ? (totalesAnterior.TotalSem1.compras = (Number(totalesAnterior.TotalSem1.compras) + (Number(element?.compras) || 0))) : null;
              (element?.diferencia && element?.diferencia != '') ? (totalesAnterior.TotalSem1.dif = (Number(totalesAnterior.TotalSem1.dif) + (Number(element?.diferencia) || 0))) : null;
              (element?.posterga && element?.posterga != '') ? (totalesAnterior.TotalSem1.posterga = (Number(totalesAnterior.TotalSem1.posterga) + (Number(element?.posterga) || 0))) : null;
            }
            if(index > 5){
              (element?.ventas && element?.ventas != '') ? (totalesAnterior.TotalSem2.ventas = (Number(totalesAnterior.TotalSem2.ventas) + (Number(element?.ventas) || 0))) : null;
              (element?.compras && element?.compras != '') ? (totalesAnterior.TotalSem2.compras = (Number(totalesAnterior.TotalSem2.compras) + (Number(element?.compras) || 0))) : null;
              (element?.diferencia && element?.diferencia != '') ? (totalesAnterior.TotalSem2.dif = (Number(totalesAnterior.TotalSem2.dif) + (Number(element?.diferencia) || 0))) : null;
              (element?.posterga && element?.posterga != '') ? (totalesAnterior.TotalSem2.posterga = (Number(totalesAnterior.TotalSem2.posterga) + (Number(element?.posterga) || 0))) : null;
            }
            (element?.ventas && element?.ventas != '') ? (totalesAnterior.TotalAnio.ventas = (Number(totalesAnterior.TotalAnio.ventas) + (Number(element?.ventas) || 0))) : null;
            (element?.compras && element?.compras != '') ? (totalesAnterior.TotalAnio.compras = (Number(totalesAnterior.TotalAnio.compras) + (Number(element?.compras) || 0))) : null;
            (element?.diferencia && element?.diferencia != '') ? (totalesAnterior.TotalAnio.dif = (Number(totalesAnterior.TotalAnio.dif) + (Number(element?.diferencia) || 0))) : null;
            (element?.posterga && element?.posterga != '') ? (totalesAnterior.TotalAnio.posterga  = (Number(totalesAnterior.TotalAnio.posterga ) + (Number(element?.posterga) || 0))) : null;
            if(totalesAnterior.TotalAnio.meses != undefined && element.autoGenerado == false)
              totalesAnterior.TotalAnio.meses += 1
          });

          objTempActual.forEach((element: any, index: number) => {
            if(index <= 5){
              (element?.ventas && element?.ventas != '') ? totalesActual.TotalSem1.ventas = (Number(totalesActual.TotalSem1.ventas) + (Number(element?.ventas) || 0)) : null;
              (element?.compras && element?.compras != '') ? totalesActual.TotalSem1.compras = (Number(totalesActual.TotalSem1.compras) + (Number(element?.compras) || 0)) : null;
              (element?.diferencia && element?.diferencia != '') ? totalesActual.TotalSem1.dif = (Number(totalesActual.TotalSem1.dif) + (Number(element?.diferencia) || 0)) : null;
              (element?.posterga && element?.posterga != '') ? totalesActual.TotalSem1.posterga  = (Number(totalesActual.TotalSem1.posterga ) + (Number(element?.posterga) || 0)) : null;
            }
            if(index > 5){
              (element?.ventas && element?.ventas != '') ? totalesActual.TotalSem2.ventas = (Number(totalesActual.TotalSem2.ventas) + (Number(element?.ventas) || 0)) : null;
              (element?.compras && element?.compras != '') ? totalesActual.TotalSem2.compras = (Number(totalesActual.TotalSem2.compras) + (Number(element?.compras) || 0)) : null;
              (element?.diferencia && element?.diferencia != '') ? totalesActual.TotalSem2.dif = (Number(totalesActual.TotalSem2.dif) + (Number(element?.diferencia) || 0)) : null;
              (element?.posterga && element?.posterga != '') ? totalesActual.TotalSem2.posterga  = (Number(totalesActual.TotalSem2.posterga ) + (Number(element?.posterga) || 0)) : null;
            }
            (element?.ventas && element?.ventas != '') ? totalesActual.TotalAnio.ventas = (Number(totalesActual.TotalAnio.ventas) + (Number(element?.ventas) || 0)) : null;
            (element?.compras && element?.compras != '') ? totalesActual.TotalAnio.compras = (Number(totalesActual.TotalAnio.compras) + (Number(element?.compras) || 0)) : null;
            (element?.diferencia && element?.diferencia != '') ? totalesActual.TotalAnio.dif = (Number(totalesActual.TotalAnio.dif) + (Number(element?.diferencia) || 0)) : null;
            (element?.posterga && element?.posterga != '') ? totalesActual.TotalAnio.posterga  = (Number(totalesActual.TotalAnio.posterga ) + (Number(element?.posterga) || 0)) : null;
            if(totalesActual.TotalAnio.meses != undefined && element.autoGenerado == false)
              totalesActual.TotalAnio.meses += 1
          });

          if(totalesAnterior.TotalAnio.meses){
            totalesAnterior.TotalProm.ventas = totalesAnterior.TotalAnio.ventas || totalesAnterior.TotalAnio.ventas == 0 ? Math.round((Number(totalesAnterior.TotalAnio.ventas)/totalesAnterior.TotalAnio.meses)) : ''
            totalesAnterior.TotalProm.compras = totalesAnterior.TotalAnio.compras || totalesAnterior.TotalAnio.compras == 0 ? Math.round((Number(totalesAnterior.TotalAnio.compras)/totalesAnterior.TotalAnio.meses)) : ''
            totalesAnterior.TotalProm.dif = totalesAnterior.TotalAnio.dif || totalesAnterior.TotalAnio.dif == 0 ? Math.round((Number(totalesAnterior.TotalAnio.dif)/totalesAnterior.TotalAnio.meses)) : ''
            totalesAnterior.TotalProm.posterga = totalesAnterior.TotalAnio.posterga || totalesAnterior.TotalAnio.posterga == 0 ? Math.round((Number(totalesAnterior.TotalAnio.posterga)/totalesAnterior.TotalAnio.meses)) : ''
            totalesAnterior.TotalProm.margen = 
              (totalesAnterior.TotalAnio.ventas || totalesAnterior.TotalAnio.ventas == 0) && (totalesAnterior.TotalAnio.dif || totalesAnterior.TotalAnio.dif == 0) ? 
              (
                Number(totalesAnterior.TotalAnio.ventas) > 0 ? (Number(totalesAnterior.TotalAnio.dif)/Number(totalesAnterior.TotalAnio.ventas) * 100) : 0
              ) : ''
          }

          if(totalesActual.TotalAnio.meses){
            totalesActual.TotalProm.ventas = totalesActual.TotalAnio.ventas || totalesActual.TotalAnio.ventas == 0 ? Math.round((Number(totalesActual.TotalAnio.ventas)/totalesActual.TotalAnio.meses)) : ''
            totalesActual.TotalProm.compras = totalesActual.TotalAnio.compras || totalesActual.TotalAnio.compras == 0 ? Math.round((Number(totalesActual.TotalAnio.compras)/totalesActual.TotalAnio.meses)) : ''
            totalesActual.TotalProm.dif = totalesActual.TotalAnio.dif || totalesActual.TotalAnio.dif == 0 ? Math.round((Number(totalesActual.TotalAnio.dif)/totalesActual.TotalAnio.meses)) : ''
            totalesActual.TotalProm.posterga = totalesActual.TotalAnio.posterga || totalesActual.TotalAnio.posterga == 0 ? Math.round((Number(totalesActual.TotalAnio.posterga)/totalesActual.TotalAnio.meses)) : ''
            totalesActual.TotalProm.margen = 
              (totalesActual.TotalAnio.ventas || totalesActual.TotalAnio.ventas == 0) && (totalesActual.TotalAnio.dif || totalesActual.TotalAnio.dif == 0) ? 
              (
                Number(totalesActual.TotalAnio.ventas) > 0 ? (Number(totalesActual.TotalAnio.dif)/Number(totalesActual.TotalAnio.ventas) * 100) : 0
              ) : ''
          }

        totalesInformacionFinanciera.totalAnterior = totalesAnterior;
        totalesInformacionFinanciera.totalActual = totalesActual;

        for (let i = 0; i <= 11; i++) {
          objTemp.push({
            anterior: objTempAnterior[i] ? objTempAnterior[i] : null,
            actual: objTempActual[i] ? objTempActual[i]: null,
          })
        }

        informacionFinancieraTable = objTemp;
      }
    }
    
    return {
      totalesInformacionFinanciera: totalesInformacionFinanciera,
      informacionFinancieraTable: informacionFinancieraTable
    }
  }

  validarClaves(obj: any, claves: string[]) {
    if(obj && Object.keys(obj).length > 0){
      for (const clave of claves) {
        if (!obj.hasOwnProperty(clave)) {
          return false;
        }
  
        let valor = obj[clave];
  
        if (typeof valor === "string") {
          valor = parseFloat(valor);
          if (isNaN(valor)) {
            return false;
          }
        } else if (typeof valor !== "number") {
          return false;
        }
      }
  
      return true;
    }

    return false;
  }

  validaMesAnioActual(mes1: any, anio: any): boolean {
    if(!mes1 || !anio){
      return false;
    }
    const mesActual = new Date().getMonth() + 1;
    const anioActual = new Date().getFullYear();

    if(Number(anio) > anioActual || (Number(anio) == anioActual && Number(mes1) >= mesActual)){
      return false;
    }

    return true;
  }

  obtenerDatosCompraVenta(compraVenta: any[], anio: number, mes: number): any {    
    const notasCredito: string[] = ['60','61','112'];
    const elemento: any = compraVenta.find(e => e.periodo == anio && e.mes == mes && this.validaMesAnioActual(e.mes, e.periodo));
    let montoTotalVentaMes: string | number = '';
    let montoTotalCompraMes: string | number = '';
    let montoTotalDiferenciaMes: string | number = '';
    const clavesTotales: string[] = [
      "montoTotalCompra",
      "montoIvaRCompra",
      "montoIvaNoRCompra",
      "montoNotasCompra",
      "montoTotalVenta",
      "montoIvaVenta",
      "montoNotasVenta"
    ];

    if(this.validarClaves(elemento?.totalizadores, clavesTotales)) { // valida totalizadores si tiene los campos

      montoTotalVentaMes = (Number(elemento?.totalizadores?.montoTotalVenta) - Number(elemento?.totalizadores?.montoIvaVenta) - Number(elemento?.totalizadores?.montoNotasVenta)) / 1000;
      montoTotalCompraMes = (Number(elemento?.totalizadores?.montoTotalCompra) - Number(elemento?.totalizadores?.montoIvaRCompra) - Number(elemento?.totalizadores?.montoIvaNoRCompra) - Number(elemento?.totalizadores?.montoNotasCompra)) / 1000;
      montoTotalDiferenciaMes = Number(montoTotalVentaMes) - Number(montoTotalCompraMes);

    } else {
      if(elemento && elemento?.registros?.venta && elemento?.registros?.venta.length > 0) {
        let montoTotalVenta = 0;
        let montoIvaVenta = 0;
        let montoNotasVenta = 0;
        elemento?.registros?.venta.forEach((element: any) => {
          montoIvaVenta += Number(element?.MontoIVA) || 0;
          if(!notasCredito.includes(element?.TipoDoc)){
            montoTotalVenta += Number(element?.Montototal) || 0;
          } else {
            montoNotasVenta += Number(element?.Montototal) || 0;
          }
        });
        montoTotalVentaMes = (montoTotalVenta - montoIvaVenta - montoNotasVenta) / 1000;
      }
      if(elemento && elemento?.registros?.compra && elemento?.registros?.compra.length > 0) {
        let montoTotalCompra = 0;
        let montoIvaRCompra = 0;
        let montoIvaNoRCompra = 0;
        let montoNotasCompra = 0;
        elemento?.registros?.compra.forEach((element: any) => {
          montoIvaRCompra += Number(element?.MontoIVARecuperable) || 0;
          montoIvaNoRCompra += Number(element?.MontoIvaNoRecuperable) || 0;
          if(!notasCredito.includes(element?.TipoDoc)){
            montoTotalCompra += Number(element?.MontoTotal) || 0;
          } else {
            montoNotasCompra += Number(element?.MontoTotal) || 0;
          }
        });
        montoTotalCompraMes = (montoTotalCompra - montoIvaRCompra - montoIvaNoRCompra - montoNotasCompra) / 1000;
      }
    }
    

    if(montoTotalVentaMes != '' || montoTotalCompraMes != ''){
      montoTotalDiferenciaMes = Number(montoTotalVentaMes) - Number(montoTotalCompraMes);
    }
    
    return {
      montoTotalVentaMes: montoTotalVentaMes,
      montoTotalCompraMes: montoTotalCompraMes,
      montoTotalDiferenciaMes: montoTotalDiferenciaMes
    };
  }

  crearInformacionFinancieraTableCapex(anioActual: number, anioAnterior: number, listaF29: any[], compraVenta: any[]): any {
    const totalesInformacionFinanciera: any = {};
    let informacionFinancieraTable: any = {};

    if(anioActual || anioAnterior){
      const objTemp: any[] = [];
      let objTempActual: any[] = [];
      let objTempAnterior: any[] = [];

      if (anioAnterior || anioActual) {
        listaF29
          .map((item: any) => {
            if (anioAnterior && (String(anioAnterior) === (String(item?.calculado?.periodo)))) {
              objTempAnterior.push({
                mes: this.obtenerMes(Number(item?.calculado?.mes)),
                date: item?.calculado?.periodo + '-' + ((item?.calculado?.mes) < 10 ? '0' + (item?.calculado?.mes) : (item?.calculado?.mes)) + '-' + '02',
                ventas: (item.calculado?.ventasNetasMilesPesos || item.calculado?.ventasNetasMilesPesos == 0) ? (item.calculado?.ventasNetasMilesPesos) : '',
                compras: (item.calculado?.comprasNetasMilesPesos || item.calculado?.comprasNetasMilesPesos == 0) ? (item.calculado?.comprasNetasMilesPesos) : '',
                diferencia: (item.calculado?.margenMiles || item.calculado?.margenMiles == 0) ? (item.calculado?.margenMiles) : '',
                fechaPago: item.calculado?.fechaPago || '',
                tipoDeclaracion: item.calculado?.tipoDeclaracion || '',
                posterga: (item.calculado?.postergacionIVA || item.calculado?.postergacionIVA == 0) ? item.calculado?.postergacionIVA : '',
                autoGenerado: false,
                lcv: false,
              });
            } else if (anioActual && (String(anioActual) === (String(item?.calculado?.periodo)))) {
              objTempActual.push({
                mes: this.obtenerMes(Number(item?.calculado?.mes)),
                date: item?.calculado?.periodo + '-' + ((item?.calculado?.mes) < 10 ? '0' + (item?.calculado?.mes) : (item?.calculado?.mes)) + '-' + '02',
                ventas: (item.calculado?.ventasNetasMilesPesos || item.calculado?.ventasNetasMilesPesos == 0) ? item.calculado?.ventasNetasMilesPesos : '',
                compras: (item.calculado?.comprasNetasMilesPesos || item.calculado?.comprasNetasMilesPesos == 0) ? item.calculado?.comprasNetasMilesPesos : '',
                diferencia: (item.calculado?.margenMiles || item.calculado?.margenMiles == 0) ? item.calculado?.margenMiles : '',
                fechaPago: item.calculado?.fechaPago || '',
                tipoDeclaracion: item.calculado?.tipoDeclaracion || '',
                posterga: (item.calculado?.postergacionIVA || item.calculado?.postergacionIVA == 0) ? item.calculado?.postergacionIVA : '',
                autoGenerado: false,
                lcv: false,
              });
            }
          });
        
          if(anioAnterior){
            for (let i = 1; i <= 12; i++) {
              if (objTempAnterior.find((e: any) => e.date === anioAnterior + '-' + ((i) < 10 ? '0' + (i) : (i)) + '-' + '02') === undefined) {
                objTempAnterior.push({
                  mes: this.obtenerMes(Number(i)),
                  date: anioAnterior + '-' + ((i) < 10 ? '0' + (i) : (i)) + '-' + '02',
                  ventas: '',
                  compras: '',
                  diferencia: '',
                  fechaPago: '',
                  tipoDeclaracion: '',
                  posterga: '',
                  autoGenerado: true,
                  lcv: false,
                });
              }
            }
          }

          if(anioActual){
            for (let i = 1; i <= 12; i++) {
              if (objTempActual.find((e: any) => e.date === anioActual + '-' + ((i) < 10 ? '0' + (i) : (i)) + '-' + '02') === undefined) {
                objTempActual.push({
                  mes: this.obtenerMes(Number(i)),
                  date: anioActual + '-' + ((i) < 10 ? '0' + (i) : (i)) + '-' + '02',
                  ventas: '',
                  compras: '',
                  diferencia: '',
                  fechaPago: '',
                  tipoDeclaracion: '',
                  posterga: '',
                  autoGenerado: true,
                  lcv: false,
                });
              }
            }
          }

          // VALIDACION DE DATOS LCV

          if(anioAnterior) {
            for (let i = 1; i <= 12; i++) {
              const elementConDatos = objTempAnterior.findIndex(e => (e.mes === this.obtenerMes(Number(i))) && (e.ventas === '' && e.compras === '' && e.diferencia === ''));
              if(elementConDatos >= 0){
                const { montoTotalVentaMes, montoTotalCompraMes, montoTotalDiferenciaMes } = this.obtenerDatosCompraVenta(compraVenta, anioAnterior, this.obtenerNumeroDelMes(objTempAnterior[elementConDatos].mes));
                objTempAnterior[elementConDatos].ventas = montoTotalVentaMes;
                objTempAnterior[elementConDatos].compras = montoTotalCompraMes;
                objTempAnterior[elementConDatos].diferencia = montoTotalDiferenciaMes;
                objTempAnterior[elementConDatos].lcv = montoTotalVentaMes != '' || montoTotalCompraMes != '' || montoTotalDiferenciaMes != '' ? true : false;
              }
            }
          }

          if(anioActual) {
            for (let i = 1; i <= 12; i++) {
              const elementConDatos = objTempActual.findIndex(e => (e.mes === this.obtenerMes(Number(i))) && (e.ventas === '' && e.compras === '' && e.diferencia === ''));
              if(elementConDatos >= 0){
                const { montoTotalVentaMes, montoTotalCompraMes, montoTotalDiferenciaMes } = this.obtenerDatosCompraVenta(compraVenta, anioActual, this.obtenerNumeroDelMes(objTempActual[elementConDatos].mes));
                objTempActual[elementConDatos].ventas = montoTotalVentaMes;
                objTempActual[elementConDatos].compras = montoTotalCompraMes;
                objTempActual[elementConDatos].diferencia = montoTotalDiferenciaMes;
                objTempActual[elementConDatos].lcv = montoTotalVentaMes != '' || montoTotalCompraMes != '' || montoTotalDiferenciaMes != '' ? true : false;
              }
            }
          }

          // FIN VALIDACION DE DATOS LCV

          const totalesAnterior: TotalesCategorias = {
            anio: anioAnterior,
              TotalSem1: {
              ventas: 0,
              compras: 0,
              dif: 0,
              posterga: 0,
              margen: 0,
            },
              TotalSem2: {
              ventas: 0,
              compras: 0,
              dif: 0,
              posterga: 0,
              margen: 0,
            },
              TotalAnio: {
              ventas: 0,
              compras: 0,
              dif: 0,
              posterga: 0,
              meses: 0,
              mesesPosterga: 0,
              margen: 0,
            },
              TotalProm: {
              ventas: 0,
              compras: 0,
              dif: 0,
              posterga: 0,
              margen: 0,
            },
          };
          const totalesActual: TotalesCategorias = {
            anio: anioActual,
              TotalSem1: {
              ventas: 0,
              compras: 0,
              dif: 0,
              posterga: 0,
              margen: 0,
            },
              TotalSem2: {
              ventas: 0,
              compras: 0,
              dif: 0,
              posterga: 0,
              margen: 0,
            },
              TotalAnio: {
              ventas: 0,
              compras: 0,
              dif: 0,
              posterga: 0,
              meses: 0,
              mesesPosterga: 0,
              margen: 0,
            },
              TotalProm: {
              ventas: 0,
              compras: 0,
              dif: 0,
              posterga: 0,
              margen: 0,
            },
          };

          // calculo de totales

          objTempAnterior = objTempAnterior.sort((a, b) => (a.date > b.date ? 1 : -1));
          objTempActual = objTempActual.sort((a, b) => (a.date > b.date ? 1 : -1));

          objTempAnterior.forEach((element: any, index: number) => {
            if(index <= 5){
              totalesAnterior.TotalSem1.ventas += (Number(element?.ventas) || 0)
              totalesAnterior.TotalSem1.compras += (Number(element?.compras) || 0)
              totalesAnterior.TotalSem1.dif += (Number(element?.diferencia) || 0)
              totalesAnterior.TotalSem1.posterga += (Number(element?.posterga) || 0)
            }
            if(index > 5){
              totalesAnterior.TotalSem2.ventas += (Number(element?.ventas) || 0)
              totalesAnterior.TotalSem2.compras += (Number(element?.compras) || 0)
              totalesAnterior.TotalSem2.dif += (Number(element?.diferencia) || 0)
              totalesAnterior.TotalSem2.posterga += (Number(element?.posterga) || 0)
            }
            totalesAnterior.TotalAnio.ventas += (Number(element?.ventas) || 0)
            totalesAnterior.TotalAnio.compras += (Number(element?.compras) || 0)
            totalesAnterior.TotalAnio.dif += (Number(element?.diferencia) || 0)
            totalesAnterior.TotalAnio.posterga += (Number(element?.posterga) || 0)
            if(totalesAnterior.TotalAnio.mesesPosterga != undefined && element.autoGenerado == false)
              totalesAnterior.TotalAnio.mesesPosterga += 1
            if(totalesAnterior.TotalAnio.meses != undefined && (element.autoGenerado == false || element.lcv == true))
              totalesAnterior.TotalAnio.meses += 1
          });

          objTempActual.forEach((element: any, index: number) => {
            if(index <= 5){
              totalesActual.TotalSem1.ventas += (Number(element?.ventas) || 0)
              totalesActual.TotalSem1.compras += (Number(element?.compras) || 0)
              totalesActual.TotalSem1.dif += (Number(element?.diferencia) || 0)
              totalesActual.TotalSem1.posterga += (Number(element?.posterga) || 0)
            }
            if(index > 5){
              totalesActual.TotalSem2.ventas += (Number(element?.ventas) || 0)
              totalesActual.TotalSem2.compras += (Number(element?.compras) || 0)
              totalesActual.TotalSem2.dif += (Number(element?.diferencia) || 0)
              totalesActual.TotalSem2.posterga += (Number(element?.posterga) || 0)
            }
            totalesActual.TotalAnio.ventas += (Number(element?.ventas) || 0)
            totalesActual.TotalAnio.compras += (Number(element?.compras) || 0)
            totalesActual.TotalAnio.dif += (Number(element?.diferencia) || 0)
            totalesActual.TotalAnio.posterga += (Number(element?.posterga) || 0)
            if(totalesActual.TotalAnio.mesesPosterga != undefined && element.autoGenerado == false)
              totalesActual.TotalAnio.mesesPosterga += 1
            if(totalesActual.TotalAnio.meses != undefined && (element.autoGenerado == false || element.lcv == true))
            totalesActual.TotalAnio.meses += 1
          });

          if(totalesAnterior.TotalAnio.meses){
            totalesAnterior.TotalProm.ventas = Math.round((totalesAnterior.TotalAnio.ventas/totalesAnterior.TotalAnio.meses))
            totalesAnterior.TotalProm.compras = Math.round((totalesAnterior.TotalAnio.compras/totalesAnterior.TotalAnio.meses))
            totalesAnterior.TotalProm.dif = Math.round((totalesAnterior.TotalAnio.dif/totalesAnterior.TotalAnio.meses))
            totalesAnterior.TotalProm.margen = totalesAnterior.TotalAnio.ventas > 0 ? (totalesAnterior.TotalAnio.dif/totalesAnterior.TotalAnio.ventas * 100) : 0
          }
          
          if(totalesAnterior.TotalAnio.mesesPosterga){
            totalesAnterior.TotalProm.posterga = Math.round((totalesAnterior.TotalAnio.posterga/totalesAnterior.TotalAnio.mesesPosterga))
          }

          if(totalesActual.TotalAnio.meses){
            totalesActual.TotalProm.ventas = Math.round((totalesActual.TotalAnio.ventas/totalesActual.TotalAnio.meses))
            totalesActual.TotalProm.compras = Math.round((totalesActual.TotalAnio.compras/totalesActual.TotalAnio.meses))
            totalesActual.TotalProm.dif = Math.round((totalesActual.TotalAnio.dif/totalesActual.TotalAnio.meses))
            totalesActual.TotalProm.margen = totalesActual.TotalAnio.ventas > 0 ? (totalesActual.TotalAnio.dif/totalesActual.TotalAnio.ventas * 100) : 0
          }
          
          if(totalesActual.TotalAnio.mesesPosterga){
            totalesActual.TotalProm.posterga = Math.round((totalesActual.TotalAnio.posterga/totalesActual.TotalAnio.mesesPosterga))
          }

        totalesInformacionFinanciera.totalAnterior = totalesAnterior;
        totalesInformacionFinanciera.totalActual = totalesActual;

        for (let i = 0; i <= 11; i++) {
          objTemp.push({
            anterior: objTempAnterior[i] ? objTempAnterior[i] : null,
            actual: objTempActual[i] ? objTempActual[i]: null,
          })
        }

        informacionFinancieraTable = objTemp;
      }
    }
    
    return {
      totalesInformacionFinanciera: totalesInformacionFinanciera,
      informacionFinancieraTable: informacionFinancieraTable
    }
  }

  // Objeto para primera tabla de informacion financiera

  crearInformacionFinancieraCabecera(anioActual: number, anioAnterior: number, anioActualVentas: number, anioAnteriorVentas: number, f22Actual: any, totalesInformacionFinanciera: any, basicReport: any): any {
    return {
      anioActual: anioActualVentas || '',
      anioAnterior: anioAnteriorVentas|| '',
      anioActualAbreviado: anioActual || '',
      dic: this.obtenerMesAbreviado(f22Actual?.calculado?.nMeses) + '-' +  
        (!isNaN(anioActual) ? anioActual.toString().substr(-2) : '') || '',
      patrimonio: f22Actual?.calculado?.totalpatrimonio || '',
      leverage: f22Actual?.calculado?.leverage || '',
      utilidad: f22Actual?.calculado?.utilidadneta || '',
      promVentasAnterior: totalesInformacionFinanciera?.totalAnterior?.TotalProm?.ventas || '',
      promVentasActual: totalesInformacionFinanciera?.totalActual?.TotalProm?.ventas || '',
      totalImpagoCant: basicReport?.reporte?.AntecedentesComerciales?.Resumen?.TotalImpagosCantidad || '',
      totalImpagoMonto: basicReport?.reporte?.AntecedentesComerciales?.Resumen?.TotalImpagosMonto || ''
    }
  }

  // Funcion para generar datos de tablas de composicion de la sociedad e informes comerciales

  crearSociosComposicionInforme(entidad: string, entidadesDerivadas: any, reportesDerivadosObj: any): any { 
    const entidadComposicion: any[] = [];
    const entidadInformes: any[] = [];
    const indexEntidadSocios = entidadesDerivadas?.findIndex((elemento: any) => elemento.entidad === entidad);
    let socios: any[] = [];
    if(indexEntidadSocios >= 0){
      socios = entidadesDerivadas[indexEntidadSocios].items;
    }

    if(reportesDerivadosObj[entidad]){
      for (const [key, value] of Object.entries(reportesDerivadosObj[entidad])) {
        const objeto: any = value;
        let nombreSocio = ''; 
        const indexSocio = socios.findIndex((elemento: any) => elemento.rut === key);
        if(indexSocio >= 0){
          const indexAtributo = socios[indexSocio].atributos.findIndex((elemento: any) => elemento.name.toUpperCase() === 'NOMBRE');
          if(indexAtributo >= 0){
            nombreSocio = socios[indexSocio].atributos[indexAtributo].value;
          }
        }
        const objetoComposicion = {
          socio: nombreSocio || '', // regla
          rut: key || '', // regla
          porcentaje: '', // ingreso amnual
          fs: '', // ingreso amnual
        }
        let motivo = '';

        if(objeto?.basicReport?.reporte?.AntecedentesComerciales?.DetalleMorosidadesYProtestos?.length > 0) {
          if(objeto?.basicReport?.reporte?.AntecedentesComerciales?.DetalleMorosidadesYProtestos?.length === 1) {
            motivo += objeto?.basicReport?.reporte?.AntecedentesComerciales?.DetalleMorosidadesYProtestos?.[0]?.tipoDocumento || '';
          } else {
            const arrayMorosidades: any[] = objeto?.basicReport?.reporte?.AntecedentesComerciales?.DetalleMorosidadesYProtestos || [];
            arrayMorosidades.sort((a, b) => {
              const fechaA = new Date(a?.fechaVencimiento);
              const fechaB = new Date(b?.fechaVencimiento);
              return fechaA < fechaB ? 1 : -1;
            });
            motivo += arrayMorosidades?.[0]?.tipoDocumento || '';
          }
        }

        if(objeto?.basicReport?.reporte?.AntecedentesComerciales?.Resumen?.InfraccionesLaboralesMonto > 0){
          if(motivo.length > 0)
            motivo += ', ';
          motivo += 'Infraciones Laborales';
        }
        const numero = objeto?.basicReport?.reporte?.AntecedentesComerciales?.Resumen?.TotalImpagosCantidad;
        const monto = objeto?.basicReport?.reporte?.AntecedentesComerciales?.Resumen?.TotalImpagosMonto;
        const objetoInformes = {
          numero: numero || numero == 0 ? numero : '',
          monto: monto || monto == 0 ? monto : '',
          motivo: motivo || '',
          entidad: entidad
        }

        entidadComposicion.push(objetoComposicion)
        entidadInformes.push(objetoInformes)
      }

    }

    return {
      entidadComposicion: entidadComposicion,
      entidadInformes: entidadInformes
    }
    
  }

  crearDeudores(entidad: string, entidadesDerivadas: any, reportesDerivadosObj: any): any[] {
    const deudoresResponse: any[] = [];
    const indexEntidad = entidadesDerivadas?.findIndex((elemento: any) => elemento.entidad === entidad);
    let deudores: any[] = [];
    if(indexEntidad >= 0){
      deudores = entidadesDerivadas[indexEntidad].items;
    }

    const deudoresObj = reportesDerivadosObj[entidad];

    if(deudoresObj){
      for (const [key, value] of Object.entries(deudoresObj)) {
        const rut = key;
  
        let nombreSocio = '';
        let montoTotal = 0;
        const indexSocio = deudores.findIndex((elemento: any) => elemento.rut === rut);
        if(indexSocio >= 0){
          const indexAtributoNombre = deudores[indexSocio].atributos.findIndex((elemento: any) => elemento.name.toUpperCase() === 'NOMBRE');
          if(indexAtributoNombre >= 0){
            nombreSocio = deudores[indexSocio].atributos[indexAtributoNombre].value;
          }
          const indexAtributoMonto = deudores[indexSocio].atributos.findIndex((elemento: any) => elemento.name.toUpperCase() === 'MONTOTOTAL');
          if(indexAtributoMonto >= 0){
            montoTotal = Number(deudores[indexSocio].atributos[indexAtributoMonto].value);
          }
        }
  
  
        const objeto: any = value;
        const elementoSbif: any[] = objeto?.deudaSbif?.reporte?.Sbif?.rowsMeses?.item || [];
        const ultimoElementoSbif = elementoSbif[elementoSbif.length - 1];
        const objetoDeudores = {
          nombre: nombreSocio || '', // libro compra venta
          rut: rut || '', // libro compra venta
          facturas: montoTotal || '', // libro compra venta -> ordenar por este valor de mayor a menor
          facturaFinanciar: 0, // manual
          cheques: 0, // manual
          numero: objeto?.basicReport?.reporte?.AntecedentesComerciales?.Resumen?.TotalImpagosCantidad || '',
          monto: objeto?.basicReport?.reporte?.AntecedentesComerciales?.Resumen?.TotalImpagosMonto || '',
          miles: Number(ultimoElementoSbif?.dirvigente) + Number(ultimoElementoSbif?.dirvencida) + Number(ultimoElementoSbif?.dirven180mayor3year) + Number(ultimoElementoSbif?.dirmorosa) || '-', // deuda sbif
          rangoSii: this.transformacionRangoSii(objeto?.basicReport?.reporte?.AntecedentesFinancieros?.TamanoSubsegmento || ''),
          web: '0', // manual
          histCancelado: 0, // manual / base capex
          diasPromedio: 0,  // manual / base capex
        }
        deudoresResponse.push(objetoDeudores)
  
      }
      
      deudoresResponse.sort((a, b) => parseFloat(b.facturas) - parseFloat(a.facturas)); // ordena de mayor a menor
    }

    return deudoresResponse;
  }

  crearCargaFinanciera(basicReport: any): any {
    const cargaFinanciera: any[] = []; 
    cargaFinanciera.push({
      fechaCurse: '',
      institucion: '',
      tipoCredito: '',
      creditoOriginal: '',
      deudaActual: '',
      cuota: '',
      nCuotasPorPagar: '',
      nCuotasMorosas: '',
      ultimoVencimiento: '',
      garantiasVigentes: ''
    });
    
    const nombreClienteCargaFinanciera: string = basicReport?.reporte?.InformacionBasica?.NombreORazonSocial || '';

    return {
      cargaFinanciera: cargaFinanciera,
      nombreClienteCargaFinanciera: nombreClienteCargaFinanciera
    }
  }

  crearFactoring(cesionFacturas: any): any[] {
    const factoring: any[] = [];
    if(cesionFacturas?.reporte?.registros && cesionFacturas?.reporte?.registros.length > 0){
      const registros = cesionFacturas?.reporte?.registros || [];
      let arrayReporte: any[] = [];
      registros.forEach((element: any) => {
        arrayReporte = arrayReporte.concat(element?.Reporte?.registros);
      });
      // Ordena segun la fecha de mayor a menor
      arrayReporte.sort((a:any, b:any) => {
        const fechaA = new Date(a.FCH_CESION);
        const fechaB = new Date(b.FCH_CESION);
        return fechaA < fechaB ? 1 : -1;
      });
      const fechasAnteriores: any[] = [];

      arrayReporte.forEach((element: any) => {
        let indice = factoring.findIndex((elemento: any) => elemento.CESIONARIO == element.CESIONARIO);
        if(indice >= 0){ // Si existe el elemento en el array
          let contador = factoring[indice].contador; // Se agrega uno mas al contador que esta guardado

          if(contador < 12){ // Se valida que el contador sea menor que el maximo
            const indiceFecha = fechasAnteriores.findIndex((elemento: any) => elemento.rut == element.CESIONARIO);
            if(indiceFecha >= 0){ // Se obtienen los datos de la ultima fecha guardada
              const fechaA = new Date(element.FCH_CESION);
              const mesElemento = fechaA.getMonth();
              const anioElemento = fechaA.getFullYear();

              const fechaB = new Date(fechasAnteriores[indiceFecha].fecha);
              let mesElementoAnterior = fechaB.getMonth();
              let anioElementoAnterior = fechaB.getFullYear();

              // Si el mes es el mismo es que se repitio la fecha en el array por lo que se sumara y se guardara mas abajo
              // solo avanza el contador si son diferentes
              if(mesElementoAnterior !== mesElemento && anioElementoAnterior !== anioElemento){
                if(mesElementoAnterior === 0){
                  anioElementoAnterior -= 1;
                  mesElementoAnterior = 11;
                } else {
                  mesElementoAnterior -= 1;
                }
                contador++;
              }

              // Rellena con sumas con cero a las fechas que no estan
              while((mesElemento !== mesElementoAnterior || anioElemento !== anioElementoAnterior) && contador < 12){
                factoring[indice].Monto12Meses += 0;
                if(contador < 6){
                  factoring[indice].monto6Meses += 0;
                }
                if(contador < 3){
                  factoring[indice].monto3Meses += 0;
                }
                factoring[indice].contador =  contador;
                fechasAnteriores[indiceFecha].fecha = new Date(anioElementoAnterior, mesElementoAnterior);
                if(mesElementoAnterior === 0){
                  anioElementoAnterior -= 1;
                  mesElementoAnterior = 11;
                } else {
                  mesElementoAnterior -= 1;
                }
                contador++;
              }

              // Si mes y año corresponden con el elemento y el contador es menor que el maximo se agregan los datos al array
              if(mesElemento === mesElementoAnterior && anioElemento === anioElementoAnterior && contador < 12){
                factoring[indice].Monto12Meses += (element?.MNT_CESION || element.MNT_CESION == 0 ? (Number(element.MNT_CESION) / 1000) : 0);
                if(contador < 6){
                  factoring[indice].monto6Meses += (element?.MNT_CESION || element.MNT_CESION == 0 ? (Number(element.MNT_CESION) / 1000) : 0);
                }
                if(contador < 3){
                  factoring[indice].monto3Meses += (element?.MNT_CESION || element.MNT_CESION == 0 ? (Number(element.MNT_CESION) / 1000) : 0);
                }
                fechasAnteriores[indiceFecha].fecha = element.FCH_CESION;
                factoring[indice].contador =  contador;
              }

            }
          }
        } else { // Si no existe el elemento en el array
          const fechaElemento = new Date(element.FCH_CESION);
          const mesElemento = fechaElemento.getMonth();
          const anioElemento = fechaElemento.getFullYear();
          let contador = 0;

          const fechaActual = new Date();
          let mesElementoAnterior = fechaActual.getMonth();
          let anioElementoAnterior = fechaActual.getFullYear();

          // Si el mes y el año es el actual se crea el array con los datos
          if(mesElemento === mesElementoAnterior && anioElemento === anioElementoAnterior){
            factoring.push({
              CESIONARIO: element.CESIONARIO,
              monto3Meses: (element?.MNT_CESION || element.MNT_CESION == 0 ? (Number(element.MNT_CESION) / 1000) : 0),
              monto6Meses: (element?.MNT_CESION || element.MNT_CESION == 0 ? (Number(element.MNT_CESION) / 1000) : 0),
              Monto12Meses: (element?.MNT_CESION || element.MNT_CESION == 0 ? (Number(element.MNT_CESION) / 1000) : 0),
              rz_cesionario: element.RZ_CESIONARIO || '',
              contador: 0,
            });
            fechasAnteriores.push(
              {
                rut: element.CESIONARIO, 
                fecha: element.FCH_CESION
              }
            );
          } else if ((mesElemento !== mesElementoAnterior || anioElemento !== anioElementoAnterior) && contador < 12) {
            // Si el mes y el año no es el actual se crea el array con los datos en cero
            factoring.push({
              CESIONARIO: element.CESIONARIO,
              monto3Meses: 0,
              monto6Meses: 0,
              Monto12Meses: 0,
              rz_cesionario: element.RZ_CESIONARIO || '',
              contador: 0,
            });
            fechasAnteriores.push(
              {
                rut: element.CESIONARIO, 
                fecha: fechaActual
              }
            );
            contador++; // Despues de agregar se aumenta el contador
  
            //Se disminuye un mes y un año eventualmente
            if(mesElementoAnterior === 0){
              anioElementoAnterior -= 1;
              mesElementoAnterior = 11;
            } else {
              mesElementoAnterior -= 1;
            }
  
            indice = factoring.findIndex((elemento: any) => elemento.CESIONARIO == element.CESIONARIO);
            const indiceFecha = fechasAnteriores.findIndex((elemento: any) => elemento.rut == element.CESIONARIO);
  
            // Si el mes y el año son diferentes y el contador no llega al maximo se incluyen datos en cero
            while((mesElemento !== mesElementoAnterior || anioElemento !== anioElementoAnterior) && contador < 12){
              factoring[indice].Monto12Meses += 0;
              if(contador < 6){
                factoring[indice].monto6Meses += 0;
              }
              if(contador < 3){
                factoring[indice].monto3Meses += 0;
              }
              factoring[indice].contador =  contador;
              fechasAnteriores[indiceFecha].fecha = new Date(anioElementoAnterior, mesElementoAnterior);
              contador++;

              //Se disminuye un mes y un año eventualmente
              if(mesElementoAnterior === 0){
                anioElementoAnterior -= 1;
                mesElementoAnterior = 11;
              } else {
                mesElementoAnterior -= 1;
              }
            }
  
            // Si el mes y el año es igual al del elemento se agregan los datos del elemento
            if(mesElemento === mesElementoAnterior && anioElemento === anioElementoAnterior && contador < 12){
              factoring[indice].Monto12Meses += (element?.MNT_CESION || element.MNT_CESION == 0 ? (Number(element.MNT_CESION) / 1000) : 0);
              if(contador < 6){
                factoring[indice].monto6Meses += (element?.MNT_CESION || element.MNT_CESION == 0 ? (Number(element.MNT_CESION) / 1000) : 0);
              }
              if(contador < 3){
                factoring[indice].monto3Meses += (element?.MNT_CESION || element.MNT_CESION == 0 ? (Number(element.MNT_CESION) / 1000) : 0);
              }
              fechasAnteriores[indiceFecha].fecha = element.FCH_CESION;
              factoring[indice].contador = contador;
            }
          }
        }
      });      
    }

    return factoring;

  }

  addNdocAndPrincipalDeudor(factoringInput: any[], cesionFacturas: any): any[] {
    // const factoring: any[] = [];
    if(cesionFacturas?.reporte?.registros && cesionFacturas?.reporte?.registros.length > 0){
      const registros = cesionFacturas?.reporte?.registros || [];
      let arrayReporte: any[] = [];
      registros.forEach((element: any) => {
        arrayReporte = arrayReporte.concat(element?.Reporte?.registros);
      });

      // Ordena segun la fecha de mayor a menor
      arrayReporte.sort((a:any, b:any) => {
        const fechaA = new Date(a.FCH_CESION);
        const fechaB = new Date(b.FCH_CESION);
        return fechaA < fechaB ? 1 : -1;
      });
      const fechasAnteriores: any[] = [];

      factoringInput.forEach((factoring: any) => {
        let nDocs = 0;
        const deudorCount: { [key: string]: number } = {};


        arrayReporte.forEach((element: any) => {
          if(element.CESIONARIO == factoring.CESIONARIO) {
            nDocs++;
          }
          const deudor = element.DEUDOR;
          if (deudor) {
            deudorCount[deudor] = (deudorCount[deudor] || 0) + 1;
          }
        })
        let mostFrequentDeudor = '';
        let maxCount = 0;

        for (const [deudor, count] of Object.entries(deudorCount)) {
          if (count > maxCount) {
            mostFrequentDeudor = deudor;
            maxCount = count;
          }
        }

        factoring.ppalDeudor = mostFrequentDeudor;
        factoring.nDocs = nDocs;
      })
    }

    return factoringInput;
  }

  divisionNumber(numero: number, divisor: number): number {
    if((divisor || divisor === 0) && numero !== 0){
      return Number( numero / divisor );
    }
    return numero;
  }

  obtenerTop10Clientes(reporte: any[]): any[] {
    const datos: any[] = JSON.parse(JSON.stringify(reporte));  
    const datosVentasDistribucion: any[] = [];

    datos.forEach(item => {
      if(item?.atributos && item?.atributos.length > 0) {
        const monto = item?.atributos.find((e: any) => e?.name === 'montoTotal');
        const nombre = item?.atributos.find((e: any) => e?.name === 'nombre');
        if(monto && nombre) {
          datosVentasDistribucion.push(
            {
              nombre: nombre?.value, 
              monto: monto?.value, 
              montoM: Number(this.divisionNumber(monto?.value, 1000).toFixed(2)) 
            }
          );
        }
      }
    });
  
    let datosVentasDistribucionTop10: any[] = JSON.parse(JSON.stringify(datosVentasDistribucion));
    datosVentasDistribucionTop10.sort((a, b) => parseFloat(b.monto) - parseFloat(a.monto));
    datosVentasDistribucionTop10 = datosVentasDistribucionTop10.slice(0, 10);
    
    return datosVentasDistribucionTop10 || [];
  }

  addRangoSiiTop10(top10: any[], deudores: any[]): any[] {
    const resultado: any[] = top10 || []
    resultado.forEach(e => {
      deudores.forEach(deudor => {
        if(e.nombre === deudor.nombre){
          e.rangoSii = deudor.rangoSii || '-';
        }
      })
    })
    return resultado;
  }

  obtenerNombreMes(numeroMes: number): string {
    const nombresMeses = [
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre'
    ];
  
    if (numeroMes && numeroMes >= 1 && numeroMes <= 12) {
      return nombresMeses[numeroMes - 1];
    } else {
      return '-';
    }
  }

  getFechasCompraVenta(reporte: any[]): any[] {
    const respuesta: any[] = [];

    reporte.forEach(element => {
      if( ((element?.registros?.compra && element?.registros?.compra?.length > 0) || (element?.registros?.venta && element?.registros?.venta?.length > 0))
        || (element?.totalizadores && Object.keys(element?.totalizadores).length > 0 && element?.periodo && element?.mes)
      ){
        respuesta.push({Periodo: element.periodo, Mes: element.mes, MesNombre: this.obtenerNombreMes(element.mes)});
      }
    });

    respuesta.sort((a, b) => {
      if (a.Periodo && b.Periodo) {
        if (a.Periodo === b.Periodo) {
          if (a.Mes && b.Mes) {
            return a.Mes - b.Mes;
          } else {
            return a.Mes ? -1 : 1;
          }
        } else {
          return a.Periodo - b.Periodo;
        }
      } else {
        return a.Periodo ? -1 : 1;
      }
    });

    return respuesta;
  }

  getElementMalla(
    id: string, 
    parentNodeId: string | null, 
    expanded: boolean, 
    tipo: string, 
    razonSocial: string, 
    rutFormatted: string, 
    entidad?: string, 
    tipoRut?: string,
    idTable?: string,
    classEntidad?: string,
    fromModal: boolean = true,
    tooltipData?: any
  ): any {
    const respuesta: any = {};
    respuesta.id = id;
    respuesta.parentNodeId = parentNodeId;
    respuesta.expanded = expanded;
    respuesta.idTable = idTable;
    respuesta.entidad = entidad;
    const tipoIdHtml = tipo.replace(" ", "");

    switch (tipo) {
      case 'Principal':
        respuesta.template = fromModal ? (
          `<div class="row h-100">
            <div class="col-12 h-100">
              <div class="card card-malla shadow-lg tipo-${tipoIdHtml} text-center h-100" title="Rut Principal: ${razonSocial}">
                <div class="">
                  <div class="card-header card-header-${tipoIdHtml} col-12 font-italic font-weight-bold text-center">
                    ${razonSocial}
                  </div>
                  <div class="col-12 pt-3 pb-3 text-center">
                    ${rutFormatted}
                  </div>
                </div>
              </div>
            </div>
          </div>`
        ) : 
        (
          `<div class="row h-100">
            <div class="col-12 h-100">
              <div class="card card-malla shadow-lg tipo-${tipoIdHtml} text-center h-100"
                title="${tipo} : ${razonSocial}
${tooltipData?.fechaInicio ? 'Inicio Act: '+tooltipData.fechaInicio : ''}
${tooltipData?.fechaTermino ? 'Termino Giro: '+tooltipData.fechaTermino : ''}
${tooltipData?.sociedadDisueltaPorAbsorcion ? 'Sociedad Disuelta por Absorción:-'+tooltipData.sociedadDisueltaPorAbsorcion : ''}">
                <div class="">
                  <div class="card-header card-header-${tipoIdHtml} col-12 font-italic font-weight-bold text-center">
                    ${razonSocial}
                  </div>
                  <div class="col-12 pt-3 pb-3 text-center">
                    ${rutFormatted}
                  </div>
                </div>
              </div>
            </div>
          </div>`
        );
        break;
      case 'Entidad':
        respuesta.template = (
         `<div class="row h-100">
            <div class="col-12 h-100">
              <div class="card card-malla shadow-lg ${classEntidad} text-center h-100 justify-content-center" title="${tipo} : ${razonSocial}">
                <div class="">
                  <div class="col-12 card-header-${tipoIdHtml} font-italic pt-3 pb-2 font-weight-bold text-center">
                    ${razonSocial}
                  </div>
                </div>
              </div>
            </div>
          </div>`
        );
        break;
      default:
        respuesta.template = fromModal ? (
          `<div class="row h-100">
            <div class="col-12 h-100">
              <div class="card card-malla shadow-lg ${classEntidad ? classEntidad : 'tipo-'+tipoIdHtml} text-center h-100" title="${tipo} : ${razonSocial}">
                <div class="h-100" id="${id}" entidad="${entidad || ''}" tipoRut="${tipoRut || ''}" idTable="${idTable || ''}" parentNodeId="${parentNodeId || ''}" onclick="selectNode('${id}')">
                  <div class="card-header ${classEntidad ? 'card-header-'+classEntidad : 'card-header-'+tipoIdHtml} col-12 font-weight-bold text-center">
                    ${tipo}
                  </div>
                  <div class="col-12 font-italic pt-3 pb-2 font-weight-bold text-center">
                    ${razonSocial}
                  </div>
                  <div class="col-12 pb-3 text-center">
                    ${rutFormatted}
                  </div>
                </div>
              </div>
            </div>
          </div>`
        ) : 
        (
          `<div class="row h-100">
            <div class="col-12 h-100">
              <div class="card card-malla shadow-lg ${classEntidad ? classEntidad : 'tipo-'+tipoIdHtml} text-center h-100"
                title="${tipo} : ${razonSocial}
${tooltipData?.fechaInicio ? 'Inicio Act: '+tooltipData.fechaInicio : ''}
${tooltipData?.fechaTermino ? 'Termino Giro: '+tooltipData.fechaTermino : ''}
${tooltipData?.sociedadDisueltaPorAbsorcion ? 'Sociedad Disuelta por Absorción:-'+tooltipData.sociedadDisueltaPorAbsorcion : ''}">
                <div class="h-100" id="${id}">
                  <div class="card-header ${classEntidad ? 'card-header-'+classEntidad : 'card-header-'+tipoIdHtml} col-12 font-weight-bold text-center">
                    ${tipo}
                  </div>
                  <div class="col-12 font-italic pt-3 pb-2 font-weight-bold text-center">
                    ${razonSocial}
                  </div>
                  <div class="col-12 pb-3 text-center">
                    ${rutFormatted}
                  </div>
                </div>
              </div>
            </div>
          </div>`
        );
        break;

    }

    return respuesta;
  }

  capitalize(value: string): any {
    if (value && typeof(value) === 'string' && value.length > 0 && value.trim() !== '') {
      const words = value.split(" ");
      const capitalizedWords = words.map((word) => {
        if (word && word[0]) 
          return word[0].toUpperCase() + word.substring(1); 
        return word;
      });
      return capitalizedWords.join(" ");
    }
    return value;
  }

  crearTablaDeudaMorosaTGR(tesoreria: any): any[] {
    const respuesta: any[] = [];
    if(tesoreria) {
      if(tesoreria?.deudas?.fiscales?.detalle?.deudas && tesoreria?.deudas?.fiscales?.detalle?.deudas.length > 0){
        for (const item of tesoreria?.deudas?.fiscales?.detalle?.deudas) {
          if (item?.tipoDeuda === "deudaMorosa") {
            const formulario = item?.formulario;
            const total = Number(item?.total) / 1000;
        
            const existingItem = respuesta.find(obj => obj?.name === formulario);
            if (existingItem) {
              existingItem.value += total;
            } else {
              respuesta.push({ name: formulario, value: total });
            }
          }
        }
        
      }

      if(tesoreria?.cobranzas?.judicial?.monto || tesoreria?.cobranzas?.judicial?.monto == 0) {
        const montoCobranza = Number(tesoreria?.cobranzas?.judicial?.monto) / 1000;
        respuesta.push({name: 'Cobranza Judicial', value: montoCobranza})
      }

    }
    return respuesta;
  }

  getTemporalContinentalMiles(reporte: any, valorUFActual: number, addFecha: boolean): any {
    let lineaTemporalContinental: any = '';
    let fechaExpiracionTemporal: any = '';

    if(reporte && reporte.length > 0){
      const fechaActual = new Date();
      const lineaTemporalContinentalArray = reporte.filter((e: any) => {
        // el formato de VigHasta es dd-mm-yyyy
        if(!e.VigHasta || !e.VigDesde){
          return false;
        }
        const [dia, mes, año] = e.VigHasta.split('-');
        const fechaItem = new Date(`${año}-${mes}-${dia}`);

        return fechaItem >= fechaActual;
      }).sort((a: any, b: any) => {
        // ordenar por fecha de vigencia desde
        const dateA = a.VigDesde ? new Date(a.VigDesde) : new Date('9999-12-31');
        const dateB = b.VigDesde ? new Date(b.VigDesde) : new Date('9999-12-31');
        return dateB.getTime() - dateA.getTime(); // Ordenar de mayor a menor
      });
      const lineaTemporalContinentalObj = lineaTemporalContinentalArray[0];
      if(lineaTemporalContinentalObj){
        const montoTemporal = lineaTemporalContinentalObj?.MontoTemporal;
        fechaExpiracionTemporal = lineaTemporalContinentalObj?.VigHasta || '';
        // si es UF se debe multiplicar por el valor de la UF
        if(lineaTemporalContinentalObj?.Moneda == 'UF'){
          lineaTemporalContinental = (montoTemporal || montoTemporal == 0) && valorUFActual > 0 ? ( this.toMiles((Number(montoTemporal || 0) * valorUFActual)) ) : '';
        } else {
          lineaTemporalContinental = montoTemporal && montoTemporal == 0 ? montoTemporal : '';
        }
      }
    }

    return addFecha ? { lineaTemporalContinental, fechaExpiracionTemporal } : lineaTemporalContinental;
  }

  getTemporalContinentalUF(reporte: any, addFecha: boolean): any {
    let lineaTemporalContinental: any = '';
    let fechaExpiracionTemporal: any = '';

    if(reporte && reporte.length > 0){
      const fechaActual = new Date();
      const lineaTemporalContinentalArray = reporte.filter((e: any) => {
        // el formato de VigHasta es dd-mm-yyyy
        if(!e.VigHasta || !e.VigDesde){
          return false;
        }
        const [dia, mes, año] = e.VigHasta.split('-');
        const fechaItem = new Date(`${año}-${mes}-${dia}`);

        return fechaItem >= fechaActual;
      }).sort((a: any, b: any) => {
        // ordenar por fecha de vigencia desde
        const dateA = a.VigDesde ? new Date(a.VigDesde) : new Date('9999-12-31');
        const dateB = b.VigDesde ? new Date(b.VigDesde) : new Date('9999-12-31');
        return dateB.getTime() - dateA.getTime(); // Ordenar de mayor a menor
      });
      const lineaTemporalContinentalObj = lineaTemporalContinentalArray[0];
      if(lineaTemporalContinentalObj){
        const montoTemporal = lineaTemporalContinentalObj?.MontoTemporal;
        fechaExpiracionTemporal = lineaTemporalContinentalObj?.VigHasta || '';
        // si es UF se debe multiplicar por el valor de la UF
        if(lineaTemporalContinentalObj?.Moneda == 'UF'){
          lineaTemporalContinental = (montoTemporal || montoTemporal == 0) ? ( Number(montoTemporal || 0) ) : '';
        } else {
          lineaTemporalContinental = '';
          console.error('El monto temporal no es en UF');
        }
      }
    }

    return addFecha ? { lineaTemporalContinental, fechaExpiracionTemporal } : lineaTemporalContinental;
  }

  getLCTotalCiaSeguro(inMiles: boolean, temporalUF: any, montoAprobadoUF: any, valorUFActual: number | undefined): any { // (temporalUF + montoAprobadoUF) * valorUFActual
    if(!temporalUF && temporalUF != 0) {
      return '';
    }

    if(!montoAprobadoUF && montoAprobadoUF != 0) {
      return '';
    }

    if(inMiles){
      if(valorUFActual === null || valorUFActual === undefined){
        console.error('El valor de la UF actual no puede ser nulo o indefinido para obtener el monto en miles');
        return '';
      }

      return this.toMiles((temporalUF + montoAprobadoUF) * valorUFActual);

    } else {
      return (temporalUF + montoAprobadoUF);
    }

  }

  toMiles(value: any): string | number {
    if(value || value == 0) {
      return Math.round(Number(value) / 1000);
    }
    return '';
  }

  calcularVentaPromedioMensual(lcv: any[]): any {
    if (lcv && lcv.length > 0) {
      const fechaActual = new Date();
      const mesActual = fechaActual.getMonth() + 1;
      const anioActual = fechaActual.getFullYear();
      let ventas: number = 0;
      for(let i = 0; i < 12; i++) {
        let mes = mesActual - i;
        let anio = anioActual;
        if(mes <= 0) {
          mes = 12 + mes;
          anio = anio - 1;
        }
        const lcvMes = lcv.find((e: any) => e?.mes == mes && e?.periodo == anio);
        if(lcvMes && lcvMes?.totalizadores?.montoTotalVenta) {
          ventas += Number(lcvMes?.totalizadores?.montoTotalVenta) / 1000;
        }
      }
      const promedio = ventas / 12;
      return Math.round(promedio);
    }
    return null;
  }

  getLibrosMapeados(librosLCV: any[], librosBuscar: any[]): any[] {
    let libros: any = [];
    if(librosLCV && librosLCV.length > 0){
      if(librosLCV.some((e: any) => e?.Reporte?.totalizadores && Object.keys(e?.Reporte?.totalizadores).length > 0)){
        const librosAux = librosLCV || [];
        librosAux.forEach((element: any) => {
          libros.push(element?.Reporte);
        });
        //libros = librosLCV;
      }
      if(librosLCV.some((e: any) => e?.totalizadores && Object.keys(e?.totalizadores).length > 0)){
        libros = librosLCV;
      }
    }
    if (librosBuscar && librosBuscar.length > 0) {
      if(librosBuscar.some((e: any) => e?.Reporte?.totalizadores && Object.keys(e?.Reporte?.totalizadores).length > 0)){
        //libros = librosBuscar;
        const librosAux = librosBuscar || [];
        librosAux.forEach((element: any) => {
          libros.push(element?.Reporte);
        });
      }
      if(librosBuscar.some((e: any) => e?.totalizadores && Object.keys(e?.totalizadores).length > 0)){
        libros = librosBuscar;
      }
    }
    return libros;
  }

  getLineaAvla(reporteLinea: any[]): any {
    let respuesta: any = null;
    if(reporteLinea && reporteLinea.length > 0) {
      const estadoAprobado = [
        "APROBADA",
        "APROBADA PARCIAL",
        "REDUCIDA",
        "REDUCIDA DE APROBADA PARCIAL"
      ];
      reporteLinea = reporteLinea.filter((linea: any) => linea?.stateDto?.name && typeof(linea?.stateDto?.name) == 'string' && 
        estadoAprobado.some(elemento => linea?.stateDto?.name.toUpperCase().includes(elemento) && linea?.stateDto?.name != 'Aprobada (H)')
      );
      
      // Sort reporteLinea array based on createDateStr in descending order
      reporteLinea = reporteLinea.sort((a: { createDateStr?: string }, b: { createDateStr?: string }) => {
        const fechaResolucionA = a?.createDateStr || '';
        const fechaResolucionB = b?.createDateStr || '';
        return new Date(fechaResolucionB).getTime() - new Date(fechaResolucionA).getTime();
      });
      
      if(reporteLinea.length > 0) {
        respuesta = reporteLinea[0];
      }
    }

    return respuesta;
  }

}
