<div class="container-gtp mb-5">
    <img class="banner-img center-block" src="https://gestionatupyme-public.s3.amazonaws.com/banner.png" alt=""/>
    <h2 class="text-center pt-3">Tesoreria</h2>
    <h5 class="text-center">
        <span class="badge bg-secondary text-center">
            <span *ngIf="rut">
                {{rut | rutFormat | uppercase}}
            </span>
        </span>
    </h5>

    <div class="break-box">
        <div class="row" [ngClass]="{'no-print': isResumida}">
            <div class="col-sm-12">
                <div class="card textos-campo">
                    <h5 class="container-fluid card-header list-group-item cuadro-color" >
                        Deudas Contribuciones
                    </h5>
                    <div class="card-body">
                        <div class="col-12">
                            <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <ng-container *ngFor="let item of headerContribuciones">
                                                <th class="border-0">{{item?.value}}</th>
                                            </ng-container>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <ng-container *ngFor="let item of headerContribuciones">
                                                <td>
                                                    <span *ngIf="contribuciones[item.key] || contribuciones[item.key] == 0">                                                        
                                                        {{ 
                                                            camposEnteros.includes(item.key) ? (contribuciones[item.key] | number: '1.0-0') : 
                                                            (camposFechas.includes(item.key) ? (contribuciones[item.key] | date: 'dd-MM-yyyy') : 
                                                            contribuciones[item.key])
                                                        }}
                                                    </span>
                                                </td>
                                            </ng-container>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="row mt-5">
            <div class="col-sm-12">
                <div class="card textos-campo">
                    <h5 class="container-fluid card-header list-group-item cuadro-color" >
                        Deudas Fiscales Totales
                    </h5>
                    <div class="card-body">
                        <div class="col-12">
                            <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <ng-container *ngFor="let item of headerFiscalesTotales">
                                                <th class="border-0">{{item?.value}}</th>
                                            </ng-container>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <ng-container *ngFor="let item of headerFiscalesTotales">
                                                <td>
                                                    <span *ngIf="fiscalesTotal[item.key] || fiscalesTotal[item.key] == 0">
                                                        {{ 
                                                            camposEnteros.includes(item.key) ? (fiscalesTotal[item.key] | number: '1.0-0') : 
                                                            (camposFechas.includes(item.key) ? (fiscalesTotal[item.key] | date: 'dd-MM-yyyy') : 
                                                            fiscalesTotal[item.key])
                                                        }}
                                                    </span>
                                                </td>
                                            </ng-container>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" *ngIf="fiscales.length > 0">
            <div class="col-sm-12">
                <div class="card textos-campo">
                    <h5 class="container-fluid card-header list-group-item cuadro-color" >
                        Deudas Fiscales Detalle
                    </h5>
                    <div class="card-body">
                        <div class="col-12">
                            <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <ng-container *ngFor="let item of headerFiscales">
                                                <th class="border-0">{{item?.value}}</th>
                                            </ng-container>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let itemFiscal of fiscales">
                                            <ng-container *ngFor="let item of headerFiscales">
                                                <td>
                                                    <span *ngIf="itemFiscal[item.key] || itemFiscal[item.key] == 0">
                                                        {{ 
                                                            camposEnteros.includes(item.key) ? (itemFiscal[item.key] | number: '1.0-0') : 
                                                            (camposFechas.includes(item.key) ? (itemFiscal[item.key] | date: 'dd-MM-yyyy') : 
                                                            itemFiscal[item.key])
                                                        }}
                                                    </span>
                                                </td>
                                            </ng-container>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>

    <!-- SECCION COBRANZAS -->
    <div class="break-box">
        <!-- TOTAL COBRANZAS -->
        <div class="row mt-5" *ngIf="validaCobranzasAdministrativas() || validaCobranzasJudiciales() || validaCobranzasQuiebra()">
            <div class="col-sm-12">
                <div class="card textos-campo">
                    <h5 class="container-fluid card-header list-group-item cuadro-color" >
                        Total Cobranzas
                    </h5>
                    <div class="card-body">
                        <div class="col-12">
                            <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th colspan="2" *ngIf="validaCobranzasAdministrativas()">Administrativa</th>
                                            <th colspan="2" *ngIf="validaCobranzasJudiciales()">Judicial</th>
                                            <th colspan="2" *ngIf="validaCobranzasQuiebra()">Quiebra</th>
                                        </tr>
                                        <tr>
                                            <ng-container *ngFor="let item of headerCobranzasTotales">
                                                <ng-container *ngIf="validarCobranzasTotales(item.key)">
                                                    <th class="border-0">{{item?.value}}</th>
                                                </ng-container>
                                            </ng-container>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <ng-container *ngFor="let item of headerCobranzasTotales">
                                                <ng-container *ngIf="validarCobranzasTotales(item.key)">
                                                    <td>
                                                        <span *ngIf="cobranzasTotales[item.key] || cobranzasTotales[item.key] == 0">
                                                            {{ 
                                                                camposEnteros.includes(item.key) ? (cobranzasTotales[item.key] | number: '1.0-0') : 
                                                                (camposFechas.includes(item.key) ? (cobranzasTotales[item.key] | date: 'dd-MM-yyyy') : 
                                                                cobranzasTotales[item.key])
                                                            }}
                                                        </span>
                                                    </td>
                                                </ng-container>                                                
                                            </ng-container>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" *ngIf="cuentasCobranzasAdministrativa.length > 0">
            <div class="col-sm-12">
                <div class="card textos-campo">
                    <h5 class="container-fluid card-header list-group-item cuadro-color" >
                        Cuentas Cobranzas Administrativas
                    </h5>
                    <div class="card-body">
                        <div class="col-12">
                            <div class="table-responsive">
                                <table class="table table-hover">
                                    <thead>
                                        <tr>
                                            <ng-container *ngFor="let item of headerCuentasCobranzasAdministrativa">
                                                <th class="border-0">{{item?.value}}</th>
                                            </ng-container>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container *ngFor="let itemCuentas of cuentasCobranzasAdministrativa">
                                            <tr [ngClass]="{'cursor-pointer': (itemCuentas?.detalle.length && itemCuentas?.detalle.length > 0), 'bg-primary': itemCuentas.isExpanded}" (click)="itemCuentas.isExpanded = !itemCuentas.isExpanded">
                                                <ng-container *ngFor="let item of headerCuentasCobranzasAdministrativa">
                                                    <td>
                                                        <span *ngIf="itemCuentas[item.key] || itemCuentas[item.key] == 0">
                                                            {{ 
                                                                camposEnteros.includes(item.key) ? (itemCuentas[item.key] | number: '1.0-0') : 
                                                                (camposFechas.includes(item.key) ? (itemCuentas[item.key] | date: 'dd-MM-yyyy') : 
                                                                itemCuentas[item.key])
                                                            }}
                                                        </span>
                                                    </td>
                                                </ng-container>
                                            </tr>
                                            <tr *ngIf="itemCuentas.isExpanded && itemCuentas?.detalle && itemCuentas?.detalle.length > 0">
                                                <td colspan="5">
                                                    <div class="expanded-panel">
                                                        <div class="table-container">
                                                          <table class="table border">
                                                            <tr class="header-row">
                                                                <ng-container *ngFor="let itemHeader of headerDetalleCobranza">
                                                                    <th class="border-0">{{itemHeader?.value}}</th>
                                                                </ng-container>
                                                            </tr>
                                                            <tr class="table-row" *ngFor="let itemDetalle of itemCuentas.detalle">
                                                                <ng-container *ngFor="let itemHeader of headerDetalleCobranza">
                                                                    <td class="cell">
                                                                        <span *ngIf="itemDetalle[itemHeader.key] || itemDetalle[itemHeader.key] == 0">
                                                                            {{ 
                                                                                camposEnteros.includes(itemHeader.key) ? (itemDetalle[itemHeader.key] | number: '1.0-0') : 
                                                                                (camposFechas.includes(itemHeader.key) ? (itemDetalle[itemHeader.key] | date: 'dd-MM-yyyy') : 
                                                                                itemDetalle[itemHeader.key])
                                                                            }}
                                                                        </span>
                                                                    </td>
                                                                </ng-container>
                                                            </tr>
                                                          </table>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </ng-container>
                                        
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- CUENTAS COBRANZAS JUDICIALES -->
        <div class="row" *ngIf="cuentasCobranzasJudiciales.length > 0">
            <div class="col-sm-12">
                <div class="card textos-campo">
                    <h5 class="container-fluid card-header list-group-item cuadro-color" >
                        Cuentas Cobranzas Judiciales
                    </h5>
                    <div class="card-body">
                        <div class="col-12">
                            <div class="table-responsive">
                                <table class="table table-hover">
                                    <thead>
                                        <tr>
                                            <ng-container *ngFor="let item of headerCuentasCobranzasJudiciales">
                                                <th class="border-0">{{item?.value}}</th>
                                            </ng-container>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container *ngFor="let itemCuentas of cuentasCobranzasJudiciales">
                                            <tr [ngClass]="{'cursor-pointer': (itemCuentas?.detalle && itemCuentas?.detalle.length > 0), 'bg-primary': itemCuentas.isExpanded}" (click)="itemCuentas.isExpanded = !itemCuentas.isExpanded; log(itemCuentas)">
                                                <ng-container *ngFor="let item of headerCuentasCobranzasJudiciales">
                                                    <td>
                                                        <span *ngIf="itemCuentas[item.key] || itemCuentas[item.key] == 0">
                                                            {{ 
                                                                camposEnteros.includes(item.key) ? (itemCuentas[item.key] | number: '1.0-0') : 
                                                                (camposFechas.includes(item.key) ? (itemCuentas[item.key] | date: 'dd-MM-yyyy') : 
                                                                itemCuentas[item.key])
                                                            }}
                                                        </span>
                                                    </td>
                                                </ng-container>
                                            </tr>
                                            <tr *ngIf="itemCuentas.isExpanded && itemCuentas?.detalle && itemCuentas?.detalle.length > 0">
                                                <td colspan="5">
                                                    <div class="expanded-panel">
                                                        <div class="table-container">
                                                          <table class="table border">
                                                            <tr class="header-row">
                                                                <ng-container *ngFor="let itemHeader of headerDetalleCobranzaJudicial">
                                                                    <th class="border-0">{{itemHeader?.value}}</th>
                                                                </ng-container>
                                                            </tr>
                                                            <tr class="table-row" *ngFor="let itemDetalle of itemCuentas.detalle">
                                                                <ng-container *ngFor="let itemHeader of headerDetalleCobranzaJudicial">
                                                                    <td class="cell">
                                                                        <span *ngIf="itemDetalle[itemHeader.key] || itemDetalle[itemHeader.key] == 0">
                                                                            {{ 
                                                                                camposEnteros.includes(itemHeader.key) ? (itemDetalle[itemHeader.key] | number: '1.0-0') : 
                                                                                (camposFechas.includes(itemHeader.key) ? (itemDetalle[itemHeader.key] | date: 'dd-MM-yyyy') : 
                                                                                itemDetalle[itemHeader.key])
                                                                            }}
                                                                        </span>
                                                                    </td>
                                                                </ng-container>
                                                            </tr>
                                                          </table>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </ng-container>
                                        
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" *ngIf="cuentasCobranzasQuiebra.length > 0">
            <div class="col-sm-12">
                <div class="card textos-campo">
                    <h5 class="container-fluid card-header list-group-item cuadro-color" >
                        Cuentas Cobranzas Quiebra
                    </h5>
                    <div class="card-body">
                        <div class="col-12">
                            <div class="table-responsive">
                                <table class="table table-hover">
                                    <thead>
                                        <tr>
                                            <ng-container *ngFor="let item of headerCuentasCobranzasQuiebra">
                                                <th class="border-0">{{item?.value}}</th>
                                            </ng-container>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container *ngFor="let itemCuentas of cuentasCobranzasQuiebra">
                                            <tr [ngClass]="{'cursor-pointer': (itemCuentas?.detalle.length && itemCuentas?.detalle.length > 0), 'bg-primary': itemCuentas.isExpanded}" (click)="itemCuentas.isExpanded = !itemCuentas.isExpanded">
                                                <ng-container *ngFor="let item of headerCuentasCobranzasQuiebra">
                                                    <td>
                                                        <span *ngIf="itemCuentas[item.key] || itemCuentas[item.key] == 0">
                                                            {{ 
                                                                camposEnteros.includes(item.key) ? (itemCuentas[item.key] | number: '1.0-0') : 
                                                                (camposFechas.includes(item.key) ? (itemCuentas[item.key] | date: 'dd-MM-yyyy') : 
                                                                itemCuentas[item.key])
                                                            }}
                                                        </span>
                                                    </td>
                                                </ng-container>
                                            </tr>
                                            <tr *ngIf="itemCuentas.isExpanded && itemCuentas?.detalle && itemCuentas?.detalle.length > 0">
                                                <td colspan="5">
                                                    <div class="expanded-panel">
                                                        <div class="table-container">
                                                          <table class="table border">
                                                            <tr class="header-row">
                                                                <ng-container *ngFor="let itemHeader of headerDetalleCobranza">
                                                                    <th class="border-0">{{itemHeader?.value}}</th>
                                                                </ng-container>
                                                            </tr>
                                                            <tr class="table-row" *ngFor="let itemDetalle of itemCuentas.detalle">
                                                                <ng-container *ngFor="let itemHeader of headerDetalleCobranza">
                                                                    <td class="cell">
                                                                        <span *ngIf="itemDetalle[itemHeader.key] || itemDetalle[itemHeader.key] == 0">
                                                                            {{ 
                                                                                camposEnteros.includes(itemHeader.key) ? (itemDetalle[itemHeader.key] | number: '1.0-0') : 
                                                                                (camposFechas.includes(itemHeader.key) ? (itemDetalle[itemHeader.key] | date: 'dd-MM-yyyy') : 
                                                                                itemDetalle[itemHeader.key])
                                                                            }}
                                                                        </span>
                                                                    </td>
                                                                </ng-container>
                                                            </tr>
                                                          </table>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </ng-container>
                                        
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- SECCION CONVENIOS -->
    <div class="break-box">
        <!-- TOTAL CONVENIOS -->
        <div class="row mt-5">
            <div class="col-sm-12">
                <div class="card textos-campo">
                    <h5 class="container-fluid card-header list-group-item cuadro-color" >
                        Total Convenios
                    </h5>
                    <div class="card-body">
                        <div class="col-12">
                            <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th colspan="2">Vigentes</th>
                                            <th colspan="2">Caducados</th>
                                            <th colspan="2">Pagados</th>
                                        </tr>
                                        <tr>
                                            <ng-container *ngFor="let item of headerConveniosTotales">
                                                <th class="border-0">{{item?.value}}</th>
                                            </ng-container>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <ng-container *ngFor="let item of headerConveniosTotales">
                                                <td>
                                                    <span *ngIf="conveniosTotales[item.key] || conveniosTotales[item.key] == 0">
                                                        {{ 
                                                            camposEnteros.includes(item.key) ? (conveniosTotales[item.key] | number: '1.0-0') : 
                                                            (camposFechas.includes(item.key) ? (conveniosTotales[item.key] | date: 'dd-MM-yyyy') : 
                                                            conveniosTotales[item.key])
                                                        }}
                                                    </span>
                                                </td>
                                            </ng-container>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="break-box">
        <!-- CONVENIOS VIGENTES -->
        <div class="row" *ngIf="conveniosVigentes.length > 0">
            <div class="col-sm-12">
                <div class="card textos-campo">
                    <h5 class="container-fluid card-header list-group-item cuadro-color" >
                        Convenios Vigentes
                    </h5>
                    <div class="card-body">
                        <div class="col-12">
                            <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <ng-container *ngFor="let item of headerConveniosVigentes">
                                                <th class="border-0">{{item?.value}}</th>
                                            </ng-container>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container *ngFor="let itemConvenio of conveniosVigentes">
                                            <tr [ngClass]="{'cursor-pointer': (itemConvenio), 'bg-primary': itemConvenio.isExpanded}" (click)="itemConvenio.isExpanded = !itemConvenio.isExpanded;">
                                                <ng-container *ngFor="let item of headerConveniosVigentes">
                                                    <td>
                                                        <span *ngIf="itemConvenio[item.key] || itemConvenio[item.key] == 0">
                                                            {{ 
                                                            camposEnteros.includes(item.key) ? (itemConvenio[item.key] | number: '1.0-0') : 
                                                            (camposFechas.includes(item.key) ? (itemConvenio[item.key] | date: 'dd-MM-yyyy') : 
                                                            itemConvenio[item.key])
                                                        }}
                                                        </span>
                                                    </td>
                                                </ng-container>
                                            </tr>
                                            <!-- Datos Convenio -->
                                            <tr *ngIf="itemConvenio.isExpanded">
                                                <td colspan="5">
                                                    <div class="expanded-panel">
                                                        <div class="table-container">
                                                          <table class="table border">
                                                            <tr class="header-row">
                                                                <ng-container *ngFor="let itemHeader of headerDatosConvenio">
                                                                    <th class="border-0">{{itemHeader?.value}}</th>
                                                                </ng-container>
                                                            </tr>
                                                            <tr class="table-row" *ngIf="itemConvenio.datos">
                                                                <ng-container *ngFor="let itemHeader of headerDatosConvenio">
                                                                    <td class="cell">
                                                                        <span *ngIf="itemConvenio.datos[itemHeader.key] || itemConvenio.datos[itemHeader.key] == 0">
                                                                            {{ 
                                                                                camposEnteros.includes(itemHeader.key) ? (itemConvenio.datos[itemHeader.key] | number: '1.0-0') : 
                                                                                (camposFechas.includes(itemHeader.key) ? (itemConvenio.datos[itemHeader.key] | date: 'dd-MM-yyyy') : 
                                                                                itemConvenio.datos[itemHeader.key])
                                                                            }}
                                                                        </span>
                                                                    </td>
                                                                </ng-container>
                                                            </tr>
                                                          </table>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <!-- Deudas Convenio -->
                                            <tr *ngIf="itemConvenio.isExpanded && itemConvenio.datos">
                                                <td colspan="5">
                                                    <div class="expanded-panel">
                                                        <div class="table-container">
                                                          <h5>Deudas</h5>
                                                          <table class="table border">
                                                            <tr class="header-row">
                                                                <ng-container *ngFor="let itemHeader of headerDeudasConvenio">
                                                                    <th class="border-0">{{itemHeader?.value}}</th>
                                                                </ng-container>
                                                            </tr>
                                                            <tr class="table-row" *ngFor="let itemDeduda of itemConvenio.datos.deudas">
                                                                <ng-container *ngFor="let itemHeader of headerDeudasConvenio">
                                                                    <td class="cell">
                                                                        <span *ngIf="itemDeduda[itemHeader.key] || itemDeduda[itemHeader.key] == 0">
                                                                            {{ 
                                                                                camposEnteros.includes(itemHeader.key) ? (itemDeduda[itemHeader.key] | number: '1.0-0') : 
                                                                                (camposFechas.includes(itemHeader.key) ? (itemDeduda[itemHeader.key] | date: 'dd-MM-yyyy') : 
                                                                                itemDeduda[itemHeader.key])
                                                                            }}

                                                                            <!-- {{itemHeader.key}} -->
                                                                        </span>
                                                                    </td>
                                                                </ng-container>
                                                            </tr>
                                                          </table>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <!-- Ver Cuotas -->
                                            <button *ngIf="itemConvenio.isExpanded && itemConvenio.datos && !itemConvenio.datos.seeCuotas" type="button" class="btn btn-link" (click)="itemConvenio.datos.seeCuotas = true">Ver Cuotas</button>
                                            <button *ngIf="itemConvenio.isExpanded && itemConvenio.datos && itemConvenio.datos.seeCuotas" type="button" class="btn btn-link" (click)="itemConvenio.datos.seeCuotas = false">Cerrar Cuotas</button>
                                            <div *ngIf="itemConvenio.datos && itemConvenio.datos.seeCuotas">
                                                <td colspan="5">
                                                    <div class="expanded-panel">
                                                        <div class="table-container">
                                                          <!-- <h5 class="btn btn-link">Cerrar Cuotas</h5> -->
                                                          <h5>Cuotas</h5>
                                                          <table class="table border">
                                                            <tr class="header-row">
                                                                <ng-container *ngFor="let itemHeader of headerCuotasConvenio">
                                                                    <th class="border-0">{{itemHeader?.value}}</th>
                                                                </ng-container>
                                                            </tr>
                                                            <tr class="table-row" *ngFor="let itemCuota of itemConvenio.datos.cuotas">
                                                                <ng-container *ngFor="let itemHeader of headerCuotasConvenio">
                                                                    <td class="cell">
                                                                        <span *ngIf="itemCuota[itemHeader.key] || itemCuota[itemHeader.key] == 0">
                                                                            {{ 
                                                                                camposEnteros.includes(itemHeader.key) ? (itemCuota[itemHeader.key] | number: '1.0-0') : 
                                                                                (camposFechas.includes(itemHeader.key) ? (itemCuota[itemHeader.key] | date: 'dd-MM-yyyy') : 
                                                                                itemCuota[itemHeader.key])
                                                                            }}

                                                                            <!-- {{itemHeader.key}} -->
                                                                        </span>
                                                                    </td>
                                                                </ng-container>
                                                            </tr>
                                                          </table>
                                                        </div>
                                                    </div>
                                                </td>
                                            </div>
                                        </ng-container>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
        <!-- CONVENIOS CADUCADOS -->
        <div class="row" *ngIf="conveniosCaducados.length > 0">
            <div class="col-sm-12">
                <div class="card textos-campo">
                    <h5 class="container-fluid card-header list-group-item cuadro-color" >
                        Convenios Caducados
                    </h5>
                    <div class="card-body">
                        <div class="col-12">
                            <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <ng-container *ngFor="let item of headerConveniosCaducados">
                                                <th class="border-0">{{item?.value}}</th>
                                            </ng-container>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container *ngFor="let itemConvenio of conveniosCaducados">
                                            <tr [ngClass]="{'cursor-pointer': (itemConvenio), 'bg-primary': itemConvenio.isExpanded}" (click)="itemConvenio.isExpanded = !itemConvenio.isExpanded;">
                                                <ng-container *ngFor="let item of headerConveniosCaducados">
                                                    <td>
                                                        <span *ngIf="itemConvenio[item.key] || itemConvenio[item.key] == 0">
                                                            {{ 
                                                                camposEnteros.includes(item.key) ? (itemConvenio[item.key] | number: '1.0-0') : 
                                                                (camposFechas.includes(item.key) ? (itemConvenio[item.key] | date: 'dd-MM-yyyy') : 
                                                                itemConvenio[item.key])
                                                            }}
                                                        </span>
                                                    </td>
                                                </ng-container>
                                            </tr>
                                            <!-- Datos Convenio -->
                                            <tr *ngIf="itemConvenio.isExpanded">
                                                <td colspan="5">
                                                    <div class="expanded-panel">
                                                        <div class="table-container">
                                                          <table class="table border">
                                                            <tr class="header-row">
                                                                <ng-container *ngFor="let itemHeader of headerDatosConvenio">
                                                                    <th class="border-0">{{itemHeader?.value}}</th>
                                                                </ng-container>
                                                            </tr>
                                                            <tr class="table-row" *ngIf="itemConvenio.datos">
                                                                <ng-container *ngFor="let itemHeader of headerDatosConvenio">
                                                                    <td class="cell">
                                                                        <span *ngIf="itemConvenio.datos[itemHeader.key] || itemConvenio.datos[itemHeader.key] == 0">
                                                                            {{ 
                                                                                camposEnteros.includes(itemHeader.key) ? (itemConvenio.datos[itemHeader.key] | number: '1.0-0') : 
                                                                                (camposFechas.includes(itemHeader.key) ? (itemConvenio.datos[itemHeader.key] | date: 'dd-MM-yyyy') : 
                                                                                itemConvenio.datos[itemHeader.key])
                                                                            }}
                                                                        </span>
                                                                    </td>
                                                                </ng-container>
                                                            </tr>
                                                          </table>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <!-- Deudas Convenio -->
                                            <tr *ngIf="itemConvenio.isExpanded && itemConvenio.datos">
                                                <td colspan="5">
                                                    <div class="expanded-panel">
                                                        <div class="table-container">
                                                          <h5>Deudas</h5>
                                                          <table class="table border">
                                                            <tr class="header-row">
                                                                <ng-container *ngFor="let itemHeader of headerDeudasConvenio">
                                                                    <th class="border-0">{{itemHeader?.value}}</th>
                                                                </ng-container>
                                                            </tr>
                                                            <tr class="table-row" *ngFor="let itemDeduda of itemConvenio.datos.deudas">
                                                                <ng-container *ngFor="let itemHeader of headerDeudasConvenio">
                                                                    <td class="cell">
                                                                        <span *ngIf="itemDeduda[itemHeader.key] || itemDeduda[itemHeader.key] == 0">
                                                                            {{ 
                                                                                camposEnteros.includes(itemHeader.key) ? (itemDeduda[itemHeader.key] | number: '1.0-0') : 
                                                                                (camposFechas.includes(itemHeader.key) ? (itemDeduda[itemHeader.key] | date: 'dd-MM-yyyy') : 
                                                                                itemDeduda[itemHeader.key])
                                                                            }}
    
                                                                            <!-- {{itemHeader.key}} -->
                                                                        </span>
                                                                    </td>
                                                                </ng-container>
                                                            </tr>
                                                          </table>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <!-- Ver Cuotas -->
                                            <button *ngIf="itemConvenio.isExpanded && itemConvenio.datos && !itemConvenio.datos.seeCuotas" type="button" class="btn btn-link" (click)="itemConvenio.datos.seeCuotas = true">Ver Cuotas</button>
                                            <button *ngIf="itemConvenio.isExpanded && itemConvenio.datos && itemConvenio.datos.seeCuotas" type="button" class="btn btn-link" (click)="itemConvenio.datos.seeCuotas = false">Cerrar Cuotas</button>
                                            <div *ngIf="itemConvenio.datos && itemConvenio.datos.seeCuotas">
                                                <td colspan="5">
                                                    <div class="expanded-panel">
                                                        <div class="table-container">
                                                          <h5>Cuotas</h5>
                                                          <table class="table border">
                                                            <tr class="header-row">
                                                                <ng-container *ngFor="let itemHeader of headerCuotasConvenio">
                                                                    <th class="border-0">{{itemHeader?.value}}</th>
                                                                </ng-container>
                                                            </tr>
                                                            <tr class="table-row" *ngFor="let itemCuota of itemConvenio.datos.cuotas">
                                                                <ng-container *ngFor="let itemHeader of headerCuotasConvenio">
                                                                    <td class="cell">
                                                                        <span *ngIf="itemCuota[itemHeader.key] || itemCuota[itemHeader.key] == 0">
                                                                            {{ 
                                                                                camposEnteros.includes(itemHeader.key) ? (itemCuota[itemHeader.key] | number: '1.0-0') : 
                                                                                (camposFechas.includes(itemHeader.key) ? (itemCuota[itemHeader.key] | date: 'dd-MM-yyyy') : 
                                                                                itemCuota[itemHeader.key])
                                                                            }}
    
                                                                            <!-- {{itemHeader.key}} -->
                                                                        </span>
                                                                    </td>
                                                                </ng-container>
                                                            </tr>
                                                          </table>
                                                        </div>
                                                    </div>
                                                </td>
                                            </div>
                                        </ng-container>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="break-box">
            <div class="row" *ngIf="conveniosPagados.length > 0">
                <div class="col-sm-12">
                    <div class="card textos-campo">
                        <h5 class="container-fluid card-header list-group-item cuadro-color" >
                            Convenios Pagados
                        </h5>
                        <div class="card-body">
                            <div class="col-12">
                                <div class="table-responsive">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <ng-container *ngFor="let item of headerConveniosPagados">
                                                    <th class="border-0">{{item?.value}}</th>
                                                </ng-container>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <ng-container *ngFor="let itemConvenio of conveniosPagados">
                                                <tr [ngClass]="{'cursor-pointer': (itemConvenio), 'bg-primary': itemConvenio.isExpanded}" (click)="itemConvenio.isExpanded = !itemConvenio.isExpanded;">
                                                    <ng-container *ngFor="let item of headerConveniosPagados">
                                                        <td>
                                                            <span *ngIf="itemConvenio[item.key] || itemConvenio[item.key] == 0">
                                                                {{ 
                                                                    camposEnteros.includes(item.key) ? (itemConvenio[item.key] | number: '1.0-0') : 
                                                                    (camposFechas.includes(item.key) ? (itemConvenio[item.key] | date: 'dd-MM-yyyy') : 
                                                                    itemConvenio[item.key])
                                                                }}
                                                            </span>
                                                        </td>
                                                    </ng-container>
                                                </tr>

                                                <!-- Datos Convenio -->
                                            <tr *ngIf="itemConvenio.isExpanded">
                                                <td colspan="5">
                                                    <div class="expanded-panel">
                                                        <div class="table-container">
                                                          <table class="table border">
                                                            <tr class="header-row">
                                                                <ng-container *ngFor="let itemHeader of headerDatosConvenio">
                                                                    <th class="border-0">{{itemHeader?.value}}</th>
                                                                </ng-container>
                                                            </tr>
                                                            <tr class="table-row" *ngIf="itemConvenio.datos">
                                                                <ng-container *ngFor="let itemHeader of headerDatosConvenio">
                                                                    <td class="cell">
                                                                        <span *ngIf="itemConvenio.datos[itemHeader.key] || itemConvenio.datos[itemHeader.key] == 0">
                                                                            {{ 
                                                                                camposEnteros.includes(itemHeader.key) ? (itemConvenio.datos[itemHeader.key] | number: '1.0-0') : 
                                                                                (camposFechas.includes(itemHeader.key) ? (itemConvenio.datos[itemHeader.key] | date: 'dd-MM-yyyy') : 
                                                                                itemConvenio.datos[itemHeader.key])
                                                                            }}
                                                                        </span>
                                                                    </td>
                                                                </ng-container>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                            <!-- Deudas Convenio -->
                                            <tr *ngIf="itemConvenio.isExpanded && itemConvenio.datos">
                                                <td colspan="5">
                                                    <div class="expanded-panel">
                                                        <div class="table-container">
                                                            <h5>Deudas</h5>
                                                            <table class="table border">
                                                                <tr class="header-row">
                                                                    <ng-container *ngFor="let itemHeader of headerDeudasConvenio">
                                                                        <th class="border-0">{{itemHeader?.value}}</th>
                                                                    </ng-container>
                                                                </tr>
                                                                <tr class="table-row" *ngFor="let itemDeduda of itemConvenio.datos.deudas">
                                                                    <ng-container *ngFor="let itemHeader of headerDeudasConvenio">
                                                                        <td class="cell">
                                                                            <span *ngIf="itemDeduda[itemHeader.key] || itemDeduda[itemHeader.key] == 0">
                                                                                {{ 
                                                                                camposEnteros.includes(itemHeader.key) ? (itemDeduda[itemHeader.key] | number: '1.0-0') : 
                                                                                (camposFechas.includes(itemHeader.key) ? (itemDeduda[itemHeader.key] | date: 'dd-MM-yyyy') : 
                                                                                itemDeduda[itemHeader.key])
                                                                            }}

                                                                            <!-- {{itemHeader.key}} -->
                                                                        </span>
                                                                    </td>
                                                                </ng-container>
                                                            </tr>
                                                          </table>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <!-- Ver Cuotas -->
                                            <button *ngIf="itemConvenio.isExpanded && itemConvenio.datos && !itemConvenio.datos.seeCuotas" type="button" class="btn btn-link" (click)="itemConvenio.datos.seeCuotas = true">Ver Cuotas</button>
                                            <button *ngIf="itemConvenio.isExpanded && itemConvenio.datos && itemConvenio.datos.seeCuotas" type="button" class="btn btn-link" (click)="itemConvenio.datos.seeCuotas = false">Cerrar Cuotas</button>
                                            <div *ngIf="itemConvenio.datos && itemConvenio.datos.seeCuotas">
                                                <td colspan="5">
                                                    <div class="expanded-panel">
                                                        <div class="table-container">
                                                            <h5>Cuotas</h5>
                                                            <table class="table border">
                                                                <tr class="header-row">
                                                                    <ng-container *ngFor="let itemHeader of headerCuotasConvenio">
                                                                        <th class="border-0">{{itemHeader?.value}}</th>
                                                                    </ng-container>
                                                                </tr>
                                                                <tr class="table-row" *ngFor="let itemCuota of itemConvenio.datos.cuotas">
                                                                    <ng-container *ngFor="let itemHeader of headerCuotasConvenio">
                                                                        <td class="cell">
                                                                            <span *ngIf="itemCuota[itemHeader.key] || itemCuota[itemHeader.key] == 0">
                                                                                {{ 
                                                                                camposEnteros.includes(itemHeader.key) ? (itemCuota[itemHeader.key] | number: '1.0-0') : 
                                                                                (camposFechas.includes(itemHeader.key) ? (itemCuota[itemHeader.key] | date: 'dd-MM-yyyy') : 
                                                                                itemCuota[itemHeader.key])
                                                                            }}

                                                                            <!-- {{itemHeader.key}} -->
                                                                        </span>
                                                                    </td>
                                                                </ng-container>
                                                            </tr>
                                                        </table>
                                                        </div>
                                                    </div>
                                                </td>
                                            </div>
                                            </ng-container>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    </div>
    
    <div class="break-box" [ngClass]="{'no-print': isResumida}">
        <!-- TOTAL PAGOS REALIZADOS -->
        <div class="row mt-5">
            <div class="col-sm-12">
                <div class="card textos-campo">
                    <h5 class="container-fluid card-header list-group-item cuadro-color" >
                        Total Pagos Realizados
                    </h5>
                    <div class="card-body">
                        <div class="col-12">
                            <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th colspan="2">Voluntarios</th>
                                            <th colspan="2">No Voluntarios</th>
                                        </tr>
                                        <tr>
                                            <ng-container *ngFor="let item of headerPagosTotales">
                                                <th class="border-0">{{item?.value}}</th>
                                            </ng-container>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <ng-container *ngFor="let item of headerPagosTotales">
                                                <td>
                                                    <span *ngIf="pagosTotal[item.key] || pagosTotal[item.key] == 0">
                                                        {{ 
                                                            camposEnteros.includes(item.key) ? (pagosTotal[item.key] | number: '1.0-0') : 
                                                            (camposFechas.includes(item.key) ? (pagosTotal[item.key] | date: 'dd-MM-yyyy') : 
                                                            pagosTotal[item.key])
                                                        }}
                                                    </span>
                                                </td>
                                            </ng-container>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- DETALLE PAGOS VOLUNTARIOS -->
        <div class="row" *ngIf="pagosVoluntarios.length > 0">
            <div class="col-sm-12">
                <div class="card textos-campo">
                    <h5 class="container-fluid card-header list-group-item cuadro-color" >
                        Detalle Pagos Voluntarios
                    </h5>
                    <div class="card-body">
                        <div class="col-12">
                            <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <ng-container *ngFor="let item of headerPagosVoluntarios">
                                                <th class="border-0">{{item?.value}}</th>
                                            </ng-container>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let itemPagos of pagosVoluntarios">
                                            <ng-container *ngIf="itemPagos && objectKeys(itemPagos).length > 0">
                                                <ng-container *ngFor="let item of headerPagosVoluntarios">
                                                    <td>
                                                        <span *ngIf="itemPagos[item.key] || itemPagos[item.key] == 0">
                                                            {{ 
                                                                camposEnteros.includes(item.key) ? (itemPagos[item.key] | number: '1.0-0') : 
                                                                (camposFechas.includes(item.key) ? (itemPagos[item.key] | date: 'dd-MM-yyyy') : 
                                                                itemPagos[item.key])
                                                            }}
                                                        </span>
                                                    </td>
                                                </ng-container>
                                            </ng-container>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="pagosNoVoluntarios.length > 0">
            <div class="col-sm-12">
                <div class="card textos-campo">
                    <h5 class="container-fluid card-header list-group-item cuadro-color" >
                        Detalle Pagos No Voluntarios
                    </h5>
                    <div class="card-body">
                        <div class="col-12">
                            <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <ng-container *ngFor="let item of headerPagosNoVoluntarios">
                                                <th class="border-0">{{item?.value}}</th>
                                            </ng-container>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let itemPagos of pagosNoVoluntarios">
                                            <ng-container *ngIf="itemPagos && objectKeys(itemPagos).length > 0">
                                                <ng-container *ngFor="let item of headerPagosNoVoluntarios">
                                                    <td>
                                                        <span *ngIf="itemPagos[item.key] || itemPagos[item.key] == 0">
                                                            {{ 
                                                                camposEnteros.includes(item.key) ? (itemPagos[item.key] | number: '1.0-0') : 
                                                                (camposFechas.includes(item.key) ? (itemPagos[item.key] | date: 'dd-MM-yyyy') : 
                                                                itemPagos[item.key])
                                                            }}
                                                        </span>
                                                    </td>
                                                </ng-container>
                                            </ng-container>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- TOTAL PAGOS RECIBIDOS -->
        <div class="row mt-5">
            <div class="col-sm-12">
                <div class="card textos-campo">
                    <h5 class="container-fluid card-header list-group-item cuadro-color" >
                        Total Pagos Recibidos
                    </h5>
                    <div class="card-body">
                        <div class="col-12">
                            <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th colspan="2">Renta</th>
                                            <th colspan="2">Devoluciones</th>
                                            <th colspan="2">Bonificaciones</th>
                                            <th colspan="2">Subvenciones</th>
                                        </tr>
                                        <tr>
                                            <ng-container *ngFor="let item of headerPagosRecTotales">
                                                <th class="border-0">{{item?.value}}</th>
                                            </ng-container>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <ng-container *ngFor="let item of headerPagosRecTotales">
                                                <td>
                                                    <span *ngIf="pagosRecTotal[item.key] || pagosRecTotal[item.key] == 0">
                                                        {{ 
                                                            camposEnteros.includes(item.key) ? (pagosRecTotal[item.key] | number: '1.0-0') : 
                                                            (camposFechas.includes(item.key) ? (pagosRecTotal[item.key] | date: 'dd-MM-yyyy') : 
                                                            pagosRecTotal[item.key])
                                                        }}
                                                    </span>
                                                </td>
                                            </ng-container>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- DETALLE PAGOS RECIBIDOS RENTA -->
        <div class="row" *ngIf="pagosRecRenta.length > 0">
            <div class="col-sm-12">
                <div class="card textos-campo">
                    <h5 class="container-fluid card-header list-group-item cuadro-color" >
                        Detalle Pagos Recibidos Renta
                    </h5>
                    <div class="card-body">
                        <div class="col-12">
                            <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <ng-container *ngFor="let item of headerPagosRenta">
                                                <th class="border-0">{{item?.value}}</th>
                                            </ng-container>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let itemPagos of pagosRecRenta">
                                            <ng-container *ngFor="let item of headerPagosRenta">
                                                <td>
                                                    <span *ngIf="itemPagos[item.key] || itemPagos[item.key] == 0">
                                                        {{ 
                                                            camposEnteros.includes(item.key) ? (itemPagos[item.key] | number: '1.0-0') : 
                                                            (camposFechas.includes(item.key) ? (itemPagos[item.key] | date: 'dd-MM-yyyy') : 
                                                            itemPagos[item.key])
                                                        }}
                                                    </span>
                                                </td>
                                            </ng-container>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="row" *ngIf="pagosRecDevoluciones.length > 0">
            <div class="col-sm-12">
                <div class="card textos-campo">
                    <h5 class="container-fluid card-header list-group-item cuadro-color" >
                        Detalle Pagos Recibidos Devoluciones
                    </h5>
                    <div class="card-body">
                        <div class="col-12">
                            <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <ng-container *ngFor="let item of headerPagosDevoluciones">
                                                <th class="border-0">{{item?.value}}</th>
                                            </ng-container>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let itemPagos of pagosRecDevoluciones">
                                            <ng-container *ngFor="let item of headerPagosDevoluciones">
                                                <td>
                                                    <span *ngIf="itemPagos[item.key] || itemPagos[item.key] == 0">
                                                        {{ 
                                                            camposEnteros.includes(item.key) ? (itemPagos[item.key] | number: '1.0-0') : 
                                                            (camposFechas.includes(item.key) ? (itemPagos[item.key] | date: 'dd-MM-yyyy') : 
                                                            itemPagos[item.key])
                                                        }}
                                                    </span>
                                                </td>
                                            </ng-container>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row" *ngIf="pagosRecBonificaciones.length > 0" [ngClass]="{'no-print': isResumida}">
        <div class="col-sm-12">
            <div class="card textos-campo">
                <h5 class="container-fluid card-header list-group-item cuadro-color" >
                    Detalle Pagos Recibidos Bonificaciones
                </h5>
                <div class="card-body">
                    <div class="col-12">
                        <div class="table-responsive">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <ng-container *ngFor="let item of headerPagosBonificaciones">
                                            <th class="border-0">{{item?.value}}</th>
                                        </ng-container>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let itemPagos of pagosRecBonificaciones">
                                        <ng-container *ngFor="let item of headerPagosBonificaciones">
                                            <td>
                                                <span *ngIf="itemPagos[item.key] || itemPagos[item.key] == 0">
                                                    {{ 
                                                        camposEnteros.includes(item.key) ? (itemPagos[item.key] | number: '1.0-0') : 
                                                        (camposFechas.includes(item.key) ? (itemPagos[item.key] | date: 'dd-MM-yyyy') : 
                                                        itemPagos[item.key])
                                                    }}
                                                </span>
                                            </td>
                                        </ng-container>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row" *ngIf="pagosRecSubvenciones.length > 0" [ngClass]="{'no-print': isResumida}">
        <div class="col-sm-12">
            <div class="card textos-campo">
                <h5 class="container-fluid card-header list-group-item cuadro-color" >
                    Detalle Pagos Recibidos Subvenciones
                </h5>
                <div class="card-body">
                    <div class="col-12">
                        <div class="table-responsive">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <ng-container *ngFor="let item of headerPagosSubvenciones">
                                            <th class="border-0">{{item?.value}}</th>
                                        </ng-container>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let itemPagos of pagosRecSubvenciones">
                                        <ng-container *ngFor="let item of headerPagosSubvenciones">
                                            <td>
                                                <span *ngIf="itemPagos[item.key] || itemPagos[item.key] == 0">
                                                    {{ 
                                                        camposEnteros.includes(item.key) ? (itemPagos[item.key] | number: '1.0-0') : 
                                                        (camposFechas.includes(item.key) ? (itemPagos[item.key] | date: 'dd-MM-yyyy') : 
                                                        itemPagos[item.key])
                                                    }}
                                                </span>
                                            </td>
                                        </ng-container>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    
    <div class="break-box" [ngClass]="{'no-print': isResumida}">
        <div class="row mt-5" *ngIf="registroMercadoPublicoDeudas.length > 0">
            <div class="col-sm-12">
                <div class="card textos-campo">
                    <h5 class="container-fluid card-header list-group-item cuadro-color" >
                        Registro Mercado Publico Deudas
                    </h5>
                    <div class="card-body">
                        <div class="col-12">
                            <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <ng-container *ngFor="let item of headerRegistroMercadoPublicoDeudas">
                                                <th class="border-0">{{item?.value}}</th>
                                            </ng-container>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let itemRegistro of registroMercadoPublicoDeudas">
                                            <ng-container *ngFor="let item of headerRegistroMercadoPublicoDeudas">
                                                <td>
                                                    <span *ngIf="itemRegistro[item.key] || itemRegistro[item.key] == 0">
                                                        {{ 
                                                            camposEnteros.includes(item.key) ? (itemRegistro[item.key] | number: '1.0-0') : 
                                                            (camposFechas.includes(item.key) ? (itemRegistro[item.key] | date: 'dd-MM-yyyy') : 
                                                            itemRegistro[item.key])
                                                        }}
                                                    </span>
                                                </td>
                                            </ng-container>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="no-print">
        <div class="row mt-5" *ngIf="errores.length > 0">
            <div class="col-sm-12">
                <div class="card textos-campo">
                    <h5 class="container-fluid card-header list-group-item cuadro-color" >
                        Detalle Errores
                    </h5>
                    <div class="card-body">
                        <div class="col-12">
                            <ul class="list-group list-group-light">
                                <li class="list-group-item list-group-item-action d-flex justify-content-between align-items-center my-2 list-group-error" *ngFor="let item of errores">
                                    <div class="col-12">
                                    <div class="text-error-color fw-bold"><b>{{ item.title || '' }}</b></div>
                                    <div class="text-muted">{{ item.message || '' }}</div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    
<!-- </div> -->

</div>