import { Component, Input, OnInit, OnDestroy, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
//import { SimpleModalService } from 'ngx-simple-modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { SolicitudCreditoService } from 'src/app/shared/services/solicitud-credito.service';
import { ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';
import { AlertService } from '../../_alert';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-ficha-analisis',
  templateUrl: './ficha-analisis.component.html',
  styleUrls: ['./ficha-analisis.component.scss']
})
export class FichaAnalisisComponent implements OnInit, OnDestroy {
  @Output() closeSolicitud = new EventEmitter<string>();
  @Input() informacionCliente: any;
  @Input() tipoSolicitud = '';
  @Input() idTransaccion = '';
  @Input() rut = '';
  @Input() statusTX = '';
  @Input() validEdition: boolean = false;
  public montoAprobado: number = 0;
  public sociedadNum = 0;
  public sociedadText = '';
  public estadoAprobacion = 'aprobado';
  public estadoGarantia = 'boletaGarantia';
  public estadoDocumentacion = 'cheque';
  public data: any[] = [];
  public estadoAprobacionSel: any[] = [];
  public antecedentesSalfa: any[] = [];
  public garantiaSel: any[] = [];
  public documentacionSel: any[] = [];
  public estadoAprobacionForm!: UntypedFormGroup;
  public subscriptionForm!: Subscription;
  public informe: any[] = [];
  public informeRiesgo: any[] = [];
  public seriadosNoSeriados: any = { seriados: 0, noSeriados: 0 };
  public lastValuesDeudaSbig: any = {};
  public lastValuesDeudaBancaria: any = {};
  public lastValuesDeudaMorosaBancaria: any = {};
  public noSeriadosVal: number = 0;
  public deudas: any = {};
  private subscriptions: Subscription[] = [];
  private enableInput: boolean = false;
  private valuesForm: any[] = [];


  constructor(
    private solicitudCreditoService: SolicitudCreditoService,
    private cdRef: ChangeDetectorRef,
    private fb: UntypedFormBuilder,
    //private simpleModalService: SimpleModalService,
    private spinner: NgxSpinnerService,
    private alertService: AlertService,
    public dialog: MatDialog) { }

  ngOnInit(): void {
    this.deudaVigenteBancaria();
    this.enableInput = this.statusTX !== 'CLOSED' ? false : true;

    this.valuesForm = this.informacionCliente?.SolicitudDeCredito?.TablasInformesCalculados?.datosCalculados || [];
    this.informe.push(this.informacionCliente?.SolicitudDeCredito);
    this.setInformeRiesgo();
    this.setEstadoAprobacion();
    this.setGarantia();
    this.setDocumentos();
    this.obtenerPromedios();
    this.initForm();
    this.obtenerLastValuesDeudaSbif();
    this.deudaMorosaBancaria();
  }

  validarDetalleGrande4(): boolean{
    const tamanioSegmento: any = this.informacionCliente?.SolicitudDeCredito?.Reportes?.basicReport?.AntecedentesFinancieros?.TamanoSubsegmento || '';
    const cteErrors: any = this.informacionCliente?.SolicitudDeCredito?.Reportes?.carpetaTributariaElectronica?.hasErrors || '';
    const cteObjeto: any = this.informacionCliente?.SolicitudDeCredito?.Reportes?.carpetaTributariaElectronica?.CarpetaTributariaElectronicaReporte;

    if(tamanioSegmento.toUpperCase() === 'GRANDE 4' && (cteErrors != false || !cteObjeto)){
      return true;
    }
    return false;
  }

  initForm(): void {
    const valores = this.findObjEstadoAprobacion();
    
    this.estadoAprobacionForm = this.fb.group({
      estadoAprobacion: [{
        value: (valores?.estadoAprobacion ? valores.estadoAprobacion : ''),
        disabled: this.enableInput
      }, [Validators.required]]
    });

    this.enableInput ? (
      valores?.estadoAprobacion === 'aprobado' ? this.addFieldsAprobados() : valores?.estadoAprobacion === 'rechazado' ?
        this.addFieldsRechazados() : this.addFieldsAprobadosCondicion()
    ) : this.eventForm();
  }

  eventForm() {
    this.subscriptionForm = this.estadoAprobacionForm.valueChanges.subscribe((form) => {
      const formValues = this.estadoAprobacionForm.getRawValue();
      if (form.estadoAprobacion) {
        formValues.estadoAprobacion === 'aprobado' ? this.addFieldsAprobados() : formValues.estadoAprobacion === 'rechazado' ?
          this.addFieldsRechazados() : this.addFieldsAprobadosCondicion();
      }
    });
  }
  addFieldsAprobadosCondicion(): void {
    const valores = this.findObjEstadoAprobacion();

    if (!this.enableInput) {
      this.subscriptionForm.unsubscribe();
    }

    if (this.tipoSolicitud !== 'lineaDeCredito') {
      this.estadoAprobacionForm.addControl('garantia', new UntypedFormControl({
        value: (valores?.garantia ? valores?.garantia : ''),
        disabled: this.enableInput
      }, Validators.required));
      this.estadoAprobacionForm.addControl('documentacion', new UntypedFormControl({
        value: (valores?.documentacion ? valores?.documentacion : ''),
        disabled: this.enableInput
      }, Validators.required));
      this.estadoAprobacionForm.addControl('pie', new UntypedFormControl({
        value: (valores?.pie ? valores?.pie : ''),
        disabled: this.enableInput
      }));
      this.estadoAprobacionForm.addControl('plazo', new UntypedFormControl({
        value: (valores?.plazo ? valores?.plazo : ''),
        disabled: this.enableInput
      }, Validators.required));
    }
    this.estadoAprobacionForm.addControl('areaComercial', new UntypedFormControl({
      value: (valores?.areaComercial ? valores?.areaComercial : ''),
      disabled: this.enableInput
    }));
    this.estadoAprobacionForm.addControl('comentariosGlosa', new UntypedFormControl({
      value: (valores?.comentariosGlosa ? valores?.comentariosGlosa : ''),
      disabled: this.enableInput
    }));
    if (this.tipoSolicitud === 'lineaDeCredito') {
      this.estadoAprobacionForm.addControl('montoAprobado', new UntypedFormControl({
        value: (valores?.montoAprobado ? valores?.montoAprobado : ''),
        disabled: this.enableInput
      }, [Validators.required]));
    }
    this.eventForm();
  }

  findObjEstadoAprobacion(): any {
    const objTemp = this.valuesForm.find((e: any) => Object.keys(e)[0] === 'estadoAprobacion');
    if (objTemp) {      
      return Object.keys(objTemp.estadoAprobacion).length === 1 ? objTemp.estadoAprobacion.estadoAprobacion : Object.keys(objTemp.estadoAprobacion).length > 1? objTemp.estadoAprobacion: {};
    }
    return {};
  }

  addFieldsRechazados(): void {
    const valores = this.findObjEstadoAprobacion();
    
    if (!this.enableInput) {
      this.subscriptionForm.unsubscribe();
    }

    this.estadoAprobacionForm.addControl('comentariosGlosa', new UntypedFormControl({
      value: (valores?.comentariosGlosa ? valores?.comentariosGlosa : ''),
      disabled: this.enableInput
    }));
    this.estadoAprobacionForm.removeControl('areaComercial');
    this.estadoAprobacionForm.removeControl('garantia');
    this.estadoAprobacionForm.removeControl('documentacion');
    this.estadoAprobacionForm.removeControl('pie');
    this.estadoAprobacionForm.removeControl('plazo');
    this.estadoAprobacionForm.removeControl('montoAprobado');
    this.eventForm();
  }

  addFieldsAprobados(): void {
    const valores = this.findObjEstadoAprobacion();
    if (!this.enableInput) {
      this.subscriptionForm.unsubscribe();
    }

    if (this.tipoSolicitud !== 'lineaDeCredito') {
      this.estadoAprobacionForm.addControl('garantia', new UntypedFormControl({
        value: (valores?.garantia ? valores?.garantia : ''),
        disabled: this.enableInput
      }, Validators.required));
    }

    this.estadoAprobacionForm.addControl('areaComercial', new UntypedFormControl({
      value: (valores?.areaComercial ? valores?.areaComercial : ''),
      disabled: this.enableInput
    }));
    this.estadoAprobacionForm.addControl('comentariosGlosa', new UntypedFormControl({
      value: (valores?.comentariosGlosa ? valores?.comentariosGlosa : ''),
      disabled: this.enableInput
    }));

    if (this.tipoSolicitud === 'lineaDeCredito') {
      this.estadoAprobacionForm.addControl('montoAprobado', new UntypedFormControl({
        value: (valores?.montoAprobado ? valores?.montoAprobado : ''),
        disabled: this.enableInput
      }, [Validators.required]));
    }

    this.estadoAprobacionForm.removeControl('documentacion');
    this.estadoAprobacionForm.removeControl('pie');
    this.estadoAprobacionForm.removeControl('plazo');
    this.eventForm();
  }

  setInformeRiesgo(): void {
    const keyUrl = 'rulesEngine-reporte';
    const keyObj = 'riesgoIndustria';
    const keyFieldsReport = ['score', 'protestosInternosVigentesMonto', 'protestosInternosVigentesCantidad',
      'morosidadesInternas', 'morosidadesInternasMayores30'];
    const objGeneral: any = {};
    const riesgo = this.informacionCliente?.SolicitudDeCredito?.Reportes?.[keyUrl]?.Reporte || [];
    if (riesgo.length > 0) {
      riesgo.forEach((element: any) => {
        if (keyFieldsReport.includes(element.name)) {
          if (element.name === 'score') {
            objGeneral[element.name] = Number(element.value).toFixed(0) || 0;
          } else {
            objGeneral[element.name] = element.value || '';
          }
        }
      });

      if (Object.keys(objGeneral).length > 0) {
        objGeneral[keyObj] = this.informacionCliente?.SolicitudDeCredito?.Reportes?.basicReport?.Riesgo?.RiesgoIndustria || '';
      }
      this.informeRiesgo.push(objGeneral);
    }
  }

  submit() {
    if (!this.estadoAprobacionForm.valid) {
      this.solicitudCreditoService.validateAllFormFields(this.estadoAprobacionForm);
      if (this.estadoAprobacionForm.controls.estadoAprobacion.value) {
        this.validMontoAprobado();
      }
      return;
    }

    this.spinner.show();
    const ObjCalculados = this.solicitudCreditoService.getObjResumen();
    if (this.tipoSolicitud === 'lineaDeCredito') {
      if (this.validarToCloseReporte()) {        
        this.solicitudCreditoService.setUpdateEstadoAprobracion(this.estadoAprobacionForm.getRawValue());
        this.solicitudCreditoService.cerrarSolicitud(this.idTransaccion, this.rut, ObjCalculados).subscribe(resp => {
          this.spinner.hide();
          this.closedSolicitud();
        }, (error: any) => {
          this.alertService.error(error?.error?.message || 'Ocurrio un Error');
          this.spinner.hide();
        });
      } else {
        this.spinner.hide();
        /*this.simpleModalService.addModal(ConfirmDialogComponent, {
          title: 'Solicitud de Crédito',
          message: `Debe completar la linea de crédito para poder cerrar la solicitud.`,
          hideCancelmButtom: true
        });*/
        this.dialog.open(ConfirmDialogComponent, {
          data: {
            title: 'Solicitud de Crédito',
            message: `Debe completar la linea de crédito para poder cerrar la solicitud.`,
            hideCancelmButtom: true
          }
        });
      }
    } else {
      this.solicitudCreditoService.setUpdateEstadoAprobracion(this.estadoAprobacionForm.getRawValue());
      this.solicitudCreditoService.cerrarSolicitud(this.idTransaccion, this.rut, ObjCalculados).subscribe(resp => {
        this.spinner.hide();
        this.closedSolicitud();
      }, (error: any) => {
        this.alertService.error(error?.error?.message || 'Ocurrio un Error');
        this.spinner.hide();
      });
    }

  }

  validMontoAprobado(): void {
    if (this.tipoSolicitud === 'lineaDeCredito' && this.estadoAprobacionForm.controls['estadoAprobacion'].value !== 'rechazado') {
      if (!this.estadoAprobacionForm.controls['montoAprobado'].value || this.estadoAprobacionForm.controls['montoAprobado'].value === 0) {
        /*this.simpleModalService.addModal(ConfirmDialogComponent, {
          title: 'Solicitud de Crédito',
          message: `Debe ingresar el monto aprobado para poder cerrar la solicitud.`,
          hideCancelmButtom: true
        });*/
        this.dialog.open(ConfirmDialogComponent, {
          data: {
            title: 'Solicitud de Crédito',
            message: `Debe ingresar el monto aprobado para poder cerrar la solicitud.`,
            hideCancelmButtom: true
          }
        });
      }
    }
  }

  validarToCloseReporte(): boolean {
    if (this.informacionCliente?.SolicitudDeCredito?.Reportes?.camposPersonalizadosAutomatic?.length > 0) {
      let response: boolean = false;
      this.solicitudCreditoService.getObjResumenValidation('lineaCredito').subscribe((resp: boolean) => {
        response = resp;
      });
      return response;
    } else {
      return true;
    }

  }

  obtenerValorCamposPersonalizados(key: string) {
    return this.informacionCliente?.SolicitudDeCredito?.Reportes?.camposPersonalizados?.Atributos
      .find((e: any) => e.nameAttribute === key)?.value || '';
  }

  obtenerMorosidadSocios(key: string): string {
    if (this.informacionCliente?.SolicitudDeCredito?.Socios.length > 0) {
      return this.informacionCliente?.SolicitudDeCredito?.Socios
        .filter((e: any) => e.Reportes?.basicReportInformeComercial?.AntecedentesComerciales?.Resumen?.[key] > 0)
        .reduce((a: any, b: any) => {
          return a + Number(b.Reportes?.basicReportInformeComercial?.AntecedentesComerciales?.
            Resumen?.[key]) || 0;
        }, 0);
    }
    return '';
  }

  obtenerLastValorDeudaSbif(key: string, property: string, segments?: string[]): string {
    let objTemp: any[] = [];
    const objDeudaSbif = this.informacionCliente?.SolicitudDeCredito?.Reportes?.leasingSbif?.Reporte?.Sbif;
    if (segments) {
      if (objDeudaSbif) {
        objTemp = objDeudaSbif?.Reporte?.[[segments[0]][0]]?.[[segments[1]][0]]?.filter(
          (e: any) => e.Item === key) || [];
      }
    }

    if ((!segments && objDeudaSbif?.Reporte?.[key]) || objTemp.length > 0) {
      let obj = this.solicitudCreditoService.obtenerUltimoObj({
        [key]: !segments && objDeudaSbif?.Reporte?.[key] !== undefined ? objDeudaSbif?.Reporte?.[key] : objTemp.length > 0 ? objTemp : []
      });

      return obj[0][0][key][property] || '';
    }
    return '';
  }

  obtenerLastValorDeudaLeasing(key: string): any {
    const objDeudaLeasing = this.informacionCliente?.SolicitudDeCredito?.Reportes?.leasingSbif?.Reporte?.Leasing?.Reporte?.Deudas;
    let obj: any = [];
    let tempObj: any = {};

    if (objDeudaLeasing) {
      const objTemp = this.solicitudCreditoService.agruparObjetos(objDeudaLeasing);
      for (const element of Object.keys(objTemp)) {
        //Sumar periodos duplicados
        const temporal = objTemp[element].reduce((acumulador: any, valorActual: any) => {
          const elementoYaExiste = acumulador.find((elemento: any) => elemento.Item === valorActual.Item && elemento.Periodo === valorActual.Periodo);
          if (elementoYaExiste) {
            return acumulador.map((elemento: any) => {
              if (elemento.id_producto === valorActual.id_producto && elemento.Periodo === valorActual.Periodo) {
                return {
                  ...elemento,
                  Valor: Number(elemento.Valor) + Number(valorActual.Valor)
                }
              }
              return elemento;
            });
          }

          return [...acumulador, valorActual];
        }, []);

        tempObj[element] = temporal;
      }
      obj = this.solicitudCreditoService.obtenerUltimoObj({
        deudasLeasing: tempObj
      });

      const temp: any = obj[0];

      for (let keyObj of Object.keys(temp)) {

        if (temp[keyObj][key]) {
          if (temp[keyObj][key].Valor || temp[keyObj][key].Valor === 0) {
            return temp[keyObj][key];
          }
        }
      }
    }
    return {};
  }

  getTotal(civil: string, cobranza: string, laboral: string): number {
    return Number(civil) + Number(cobranza) + Number(laboral);
  }

  setEstadoAprobacion(): void {
    this.subscriptions.push(this.solicitudCreditoService.getEstadoAprobacion().subscribe(resp => {
      this.estadoAprobacionSel = resp;
    }));
  }

  obtenerValorRulesEngine(key: string): string {
    if (this.informacionCliente?.SolicitudDeCredito?.Reportes?.['rulesEngine-reporte']?.Reporte) {
      if (Object.keys(this.informacionCliente?.SolicitudDeCredito?.Reportes?.['rulesEngine-reporte']?.Reporte).length > 0) {
        return this.informacionCliente?.SolicitudDeCredito?.Reportes?.['rulesEngine-reporte']?.Reporte
          .find((e: any) => e.name === key)?.value || '';
      }
      return '';
    }
    return '';
  }

  setGarantia(): void {
    this.subscriptions.push(this.solicitudCreditoService.getGarantias().subscribe(resp => {
      this.garantiaSel = resp;
    }));
  }

  setDocumentos(): void {
    this.subscriptions.push(this.solicitudCreditoService.getDocumentos().subscribe(resp => {
      this.documentacionSel = resp;
    }));
  }

  setNumber(num: string): number {
    return Number(num);
  }

  calcularAntiguedad(fecha: string) {
    const a = moment(new Date().toISOString().slice(0, 10));
    const b = moment(new Date(this.solicitudCreditoService.formatDate(fecha)));
    let result = a.diff(b, 'years');

    return result ? 'Antiguo' : 'Nuevo';
  }

  obtenerValorCamposPersonalizadosAutomatic(key: string): any[] {
    if (this.informacionCliente?.SolicitudDeCredito?.Reportes?.camposPersonalizadosAutomatic) {
      if (this.informacionCliente?.SolicitudDeCredito?.Reportes?.camposPersonalizadosAutomatic?.SolicitudCredito?.length > 0) {
        return this.informacionCliente?.SolicitudDeCredito?.Reportes?.camposPersonalizadosAutomatic?.SolicitudCredito
          .find((e: any) => e.nameAttribute === key)?.values || [];
      }
      return [];
    }
    return [];
  }

  obtenerPromedios() {
    const objTemp: any[] = [];
    const objResponse = this.obtenerValorCamposPersonalizadosAutomatic('item');
    const keyValue = 'Fact';
    const keysValues = ['ImporteML', 'Periodo', 'UnidadNegocio'];
    const objImporte = this.convertirMiles(this.obtenerValorCamposPersonalizadosAutomatic(keysValues[0]));
    const objPeriodo = this.obtenerValorCamposPersonalizadosAutomatic(keysValues[1]);
    const objUnidadNegocio = this.obtenerValorCamposPersonalizadosAutomatic(keysValues[2]);
    const keysObjs = [objImporte, objPeriodo, objUnidadNegocio];
    const actualDate = new Date().getFullYear();
    const periodosDate = [actualDate - 1, actualDate];
    const seriados = ['Maquinaria Agricola', 'Autycam', 'Camiones', 'Maquinaria Const. y Forest.', 'Equipos Especiales'];
    const noSeriados = ['Servicio Pesado', 'Salfa Rent', 'Servicio Liviano', 'Corportativos & Generales', 'Repuestos', 'Neumaticos y Baterias', 'Servicio Agricola'];

    if (objResponse.length > 0) {
      //Obtener posicion de la key Fact
      let idx = objResponse.indexOf(keyValue);
      while (idx != -1) {
        objTemp.push({ position: idx });
        idx = objResponse.indexOf(keyValue, idx + 1);
      }

      //Obtener valores de la key
      if (objTemp.length > 0) {
        keysValues.forEach((e: any, i: number) => {
          objTemp.forEach((f: any) => {
            f[e] = e !== 'UnidadNegocio' ? (keysObjs[i][f.position] || '') : keysObjs[i][f.position] || '';
          });
        });
      }

      //Obtener promedio de unidadNegocio para seriados y no seriados
      const tempSeriados = objTemp.filter((e: any) => seriados.includes(e.UnidadNegocio));
      const tempNoSeriados = objTemp.filter((e: any) => noSeriados.includes(e.UnidadNegocio));

      tempSeriados.length > 0 ?
        this.seriadosNoSeriados.seriados = tempSeriados.reduce((acc: any, cur: any) => {
          return acc + Number(cur.ImporteML);
        }, 0) / tempSeriados.length : 0;

      tempNoSeriados.length > 0 ?
        this.seriadosNoSeriados.noSeriados = tempNoSeriados.reduce((acc: any, cur: any) => {
          return acc + Number(cur.ImporteML);
        }, 0) / tempNoSeriados.length : 0;
      this.solicitudCreditoService.ResumenObjSubject.next({ data: { promediosSeriadosNoSeriados: this.seriadosNoSeriados }, origin: 'promediosSeriadosNoSeriados' })
    }
  }

  quitarEspacios(str: any): any {
    if (typeof str === 'string') {
      return str.replace(/\s/g, '_');
    } else {
      return str.map((e: any) => e.replace(/\s/g, '_'));
    }
  }

  setFechaPeriodo(fecha: string): Date {
    const year = fecha.substring(0, 4);
    const month = fecha.substring(4, 6);
    const day = '02';
    return new Date(Number(year), (Number(month) - 1), Number(day));
  }

  convertirMiles(obj: number[]): number[] {
    if (obj.length > 0) {
      return obj.map((e: number) => {
        return e / 1000;
      });
    }
    return [];
  }

  obtenerLastValuesDeudaSbif() {
    this.lastValuesDeudaSbig['creditosContingentes'] = this.obtenerLastValorDeudaSbif('CreditosContingentes', 'Valor', ['TiposDeCredito', 'CreditoDirecto']) || 0;
    this.lastValuesDeudaSbig['lcBancarias'] = this.obtenerLastValorDeudaSbif('LineasDeCreditoDisponible', 'Valor') || 0;
  }

  obtenerFecha() {
    return this.obtenerLastValorDeudaSbif('LineasDeCreditoDisponible', 'Periodo') || '';
  }

  closedSolicitud(): void {
    this.estadoAprobacionForm.disable();
    this.closeSolicitud.emit('CLOSED');
  }

  deudaVigenteBancaria() {
    if (this.statusTX !== 'CLOSED') {
      this.lastValuesDeudaBancaria['deudaVigenteSbif'] = this.obtenerLastValorDeudaSbif('AldiaeImp<30dias', 'Valor', ['TiposDeCredito', 'CreditoDirecto']) || 0;
      if(!this.lastValuesDeudaBancaria['deudaVigenteSbif']) {
        this.lastValuesDeudaBancaria['deudaVigenteSbif'] = this.obtenerLastValorDeudaSbif('AlliaeImp<30dias', 'Valor', ['TiposDeCredito', 'CreditoDirecto']) || 0;
      }
      const temp = this.obtenerLastValorDeudaLeasing('DeudaDirectaVigente');
      if (Object.keys(temp).length > 0) {
        this.spinner.show();
        const periodo = this.solicitudCreditoService.obtenerPeriodo(temp.Periodo)
        this.subscriptions.push(this.solicitudCreditoService.calcularUF(periodo).subscribe((resp: any) => {
          this.spinner.hide();
          resp ? this.lastValuesDeudaBancaria['deudaVigenteLeasing'] = temp.Valor * resp.valores[0].valor || 0 : 0;
          this.solicitudCreditoService.ResumenObjSubject.next({ data: { deudasBancaria: this.lastValuesDeudaBancaria }, origin: 'deudasBancaria' })
        }, (error: any) => {
          this.spinner.hide();
          this.alertService.error(error.error.message || 'Ocurrio un Error');
        }));
      } else {
        this.lastValuesDeudaBancaria['deudaVigenteLeasing'] = "0";
        this.solicitudCreditoService.ResumenObjSubject.next({ data: { deudasBancaria: this.lastValuesDeudaBancaria }, origin: 'deudasBancaria' })
      }
    } else {
      const objetoLineaCreditoReduce = this.informacionCliente?.SolicitudDeCredito?.TablasInformesCalculados?.datosCalculados || [];
      this.lastValuesDeudaBancaria = objetoLineaCreditoReduce.length > 0 ? objetoLineaCreditoReduce.find((e: any) => Object.keys(e)[0] === 'deudasBancaria')?.deudasBancaria || {} : {};
      if(this.lastValuesDeudaBancaria?.deudasBancaria){
        this.lastValuesDeudaBancaria = this.lastValuesDeudaBancaria?.deudasBancaria
      }
    }
  }

  deudaMorosaBancaria() {

    if (this.statusTX !== 'CLOSED') {
      const temp: any[] = [];
      const keyValues = ['Morosa>30Dias', 'Vencida>90Dias', 'Castigada>360Dias'];

      const impagos30 = this.setNumber(this.obtenerLastValorDeudaSbif('Impagos>=30<90dias', 'Valor', ['TiposDeCredito', 'CreditoDirecto'])) || 0;
      const impagos90 = this.setNumber(this.obtenerLastValorDeudaSbif('Impagos>=90<180dias', 'Valor', ['TiposDeCredito', 'CreditoDirecto'])) || 0;
      const impagos180 = this.setNumber(this.obtenerLastValorDeudaSbif('Impagos>=180<3anos', 'Valor', ['TiposDeCredito', 'CreditoDirecto'])) || 0;
      const impagosMayor3 = this.setNumber(this.obtenerLastValorDeudaSbif('Impagos>=3anos', 'Valor', ['TiposDeCredito', 'CreditoDirecto'])) || 0;
      this.lastValuesDeudaMorosaBancaria['impagosSbif'] = impagos30 + impagos90 + impagos180 + impagosMayor3;

      keyValues.forEach(element => {
        if (this.obtenerLastValorDeudaLeasing(element).Periodo) {
          this.spinner.show();
          const periodo = this.solicitudCreditoService.obtenerPeriodo(this.obtenerLastValorDeudaLeasing(element).Periodo)
          this.subscriptions.push(this.solicitudCreditoService.calcularUF(periodo).subscribe((resp: any) => {
            this.spinner.hide();
            temp.push(resp ? this.obtenerLastValorDeudaLeasing(element).Valor * (resp.valores[0].valor ? resp.valores[0].valor : 0) : 0);

            this.lastValuesDeudaMorosaBancaria['impagosLeasing'] = temp.length > 0 ? (temp.reduce((valorAnterior, valorActual) => {
              return valorAnterior + valorActual;
            })) : 0;
            this.solicitudCreditoService.ResumenObjSubject.next({ data: { deudasMorosaBancaria: this.lastValuesDeudaMorosaBancaria }, origin: 'deudasMorosaBancaria' })
          }, (error: any) => {
            this.spinner.hide();
            this.alertService.error(error.error.message || 'Ocurrio un Error');
          }));
        } else {
          this.lastValuesDeudaMorosaBancaria['impagosLeasing'] = 0;
          this.solicitudCreditoService.ResumenObjSubject.next({ data: { deudasMorosaBancaria: this.lastValuesDeudaMorosaBancaria }, origin: 'deudasMorosaBancaria' })
        }
      });
    } else {
      const objetoLineaCreditoReduce = this.informacionCliente?.SolicitudDeCredito?.TablasInformesCalculados?.datosCalculados || [];
      this.lastValuesDeudaMorosaBancaria = objetoLineaCreditoReduce.length > 0 ? objetoLineaCreditoReduce.find((e: any) => Object.keys(e)[0] === 'deudasMorosaBancaria')?.deudasMorosaBancaria || {} : {};
      if(this.lastValuesDeudaMorosaBancaria?.deudasMorosaBancaria){
        this.lastValuesDeudaMorosaBancaria = this.lastValuesDeudaMorosaBancaria?.deudasMorosaBancaria
      }
    }
  }

  sendMontoAprobado(): void {
    const form = this.estadoAprobacionForm.controls;

    this.montoAprobado = Number(form.montoAprobado.value || 0);
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

}
