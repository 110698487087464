import { AfterViewInit, Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable, timer } from 'rxjs';

export interface ConfirmModel {
  title: string;
  f29Tablas?: any[];
}

@Component({
  selector: 'app-modal-f29',
  templateUrl: './modal-f29.component.html',
  styleUrls: ['./modal-f29.component.scss']
})
export class ModalF29Component /*implements AfterViewInit*/ {
  @Input() title: string = "";
  @Input() access: string[] = [];

  public counter$: Observable<number> = new Observable;
  public counter = 0;
  public f29Tablas: any[] = [];

  constructor(public dialogRef: MatDialogRef<ModalF29Component>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmModel) {
    this.title = this.data?.title || '';
    if(this.data?.f29Tablas && this.data?.f29Tablas.length > 0){
      this.f29Tablas = this.data?.f29Tablas;
    }
  }
  
    //confirm
    confirm() {
      // we set modal result as true on click on confirm button,
      // then we can get modal result from caller code
      // this.result = true;
      // this.close();
      this.dialogRef.close(true);
    }
  
    //cancel
    close() {
      this.dialogRef.close(false);
    }
  
    /*async ngAfterViewInit(): Promise<void> {
      if (this.confirmData.textTimer > 0) {
        this.counter$ = await timer(0, 1000).pipe(
          take(this.textTimer),
          map(() => {
            return --this.textTimer
          }));
      }
    }*/

}
