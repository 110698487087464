import { Injectable } from '@angular/core';
import { create, all } from 'mathjs';
import { Subscription } from 'rxjs';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { UtilsService } from './utils.service';

const math = create(all);

@Injectable({
  providedIn: 'root'
})
export class CpMathFunctionsService extends UtilsService {

  private modelo: string | undefined;
  private isArray: boolean | undefined;
  private formOrArray: any;
  private campos: any[] | undefined;

  initializeMathFunctions() {
    // Importa las funciones personalizadas solo si no existen
    const originalMathEqual = math.equal;

    math.import({
      valor: (name: string) => {
        if (this.isArray) {
          const element = (this.formOrArray as any[]).find((element: any) => element.nameAttribute === name);
          if (!element) {
            console.warn(`No se ha encontrado campo '${name}'.`);
            return false; // Devuelve false si el campo no existe
          }
          return element.value ?? element.defaultValue ?? false; // Devuelve el valor o el valor por defecto, o false si ambos son undefined
        } else {
          const campo = this.campos ? this.getCampo(name, this.formOrArray, this.campos) : null;
          if (!campo) {
            console.warn(`No se ha encontrado campo '${name}'.`);
            return false;
          }
          return campo.value ?? false; // Devuelve false si el valor es undefined
        }
      },
      valorAv: (name: string) => {
        if (this.isArray) {
          const element = (this.formOrArray as any[]).find((element: any) => element.nameAttribute === name);
          if (!element) {
            console.warn(`No se ha encontrado campo '${name}'.`);
            return false; // Devuelve false si el campo no existe
          }
          return element.allowedValues[element.value] ?? element.allowedValues[element.defaultValue] ?? false; // Devuelve el valor permitido o el valor permitido por defecto, o false si ambos son undefined
        } else {
          const campo = this.campos ? this.getCampo(name, this.formOrArray, this.campos) : null;
          if (!campo) {
            console.warn(`No se ha encontrado campo '${name}'.`);
            return false;
          }
          return campo.value2 ?? false; // Devuelve false si el valor es undefined
        }
      },
      equal: function (x: any, y: any): boolean {
        //check if both values are of type string
        if (typeof x === "string" && typeof y === "string") {
          return !!math.equalText(x, y);
        }
        if((x == 0 && y === '') || (y == 0 && x === '')) {
          return false;
        }
        //strict equality for null and undefined?
        if (x === null) {
          return y === null;
        }
        if (y === null) {
          return x === null;
        }
        if (x === undefined) {
          return y === undefined;
        }
        if (y === undefined) {
            return x === undefined;
        }
        return !!originalMathEqual(x, y);
      },
      unequal: function (x: any, y: any): boolean {
        return !math.equal(x, y);
      },
      if: function (cond: any, trueExpr: any, falseExpr: any) {
        if (Boolean(cond)) {
          return trueExpr
        }
        return falseExpr
      },
      switch: function (expr: any, ...args: any) {
        if (args.length % 2 !== 0) {
          console.warn(`Switch debe tener un número par de argumentos.`);
          return false;
        }
        for (let i = 0; i < args.length; i += 2) {
          if (math.equal(expr, args[i])) {
            return args[i + 1]
          }
        }
      }
    }, 
    {
      override: true
    });
  }

  getCampo(name: string, form: any, campos: any[]): any {
    const control = form.get(name);
    if (control) {
      const campo = campos.find(c => c.nameAttribute === name);
      return {
        value: control.value,
        value2: campo ? campo.allowedValues[control.value] : undefined,
        editable: control.enabled
      };
    }
    return null;
  }

  setContext(modelo: string | undefined, isArray: boolean | undefined, formOrArray: any | undefined, campos: any[] | undefined) {
    modelo != undefined ? this.modelo = modelo : null;
    isArray != undefined ? this.isArray = isArray : null;
    formOrArray != undefined ? this.formOrArray = formOrArray : null;
    campos != undefined ? this.campos = campos : null;
  }

  processFormula(formula: string): string {
    // Reemplaza todas las ocurrencias de la palabra 'modelo' en la fórmula
    return formula.replace(/modelo/g, `'${this.modelo}'`);
  }


  processFormulaArray(formula: string, array: any[], modelo: string): string {
    this.setContext(modelo, true, array, undefined);

    return formula.replace(/modelo/g, `'${modelo}'`);
  }

  evaluateFormula(formula: string): any {
    const processedFormula = this.processFormula(formula);
    return math.evaluate(processedFormula);
  }

  evaluateFormulaArray(formula: string, array: any[], modelo: string): any {
    const processedFormula = this.processFormulaArray(formula, array, modelo);
    return math.evaluate(processedFormula);
  }

  addEventFormDependientes(subscriptionForm: Subscription): Subscription {
    if (!subscriptionForm) {
      subscriptionForm = new Subscription();
    }

    const camposExpresion: { campo: string, formula: string }[] = [];
    const camposValidacion: { campo: string, formula: string }[] = [];
    const camposEditable: { campo: string, formula: string }[] = [];

    if(this.campos) {
      for (const campo of this.campos) {
        if (campo.creditRequestActive && campo.enabled && this.formOrArray.get(campo.nameAttribute)) {
          if (campo.expression) {
            camposExpresion.push({ campo: campo.nameAttribute, formula: campo.expression });
          }
          if (campo.validation) {
            camposValidacion.push({ campo: campo.nameAttribute, formula: campo.validation });
          }
          if (campo.editable && (campo.editable != true && campo.editable != false) && typeof campo.editable === 'string') {
            camposEditable.push({ campo: campo.nameAttribute, formula: campo.editable });
          }
        }
      }
    }

    const subscribeToChanges = (campo: { campo: string, formula: string }, form: UntypedFormGroup, callback: (value: any) => void) => {
      const control = form.get(campo.campo);
      if (control) {
        subscriptionForm.add(control.valueChanges.subscribe(callback));
      }
    };

    const extractCampos = (formula: string): string[] => {
      // Encuentra todas las coincidencias de valor('campo') y valorAv('campo')
      const camposValor = formula.match(/valor\(['"].*?['"]\)/g) || []; 
      const camposAv = formula.match(/valorAv\(['"].*?['"]\)/g) || [];
      
      const camposValorLimpios = camposValor.map(c => c.replace(/valor\(['"]|['"]\)/g, '')); 
      const camposAvLimpios = camposAv.map(c => c.replace(/valorAv\(['"]|['"]\)/g, ''));

      // Combina los resultados y elimina duplicados usando un Set
      const camposUnicos = new Set([...camposValorLimpios, ...camposAvLimpios]);

      // Convierte el Set de nuevo a un array
      const camposFinales = Array.from(camposUnicos);
      // Devuelve el array final con valores únicos
      return camposFinales;
    };

    // CREAR EVENTOS PARA LOS CAMPOS QUE APARECEN EN LAS FORMULAS DE EXPRESSION
    for (const campoExpresion of camposExpresion) {
      const keyCampos = extractCampos(campoExpresion.formula);
      if (keyCampos.length > 0) {
        if (keyCampos.some(val => val != '' ? this.formOrArray.get(val) : this.formOrArray.get(campoExpresion.campo))) {
          for (const campo of keyCampos) {
            subscribeToChanges({ campo: campo, formula: campoExpresion.formula }, this.formOrArray, () => {
              const isNumber = typeof this.formOrArray.get(campoExpresion.campo)?.value === 'number';
              const formula = campoExpresion.formula.replace(/valor\(\)/g, isNumber ? `${this.formOrArray.get(campoExpresion.campo)?.value}` : `'${this.formOrArray.get(campoExpresion.campo)?.value}'`);
              const valor = this.evaluateFormula(formula);
              this.formOrArray.get(campoExpresion.campo)?.setValue(valor);
            });
          }
        }
      }
    }

    for (const campo of camposValidacion) {
      const keyCampos = extractCampos(campo.formula);
      if (keyCampos.length > 0) {
        if (keyCampos.some(val => val != '' ? this.formOrArray.get(val) : this.formOrArray.get(campo.campo))) {
          subscribeToChanges(campo, this.formOrArray, () => {
            const isNumber = typeof this.formOrArray.get(campo.campo)?.value === 'number';
            const formula = campo.formula.replace(/valor\(\)/g, isNumber ? `${this.formOrArray.get(campo.campo)?.value}` : `'${this.formOrArray.get(campo.campo)?.value}'`);
            const valorValidation = this.evaluateFormula(formula);
            if (valorValidation == 1) {
              //console.log("valor ok", valorValidation);
            } else {
              this.formOrArray.get(campo.campo)?.setErrors({ 'invalidValue': true });
            }
          });
        }
      }
    }

    for (const campoDisabled of camposEditable) {
      const keyCampos = extractCampos(campoDisabled.formula);
      if (keyCampos.length > 0) {
        for (const campo of keyCampos) {
          subscribeToChanges({ campo: campo, formula: campoDisabled.formula }, this.formOrArray, () => {
            const isNumber = typeof this.formOrArray.get(campoDisabled.campo)?.value === 'number';
            const formula = campoDisabled.formula.replace(/valor\(\)/g, isNumber ? `${this.formOrArray.get(campoDisabled.campo)?.value}` : `'${this.formOrArray.get(campoDisabled.campo)?.value}'`);
            const valorValidation = this.evaluateFormula(formula);
            const control = this.formOrArray.get(campoDisabled.campo);
            if (/*valorValidation == 1 || */valorValidation == false) {
              control?.setValue('', { emitEvent: false });
              control?.disable();
              control?.clearValidators();
            } else if(/* valorValidation == 0 ||*/ valorValidation == true) {
              control?.enable();
              const campoContrato = this.campos ? this.campos.find(c => c.nameAttribute === campoDisabled.campo) : undefined;
              if (campoContrato) {
                if (campoContrato.typeAttibute === 'Number' || this.objectKeys(campoContrato.allowedValues).length > 0) {
                  control?.setValidators([Validators.required, Validators.pattern("^[0-9]*$")]);
                } else {
                  control?.setValidators([Validators.required]);
                }
              }
            }
          });
        }
      }
    }

    return subscriptionForm;
  }
}
