import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AlertService } from 'src/app/components/_alert';
import { IEmpresaEnUnDiaCreateReportResponse } from 'src/app/interface/IEmpresaEnUnDiaCreateReportResponse';
import { IEmpresaEnUnDiaGetReportResponse } from 'src/app/interface/IEmpresaEnUnDiaGetReportResponse';
import { IEmpresaEnUnDiaGetReportStatusResponse } from 'src/app/interface/IEmpresaEnUnDiaGetReportStatusResponse';
import { IEmpresaEnUnDiaSearchReportResponse } from 'src/app/interface/IEmpresaEnUnDiaSearchReportResponse';
import { environment } from 'src/environments/environment';
import { SessionService } from './session.service';
import { getHeaders } from '../utils/headersUtils';

interface ICreateReport {
  rut: string;
}

@Injectable({
  providedIn: 'root'
})
export class EmpresaEnUnDiaService {
  private endpoint: string = environment.backend.empresaEnUnDia.url;
  private reportReady: boolean = true;
  private refreshDataSubject: Subject<IEmpresaEnUnDiaGetReportStatusResponse> = new Subject();
  public refreshDataObj$ = this.refreshDataSubject.asObservable();

  constructor(
    private http: HttpClient,
    private _sessionService: SessionService,
    public alertService: AlertService
  ) { }

  public createReport(rut: string): Observable<IEmpresaEnUnDiaCreateReportResponse> {

    this.reportReady = false;

    const payload: ICreateReport = {
      rut
    }
    const headers = getHeaders(this._sessionService.getOrganization(), this._sessionService.getUserInfo().username, 'WEB');

    return this.http.post<IEmpresaEnUnDiaCreateReportResponse>(this.endpoint + "crearReporte", payload, { headers: headers })

  }

  /*public getStatusReport(idTransaccion: string): Observable<IEmpresaEnUnDiaGetReportStatusResponse> {

    let params = new HttpParams();
    params = params.append("id_transaccion", idTransaccion);
    params = params.append("servicio", this.servicio);
    params = params.append("uri", 'obtenerReporte');
    params = params.append("idOrganizacion", this._sessionService.getOrganization());
    params = params.append("idUsuario", this._sessionService.getUserInfo().username);
    params = params.append("requestOrigin", 'WEB');

    return this.http.get<IEmpresaEnUnDiaGetReportStatusResponse>(this.endpoint + "obtener", { params });
  }*/

  public getReport(idTransaccion: string): Observable<IEmpresaEnUnDiaGetReportResponse> {

    let params = new HttpParams();
    params = params.append("id_transaccion", idTransaccion);
    const headers = getHeaders(this._sessionService.getOrganization(), this._sessionService.getUserInfo().username, 'WEB');

    return this.http.get<IEmpresaEnUnDiaGetReportResponse>(this.endpoint + "buscarTX", { params, headers });
  }

  public searchReportsList(rut: string, type: string): Observable<IEmpresaEnUnDiaSearchReportResponse[]> {

    let params = new HttpParams();
    params = params.append("rut", rut);
    params = params.append("type", type);
    const headers = getHeaders(this._sessionService.getOrganization(), this._sessionService.getUserInfo().username, 'WEB');

    return this.http.get<IEmpresaEnUnDiaSearchReportResponse[]>(this.endpoint + "buscarReportes", { params, headers });
  }

  public empresaEnUnDiaReportLastUpdated(rut: string, type: string): Observable<IEmpresaEnUnDiaGetReportResponse> {

    let params = new HttpParams();
    params = params.append("rut", rut);
    params = params.append("type", type);
    const headers = getHeaders(this._sessionService.getOrganization(), this._sessionService.getUserInfo().username, 'WEB');

    return this.http.get<IEmpresaEnUnDiaGetReportResponse>(this.endpoint + "buscarUltimo", { params, headers });
  }

  public getReportReady() {
    return this.reportReady;
  }

}
