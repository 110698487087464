<div class="panel panel-default border-0 not-print">
    <div class="panel-body">
        <div class="btn-option">

            <button *ngIf="showReportes && stepper === 5" [printTitle]='"FlujoDeCredito_"+(rut | rutFormat)+"_"+today'
                [useExistingCss]="true" printSectionId="print-section" ngxPrint id="printButton"
                class="d-none">Descargar</button>
            <button class="btn btn-primary float-right" *ngIf="showReportes && stepper === 5" (click)="descargarPDF()">Descargar PDF</button>
        </div>
    </div>
</div>

<div class="col-sm-12"i d="print-section">
    <div class="break-box">
    
        <div class="row p-4">

            <!--titulo-->
            <div class="col-sm-12">
                <h1 class="text-center">Panel consolidado de riesgos</h1>
            </div>
        
            <div class="col-sm-12">
        
                <div class="d-flex flex-row-reverse">
                    <!--Periodo-->
                    <div class="col-sm-6 col-lg-3 px-cuadros my-1">
                        <a class="widget widget-hover-effect1 no-print">
                            <div class="widget-extra bg-primary">
                                <h4 class="widget-content-light text-center">Periodo ultima consulta</h4>
                            </div>
                            <div class="overflow-auto">
                                <h1 class="widget-content text-right animation-pullDown" style="font-size: 20px;">
                                    <strong *ngIf="datosFichas?.fechaReporte">{{datosFichas?.fechaReporte || ''}}</strong><br>
                                </h1>
                            </div>
                        </a>
                
                        <div class="card border-0 d-none">
                            <div class="card-header">
                                <h4 class="text-white">Periodo ultima consulta</h4>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <h1 class="text-right" style="font-size: 20px;">
                                            <strong>{{datosFichas?.fechaReporte || ''}}</strong><br>
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
    
                </div>
            </div>

            <div class="col-sm-12 col-md-6 full-size-print break-box">

                <div class="card">
                    <div class="card-body">
                        {{datosFichas?.razonSocial}} - {{datosFichas?.rutEmpresa ? (datosFichas?.rutEmpresa | rutFormat) : ''}}
                    </div>
                </div>

                <div class="card mt-3">
                    <div class="card-header bg-primary pointer-cursor" (click)="abrirModalTitular()">
                        Información básica del cliente
                        <i class="fa fa-info-circle pointer-cursor ml-1 not-print" aria-hidden="true"></i>
                    </div>
                    <div class="card-body">
                        <ul *ngIf="informacionBasicaCliente && informacionBasicaCliente.length > 0">
                            <ng-container *ngFor="let item of informacionBasicaCliente">
                                <li>
                                    <strong>{{item.text}}:</strong>
                                    <ng-container *ngTemplateOutlet="valueTemplate; context: { $implicit: {item: item} }"></ng-container>
                                </li>
                            </ng-container>
                        </ul>
                    </div>
                </div>

                <div class="card mt-3">
                    <div class="card-body">
                        Boletín Concursal <strong>{{datosFichas?.boletinConcursal || ''}}</strong>
                    </div>
                </div>

                <div class="card mt-3">
                    <div class="card-body">
                        Cliente difícil fiscalización <strong>{{datosFichas?.clienteDificilFiscalicion || ''}}</strong>
                    </div>
                </div>

                <div class="card mt-3">
                    <div class="card-body">
                        <strong>Detalle Socios</strong>
                    </div>
                </div>
                <div class="col-12 mt-1 px-0">
                    <div class="table-responsive" *ngIf="showReportes">
                        <table class="table table-hover table-bordered border">
                            <thead>
                                <tr>
                                    <th scope="col" class="py-1 bg-primary font-head-size">Rut Socio</th>
                                    <th scope="col" class="py-1 bg-primary font-head-size">Nombre</th>
                                    <th scope="col" class="py-1 bg-primary font-head-size">Fecha Vinculación</th>
                                    <th scope="col" class="py-1 bg-primary font-head-size">Participacón</th>
                                    <th scope="col" class="py-1 bg-primary font-head-size">Monto Moroso</th>
                                </tr>
                            </thead>
                            <tbody>
    
                                <ng-container *ngIf="tablaSocios.length > 0; else sinDatosSocios">
                                    <ng-container *ngIf="stepper === 4 && plantillaNoEditable === false else sociosNoEditable">
                                        <ng-container *ngFor="let item of tablaSocios; let i = index">
                                            <tr class="height-row">
                                                <td class="pointer-cursor" (click)="abrirModal(tablaSocios[i].entidad, tablaSocios[i].rut)">{{item.nombre}}</td>
                                                <td class="pointer-cursor text-nowrap" (click)="abrirModal(tablaSocios[i].entidad, tablaSocios[i].rut)">{{item.rut | rutFormat}}</td>
                                                <td class="pointer-cursor" (click)="abrirModal(tablaSocios[i].entidad, tablaSocios[i].rut)">
                                                    <ng-container *ngTemplateOutlet="valueTemplate; context: { $implicit: {item: {value: item.fechaVinculacion, type: 'date'}} }"></ng-container>
                                                </td>
                                                <td>
                                                    <input type="text" class="form-control" title="Ingrese un numero entero o decimal" id='socio-participacion{{i}}' name='socio-participacion{{i}}' [(ngModel)]="tablaSocios[i].participacion">
                                                </td>
                                                <td class="pointer-cursor" (click)="abrirModal(tablaSocios[i].entidad, tablaSocios[i].rut)">
                                                    <ng-container *ngTemplateOutlet="valueTemplate; context: { $implicit: {item: {value: item.montoMoroso, type: 'decimal'}} }"></ng-container>
                                                </td>
                                            </tr>
                                        </ng-container>
                                    </ng-container>
                                    <ng-template #sociosNoEditable>
                                        <ng-container *ngFor="let item of tablaSocios; let i = index">
                                            <tr class="height-row pointer-cursor" (click)="abrirModal(tablaSocios[i].entidad, tablaSocios[i].rut)">
                                                <td>{{item.nombre}}</td>
                                                <td class="text-nowrap">{{item.rut | rutFormat}}</td>
                                                <td>
                                                    <ng-container *ngTemplateOutlet="valueTemplate; context: { $implicit: {item: {value: item.fechaVinculacion, type: 'date'}} }"></ng-container>
                                                </td>
                                                <td>
                                                    <ng-container *ngTemplateOutlet="valueTemplate; context: { $implicit: {item: {value: item.participacion, type: 'decimal'}} }"></ng-container>
                                                </td>
                                                <td>
                                                    <ng-container *ngTemplateOutlet="valueTemplate; context: { $implicit: {item: {value: item.montoMoroso, type: 'decimal'}} }"></ng-container>
                                                </td>
                                            </tr>
                                        </ng-container>
                                    </ng-template>
                                </ng-container>
                                <ng-template #sinDatosSocios>
                                    <tr class="height-row">
                                        <td colspan="100%">Sin información</td>
                                    </tr>
                                </ng-template>
                                
                            </tbody>
                        </table>
                    </div>
                </div>

                <div class="col-12 mt-4 px-0">
                    <div class="table-responsive table-entidad">
                        <table id="balances-dair" class="table table-hover table-vcenter table-condensed table-bordered">
                            <thead>
                                <tr>
                                    <th class="text-center bg-primary" rowspan="2">$M</th>
                                    <th class="bg-primary text-center" colspan="2">Balances(DAIR)</th>
                                </tr>
                                <tr>
                                    <th class="bg-primary text-center">{{tablaBalance?.anioAnterior?.anio || ''}}</th>
                                    <th class="bg-primary text-center">{{tablaBalance?.anioActual?.anio || ''}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngIf="objectKeys(tablaBalance).length > 0; else elseBalance">
                                    <tr>
                                        <td class="text-center">Ventas</td>
                                        <td class="text-center">{{validarValor(tablaBalance?.anioAnterior?.ventas) ? ('$' + (tablaBalance?.anioAnterior?.ventas | number: '1.0-0')) : ''}}</td>
                                        <td class="text-center">{{validarValor(tablaBalance?.anioActual?.ventas) ? ('$' + (tablaBalance?.anioActual?.ventas | number: '1.0-0')) : ''}}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">Utilidad</td>
                                        <td class="text-center">{{validarValor(tablaBalance?.anioAnterior?.utilidad) ? ('$' + (tablaBalance?.anioAnterior?.utilidad | number: '1.0-0')) : ''}}</td>
                                        <td class="text-center">{{validarValor(tablaBalance?.anioActual?.utilidad) ? ('$' + (tablaBalance?.anioActual?.utilidad | number: '1.0-0')) : ''}}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">Patrimonio</td>
                                        <td class="text-center">{{validarValor(tablaBalance?.anioAnterior?.patrimonio) ? ('$' + (tablaBalance?.anioAnterior?.patrimonio | number: '1.0-0')) : ''}}</td>
                                        <td class="text-center">{{validarValor(tablaBalance?.anioActual?.patrimonio) ? ('$' + (tablaBalance?.anioActual?.patrimonio | number: '1.0-0')) : ''}}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-center">Act Fijo</td>
                                        <td class="text-center">{{validarValor(tablaBalance?.anioAnterior?.actFijo) ? ('$' + (tablaBalance?.anioAnterior?.actFijo | number: '1.0-0')) : ''}}</td>
                                        <td class="text-center">{{validarValor(tablaBalance?.anioActual?.actFijo) ? ('$' + (tablaBalance?.anioActual?.actFijo | number: '1.0-0')) : ''}}</td>
                                    </tr>
                                </ng-container>
                                <ng-template #elseBalance>
                                    <tr>
                                        <td colspan="100%">Sin información</td>
                                    </tr>
                                </ng-template>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div class="card mt-3">
                    <div class="card-body pointer-cursor" (click)="showF29()">
                        <strong>Status IVAS’s últimos 12 meses</strong>
                        <i class="fa fa-info-circle pointer-cursor ml-1 not-print" aria-hidden="true" title="Abrir mas información sobre iva"></i>
                    </div>
                </div>
                <h4 class="col-12 border-0 shadow-lg p-3 my-4 pointer-cursor rounded-header" (click)="show('body-status')">
                    <div class="row">
                        <div class="col-11">
                            <i class="fa fa-circle text-danger" aria-hidden="true"></i> Análisis últimos IVAS
                            <span class="badge badge-success ml-3">{{statusIva12Meses?.totales?.pagado}}</span> Pagado
                            <span class="badge badge-info ml-3">{{statusIva12Meses?.totales?.noPagado}}</span> No Pagado
                            <span class="badge badge-warning ml-3">{{statusIva12Meses?.totales?.postergado}}</span> Postergado
                            <span class="badge badge-danger ml-3">{{statusIva12Meses?.totales?.noPublicado}}</span> No Publicado
                        </div>
                        <div class="col-1">
                            <i class="fa fa-angle-down" aria-hidden="true"></i>
                        </div>
                    </div>
                </h4>
                <div class="container-desplegables" id="body-status">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <ng-container *ngIf="statusIva12Meses?.body && statusIva12Meses?.body.length > 0">
                                    <ng-container *ngFor="let item of statusIva12Meses.body">
                                        <div class="col-3">
                                            <ng-container [ngSwitch]="item?.estado">
                                                <ng-container *ngSwitchCase="'noPublicado'">
                                                    <i class="fa fa-square text-danger" aria-hidden="true"></i> 
                                                </ng-container>
                                                <ng-container *ngSwitchCase="'pagado'">
                                                    <i class="fa fa-square text-success" aria-hidden="true"></i> 
                                                </ng-container>
                                                <ng-container *ngSwitchCase="'noPagado'">
                                                    <i class="fa fa-square text-info" aria-hidden="true"></i> 
                                                </ng-container>
                                                <ng-container *ngSwitchCase="'postergado'">
                                                    <i class="fa fa-square text-warning" aria-hidden="true"></i> 
                                                </ng-container>
                                                <ng-container *ngSwitchDefault>
                                                
                                                </ng-container>
                                                {{item?.mesName + ' ' + item?.periodo}}
                                            </ng-container>
                                        </div>
                                    </ng-container>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card mt-3">
                    <div class="card-body">
                        Monto total deuda morosa TGR ($CLP) <strong>
                            <ng-container *ngTemplateOutlet="valueTemplate; context: { $implicit: {item: {value: datosFichas.montoTotalVentaMorosaTGR, type: 'decimal'}} }"></ng-container>
                        </strong>
                    </div>
                </div>

                <div class="col-12 mt-4 px-0">
                    <div class="table-responsive table-entidad">
                        <table id="balances-dair" class="table table-hover table-vcenter table-condensed table-bordered">
                            <thead>
                                <tr>
                                    <th class="bg-primary text-center" colspan="100%">Certificado de deuda Fiscal</th>
                                </tr>
                                <tr>
                                    <th class="bg-primary">rut</th>
                                    <th class="bg-primary">Girador</th>
                                    <th class="bg-primary">form</th>
                                    <th class="bg-primary">folio</th>
                                    <th class="bg-primary">Vencimiento</th>
                                    <th class="bg-primary">Deuda Neta</th>
                                    <th class="bg-primary">Total</th>
                                    <th class="bg-primary">N° Resolución</th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngIf="deudaFiscal && deudaFiscal.length > 0; else elseDeudaFiscal">
                                    <tr *ngFor="let item of deudaFiscal">
                                        <td class="text-right">{{item?.rut ? (item?.rut | rutFormat) : ''}}</td>
                                        <td class="text-right">{{item?.girador || ''}}</td>
                                        <td class="text-right">{{item?.formulario}}</td>
                                        <td class="text-right">{{item?.folio}}</td>
                                        <td class="text-right">{{item?.fechaVencimiento ? (item?.fechaVencimiento | date: 'dd-MM-yyyy') : '-'}}</td>
                                        <td class="text-right">
                                            <ng-container *ngTemplateOutlet="valueTemplate; context: { $implicit: {item: {value: item?.deudaNeta, type: 'number-pesos'}} }"></ng-container>
                                        </td>
                                        <td class="text-right">
                                            <ng-container *ngTemplateOutlet="valueTemplate; context: { $implicit: {item: {value: item?.total, type: 'number-pesos'}} }"></ng-container>
                                        </td>
                                        <td class="text-right"></td>
                                    </tr>
                                </ng-container>
                                <ng-template #elseDeudaFiscal>
                                    <tr>
                                        <td colspan="100%">Sin información</td>
                                    </tr>
                                </ng-template>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div class="col-12 mt-4 px-0">
                    <div class="table-responsive table-entidad">
                        <table id="convenios-vigentes" class="table table-hover table-vcenter table-condensed table-bordered">
                            <thead>
                                <tr>
                                    <th class="bg-primary text-center" colspan="100%">Convenio vigentes</th>
                                </tr>
                                <tr>
                                    <th class="bg-primary">N° Resolución</th>
                                    <th class="bg-primary">Fecha Resolución</th>
                                    <th class="bg-primary">Saldo</th>
                                    <th class="bg-primary">Cuotas Morosas</th>
                                    <th class="bg-primary">Monto Moroso</th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngIf="convenioVigentes?.body && convenioVigentes?.body.length > 0; else elseConvenioVigentes">
                                    <tr *ngFor="let item of convenioVigentes?.body">
                                        <td class="text-right">{{item?.resolucion}}</td>
                                        <td class="text-right">{{item?.fechaVencimiento ? (item?.fechaVencimiento | date: 'dd-MM-yyyy') : ''}}</td>
                                        <td class="text-right">
                                            <ng-container *ngTemplateOutlet="valueTemplate; context: { $implicit: {item: {value: item?.montoLiquidado, type: 'number-pesos'}} }"></ng-container>
                                        </td>
                                        <td class="text-right">
                                            <ng-container *ngTemplateOutlet="valueTemplate; context: { $implicit: {item: {value: item?.datos?.cuotasVencidas, type: 'number'}} }"></ng-container>
                                        </td>
                                        <td class="text-right">
                                            <ng-container *ngTemplateOutlet="valueTemplate; context: { $implicit: {item: {value: item?.montoMoroso, type: 'number-pesos'}} }"></ng-container>
                                        </td>
                                    </tr>
                                </ng-container>
                                <ng-template #elseConvenioVigentes>
                                    <tr>
                                        <td colspan="100%">Sin información</td>
                                    </tr>
                                </ng-template>
                            </tbody>
                            <thead>
                                <tr>
                                    <th class="bg-primary text-right">Total</th>
                                    <th class="bg-primary"></th>
                                    <th class="bg-primary text-right">
                                        <ng-container *ngTemplateOutlet="valueTemplate; context: { $implicit: {item: {value: convenioVigentes?.totales?.totalSaldo, type: 'number-pesos'}} }"></ng-container>
                                    </th>
                                    <th class="bg-primary text-right">
                                        <ng-container *ngTemplateOutlet="valueTemplate; context: { $implicit: {item: {value: convenioVigentes?.totales?.totalCuotasVencidas, type: 'number'}} }"></ng-container>
                                    </th>
                                    <th class="bg-primary text-right">
                                        <ng-container *ngTemplateOutlet="valueTemplate; context: { $implicit: {item: {value: convenioVigentes?.totales?.totalMontoMoroso, type: 'number-pesos'}} }"></ng-container>
                                    </th>
                                </tr>
                            </thead>
                        </table>
                    </div>
                </div>

                <div class="col-12 mt-4 px-0">
                    <div class="table-responsive table-entidad">
                        <table id="nc-iva-ventas" class="table table-hover table-vcenter table-condensed table-bordered">
                            <thead>
                                <tr>
                                    <th class="bg-blue text-center">Meses</th>
                                    <th class="bg-blue text-center pointer-cursor" (click)="abrirModalCompraVenta()">
                                        Notas de Credito
                                        <i class="fa fa-info-circle pointer-cursor ml-1 not-print" aria-hidden="true" title="Para mas información revisar el modulo Libro Compra Venta"></i>
                                    </th>
                                    <th class="bg-blue text-center">IVA sobre Ventas</th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngIf="objectKeys(tablaIvaSobreVentas).length > 0; else elseIva">
                                    <tr>
                                        <th>Ultimos 12m</th>
                                        <td class="text center">{{validarValor(tablaIvaSobreVentas?.notasMeses12) ? ((tablaIvaSobreVentas?.notasMeses12 | number: '1.0-2') + '%') : ''}}</td>
                                        <td class="text center">{{validarValor(tablaIvaSobreVentas?.ivaMeses12) ? ((tablaIvaSobreVentas?.ivaMeses12 | number: '1.0-2') + '%') : ''}}</td>
                                    </tr>
                                    <tr>
                                        <th>Ultimos 6m</th>
                                        <td class="text center">{{validarValor(tablaIvaSobreVentas?.notasMeses6) ? ((tablaIvaSobreVentas?.notasMeses6 | number: '1.0-2') + '%') : ''}}</td>
                                        <td class="text center">{{validarValor(tablaIvaSobreVentas?.ivaMeses6) ? ((tablaIvaSobreVentas?.ivaMeses6 | number: '1.0-2') + '%') : ''}}</td>
                                    </tr>
                                    <ng-container *ngIf="tablaIvaSobreVentas.listaMeses && tablaIvaSobreVentas?.listaMeses.length > 0">
                                        <ng-container *ngFor="let number of [0,1,2,3,4,5]">
                                            <tr>
                                                <th>{{tablaIvaSobreVentas?.listaMeses[number].mes || ''}}</th>
                                                <td class="text center">{{validarValor(tablaIvaSobreVentas?.listaMeses[number].valueNotas) ? ((tablaIvaSobreVentas?.listaMeses[number].valueNotas | number: '1.0-2') + '%') : ''}}</td>
                                                <td class="text center">{{validarValor(tablaIvaSobreVentas?.listaMeses[number].valueIva) ? ((tablaIvaSobreVentas?.listaMeses[number].valueIva | number: '1.0-2') + '%') : ''}}</td>
                                            </tr>
                                        </ng-container>
                                    </ng-container>
                                </ng-container>
                                <ng-template #elseIva>
                                    <tr>
                                        <td colspan="100%">Sin información</td>
                                    </tr>
                                </ng-template>
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
        
            <div class="col-sm-12 col-md-6 full-size-print break-box">

                <div class="card">
                    <div class="card-body pointer-cursor" (click)="abrirModalCompraVenta()">
                        <strong>Evolución de las compras y ventas</strong>
                        <i class="fa fa-info-circle pointer-cursor ml-1 not-print" aria-hidden="true" title="Para mas información revisar el modulo Libro Compra Venta"></i>
                    </div>
                </div>

                <div class="col-12 mt-4">
                    <ng-container *ngIf="showReportes && graphEvolutivoCV && objectKeys(graphEvolutivoCV).length > 0">
                        <div [chart]="graphEvolutivoCV" class="graphContent"></div>
                    </ng-container>
                </div>

                <div class="col-12 mt-4 px-0 no-print">
                    <div class="table-responsive table-entidad">
                        <table id="detalle-documentos" class="table table-hover table-vcenter table-condensed table-bordered">
                            <thead>
                                <tr class="pointer-cursor" (click)="abrirModalCompraVentaCesion()">
                                    <th class="bg-primary text-center" colspan="100%">
                                        Detalle de los documentos (Últimos 12 meses)
                                        <i class="fa fa-info-circle pointer-cursor ml-1 not-print" aria-hidden="true" title="Para mas información revisar el modulo Libro Compra Venta"></i>
                                    </th>
                                </tr>
                                <tr>
                                    <th class="bg-primary">Razón Social</th>
                                    <th class="bg-primary">RUT</th>
                                    <th class="bg-primary">dv</th>
                                    <th class="bg-primary">Ventas</th>
                                    <th class="bg-primary">Compras</th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngIf="detalleDocumentos?.body && detalleDocumentos?.body.length > 0; else elseDetalleDocumentos">
                                    <tr *ngFor="let item of detalleDocumentos?.body | paginate:{itemsPerPage: currentItem, currentPage: currentPage}">
                                        <td>{{item?.nombre}}</td>
                                        <td>
                                            <ng-container *ngTemplateOutlet="valueTemplate; context: { $implicit: {item: {value: item?.rut, type: 'number'}} }"></ng-container>
                                        </td>
                                        <td>{{item?.dv}}</td>
                                        <td class="text-right">
                                            <ng-container *ngTemplateOutlet="valueTemplate; context: { $implicit: {item: {value: item?.ventas, type: 'number-pesos'}} }"></ng-container>
                                        </td>
                                        <td class="text-right">
                                            <ng-container *ngTemplateOutlet="valueTemplate; context: { $implicit: {item: {value: item?.compras, type: 'number-pesos'}} }"></ng-container>
                                        </td>
                                    </tr>
                                </ng-container>
                                <ng-template #elseDetalleDocumentos>
                                    <tr>
                                        <td colspan="100%">Sin información</td>
                                    </tr>
                                </ng-template>
                            </tbody>
                            <thead>
                                <tr>
                                    <th class="bg-primary text-right">Total</th>
                                    <th class="bg-primary"></th>
                                    <th class="bg-primary"></th>
                                    <th class="bg-primary text-right">
                                        <ng-container *ngTemplateOutlet="valueTemplate; context: { $implicit: {item: {value: detalleDocumentos?.totales?.totalVentas, type: 'number-pesos'}} }"></ng-container>
                                    </th>
                                    <th class="bg-primary text-right">
                                        <ng-container *ngTemplateOutlet="valueTemplate; context: { $implicit: {item: {value: detalleDocumentos?.totales?.totalCompras, type: 'number-pesos'}} }"></ng-container>
                                    </th>
                                </tr>
                            </thead>
                        </table>
                    </div>
                    <div class="col-sm-12 no-print">
                        <div id="pagination">
                            <div class="col-sm-12 col-md-8 float-right">
                                <pagination-controls (pageChange)="currentPage=$event"></pagination-controls>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-12 mt-4 px-0 d-none">
                    <div class="table-responsive table-entidad">
                        <table id="detalle-documentos-print" class="table table-hover table-vcenter table-condensed table-bordered">
                            <thead>
                                <tr>
                                    <th class="bg-primary text-center" colspan="100%">Detalle de los documentos (Últimos 12 meses)</th>
                                </tr>
                                <tr>
                                    <th class="bg-primary">Razón Social</th>
                                    <th class="bg-primary">RUT</th>
                                    <th class="bg-primary">dv</th>
                                    <th class="bg-primary">Ventas</th>
                                    <th class="bg-primary">Compras</th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngIf="detalleDocumentos?.body && detalleDocumentos?.body.length > 0; else elseDetalleDocumentos">
                                    <tr *ngFor="let item of detalleDocumentos?.body">
                                        <td>{{item?.nombre}}</td>
                                        <td>
                                            <ng-container *ngTemplateOutlet="valueTemplate; context: { $implicit: {item: {value: item?.rut, type: 'number'}} }"></ng-container>
                                        </td>
                                        <td>{{item?.dv}}</td>
                                        <td class="text-right">
                                            <ng-container *ngTemplateOutlet="valueTemplate; context: { $implicit: {item: {value: item?.ventas, type: 'number-pesos'}} }"></ng-container>
                                        </td>
                                        <td class="text-right">
                                            <ng-container *ngTemplateOutlet="valueTemplate; context: { $implicit: {item: {value: item?.compras, type: 'number-pesos'}} }"></ng-container>
                                        </td>
                                    </tr>
                                </ng-container>
                                <ng-template #elseDetalleDocumentos>
                                    <tr>
                                        <td colspan="100%">Sin información</td>
                                    </tr>
                                </ng-template>
                            </tbody>
                            <thead>
                                <tr>
                                    <th class="bg-primary text-right">Total</th>
                                    <th class="bg-primary"></th>
                                    <th class="bg-primary"></th>
                                    <th class="bg-primary text-right">
                                        <ng-container *ngTemplateOutlet="valueTemplate; context: { $implicit: {item: {value: detalleDocumentos?.totales?.totalVentas, type: 'number-pesos'}} }"></ng-container>
                                    </th>
                                    <th class="bg-primary text-right">
                                        <ng-container *ngTemplateOutlet="valueTemplate; context: { $implicit: {item: {value: detalleDocumentos?.totales?.totalCompras, type: 'number-pesos'}} }"></ng-container>
                                    </th>
                                </tr>
                            </thead>
                        </table>
                    </div>
                </div>

                <div class="col-12 px-0 card mb-3">
                    <div class="card-body">
                        <strong>
                            <ng-container *ngTemplateOutlet="valueTemplate; context: { $implicit: {item: {value: datosFichas.promVentasEvolucionCompraVenta, type: 'number-pesos'}} }"></ng-container>
                        </strong> Promedio de ventas (últ. 12 meses)
                    </div>
                </div>

                <div class="card mb-3">
                    <div class="card-body">
                        <strong>
                            <ng-container *ngTemplateOutlet="valueTemplate; context: { $implicit: {item: {value: datosFichas.porcCompraSobreVentas, type: 'porc-decimal'}} }"></ng-container>
                        </strong> Compras sobre ventas (últ. 12 meses)
                    </div>
                </div>

                <h3 class="col-12 border-0 shadow-lg p-3 my-4 pointer-cursor rounded-header" (click)="show('evolucion-dicom')">
                    Evolución puntaje Dicom ult. 12 meses
                </h3>
                <div id="evolucion-dicom">
                    <div class="row p-5">
                        <div class="col-sm-12">
                            <ng-container *ngIf="showReportes && evolucionGraphDicom && objectKeys(evolucionGraphDicom).length > 0">
                                <div [chart]="evolucionGraphDicom" class="graphContent"></div>
                            </ng-container>
                        </div>
                    </div>
                </div>

                <div class="col-12 px-0" *ngIf="antecedentesComerciales && objectKeys(antecedentesComerciales).length > 0">
                    <div class="card">
                        <h5 class="card-header list-group-item bg-primary"><i class="fa fa-file-text-o"
                            style="font-size:28px;">&nbsp;</i>ANTECEDENTES COMERCIALES <p class="d-inline fecha_size text-white">({{antecedentesComerciales.fecha | date: 'dd-MM-yyyy'}})</p></h5>
                        <div class="card-body">
                            <table class="table table-hover">
                                <thead>
                                    <tr>
                                        <td align="center" scope="col">&nbsp;</td>
                                        <td align="right"><strong>Monto miles</strong></td>
                                        <td align="right"><strong>Cantidad</strong></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="pointer-cursor" (click)="abrirModalTitular()">
                                        <th scope="row">Protestos</th>
                                        <td align="right" scope="row">{{antecedentesComerciales.protestosMonto |
                                            number:'1.0-0'}}</td>
                                        <td align="right">{{antecedentesComerciales.protestosCantidad |
                                            number:'1.0-0'}}</td>
                                    </tr>
                                    <tr class="pointer-cursor" (click)="abrirModalTitular()">
                                        <th scope="row">Morosidades</th>
                                        <td align="right">{{antecedentesComerciales.morosidadesMontos |
                                            number:'1.0-0'}}</td>
                                        <td align="right">{{antecedentesComerciales.morosidadesCantidad |
                                            number:'1.0-0'}}</td>
                                    </tr>
                                    <tr class="pointer-cursor" (click)="abrirModalTitular()">
                                        <th scope="row">Infracciones Laborales</th>
                                        <td align="right">{{antecedentesComerciales.infraccionesLaboralesMonto |
                                            number:'1.0-0'}}</td>
                                        <td align="right">{{antecedentesComerciales.infracionesLaboralesCantidad |
                                            number:'1.0-0'}}</td>
                                    </tr>
                                    <tr class="table-secondary pointer-cursor" (click)="abrirModalTitular()">
                                        <th scope="row">Total</th>
                                        <td align="right">{{antecedentesComerciales.totalesImpagosMonto |
                                            number:'1.0-0'}}</td>
                                        <td align="right">{{antecedentesComerciales.totalesImpagosCantidad |
                                            number:'1.0-0'}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

            </div>

            <div class="col-sm-12 col-md-10 mx-auto full-size-print">

                <div class="card mt-3">
                    <div class="card-body">
                        <strong>Evolución deuda CMF</strong>
                    </div>
                </div>

                <div class="col-12 mt-2 px-0" *ngIf="evolucionDeudaCMF && evolucionDeudaCMF.length > 0">
                    <div class="table-responsive">
                        <table class="table table-hover table-bordered">
                            <tbody>
                                <tr *ngFor="let row of headRow">
                                    <td class="bg-primary">{{row !== 'periodo'? headNames[row]: ''}}</td>
                                    <td [ngClass]="{'bg-primary': row == 'periodo'}" *ngFor="let item of evolucionDeudaCMF">
                                        <ng-template #periodo>
                                           <span class="text-capitalize">
                                            {{item[row] | date: 'LLL yyyy'}}
                                           </span> 
                                        </ng-template>
                                        <ng-container *ngIf="row !== 'periodo'; else periodo">
                                            <ng-template #cosumoAcreedor>
                                                {{item[row][0] | number : '1.0-0'}}/{{item[row][1] | number : '1.0-0'}}    
                                            </ng-template>
                                            
                                            <ng-container *ngIf="row !== 'diracreedor'; else cosumoAcreedor">
                                                {{item[row] | number : '1.0-0'}}
                                            </ng-container>
                                        </ng-container>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div class="col-sm-12 px-0 mt-3">
                    <div class="table-responsive table-entidad">
                        <table class="table table-hover table-bordered border">
                        <thead>
                            <tr>
                                <th scope="col" colspan="5" class="text-center py-1 bg-primary font-head-size pointer-cursor" (click)="abrirModalCompraVenta()">
                                    Factoring con que opera
                                    <i class="fa fa-info-circle pointer-cursor ml-1 not-print" aria-hidden="true" title="Para mas información revisar el modulo Libro Compra Venta"></i>
                                </th>
                            </tr>
                            <tr>
                                <th scope="col" class="text-center py-1 bg-primary font-head-size" rowspan="2">Rut</th>
                                <th scope="col" class="text-center py-1 bg-primary font-head-size" rowspan="2">Nombre</th>
                                <th scope="col" class="text-center py-1 bg-primary font-head-size" rowspan="1" colspan="3">Monto Cesiones en M$</th>
                            </tr>
                            <tr>
                                <th scope="col" class="text-center py-1 bg-primary font-head-size" rowspan="1">3 Meses</th>
                                <th scope="col" class="text-center py-1 bg-primary font-head-size" rowspan="1">6 Meses</th>
                                <th scope="col" class="text-center py-1 bg-primary font-head-size" rowspan="1">12 Meses</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-template #sinDatosFactoring>
                            <tr class="height-row">
                                <td colspan="5">Sin información</td>
                            </tr>
                            </ng-template>
            
                            <ng-container *ngIf="factoring.length > 0 else sinDatosFactoring">
                            <ng-container *ngFor="let item of factoring; let i = index">
                                <tr>
                                <td>{{item.CESIONARIO | rutFormat}}</td>
                                <td>{{item.rz_cesionario}}</td>
                                <td>{{item.monto3Meses !== ''? (item.monto3Meses | number: '1.0-0'): item.monto3Meses}}</td>
                                <td>{{item.monto6Meses !== ''? (item.monto6Meses | number: '1.0-0'): item.monto6Meses}}</td>
                                <td>{{item.Monto12Meses !== ''? (item.Monto12Meses | number: '1.0-0'): item.Monto12Meses}}</td>
                                </tr>
                            </ng-container>
                            </ng-container>
            
                        </tbody>
                        </table>
                    </div>
                </div>

            </div>

        </div>
        
    </div>
    
    <div class="row py-4 px-5">
    
        <h3 class="col-12 border-0 shadow-lg p-3 my-4 pointer-cursor rounded-header pagebreak" (click)="show('reporte-basico-consolidado')">
            Reporte Básico Consolidado
        </h3>
        
        <div class="col-sm-12 container-desplegables" id="reporte-basico-consolidado">
            <div class="row p-5">
                <div class="col-sm-12">
                      
                    <accordion [isAnimated]="true">
                      
                        <ng-container *ngIf="consolidadoTitular && objectKeys(consolidadoTitular).length > 0">
                            <accordion-group heading="Antecedentes Cliente" [isOpen]="false" panelClass="mb-4">
                                <div class="col-sm-12">
                                    <app-ant-consolidado [rut]="rut" [reporteFull]="consolidadoTitular">
                                    </app-ant-consolidado>
                                </div>
                            </accordion-group>
                        </ng-container>
    
                        <ng-container *ngIf="reportePoderJudicial">
                            <accordion-group heading="Poder judicial" [isOpen]="false" panelClass="mb-4">
                                <div class="col-sm-12">
                                    <app-poder-judicial-report-viewer [content]="reportePoderJudicial" [showHeader]="false" [fromConsolidado]="true">
                                    </app-poder-judicial-report-viewer>
                                </div>
                            </accordion-group>
                        </ng-container>

                        <ng-container *ngIf="reporteBoletinConcursal">
                            <accordion-group heading="Boletin Concursal" [isOpen]="false" panelClass="mb-4">
                                <div class="col-sm-12">
                                    <app-boletin-concursal-report-viewer [content]='reporteBoletinConcursal' [showHeader]="false">
                                    </app-boletin-concursal-report-viewer>
                                </div>
                            </accordion-group>
                        </ng-container>
    
                        <ng-container *ngIf="reporteDeudaSbif">
                            <accordion-group heading="Deuda Sbif" [isOpen]="false" panelClass="mb-4">
                                <div class="col-sm-12">
                                    <app-deuda-leasing-sbif-viewer [content]='reporteDeudaSbif' [typeTX]="false" [showHeader]="false">
                                    </app-deuda-leasing-sbif-viewer>
                                </div>
                            </accordion-group>
                        </ng-container>

                        <ng-container *ngIf="reporteCteVentasBalancesRentas">
                            <accordion-group heading="Reporte Financiero" [isOpen]="false" panelClass="mb-4">
                                <div class="col-sm-12">
                                    <app-reporte-full-viewer [reporteFullContent]="reporteCteVentasBalancesRentas" [showHeader]="false"
                                    [nombreBasico]="reporteBasicReport?.InformacionBasica?.NombreORazonSocial || ''">
                                    </app-reporte-full-viewer>
                                </div>
                            </accordion-group>
                        </ng-container>

                        <ng-container *ngIf="reporteBienesRaices">
                            <accordion-group heading="Bienes Raices" [isOpen]="false" panelClass="mb-4">
                                <div class="col-sm-12">
                                    <ng-template #bienesSinInfo>
                                        <div class="p-3"> Sin información</div>  
                                    </ng-template>
                    
                                    <ng-container *ngIf="reporteBienesRaices?.bienesraicesReporte?.bienesRaices?.listadoBienesRaices &&  reporteBienesRaices.bienesraicesReporte.bienesRaices.listadoBienesRaices.length > 0; else bienesSinInfo">
                                        <app-bienes-raices-viewer [content]='reporteBienesRaices' [showHeader]="false" [fromConsolidado]="true">
                                        </app-bienes-raices-viewer>
                                    </ng-container>
                                    
                                </div>
                            </accordion-group>
                        </ng-container>

                        <ng-container *ngIf="reporteVehiculos">
                            <accordion-group heading="Vehiculos" [isOpen]="false" panelClass="mb-4">
                                <div class="col-sm-12">
                                    <ng-template #vehiculosSinInfo>
                                        <div class="p-3"> Sin información</div>  
                                    </ng-template>
                                    
                                    <ng-container *ngIf="reporteVehiculos?.vehiculoReporte?.vehiculos?.listadoVehiculos && reporteVehiculos.vehiculoReporte.vehiculos.listadoVehiculos.length > 0; else vehiculosSinInfo">
                                        <app-vehiculos-viewer [content]='reporteVehiculos' [showHeader]="false" [fromConsolidado]="true"></app-vehiculos-viewer>
                                    </ng-container>
                                    
                                </div>
                            </accordion-group>
                        </ng-container>
                      
                    </accordion>
    
                </div>
            </div>
        </div>
    
        <div class="col-12 my-2 not-print" *ngIf="showArchivos">
            <app-modulo-archivos [idTransaccion]="idTransaccion" [rut]="rut" [closed]="closed"></app-modulo-archivos>
        </div>

        <ng-container *ngIf="plantillaNoEditable === false">
            <ng-template #botonFinalizar>
                <ng-container *ngIf="validaUsuarioAdmin()">
                  <div class="col-12 d-flex justify-content-center pt-4 no-print">
                    <button class="btn btn-sm btn-primary mx-2 rounded-header" (click)="volverEtapaPrevia()">
                      Devolver Etapa Previa
                    </button>
                  </div>
                </ng-container>
            </ng-template>
            
            <ng-container *ngIf="stepper === 4 else botonFinalizar">
            
              <div class="col-12 d-flex justify-content-center pt-4 no-print">
                <button class="btn btn-sm btn-primary mx-2 rounded-header" (click)="guardarFicha()">
                  Guardar
                </button>
                <button class="btn btn-sm btn-primary mx-2 rounded-header" [disabled]="objectKeys(fichaGuardada).length > 0 ? null : true" (click)="avanzarFlujo()">
                  Continuar
                </button>
              </div>
            
            </ng-container>
        </ng-container>
    
    </div>
</div>

<ng-template #valueTemplate let-elemento>
    <ng-container *ngIf="(elemento?.item?.value || elemento?.item?.value == 0)">
        <ng-container [ngSwitch]="elemento?.item?.type">
            <ng-container *ngSwitchCase="'date'">
                {{elemento?.item?.value | date: 'dd-MM-yyyy'}}
            </ng-container>
            <ng-container *ngSwitchCase="'number-pesos'">
                ${{elemento?.item?.value | number: '1.0-0'}}
            </ng-container>
            <ng-container *ngSwitchCase="'number'">
                {{elemento?.item?.value | number: '1.0-0'}}
            </ng-container>
            <ng-container *ngSwitchCase="'decimal'">
                {{elemento?.item?.value | number: '1.0-2'}}
            </ng-container>
            <ng-container *ngSwitchCase="'porc-decimal'">
                {{elemento?.item?.value | number: '1.0-2'}}%
            </ng-container>
            <ng-container *ngSwitchCase="'porc-int'">
                {{elemento?.item?.value | number: '1.0-0'}}%
            </ng-container>
            <ng-container *ngSwitchDefault>
                {{elemento?.item?.value}}
            </ng-container>
        </ng-container>
    </ng-container>    
</ng-template>


<app-modal-consolidado-workflow [rut]="rutSeleccionado" [idTransaccion]="idTransaccion" [dataServicios]="reportesSeleccionado" [informeComercial]="informeComercial" [default]="modalDefault" [plantilla]="plantillaAntecedentes" [descargar]="true"></app-modal-consolidado-workflow>
