import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { forkJoin, from, of, Subscription } from 'rxjs';
import { AlertService } from 'src/app/components/_alert';
import { NgxSpinnerService } from 'ngx-spinner';
import { SolicitudCreditoServiceWorkflow } from '../../../../shared/services/solicitud-credito-workflow.service';
import { catchError, filter, map, mergeMap, toArray } from 'rxjs/operators';
import { FormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ModalBasicoConsolidadoService } from '../../../../shared/services/modal-basico-consolidado.service';
import { ESystemAccess } from 'src/app/enum/EAccess';
import { SessionService } from 'src/app/shared/services/session.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { DatePipe } from '@angular/common';
import { environment as env } from 'src/environments/environment';
import { listadoReport } from 'src/app/models/utils/utils.model';
import { RutFormatPipe } from 'src/app/shared/pipes/rutFormat.pipe';
import { OrgChart } from 'd3-org-chart';
import * as d3 from 'd3';
import { NgxMasonryComponent } from 'ngx-masonry';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { CpMathFunctionsService } from 'src/app/shared/services/cp-math-functions.service';

interface InformacionCliente {
  title: string,
  scoreValue: boolean,
  values: ValuesInformacionCliente[],
  id: string
}

interface ValuesInformacionCliente {
  value: any,
  type: string,
  inputPrepend: string
}

interface AntecedentesSalfa {
  title: string,
  value: any,
  id: string,
  type: string,
  titlePromedio: boolean
}

interface InformeRiesgo {
  riesgoIndustria: any,
  protestosInternosVigentesCantidad: any,
  protestosInternosVigentesMonto: any,
  morosidadesInternas: any,
  morosidadesInternasMayores30: any,
  score: any
}

@Component({
  selector: 'app-plantilla-salfa-linea',
  templateUrl: './plantilla-salfa-linea.component.html',
  styleUrls: ['./plantilla-salfa-linea.component.scss'],
  providers: [RutFormatPipe]
})
export class PlantillaSalfaLineaComponent implements OnInit {
  //Informacion para plantilla no editable
  @Input() plantillaNoEditable: boolean = false;
  @Input() objEntradaFicha: any = {};
  @Input() objEntradaMotor: any = {};

  @Output() operacionNuevo = new EventEmitter<boolean>();
  @Output() finalizarFlujo = new EventEmitter<void>();
  @Output() bloqueaPasos5 = new EventEmitter<boolean>();
  @Output() bloqueaPasos6 = new EventEmitter<boolean>();
  @Input() idTransaccion: string = '';
  @Input() stepper!: number;
  @Input() rut: string = '';
  @Input() closed: boolean = true;
  @Input() obtenerFlujoResp: any = {};

  @ViewChild("chartContainer") chartContainer!: ElementRef;

  public objectKeys = Object.keys;

  private access: string[] = [
    ESystemAccess.FLUJO_CREDITO_BUSCAR, 
    ESystemAccess.FLUJO_CREDITO_BUSCAR_ADMIN,
    ESystemAccess.FLUJO_CREDITO,
    ESystemAccess.FLUJO_CREDITO_ADMIN
  ];

  public activateRutComiteHelper: boolean = false;
  public activateRutClienteComiteHelper: boolean = false;

  public subscriptions: Subscription[] = [];
  public subscriptionForm!: Subscription;
  public data: any;
  public today = '';
  public groupNameConsulting: string[] = ['buscarReportePrincipal', 'buscarReportesDerivados'];
  public groupNameConsultingFicha: string[] = ['buscarFichaDeCredito', 'buscarReportesDerivados', 'buscarReportePrincipal'];
  public groupNameConsultingScore: string[] = ['buscarCalculoScore', 'buscarReportesDerivados', 'buscarReportePrincipal'];
  public groupNameConsultingAprobacion: string[] = ['buscarReportesDerivados', 'buscarReportePrincipal'];
  public contratoServicios: any[] = [];
  public atributos: any[] = [];
  public atributosCargaMasiva: any[] = [];
  public reportesPrincipalesObj: any = {};
  public reportesDerivadosObj: any = {};
  public reportesDerivados: any = {};
  public entidadesDerivadas: any[] = [];
  public contratoEntidades: any[] = [];
  public errores: any[] = [];
  public bodyModelos: any = {};
  public fichaGuardada: any = {}; // es para validar la existencia de una ficha guardada y habilitar el boton continuar
  public showReportes: boolean = false;
  public showArchivos: boolean = false;
  public plantillaAntecedentes = 'cesceDemo';
  public modalDefault = true;

  public rutSeleccionado: string = '';
  public chart: any;
  public motorCalculos: any;
  public motorConDatos: boolean = false;

  public listaReportesPrincipales: any[] = [];
  public listaReportesDerivados: any = {};
  public updateMasonryLayout: boolean = false;
  public seccionesCamposPersonalizados: any[] = [];
   
  // CAMPOS PARA SALFA:
  @ViewChild(NgxMasonryComponent) masonry: NgxMasonryComponent | any;
  public informacionCliente: any[]/*{title: string, values: {value: any, type: string, inputPrepend: string}[]}[]*/ = [];
  public informeRiesgo: any = {}; 
  public antecedentesSalfa: any[] = [];
  public lineaCreditoMTickets: any = {};
  public estadosFinancierosM: any[] = [];
  public carpetaTributaria: any[] = [];
  public headerCarpetaTributaria: string[] = ['date', 'ventas', 'compras', 'diferencia', 'cod.524', 'fechaPago', 'cod.91', 'posterga'];
  public informacionBalanceEstadoResultadoM: any[] = [];
  public objFlujosFuturos: any[] = [];
  public titlesFlujosFuturos: any[] = [];
  public currentPage = 0;
  public currentItem = env.initItemPerPage;
  public titlesFlujosHistoricos: any[] = []; 
  public objFlujosHistoricos: any[] = []; 
  public mallaSocietariaTable: any[] = [];
  public currentPageMalla = 0;
  public currentItemMalla = env.initItemPerPage;
  public dicomMTable: any[] = [];
  public listadoReport: any = listadoReport;
  public mallaSocietariaGraph: any[] = [];
  public mallaSocietariaGraphPrint: any = {};
  public graphMallaReady: boolean = false;
  public colunmTitles: string[] = ['Fact', 'Saldo'];
  public categoria: any[] = [];
  public serie: any[] = [];
  public yAxix = [{
    labels: {
      format: '{value}',
      style: {
        color: '#000'
      }
    },
    title: {
      text: 'Miles',
      style: {
        color: '#000'
      }
    }
  }, {
    title: {
      text: 'Miles',
      style: {
        color: '#000'
      }
    },
    labels: {
      format: '{value}',
      style: {
        color: '#000'
      }
    },
    opposite: true
  }];
  public plotOptions = {
    series: {
      marker: {
        enabled: false
      }
    }
  };
  public graphReady: boolean = false;
  public camposPersonalizadosForm!: UntypedFormGroup;
  public fieldsReady: boolean = false; // flag para campos personalizados

  public objetoLineaCredito: any[] = [];
  public montoPreAprobado: number = 0;
  public reporteDeudaSbif: any = null;
  public deudaSbifDerivados: any[] = [];

  private observer: any = null;
  public showMalla: boolean = true;

  constructor(
    public alertService: AlertService,
    private solicitudCreditoService: SolicitudCreditoServiceWorkflow,
    private spinner: NgxSpinnerService,
    private formBuilder: FormBuilder,
    private modalService: ModalBasicoConsolidadoService,
    private _sessionService: SessionService,
    private utilsService: UtilsService,
    private datePipe: DatePipe,
    private rutFormatPipe: RutFormatPipe,
    public dialog: MatDialog,
    private cpMathFunctionsService: CpMathFunctionsService,
  ) { }

  ngOnInit(): void {
    this.operacionNuevo.emit(false);
    this.setToday();
    if(this.plantillaNoEditable === false){
      this.obtenerFlujo();
    } else {
      this.mapeoNoEditable();
      this.showArchivos = true;
    }
  }

  recalcularTablaLineaCredito(): void {
    if(!(this.stepper === 5 || this.plantillaNoEditable === true)){
      if(this.montoPreAprobado > 0){
        this.objetoLineaCredito.forEach(item => {
          this.setRowLineaAprobada(item?.lcSolicitada || item?.lcSolicitada == 0 ? item?.lcSolicitada.toString() : '', item, 'valueAcc');
          this.setAddRow(item?.lcSolicitada || item?.lcSolicitada == 0 ? item?.lcSolicitada.toString() : '', item, 'lcSolicitada');
        });
      }
    }
  }

  handleEvent(eventKey: string[]): void {
    if(eventKey.includes('recalcularTablaLineaCredito')) {
      this.recalcularTablaLineaCredito();
    }
  }

  redireccionPasoAnterior(ultimoPasoCompletado: number): void {
    let step = this.solicitudCreditoService.stepsOptions.filter((e: any) => e.stageID == (ultimoPasoCompletado + 1));
    if (!step[0]?.step){
      step = this.solicitudCreditoService.stepsOptions.filter((e: any) => e.stageID == (ultimoPasoCompletado));
    }
    this.alertService.error(`Solicitud de Crédito - Debe completar el paso N° ${Number(step[0]?.step)} para poder continuar`);
    this.solicitudCreditoService.setStep(Number(step[0]?.step));
  }

  mapeoNoEditable(): void {
    if(this.obtenerFlujoResp && Object.keys(this.obtenerFlujoResp).length > 0){
      this.bodyModelos = this.obtenerFlujoResp;
    }
    this.callServicesFichaAprobacion();    
  }

  // Logica de revision de paso y llamada a servicios

  obtenerFlujo(): void{
    this.spinner.show();
    this.subscriptions.push(this.solicitudCreditoService.obtenerFlujo(this.idTransaccion, this.rut).subscribe(resp => {
      this.bodyModelos = resp;
      let paso: any;
      let pasoAnterior: any;
      const pasosCompleted = resp.stages.filter((e: any) => e.completed);
      const cantidad = pasosCompleted.length;
      const ultimoPasoCompletado = Number(pasosCompleted[cantidad - 1].stageID);
      this.closed = (resp.closed === null || resp.closed === undefined) ? true : resp.closed;
      this.showArchivos = true;

      if(this.stepper === 5){
        resp.stages.forEach((e: any) => {
          if(e.stageID === '10'){
            paso = e;
          }
        });
        // Se valida si el paso anterior fue completado y si no se envia al ultimo completado
        if(paso?.stageID === '10' && (paso?.completed === false && paso?.optional === false)){ //pasoAnterior && !pasoAnterior?.completed
          let pasoAnteriorCompleted = ultimoPasoCompletado;
          if(ultimoPasoCompletado === 9)
            pasoAnteriorCompleted = 8;

          this.redireccionPasoAnterior(pasoAnteriorCompleted);
        } else {
          if (paso?.stageID === '10' && paso?.completed === true) { // Se realizo el calculo del score
            this.callServicesFichaScore();
          } else {
            this.redireccionPasoAnterior(ultimoPasoCompletado);
          }
        }
      } else {
        resp.stages.forEach((e: any) => {
          if(e.stageID === '9'){
            paso = e;
          } else if (e.stageID === '8'){
            pasoAnterior = e;
          }
        });
        // Se valida si el paso anterior fue completado y si no se envia al ultimo completado
        if(pasoAnterior && (!pasoAnterior?.completed && pasoAnterior?.optional === false)){
          this.redireccionPasoAnterior(ultimoPasoCompletado);
        } else {
          if (paso?.stageID === '9' && paso?.completed === false) { // primera vez en esta pantalla -> cambiar para pruebas
            this.callServices();
          }
          else if(paso?.stageID === '9' && paso?.completed === true){ // ya se habia llegado antes a esta pantalla -> cambiar para pruebas
            this.callServicesFicha();
          }
        }
      }
    }, (error: any) => {
      this.alertService.error(error?.error?.message || 'Solicitud de Crédito - Ocurrio un error al recuperar los datos del flujo de credito');
      this.spinner.hide();
    }));

  }

  // Llamada a servicios para obtener ficha con score guardada

  async callServicesFichaAprobacion(): Promise<void> {
    const apiServices: any = [];

    if (this.groupNameConsultingAprobacion.length > 0) {
      for await (const value of this.groupNameConsultingAprobacion) {
        apiServices.push(this.getServices(value))
      }
    }

    this.spinner.show();
    this.subscriptions.push(forkJoin(apiServices).subscribe((resp) => {
      this.spinner.hide();
      if (this.errores.length > 0){
        this.errores.forEach((element: any) => {
          this.alertService.error(element.msg);
        });
        this.spinner.hide();
      } else {
        this.buscarReportes("callServicesFichaAprobacion");
      }
    },
      (error) => {
        this.alertService.error(error?.message);
        this.spinner.hide();
      }
    ));
  }

  // Llamada a servicios para obtener ficha con score guardada

  async callServicesFichaScore(): Promise<void> {
    const apiServices: any = [];

    if (this.groupNameConsultingScore.length > 0) {
      for await (const value of this.groupNameConsultingScore) {
        apiServices.push(this.getServices(value))
      }
    }

    this.spinner.show();
    this.subscriptions.push(forkJoin(apiServices).subscribe((resp) => {
      this.spinner.hide();
      if (this.errores.length > 0){
        this.errores.forEach((element: any) => {
          this.alertService.error(element.msg);
        });
        this.spinner.hide();
      } else {
        this.buscarReportes("callServicesFichaScore");
      }
    },
      (error) => {
        this.alertService.error(error?.message);
        this.spinner.hide();
      }
    ));
  }

  // Llamada a servicios para obtener ficha guardada

  async callServicesFicha(): Promise<void> {
    const apiServices: any = [];

    if (this.groupNameConsulting.length > 0) {
      for await (const value of this.groupNameConsultingFicha) {
        apiServices.push(this.getServices(value))
      }
    }

    this.spinner.show();
    this.subscriptions.push(forkJoin(apiServices).subscribe((resp) => {
      this.spinner.hide();
      if (this.errores.length > 0){
        this.errores.forEach((element: any) => {
          this.alertService.error(element.msg);
        });
        this.spinner.hide();
      } else {
        this.buscarReportes("callServicesFicha");
      }
    },
      (error) => {
        this.alertService.error(error?.message);
        this.spinner.hide();
      }
    ));
  }

  // Mapeo de error en consultas asincronas

  setError(reporte: string, error: string): void {
    this.errores.push({
      id: reporte,
      msg: error
    })
  }

  obtenerSecciones(): any[] {
    const seccionesFiltradas: any[] = [];

    this.atributos.forEach(element => {
      if(element.creditRequestActive == true && element.enabled == true && !seccionesFiltradas.includes(element.section)){
        seccionesFiltradas.push(element.section)
      }
    });
    
   return seccionesFiltradas;
  }

  // Mapeo de respuestas correctas en consultas asincronas

  setResponse(service: string, response: any): void {
    if (service === 'buscarReportePrincipal'){
      this.contratoServicios = response?.Stage3Response?.ContratoServicios || [];
      this.atributos = response?.Stage2Response?.Atributos || [];
      this.seccionesCamposPersonalizados = this.obtenerSecciones();
      this.atributosCargaMasiva = response?.Stage2Response?.AtributosCargaMasiva || [];
      this.listaReportesPrincipales = response?.ReportKeys || [];
    } else if (service === 'buscarReportesDerivados'){

      this.listaReportesDerivados = response?.ReportKeys || {};
      this.entidadesDerivadas = response?.Stage5Response?.Entidades || [];
      this.contratoEntidades = response?.Stage5Response?.Contrato || [];
      
    } else if (service === 'buscarFichaDeCredito'){
      this.fichaGuardada = response?.FichaDeCredito;
      const ficha = response?.FichaDeCredito?.ficha;
      this.rut = ficha?.rut || '';
      this.informacionCliente = ficha?.informacionCliente || [];
      this.antecedentesSalfa = ficha?.antecedentesSalfa || [];
      this.lineaCreditoMTickets = ficha?.lineaCreditoMTickets || {};
      this.titlesFlujosFuturos = ficha?.titlesFlujosFuturos || [];
      this.objFlujosFuturos = ficha?.objFlujosFuturos || [];
      this.titlesFlujosHistoricos = ficha?.titlesFlujosHistoricos || [];
      this.objFlujosHistoricos = ficha?.objFlujosHistoricos || [];
      this.mallaSocietariaTable = ficha?.mallaSocietariaTable || [];
      this.dicomMTable = ficha?.dicomMTable || [];
      this.mallaSocietariaGraph = ficha?.mallaSocietariaGraph || [];
      this.mallaSocietariaGraphPrint = ficha?.mallaSocietariaGraphPrint || {};
      this.categoria = ficha?.categoria || [];
      this.serie = ficha?.serie || [];
      this.deudaSbifDerivados = ficha?.deudaSbifDerivados || [];
      
      if(this.bodyModelos?.workflowModel === 'lineaDeCredito'){ // modeloDemo
        this.objetoLineaCredito = ficha?.objetoLineaCredito || [];
        this.montoPreAprobado = ficha?.montoPreAprobado || 0;
      }

    } else if (service === 'buscarCalculoScore'){
      this.fichaGuardada = response?.FichaDeCredito;
      const ficha = response?.FichaDeCredito?.ficha;
      this.rut = ficha?.rut || '';
      this.informacionCliente = ficha?.informacionCliente || [];
      this.antecedentesSalfa = ficha?.antecedentesSalfa || [];
      this.lineaCreditoMTickets = ficha?.lineaCreditoMTickets || {};
      this.titlesFlujosFuturos = ficha?.titlesFlujosFuturos || [];
      this.objFlujosFuturos = ficha?.objFlujosFuturos || [];
      this.titlesFlujosHistoricos = ficha?.titlesFlujosHistoricos || [];
      this.objFlujosHistoricos = ficha?.objFlujosHistoricos || [];
      this.mallaSocietariaTable = ficha?.mallaSocietariaTable || [];
      this.dicomMTable = ficha?.dicomMTable || [];
      this.mallaSocietariaGraph = ficha?.mallaSocietariaGraph || [];
      this.mallaSocietariaGraphPrint = ficha?.mallaSocietariaGraphPrint || {};
      this.categoria = ficha?.categoria || [];
      this.serie = ficha?.serie || [];
      this.deudaSbifDerivados = ficha?.deudaSbifDerivados || [];
      
      if(this.bodyModelos?.workflowModel === 'lineaDeCredito'){ // modeloDemo
        this.objetoLineaCredito = ficha?.objetoLineaCredito || [];
        this.montoPreAprobado = ficha?.montoPreAprobado || 0;
      }

      // mapeo motor de calculos
      this.motorCalculos = response?.MotorDeCalculos;
      if(this.motorCalculos?.ResponseReglas?.message){
        this.alertService.error(this.motorCalculos?.ResponseReglas?.message || 'Solicitud de Crédito - Ha ocurrido un error en el motor de calculos');
        this.motorConDatos = false;
      } else {
        this.motorConDatos = true;
      }

    }
  }

  //Definicion llamada a servicios asincronos por primera vez en la vista

  getServices(service: string): any {
    const objeto: any = {
      'buscarReportePrincipal': () => {
        return this.solicitudCreditoService.buscarReportePrincipal(this.idTransaccion, this.rut)
          .pipe(
            map(resp => {
              this.setResponse(service, resp);
            })
          )
          .pipe(
            catchError((error) => (this.setError(service, error?.error?.message || 'Solicitud de Crédito - Error Inesperado en servicio de reportes principales'), of(null))));
      },
      'buscarReportesDerivados': () => {
        return this.solicitudCreditoService.buscarReportesDerivados(this.idTransaccion, this.rut)
        .pipe(
          map(resp => {
            this.setResponse(service, resp);
          })
        )
        .pipe(
          catchError((error) => (this.setError(service, error?.error?.message || 'Solicitud de Crédito - Error Inesperado en servicio de reportes derivados'), of(null))));
      },
      'buscarFichaDeCredito': () => {
        return this.solicitudCreditoService.buscarFichaDeCredito(this.idTransaccion, this.rut)
        .pipe(
          map(resp => {
            this.setResponse(service, resp);
          })
        )
        .pipe(
          catchError((error) => (this.setError(service, error?.error?.message || 'Solicitud de Crédito - Error Inesperado en servicio de carga de ficha'), of(null))));
      },
      'buscarCalculoScore': () => {
        return this.solicitudCreditoService.buscarCalculoScore(this.rut, this.idTransaccion)
        .pipe(
          map(resp => {
            this.setResponse(service, resp);
          })
        )
        .pipe(
          catchError((error) => (this.setError(service, error?.error?.message || 'Solicitud de Crédito - Error Inesperado en servicio de carga de ficha con Score'), of(null))));
      }
    };
    return objeto[service]();
  }

  // Llamada a servicios asincronos por primera vez en la vista

  async callServices(): Promise<void> {
    const apiServices: any = [];

    if (this.groupNameConsulting.length > 0) {
      for await (const value of this.groupNameConsulting) {
        apiServices.push(this.getServices(value))
      }
    }

    this.spinner.show();
    this.subscriptions.push(forkJoin(apiServices).subscribe((resp) => {
      this.spinner.hide();
      if (this.errores.length > 0){
        this.errores.forEach((element: any) => {
          this.alertService.error(element.msg);
        });
        this.spinner.hide();
      } else {
        this.buscarReportes("callServices");
      }
    },
      (error) => {
        this.alertService.error(error.message);
        this.spinner.hide();
      }
    ));
  }

  buscarReportes(etapa: string): void {
    const apiServicesReportes: any = this.utilsService.obtenerServicesReportes(this.listaReportesPrincipales, this.listaReportesDerivados) || [];
    let count = 0;

    this.spinner.show();
    from(apiServicesReportes).pipe( 
      mergeMap(
        (service: any) => 
          this.solicitudCreditoService.buscarReportes(
            this.idTransaccion, 
            this.rut, 
            service.principal, 
            service.reportKey, 
            service.entidad, 
            service.rutDerivado
          )
          .pipe(
            catchError(error => {
              //console.error(`Error en la consulta del servicio: ${service.principal}, ${service.reportKey}, ${service.entidad}, ${service.rutDerivado}`);
              return of(null); // Retorna un observable que emite un valor nulo
            }),
            map(response => {
              if (response !== null) {
                count++;
              }
              return {
                service: service,
                response: response
              };
            }),
          ),
          20
        ), 
        filter(response => response !== null),
      toArray()
    ).subscribe(responses => {
      if (count === 0) { // Si no hubo respuestas válidas
        this.alertService.error('Solicitud de Crédito - Ha ocurrido un error al consultar la información de todos los reportes');
        this.spinner.hide();
      } else if (count < apiServicesReportes.length) { // Si hubo respuestas válidas pero no para todos los servicios
        this.alertService.error('Solicitud de Crédito - Ha ocurrido un error al consultar la información de algunos de los reportes');
      }
      if(count > 0){
        this.reportesPrincipalesObj = this.utilsService.mapeoReportesPrincipales(responses);
        this.reportesDerivadosObj = this.utilsService.mapeoReportesDerivados(responses);
        if (etapa === 'callServices'){ // sin guardar
          this.mapeoReportes();
          this.initForm();
          this.spinner.hide();
        } else if (etapa === 'callServicesFicha'){ // ficha guardada
          this.initForm();
          this.reporteDeudaSbif = this.mapeoReporteSbif();
          this.cargarMallaSocietariaGraph(this.mallaSocietariaGraph && this.mallaSocietariaGraph.length > 0 ? true: false);
          this.showReportes = true;
          const ficha = this.fichaGuardada?.ficha;
          this.graphReady = ficha?.graphReady || false;
          this.spinner.hide();
        } else if (etapa === 'callServicesFichaScore'){
          if(this.motorConDatos === true) { // descomentar cuando este armado el backend - validar*
            this.mapeosMotorCalculos();
          }
          this.reporteDeudaSbif = this.mapeoReporteSbif();
          this.cargarMallaSocietariaGraph(this.mallaSocietariaGraph && this.mallaSocietariaGraph.length > 0 ? true: false);
          const ficha = this.fichaGuardada?.ficha;
          (ficha && Object.keys(ficha).length > 0) ? this.showReportes = true : this.showReportes = false;
          this.graphReady = ficha?.graphReady || false;
          this.spinner.hide();
        } else if (etapa === 'callServicesFichaAprobacion'){ // ficha desde flujo de aprobacion -> deberia ser similar a la callServicesFichaScore
          this.mapeoFichaGuardada();
          if(this.motorConDatos === true) { // descomentar cuando este armado el backend - validar*
            this.mapeosMotorCalculos();
          }
          this.reporteDeudaSbif = this.mapeoReporteSbif();
          const ficha = this.fichaGuardada?.ficha;
          (ficha && Object.keys(ficha).length > 0) ? this.showReportes = true : this.showReportes = false;
          this.graphReady = ficha?.graphReady || false;
          this.spinner.hide();
          this.escucharEventoFicha();
        } else {
          this.spinner.hide();
        }
      }
    }, error => {
      if (count > 0) {
        this.alertService.error('Solicitud de Crédito - Ha ocurrido un error al consultar la información de algunos de los reportes');
      } else {
        this.alertService.error('Solicitud de Crédito - Ha ocurrido un error al consultar la información de todos los reportes');
        this.spinner.hide();
      }
    });
  }

  escucharEventoFicha(): void {
    this.observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
            const elemento: any = mutation.target;
          if (!(elemento && elemento.classList && elemento.classList.contains('container-desplegables'))) {
            // Realiza alguna otra acción aquí
            this.showMalla = false;
            this.showMalla = true;
            this.cargarMallaSocietariaGraph(this.mallaSocietariaGraph && this.mallaSocietariaGraph.length > 0 ? true: false);
            this.actualizaLayout();
          }
        }
      }
    });

    // Seleccionar el elemento a observar
    const elemento: HTMLElement | null = document.getElementById('ficha');

    // Configurar el observador para observar cambios en los atributos
    if(elemento)
      this.observer.observe(elemento, { attributes: true });
  }

  ngOnDestroy(): void {
    if(this.observer)
      this.observer.disconnect();
  }

  initForm() {
    this.camposPersonalizadosForm = this.formBuilder.group({});
    if(this.atributos && this.atributos.length > 0){
      this.cpMathFunctionsService.initializeMathFunctions();
      this.atributos.forEach(control => {
        if(control.creditRequestActive && control.enabled){
          if(control.formatAttribute === '0' && control.typeAttibute === 'Number' && this.objectKeys(control.allowedValues).length === 0){
            this.camposPersonalizadosForm.addControl(control.nameAttribute, new UntypedFormControl(control.value || control.defaultValue, [Validators.required, Validators.pattern("^[0-9]*$")]));
          } else if(this.objectKeys(control.allowedValues).length > 0){
            const key = Object.keys(control.allowedValues)[Object.values(control.allowedValues).indexOf(control.value)];
            if (key)
              this.camposPersonalizadosForm.addControl(control.nameAttribute, new UntypedFormControl(key || control.defaultValue, [Validators.required, Validators.pattern("^[0-9]*$")]));
            else
              this.camposPersonalizadosForm.addControl(control.nameAttribute, new UntypedFormControl(control.value || control.defaultValue, [Validators.required, Validators.pattern("^[0-9]*$")]));
          } else if(control.typeAttibute === 'Date'){
            const valor = control.value ? this.getDateToForm(control.value, control.formatAttribute) : (control.defaultValue ? this.getDateToForm(control.defaultValue, control.formatAttribute) : '');
            
            this.camposPersonalizadosForm.addControl(control.nameAttribute, new UntypedFormControl(valor, Validators.required));
          } else {
            this.camposPersonalizadosForm.addControl(control.nameAttribute, new UntypedFormControl(control.value || control.defaultValue, Validators.required));
          }
        }
      });

      // validacion editables
      this.atributos.forEach(control => {
        if(control.creditRequestActive && control.enabled){
          if(typeof(control.editable) == 'boolean'){ 
            if(control.editable === false){
              this.camposPersonalizadosForm.get(control.nameAttribute)?.disable();
              this.camposPersonalizadosForm.get(control.nameAttribute)?.clearValidators();
            }
          } else {
            if(control?.editable) {
              const formula = control?.editable.replace(/valor\(\)/g, `'${this.camposPersonalizadosForm.get(control.nameAttribute)?.value}'`);
              const valor = this.cpMathFunctionsService.evaluateFormulaArray(formula || '', this.atributos || [], this.bodyModelos?.workflowModel);
              if(valor == 0) {
                this.camposPersonalizadosForm.get(control.nameAttribute)?.disable();
                this.camposPersonalizadosForm.get(control.nameAttribute)?.clearValidators();
              }
            }
          }
        }
      });

      this.fieldsReady = true;
      this.eventForm();
    }
  }

  eventForm() {
    this.subscriptionForm = this.camposPersonalizadosForm.valueChanges.subscribe(()=>{
      this.cpMathFunctionsService.setContext(this.bodyModelos?.workflowModel, false, this.camposPersonalizadosForm, this.atributos);
      this.actualizaLayout();
    });

    this.cpMathFunctionsService.setContext(this.bodyModelos?.workflowModel, false, this.camposPersonalizadosForm, this.atributos);
    this.subscriptionForm = this.cpMathFunctionsService.addEventFormDependientes(this.subscriptionForm);
  }

  actualizaLayout(){
    if(this.masonry){
      this.masonry.reloadItems();
      this.masonry.layout();
    }
  }

  /*format = 
  'DD-MM-YYYY'
  'DD-MM-YY'
  'MM-DD-YY'
  'MM-DD-YYYY'
  'YYYY-MM-DD'*/
  getDateToForm(dateInput: string, format: string): string {

    if(!dateInput) {
      return dateInput;
    }

    if (format === 'DD-MM-YYYY') {
      const [day, month, year] = dateInput?.split("-");
      return `${year}-${month}-${day}`;
    } else if (format === 'DD-MM-YY') {
      const [day, month, year] = dateInput?.split("-");
      const fourDigitYear = "20" + year;
      return `${fourDigitYear}-${month}-${day}`;
    } else if (format === 'MM-DD-YY') {
      const [month, day, year] = dateInput?.split("-");
      const fourDigitYear = "20" + year;
      return `${fourDigitYear}-${month}-${day}`;
    } else if (format === 'MM-DD-YYYY') {
      const [month, day, year] = dateInput?.split("-");
      return `${year}-${month}-${day}`;
    } else if (format === 'YYYY-MM-DD') {
      const [year, month, day] = dateInput?.split("-");
      return `${year}-${month}-${day}`;
    } else {
      const date = new Date(dateInput);
      if (date && !isNaN(date.getTime()) && format) {
        const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
        const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
        return `${date.getFullYear().toString()}-${month}-${day}`;
      }
      return dateInput;
    }
  }

  mapeoFichaGuardada(): void {
    this.fichaGuardada = this.objEntradaFicha;
    const ficha = this.objEntradaFicha?.ficha;
    this.rut = ficha?.rut || '';
    this.informacionCliente = ficha?.informacionCliente || [];
    this.antecedentesSalfa = ficha?.antecedentesSalfa || [];
    this.lineaCreditoMTickets = ficha?.lineaCreditoMTickets || {};

    this.titlesFlujosFuturos = ficha?.titlesFlujosFuturos || [];
    this.objFlujosFuturos = ficha?.objFlujosFuturos || [];
    this.titlesFlujosHistoricos = ficha?.titlesFlujosHistoricos || [];
    this.objFlujosHistoricos = ficha?.objFlujosHistoricos || [];
    this.mallaSocietariaTable = ficha?.mallaSocietariaTable || [];
    this.dicomMTable = ficha?.dicomMTable || [];
    this.mallaSocietariaGraph = ficha?.mallaSocietariaGraph || [];
    this.mallaSocietariaGraphPrint = ficha?.mallaSocietariaGraphPrint || {};
    this.categoria = ficha?.categoria || [];
    this.serie = ficha?.serie || [];
    this.deudaSbifDerivados = ficha?.deudaSbifDerivados || [];
    
    if(this.bodyModelos?.workflowModel === 'lineaDeCredito'){ // modeloDemo
      this.objetoLineaCredito = ficha?.objetoLineaCredito || [];
      this.montoPreAprobado = ficha?.montoPreAprobado || 0;
    }
    
    this.motorCalculos = this.objEntradaMotor;
    if(this.motorCalculos?.ResponseReglas?.message){
      this.alertService.error(this.motorCalculos?.ResponseReglas?.message || 'Solicitud de Crédito - Ha ocurrido un error en el motor de calculos');
      this.motorConDatos = false;
      } else {
        this.motorConDatos = true;
    }
  }

  obtenerDatosF29(): any[] {
    const F29Limpio: any[] = [];

    const carpetaF29 = this.reportesPrincipalesObj?.cteVentasBalancesRentas?.reporte?.CarpetaTributariaElectronicaReporte?.Reporte?.f29 || [];
    const historicoF29 = this.reportesPrincipalesObj?.f29?.reporte?.ListadoF29Reportes || [];
    const calculadoF29 = this.reportesPrincipalesObj?.f29Ventas?.reporte?.Formulario29Reporte?.Reporte || {};
    
    const F29: any[] = [];
    carpetaF29.forEach((element: any) => {
      if(!element?.message){
        F29.push(element);
      }
    });
    historicoF29.forEach((element: any) => {
      if(!element?.Formulario29Reporte?.Reporte.message){
        F29.push(element?.Formulario29Reporte?.Reporte);
      }
    });
    if(calculadoF29 && Object.keys(calculadoF29).length > 0 && !calculadoF29?.message){
      F29.push(calculadoF29);
    }

    const ordenadoF29 = F29.sort((a: any,b: any) => { // mayor a menor en año y menor a mayor en mes
      if (!a?.calculado) return 1;
      if (!b?.calculado) return -1;
      const periodoDiff = Number(b.calculado.periodo) - Number(a.calculado.periodo);
      if (periodoDiff !== 0) return periodoDiff;
      return Number(a.calculado.mes) - Number(b.calculado.mes);
    });

    ordenadoF29.forEach((element: any) => {
      const indexRepetido = F29Limpio.findIndex(e => Number(e?.calculado?.periodo) === Number(element?.calculado?.periodo) && Number(e?.calculado?.mes) === Number(element?.calculado?.mes));
      if(indexRepetido >= 0){
        if(F29Limpio[indexRepetido]?.fecha_ingreso < element.fecha_ingreso){
          F29Limpio[indexRepetido] = element;
        }
      } else {
        F29Limpio.push(element);
      }
    })
    return F29Limpio;
  }

  mapeosMotorCalculos(): void {
    const F29Limpio = this.obtenerDatosF29();

    this.estadosFinancierosM = this.crearEstadosFinancierosM(); // array de dos arrays de objetos
    this.carpetaTributaria = this.crearCarpetaTributaria(F29Limpio); // hacer mapeo****
    this.informacionBalanceEstadoResultadoM = this.crearInformacionBalanceEstadoResultadoM(); // array de dos arrays de objetos
    this.informeRiesgo = this.crearInformeRiesgo();//{};// parte array y parte objeto
    this.putValuesCalculoInformacionCliente();
    this.putValuesAntecedentesSalfa();
  }

  // Mapeo y generacion de objetos para tablas

  mapeoReportes(): void {
    this.informacionCliente = this.crearInformacionCliente();
    this.antecedentesSalfa = this.crearAntecedentesSalfa();// array    
    this.lineaCreditoMTickets = this.crearLineaCreditoMTickets(); // objeto {}
    this.montoPreAprobado = 0; // si esta la ficha guardada se debe mapear desde ahi

    if(this.bodyModelos?.workflowModel === 'lineaDeCredito') { // modeloDemo
      this.objetoLineaCredito = this.obtenerLineaCreditoGeneral();
    }

    const { titlesFlujosFuturos, objFlujosFuturos } = this.obtenerFechaFlujosFuturos();
    this.titlesFlujosFuturos = titlesFlujosFuturos;
    this.objFlujosFuturos = objFlujosFuturos;

    this.titlesFlujosHistoricos = [];
    this.objFlujosHistoricos = [];
    const { titlesFlujosHistoricos, objFlujosHistoricos } = this.obtenerFlujosHistoricos();
    this.titlesFlujosHistoricos = titlesFlujosHistoricos;
    this.objFlujosHistoricos = objFlujosHistoricos;

    this.mallaSocietariaTable = this.crearMallaSocietariaTable(); // array
    this.dicomMTable = this.crearDicomMTable(); // array
    this.reporteDeudaSbif = this.mapeoReporteSbif();
    this.deudaSbifDerivados = this.mapeoReporteSbifDerivados();
    this.cargarMallaSocietariaGraph();

    // grafico del final
    this.setFacturacionSaldo(); 
    this.graphReady = true;
    this.showReportes = true;
  }

  setRowLineaAprobada(value: any, item: any, key: string): void {
    const temp = value.replace(/\./g, '');    
    const valueKey = Number(temp) || 0;   
          
    item[key] = ((valueKey * 100) / this.montoPreAprobado).toFixed(2);
  }

  setAddRow(value: any, item: any, key: string): void {
    const temp = value.replace(/\./g, '');  
    const valueKey = Number(temp) || 0;
    item[key] = valueKey
  }

  showAlert(): void {
    if (this.montoPreAprobado === 0 || !this.montoPreAprobado) {
      this.dialog.open(ConfirmDialogComponent, {
        data: {
          title: 'Solicitud de Crédito',
          message: `Debe llenar el campo monto pre aprobado para poder realizar esta acción.`,
          hideCancelmButtom: true
        }
      });
    }
  }

  obtenerLineaCreditoGeneral(): any[] {
    const objetoLC = this.obtenerLc();
    const objetoAcuerdo30 = this.otenerAcuerdo30();
    let objetoLineaCreditoReduce: any[] = [];
    const resultSaldoActual: any[] = [];
    const objResponse = this.obtenerValorCamposPersonalizadosAutomatic('item');
    const keyValue = 'Linea de Credito';
    const keysValues = ['Importe ML', 'ACC', 'Unidad Negocio'];
    const keysValuesNegocio = ['CR01', 'CR10', 'CR20', 'CR21', 'CR80'];
    const objImporte = this.convertirMiles(this.obtenerValorCamposPersonalizadosAutomatic(keysValues[0]));
    const objAcc = this.obtenerValorCamposPersonalizadosAutomatic(keysValues[1]);
    const objUnidadNegocio = this.obtenerValorCamposPersonalizadosAutomatic(keysValues[2]);
    const keysObjs = [objImporte, objAcc, objUnidadNegocio];

    if (objResponse.length > 0) {
      //Obtener posicion de la key by key Linea de Credito
      let idx = objResponse.indexOf(keyValue);
      while (idx != -1) {
        resultSaldoActual.push({ position: idx });
        idx = objResponse.indexOf(keyValue, idx + 1);
      }
      //Obtener valores de la key
      if (resultSaldoActual.length > 0) {
        keysValues.forEach((e: any, i: number) => {
          resultSaldoActual.forEach((f: any) => {
            f[e.replace(' ', '')] = keysObjs[i][f.position] || '';
          });
        });
      }

      objetoLineaCreditoReduce = Object.values(resultSaldoActual.filter(({ ACC }) => keysValuesNegocio.includes(ACC)).reduce((acc, curr) => {
        acc[curr.ACC] = acc[curr.ACC] || {
          ACC: curr.ACC,
          UnidadNegocio: curr.UnidadNegocio,
          ImporteML: 0,
          valueAcc: 0,
          lcSolicitada: 0,
          lcAprobada: 0,
          acuerdo30: 0,
          lcActual: 0
        };
        acc[curr.ACC].ImporteML += +curr.ImporteML;
        return acc;
      }, {}));
    }

    if (objetoAcuerdo30) {
      objetoLineaCreditoReduce.forEach((e: any) => {
        e.acuerdo30 = objetoAcuerdo30 || 0;
      });
    }

    if (objetoLC.length > 0) {
      objetoLineaCreditoReduce.forEach((e: any) => {
        objetoLC.forEach((f: any) => {

          if (e.ACC === f.ACC) {
            e.lcActual = f.ImporteML;
            e.lcAprobada = ((e.lcActual / e.ImporteML) * 100);
          }
        });
      });
    }

    return objetoLineaCreditoReduce || [];
  }

  otenerAcuerdo30(): any {
    let objetoAcuerdo30Reduce: any[] = [];
    const resultAcuerdo30: any[] = [];
    let lastValue = 0;
    const objResponse = this.obtenerValorCamposPersonalizadosAutomatic('item');
    const keyValue = 'Saldo_Actual';
    const keysValues = ['C_Pago', 'ACC'];
    const objCme = this.obtenerValorCamposPersonalizadosAutomatic(keysValues[0]);
    const objAcc = this.obtenerValorCamposPersonalizadosAutomatic(keysValues[1]);
    const keysObjs = [objCme, objAcc];

    if (objResponse.length > 0) {
      //Obtener posicion de la key by key C_Pago
      let idx = objResponse.indexOf(keyValue);
      while (idx != -1) {
        resultAcuerdo30.push({ position: idx });
        idx = objResponse.indexOf(keyValue, idx + 1);
      }
      //Obtener valores de la key
      if (resultAcuerdo30.length > 0) {
        keysValues.forEach((e: any, i: number) => {
          resultAcuerdo30.forEach((f: any) => {
            f[e] = keysObjs[i][f.position] || '';
          });
        });
      }

      if (resultAcuerdo30.length > 0) {
        objetoAcuerdo30Reduce = Object.values(resultAcuerdo30.reduce((acc, curr) => {
          acc[curr.ACC] = acc[curr.ACC] || {
            ACC: curr.ACC,
            C_Pago: curr.C_Pago
          };
          acc[curr.ACC].ImporteML += +curr.ImporteML;
          return acc;
        }, {}));
      }
      //Obtener valor unico
      lastValue = objetoAcuerdo30Reduce.reduce((acc, curr) => {
        if (acc.C_Pago < curr.C_Pago) {
          return acc.C_Pago;
        }
        return curr.C_Pago;
      }, 0);

    }
    return lastValue;
  }

  obtenerLc(): any {
    let objetoLcReduce: any[] = [];
    const resultLc: any[] = [];
    const objResponse = this.obtenerValorCamposPersonalizadosAutomatic('item');
    const keyValue = 'Saldo_Actual';
    const keysValues = ['Importe ML', 'ACC'];
    const objImporte = this.convertirMiles(this.obtenerValorCamposPersonalizadosAutomatic(keysValues[0]));
    const objAcc = this.obtenerValorCamposPersonalizadosAutomatic(keysValues[1]);
    const keysObjs = [objImporte, objAcc];

    if (objResponse.length > 0) {
      //Obtener posicion de la key by key LineaDeCredito
      let idx = objResponse.indexOf(keyValue);
      while (idx != -1) {
        resultLc.push({ position: idx });
        idx = objResponse.indexOf(keyValue, idx + 1);
      }

      //Obtener valores de la key
      if (resultLc.length > 0) {
        keysValues.forEach((e: any, i: number) => {
          resultLc.forEach((f: any) => {
            f[e.replace(' ', '')] = keysObjs[i][f.position] || '';
          });
        });
      }

      if (resultLc.length > 0) {

        objetoLcReduce = Object.values(resultLc.reduce((acc, curr) => {
          acc[curr.ACC] = acc[curr.ACC] || {
            ACC: curr.ACC,
            ImporteML: 0
          };
          acc[curr.ACC].ImporteML += +curr.ImporteML;
          return acc;
        }, {}));
      }
    }

    return objetoLcReduce;
  }

  cargarMallaSocietariaGraph(mallaGuardada: boolean = false): void {
    if(!mallaGuardada) {
      this.mallaSocietariaGraph = this.crearMallaSocietariaGraph();
      this.mallaSocietariaGraphPrint = this.crearMallaSocietariaGraphPrint();
    }
    setTimeout(() => {
      if(this.chartContainer && this.mallaSocietariaGraph.length > 0){
        this.chart = new OrgChart();
        this.chart
          .container(this.chartContainer.nativeElement)
          .nodeHeight((d: any) => 120) // 90
          .nodeWidth((d: any) => 220)
          .data(this.mallaSocietariaGraph)
          .childrenMargin((d: any) => 40)
          .svgWidth(220)
          .compactMarginBetween((d: any) => 35)
          .compactMarginPair((d: any) => 30)
          .neighbourMargin((a: any, b: any) => 20)
          .initialZoom(1)
          .linkUpdate(function (d: any, i: any, arr: any) {
            // @ts-ignore //no reconoce el this como un elemento de d3
            d3.select(this)
              .attr('stroke', '#4D7BA0')
              .attr('stroke-width', 2);
          })
          .nodeContent((d: any) => {
            return d.data.template;
          })
          .render();
        this.chart.expandAll().compact(true).fit().render();
      }
    }, this.chartContainer ? 0 : 1000); // espera un segundo si no encuentra el elemento
  }

  zoomIn() {
    if(this.chart){
      this.chart.zoomIn();
    }
  }

  zoomOut() {
    if(this.chart){
      this.chart.zoomOut();
    }
  }

  generarElementoInformacionCliente(title: string, id: string, scoreValue: boolean, values: ValuesInformacionCliente[]): InformacionCliente {
    const informacionCliente: InformacionCliente = new Object() as InformacionCliente;
    informacionCliente.title = title;
    informacionCliente.scoreValue = scoreValue;
    informacionCliente.values = values;
    informacionCliente.id = id;
    return informacionCliente;
  }

  crearInformacionCliente(): any[] {
    const respuesta: any[] = [];

    const reporteBasico: any = this.reportesPrincipalesObj?.basicReport?.reporte || null;
    const carpetaTributariaElectronicaReporte = this.reportesPrincipalesObj?.cteVentasBalancesRentas?.reporte?.CarpetaTributariaElectronicaReporte?.Reporte || null;

    //Razón Social
    const nombreORazonSocial: ValuesInformacionCliente[] = [
      {
        value: reporteBasico?.InformacionBasica?.NombreORazonSocial || '',
        type: 'text',
        inputPrepend: ''
      }
    ]
    respuesta.push(this.generarElementoInformacionCliente('Razón Social', 'razonSocial', false, nombreORazonSocial)); // nombre, id, scoreValue = etapa score o ficha, values

    //Rut
    const rutInformacionCliente: ValuesInformacionCliente[] = [
      {
        value: reporteBasico?.InformacionBasica?.Rut || '',
        type: 'rut',
        inputPrepend: ''
      }
    ]
    respuesta.push(this.generarElementoInformacionCliente('Rut', 'rut', false, rutInformacionCliente));

    //Dirección
    const direccionInformacionCliente: ValuesInformacionCliente[] = [
      {
        value: reporteBasico?.InformacionBasica?.Direccion || '',
        type: 'text',
        inputPrepend: ''
      }
    ]
    respuesta.push(this.generarElementoInformacionCliente('Dirección', 'direccion', false, direccionInformacionCliente));

    //Actividad Economica
    const actividadEconomicaInformacionCliente: ValuesInformacionCliente[] = [
      {
        value: reporteBasico?.Riesgo?.ActividadEconomica || '',
        type: 'text',
        inputPrepend: ''
      }
    ]
    respuesta.push(this.generarElementoInformacionCliente('Actividad Economica', 'actividadEconomica', false, actividadEconomicaInformacionCliente));

    //Iniciación de Actividades
    const anoInicioActividadesInformacionCliente: ValuesInformacionCliente[] = [
      {
        value: reporteBasico?.InformacionBasica?.AnoInicioActividades || '',
        type: 'date',
        inputPrepend: ''
      }
    ]
    respuesta.push(this.generarElementoInformacionCliente('Iniciación de Actividades', 'iniciacionActividades', false, anoInicioActividadesInformacionCliente));

    //Cant. Empleados
    const numeroEmpleadosInformacionCliente: ValuesInformacionCliente[] = [
      {
        value: reporteBasico?.AntecedentesFinancieros?.NumeroEmpleados || '',
        type: 'number',
        inputPrepend: ''
      }
    ]
    respuesta.push(this.generarElementoInformacionCliente('Cant. Empleados', 'cantEmpleados', false, numeroEmpleadosInformacionCliente));

    //Tipo de Sociedad
    const tipoSociedadInformacionCliente: ValuesInformacionCliente[] = [
      {
        value: reporteBasico?.InformacionBasica?.TipoSociedad || '',
        type: 'text',
        inputPrepend: ''
      }
    ]
    respuesta.push(this.generarElementoInformacionCliente('Tipo de Sociedad', 'tipoSociedad', false, tipoSociedadInformacionCliente));

    //Malla Societaria
    let fuenteSocios: string = '';
    const entidadSocios = this.entidadesDerivadas.find((e: any) => e.entidad === 'socios') || null;
    if(entidadSocios && entidadSocios?.items && entidadSocios?.items.length > 0){
      const uniqueSources = [...new Set(entidadSocios?.items.map((item: any) => item.fuente))];
      fuenteSocios = uniqueSources.join(', ');
    }
    const mallaSocietariaInformacionCliente: ValuesInformacionCliente[] = [
      {
        value: fuenteSocios || '',
        type: 'text',
        inputPrepend: ''
      }
    ]
    respuesta.push(this.generarElementoInformacionCliente('Malla Societaria', 'mallaSocietaria', false, mallaSocietariaInformacionCliente));

    //Representante Legal {{i + 1}}
    //const carpetaTributariaElectronicaReporte = this.reportesPrincipalesObj?.cteVentasBalancesRentas?.reporte?.CarpetaTributariaElectronicaReporte?.Reporte?.f29 || [];
    const representantes: any = carpetaTributariaElectronicaReporte?.representantes || null;
    if(representantes && representantes.length > 0){
      for(const [i, representante] of representantes.entries()){
        const representanteLegalInformacionCliente: ValuesInformacionCliente[] = [
          {
            value: representante.nombreRazonSocial || '',
            type: 'text',
            inputPrepend: ''
          }
        ]
        respuesta.push(this.generarElementoInformacionCliente(`Representante Legal ${i + 1}`, `representanteLegal${i + 1}`, false, representanteLegalInformacionCliente));
      }
    }

    //Tamaño de la empresa
    const tamanoEmpresaInformacionCliente: ValuesInformacionCliente[] = [
      {
        value: reporteBasico?.AntecedentesFinancieros?.TamanoEmpresaDescripcion || '',
        type: 'text',
        inputPrepend: ''
      }
    ]
    respuesta.push(this.generarElementoInformacionCliente('Tamaño de la empresa', 'tamanoEmpresa', false, tamanoEmpresaInformacionCliente));

    //Subsegmento
    const tamanoSubsegmentoInformacionCliente: ValuesInformacionCliente[] = [
      {
        value: reporteBasico?.AntecedentesFinancieros?.TamanoSubsegmento || '',
        type: 'text',
        inputPrepend: ''
      }
    ]
    respuesta.push(this.generarElementoInformacionCliente('Subsegmento', 'subsegmento', false, tamanoSubsegmentoInformacionCliente));

    //Rango Facturación
    const tamanoEmpresaRangoInformacionCliente: ValuesInformacionCliente[] = [
      {
        value: reporteBasico?.AntecedentesFinancieros?.TamanoEmpresaRango || '',
        type: 'text',
        inputPrepend: ''
      }
    ]
    respuesta.push(this.generarElementoInformacionCliente('Rango Facturación', 'rangoFacturacion', false, tamanoEmpresaRangoInformacionCliente));

    //Causas judiciales (Cantidad de causas laborales, civiles o cobranza) Activas
    const nCausasCivil = Number(this.reportesPrincipalesObj?.poderJudicial?.reporte?.["Totalizadores Poder Judicial"]?.nCausasCivil) || 0;
    const nCausasCobranza = Number(this.reportesPrincipalesObj?.poderJudicial?.reporte?.["Totalizadores Poder Judicial"]?.nCausasCobranza) || 0;
    const nCausasLaboral = Number(this.reportesPrincipalesObj?.poderJudicial?.reporte?.["Totalizadores Poder Judicial"]?.nCausasLaboral) || 0;
    const causasJudicialesInformacionCliente: ValuesInformacionCliente[] = [
      {
        value: this.getTotal(nCausasCivil, nCausasCobranza, nCausasLaboral),
        type: 'number',
        inputPrepend: ''
      }
    ]
    respuesta.push(this.generarElementoInformacionCliente('Causas judiciales (Cantidad de causas laborales, civiles o cobranza) Activas', 'causasJudicialesActivas', false, causasJudicialesInformacionCliente));

    //Número de Vehiculos
    const numeroVehiculosInformacionCliente: ValuesInformacionCliente[] = [
      {
        value: reporteBasico?.AntecedentesFinancieros?.NumeroVehiculos || reporteBasico?.AntecedentesFinancieros?.NumeroVehiculos == 0 ? reporteBasico?.AntecedentesFinancieros?.NumeroVehiculos : 'Sin información',
        type: reporteBasico?.AntecedentesFinancieros?.NumeroVehiculos || reporteBasico?.AntecedentesFinancieros?.NumeroVehiculos == 0 ? 'intNumber' : 'text',
        inputPrepend: ''
      }
    ]
    respuesta.push(this.generarElementoInformacionCliente('Número de Vehiculos', 'numeroVehiculos', false, numeroVehiculosInformacionCliente));

    //Valor de Vehiculos
    const valorVehiculosInformacionCliente: ValuesInformacionCliente[] = [
      {
        value: reporteBasico?.AntecedentesFinancieros?.ValorVehiculos || reporteBasico?.AntecedentesFinancieros?.ValorVehiculos == 0 ? reporteBasico?.AntecedentesFinancieros?.ValorVehiculos : 'Sin información',
        type: reporteBasico?.AntecedentesFinancieros?.ValorVehiculos || reporteBasico?.AntecedentesFinancieros?.ValorVehiculos == 0 ? 'intNumber' : 'text',
        inputPrepend: ''
      }
    ]
    respuesta.push(this.generarElementoInformacionCliente('Valor de Vehiculos', 'valorVehiculos', false, valorVehiculosInformacionCliente));

    //Número de Bienes Raices
    const numeroBienesRaicesInformacionCliente: ValuesInformacionCliente[] = [
      {
        value: reporteBasico?.AntecedentesFinancieros?.NumeroBienesRaices || reporteBasico?.AntecedentesFinancieros?.NumeroBienesRaices == 0 ? reporteBasico?.AntecedentesFinancieros?.NumeroBienesRaices : 'Sin información',
        type: reporteBasico?.AntecedentesFinancieros?.NumeroBienesRaices || reporteBasico?.AntecedentesFinancieros?.NumeroBienesRaices == 0 ? 'intNumber' : 'text',
        inputPrepend: ''
      }
    ]
    respuesta.push(this.generarElementoInformacionCliente('Número de Bienes Raices', 'numeroBienesRaices', false, numeroBienesRaicesInformacionCliente));

    //Valor de Bienes Raices
    const valorBienesRaicesInformacionCliente: ValuesInformacionCliente[] = [
      {
        value: reporteBasico?.AntecedentesFinancieros?.ValorBienesRaices || reporteBasico?.AntecedentesFinancieros?.ValorBienesRaices == 0 ? reporteBasico?.AntecedentesFinancieros?.ValorBienesRaices : 'Sin información',
        type: reporteBasico?.AntecedentesFinancieros?.ValorBienesRaices || reporteBasico?.AntecedentesFinancieros?.ValorBienesRaices == 0 ? 'intNumber' : 'text',
        inputPrepend: ''
      }
    ]
    respuesta.push(this.generarElementoInformacionCliente('Valor de Bienes Raices', 'valorBienesRaices', false, valorBienesRaicesInformacionCliente));

    //Total Impago Empresa (Dicom) M$
    const totalImpagosCantidad: any = reporteBasico?.AntecedentesComerciales?.Resumen?.TotalImpagosCantidad;
    const totalImpagosMonto: any = reporteBasico?.AntecedentesComerciales?.Resumen?.TotalImpagosMonto;
    const totalImpagosEmpresaDicomInformacionCliente: ValuesInformacionCliente[] = [
      {
        value: totalImpagosCantidad || totalImpagosCantidad == 0 ? Number(totalImpagosCantidad) : '',
        type: totalImpagosCantidad || totalImpagosCantidad == 0 ? 'intNumber' : 'text',
        inputPrepend: '#'
      },
      {
        value: totalImpagosMonto || totalImpagosMonto == 0 ? Number(totalImpagosMonto) : '',
        type: totalImpagosMonto || totalImpagosMonto == 0 ?'intNumber' : 'text',
        inputPrepend: '$'
      }
    ];
    respuesta.push(this.generarElementoInformacionCliente('Total Impago Empresa (Dicom) M$', 'totalImpagoEmpresaM', false, totalImpagosEmpresaDicomInformacionCliente));

    //Total Impagos Socios (Dicom) M$ // validar de donde vienen los socios
    let totalImpagosCantidadSocios: any = '';
    let totalImpagosMontoSocios: any = '';
    if(this.reportesDerivadosObj?.socios && Object.keys(this.reportesDerivadosObj?.socios).length > 0){
      const sociosObj: any = this.reportesDerivadosObj?.socios || {}; 
      for(const value of Object.values(sociosObj)) {
        if(value && Object.keys(value).length > 0){
          if((value as any)?.basicReport?.reporte?.AntecedentesComerciales?.Resumen?.TotalImpagosCantidad || (value as any)?.basicReport?.reporte?.AntecedentesComerciales?.Resumen?.TotalImpagosCantidad == 0) {
            if(totalImpagosCantidadSocios === '') totalImpagosCantidadSocios = 0;
            totalImpagosCantidadSocios += (value as any)?.basicReport?.reporte?.AntecedentesComerciales?.Resumen?.TotalImpagosCantidad || 0;
            
          }
          if((value as any)?.basicReport?.reporte?.AntecedentesComerciales?.Resumen?.TotalImpagosMonto || (value as any)?.basicReport?.reporte?.AntecedentesComerciales?.Resumen?.TotalImpagosMonto == 0) {
            if(totalImpagosMontoSocios === '') totalImpagosMontoSocios = 0;
            totalImpagosMontoSocios += (value as any)?.basicReport?.reporte?.AntecedentesComerciales?.Resumen?.TotalImpagosMonto || 0;            
          }
        }
      }
    }

    const totalImpagosSociosDicomInformacionCliente: ValuesInformacionCliente[] = [
      {
        value: totalImpagosCantidadSocios || totalImpagosCantidadSocios == 0 ? Number(totalImpagosCantidadSocios) : '',
        type: totalImpagosCantidadSocios || totalImpagosCantidadSocios == 0 ? 'intNumber' : 'text',
        inputPrepend: '#'
      },
      {
        value: totalImpagosMontoSocios || totalImpagosMontoSocios == 0 ? Number(totalImpagosMontoSocios) : '',
        type: totalImpagosMontoSocios || totalImpagosMontoSocios == 0 ? 'intNumber' : 'text',
        inputPrepend: '$'
      }
    ];
    respuesta.push(this.generarElementoInformacionCliente('Total Impagos Socios (Dicom) M$', 'totalImpagosSociosM', false, totalImpagosSociosDicomInformacionCliente));

    const modelo = this.bodyModelos?.workflowModel || '';

    if(modelo === 'lineaDeCredito'){ // modeloDemo
      //LC Solicitada M$
      const lcSolicitada: any = this.atributos.find((e: any) => e.nameAttribute === 'LC Solicitada')?.value || ''; 
      const lcSolicitadaInformacionCliente: ValuesInformacionCliente[] = [
        {
          value: Number(lcSolicitada) / 1000,
          type: 'intNumber',
          inputPrepend: ''
        }
      ];
      respuesta.push(this.generarElementoInformacionCliente('LC Solicitada M$', 'lcSolicitadaM', false, lcSolicitadaInformacionCliente));

      //LC Calculada M$
      const lcCalculada: any = '';
      const lcCalculadaInformacionCliente: ValuesInformacionCliente[] = [
        {
          value: lcCalculada,
          type: '',//'intNumber',
          inputPrepend: ''
        }
      ];
      respuesta.push(this.generarElementoInformacionCliente('LC Calculada M$', 'lcCalculadaM', true, lcCalculadaInformacionCliente));
    }
    
    if (modelo !== 'renegociacion'){ // renegociacion
      //LC por Flota M$
      const lcFlota: any = '';
      const lcFlotaInformacionCliente: ValuesInformacionCliente[] = [
        {
          value: lcFlota, //lcFlota ? Number(lcFlota) : 'Sin Información',
          type: '',//lcFlota ? 'intNumber' : 'text',
          inputPrepend: ''
        }
      ];
      respuesta.push(this.generarElementoInformacionCliente('LC por Flota M$', 'lcFlotaM', true, lcFlotaInformacionCliente));
    }

    const alertaFraude: any = '';
    const alertaFraudeInformacionCliente: ValuesInformacionCliente[] = [
      {
        value: alertaFraude,
        type: '',
        inputPrepend: ''
      }
    ];
    respuesta.push(this.generarElementoInformacionCliente('Alerta Fraude', 'alertaFraude', true, alertaFraudeInformacionCliente));

    // dividir el array en 2 para mostrar en 2 columnas
    const mitad = Math.ceil(respuesta.length / 2);
    const respuesta1 = respuesta.slice(0, mitad);
    const respuesta2 = respuesta.slice(mitad, respuesta.length);

    return [respuesta1, respuesta2];
  }

  putValuesCalculoInformacionCliente(): void {
    const lcCalculadaM = this.informacionCliente[1].findIndex((e: any) => e.id === 'lcCalculadaM');
    if(lcCalculadaM > -1){
      this.informacionCliente[1][lcCalculadaM].values[0].value = (Number(this.validaNumber(this.obtenerValorRulesEngine('lineaCreditoAprobada')))/1000);
      this.informacionCliente[1][lcCalculadaM].values[0].type = 'intNumber';
    }

    const lcFlotaM = this.informacionCliente[1].findIndex((e: any) => e.id === 'lcFlotaM');
    if(lcFlotaM > -1){
      this.informacionCliente[1][lcFlotaM].values[0].value = (!this.obtenerValorRulesEngine('lineaCreditoFlota') && this.obtenerValorRulesEngine('lineaCreditoFlota') != '0') ? ('Sin Información') : (this.obtenerValorRulesEngine('lineaCreditoFlota'))
      this.informacionCliente[1][lcFlotaM].values[0].type = (!this.obtenerValorRulesEngine('lineaCreditoFlota') && this.obtenerValorRulesEngine('lineaCreditoFlota') != '0') ? ('text') : ('intNumber');
    }

    const alertaFraude = this.informacionCliente[1].findIndex((e: any) => e.id === 'alertaFraude');
    if(alertaFraude > -1){
      this.informacionCliente[1][alertaFraude].values[0].value = (!this.obtenerValorRulesEngine('alertafraude') && this.obtenerValorRulesEngine('alertafraude') != '0') ? ('Sin Información') : (this.obtenerValorRulesEngine('alertafraude'))
      this.informacionCliente[1][alertaFraude].values[0].type = this.obtenerValorRulesEngine('alertafraude') ? 'semaforo' : 'text';
    }
  }

  crearInformeRiesgo(): InformeRiesgo { // muchos datos vienen desde rules engine
    const respuesta: InformeRiesgo = new Object() as InformeRiesgo;

    //Riesgo Industria
    respuesta.riesgoIndustria = this.reportesPrincipalesObj?.basicReport?.reporte?.Riesgo?.RiesgoIndustria || '';

    //Protestos internos vigentes protestosInternosVigentesCantidad
    respuesta.protestosInternosVigentesCantidad = this.obtenerValorRulesEngine('protestosInternosVigentesCantidad'); // Se debe obtener desde el score - mapeo por definir

    //Protestos internos vigentes protestosInternosVigentesMonto
    respuesta.protestosInternosVigentesMonto = this.obtenerValorRulesEngine('protestosInternosVigentesMonto'); // Se debe obtener desde el score - mapeo por definir

    //Morosidades internas
    respuesta.morosidadesInternas = Number(this.validaNumber(this.obtenerValorRulesEngine('morosidadesInternas')))/1000; // setNumber(informeRiesgo?.morosidadesInternas)/1000) // Se debe obtener desde el score - mapeo por definir

    //Morosidades internas +30
    respuesta.morosidadesInternasMayores30 = Number(this.validaNumber(this.obtenerValorRulesEngine('morosidadesInternasMayores30')))/1000; // setNumber(informeRiesgo?.morosidadesInternasMayores30)/1000 // Se debe obtener desde el score - mapeo por definir

    //graph score
    respuesta.score = Number(this.validaNumber(this.obtenerValorRulesEngine('score'))).toFixed(0) || 0; // Se debe obtener desde el score - mapeo por definir

    return respuesta;
  }

  crearAntecedentesSalfa(): any[] { // obtener todos los values desde calculo del score
    const respuesta: any[] = [];

    //Antigüedad Salfa
    const antiguedadSalfa: AntecedentesSalfa = new Object() as AntecedentesSalfa;
    antiguedadSalfa.title = 'Antigüedad Salfa';
    antiguedadSalfa.id = 'antiguedadSalfa';
    antiguedadSalfa.type = 'date';
    antiguedadSalfa.titlePromedio = false;
    antiguedadSalfa.value = '';
    respuesta.push(antiguedadSalfa);

    //Cliente Nuevo/Antiguo
    const clienteNuevoAntiguo: AntecedentesSalfa = new Object() as AntecedentesSalfa;
    clienteNuevoAntiguo.title = 'Cliente Nuevo/Antiguo';
    clienteNuevoAntiguo.id = 'clienteNuevoAntiguo';
    clienteNuevoAntiguo.type = 'text';
    clienteNuevoAntiguo.titlePromedio = false;
    clienteNuevoAntiguo.value = '';
    respuesta.push(clienteNuevoAntiguo);

    // X mensual venta Salfa (No seriado) M$
    const mensualVentaSalfa: AntecedentesSalfa = new Object() as AntecedentesSalfa;
    mensualVentaSalfa.title = 'mensual venta Salfa (No seriado) M$';
    mensualVentaSalfa.id = 'promMensualVentaSalfaNoSeriado';
    mensualVentaSalfa.type = 'intNumber';
    mensualVentaSalfa.titlePromedio = true;
    mensualVentaSalfa.value = '';
    respuesta.push(mensualVentaSalfa);

    // X mensual venta Salfa (Seriado) M$
    const mensualVentaSalfaSeriado: AntecedentesSalfa = new Object() as AntecedentesSalfa;
    mensualVentaSalfaSeriado.title = 'mensual venta Salfa (Seriado) M$';
    mensualVentaSalfaSeriado.id = 'promMensualVentaSalfaSeriado';
    mensualVentaSalfaSeriado.type = 'intNumber';
    mensualVentaSalfaSeriado.titlePromedio = true;
    mensualVentaSalfaSeriado.value = '';
    respuesta.push(mensualVentaSalfaSeriado);

    //Comportamiento de Pago
    const comportamientoPago: AntecedentesSalfa = new Object() as AntecedentesSalfa;
    comportamientoPago.title = 'Comportamiento de Pago';
    comportamientoPago.id = 'comportamientoDePago';
    comportamientoPago.type = 'text';
    comportamientoPago.titlePromedio = false;
    comportamientoPago.value = '';
    respuesta.push(comportamientoPago);

    //Plazo promedio de venta últimos 4 meses
    const plazoPromedioVenta: AntecedentesSalfa = new Object() as AntecedentesSalfa;
    plazoPromedioVenta.title = 'Plazo promedio de venta últimos 4 meses';
    plazoPromedioVenta.id = 'condicionDePago';
    plazoPromedioVenta.type = 'intNumber';
    plazoPromedioVenta.titlePromedio = false;
    plazoPromedioVenta.value = '';
    respuesta.push(plazoPromedioVenta);

    //Promedio DSO *
    const promedioDSO: AntecedentesSalfa = new Object() as AntecedentesSalfa;
    promedioDSO.title = 'Promedio DSO *';
    promedioDSO.id = 'promedioDSO';
    promedioDSO.type = 'decNumber';
    promedioDSO.titlePromedio = false;
    promedioDSO.value = '';
    respuesta.push(promedioDSO);

    //Promedio DSO (Días)
    const promedioDSODias: AntecedentesSalfa = new Object() as AntecedentesSalfa;
    promedioDSODias.title = 'Promedio DSO (Días)';
    promedioDSODias.id = 'promedioDSODias';
    promedioDSODias.type = 'decNumber';
    promedioDSODias.titlePromedio = false;
    promedioDSODias.value = '';
    respuesta.push(promedioDSODias);

    //Deuda Actual M$
    const deudaActual: AntecedentesSalfa = new Object() as AntecedentesSalfa;
    deudaActual.title = 'Deuda Actual M$';
    deudaActual.id = 'deudaActual';
    deudaActual.type = 'intNumber';
    deudaActual.titlePromedio = false;
    deudaActual.value = '';
    respuesta.push(deudaActual);

    //Deuda Vencida M$
    const deudaVencida: AntecedentesSalfa = new Object() as AntecedentesSalfa;
    deudaVencida.title = 'Deuda Vencida M$';
    deudaVencida.id = 'deudaVencida';
    deudaVencida.type = 'intNumber';
    deudaVencida.titlePromedio = false;
    deudaVencida.value = '';
    respuesta.push(deudaVencida);

    //Frecuencia cliente
    const frecuenciaCliente: AntecedentesSalfa = new Object() as AntecedentesSalfa;
    frecuenciaCliente.title = 'Frecuencia cliente';
    frecuenciaCliente.id = 'frecuenciaCliente';
    frecuenciaCliente.type = 'text';
    frecuenciaCliente.titlePromedio = false;
    frecuenciaCliente.value = '';
    respuesta.push(frecuenciaCliente);

    //Grupo Economico
    const grupoEconomico: AntecedentesSalfa = new Object() as AntecedentesSalfa;
    grupoEconomico.title = 'Grupo Economico';
    grupoEconomico.id = 'grupoEconomico';
    grupoEconomico.type = 'text';
    grupoEconomico.titlePromedio = false;
    grupoEconomico.value = '';
    respuesta.push(grupoEconomico);

    return respuesta;
  }

  putValuesAntecedentesSalfa(): void {
    const divisionPor1000: string[] = ['promMensualVentaSalfaNoSeriado', 'promMensualVentaSalfaSeriado', 'deudaActual', 'deudaVencida'];
    const notNumberFields: string[] = ['antiguedadSalfa', 'clienteNuevoAntiguo', 'comportamientoDePago', 'frecuenciaCliente', 'grupoEconomico'];
    for(const element of this.antecedentesSalfa){
      if(divisionPor1000.includes(element.id)){
        element.value = Number(this.validaNumber(this.obtenerValorRulesEngine(element.id))) / 1000;
      } else if(notNumberFields.includes(element.id)){
        element.value = this.obtenerValorRulesEngine(element.id) || '';
      } else {
        element.value = Number(this.validaNumber(this.obtenerValorRulesEngine(element.id)));
      }
    }
  }

  crearCamposPersonalizados(): any[] { // debe devolver un array [] - por armar

    return [];
  }

  crearLineaCreditoMTickets(): any { // debe devolver un obejto {}
    const reporteBasico: any = this.reportesPrincipalesObj?.basicReport?.reporte || null;
    const nTickets: any = this.atributos.find((e: any) => e.nameAttribute === 'Numero Tickets')?.value || ''; 
    return {
      nTickets: nTickets,
      razonSocial: reporteBasico?.InformacionBasica?.NombreORazonSocial || '',
      rut: reporteBasico?.InformacionBasica?.Rut || ''
    };
  }

  validaNumber(value: any): any {
    return value || value == 0 ? value : '';
  }

  crearEstadosFinancierosM(): any[] { // debe devolver un array de dos arrays de obejtos [[{}], [{}]]
    // array 1
    const array1: any[] = [];
    const penultimo = Number(this.obtenerValorRulesEngine('Penultimoano')).toFixed(0);
    const ultimo = Number(this.obtenerValorRulesEngine('Ultimoano')).toFixed(0);

    //Ventas promedio {{penultimo}}
    const ventasPromedioPenultimo = {
      title: `Ventas promedio ${penultimo}`,
      value: Number(this.validaNumber(this.obtenerValorRulesEngine('VentaPromedioPenultimoano'))),
      type: 'intNumber'
    };
    array1.push(ventasPromedioPenultimo);
    
    //Ventas promedio {{ultimo}}
    const ventasPromedioUltimo = {
      title: `Ventas promedio ${ultimo}`,
      value: Number(this.validaNumber(this.obtenerValorRulesEngine('VentaPromedioUltimoano'))),
      type: 'intNumber'
    };
    array1.push(ventasPromedioUltimo);

    //Margen promedio {{penultimo}}
    const margenPromedioPenultimo = {
      title: `Margen promedio ${penultimo}`,
      value: Number(this.validaNumber(this.obtenerValorRulesEngine('MargenPromedioPenultimoAno'))),
      type: 'intNumber'
    };
    array1.push(margenPromedioPenultimo);

    //Margen promedio {{ultimo}}
    const margenPromedioUltimo = {
      title: `Margen promedio ${ultimo}`,
      value: Number(this.validaNumber(this.obtenerValorRulesEngine('MargenPromedioUltimoAno'))),
      type: 'intNumber'
    };
    array1.push(margenPromedioUltimo);

    // array 2
    const array2: any[] = [];
    //Indicador de liquidez
    const indicadorLiquidez = {
      title: 'Indicador de liquidez',
      value: Number(this.validaNumber(this.obtenerValorRulesEngine('IndicadorLiquidez'))),
      type: 'decNumber'
    };
    array2.push(indicadorLiquidez);
    //% Flujo pago (leasing y otros) sobre margen
    const flujoPagoLeasingMargen = {
      title: '% Flujo pago (leasing y otros) sobre margen',
      value: Number(this.validaNumber(this.obtenerValorRulesEngine('flujoPagoLeasingOtrosSobreMargen'))),
      type: 'decNumber'
    };
    array2.push(flujoPagoLeasingMargen);
    //% Flujo Salfa + leasing/Margen Carpeta
    const flujoSalfaLeasingMargen = {
      title: '% Flujo Salfa + leasing/Margen Carpeta',
      value: Number(this.validaNumber(this.obtenerValorRulesEngine('flujoSalfaLeasingMargenCarpetaTributaria'))),
      type: 'decNumber'
    };
    array2.push(flujoSalfaLeasingMargen);
    //% Flujo Salfa + leasing/Ventas Carpeta tributaria o SII promedio
    const flujoSalfaLeasingVentas = {
      title: '% Flujo Salfa + leasing/Ventas Carpeta tributaria o SII promedio',
      value: Number(this.validaNumber(this.obtenerValorRulesEngine('flujoSalfaLeasingVentasCarpetaTributariaOSiiPromedio'))),
      type: 'decNumber'
    };
    array2.push(flujoSalfaLeasingVentas);

    return [array1, array2];
  }

  crearCarpetaTributaria(informe: any[]): any[] {
    const objTemp: any[] = [];
    const fechasKeys: any[] = [];

    const penultimo = this.obtenerValorRulesEngine('Penultimoano');
    const ultimo = this.obtenerValorRulesEngine('Ultimoano');

    if (penultimo && penultimo > 0) {
      fechasKeys.push(Number(penultimo).toFixed(0));
    }

    if (ultimo && ultimo > 0) {
      fechasKeys.push(Number(ultimo).toFixed(0));
    }

    if (fechasKeys.length > 0) {
      informe
        .map((item: any) => {
          if (item && Object.keys(item).length > 0 && fechasKeys.includes(String(item.periodo))) {
            objTemp.push({
              date: item.periodo + '-' + ((item.mes) < 10 ? '0' + (item.mes) : (item.mes)) + '-' + '02',
              ventas: item.calculado?.ventasNetasMilesPesos || 0,
              compras: item.calculado?.comprasNetasMilesPesos || 0,
              diferencia: item.calculado?.margenMiles || 0,
              'cod.91': (item.codigos?.['91'] && Number(item.codigos?.['91']) > 0)  ? Number(item.codigos?.['91']) / 1000 : 0,
              fechaPago: this.solicitudCreditoService.formatDate(item.calculado?.fechaPago) || '',
              'cod.524': (item.codigos?.['524'] && Number(item.codigos?.['524']) > 0)  ? Number(item.codigos?.['524']) / 1000 : 0,
              posterga: (item.calculado?.postergacionIVA && Number(item.calculado?.postergacionIVA) > 0)  ? Number(item.calculado?.postergacionIVA) / 1000 : 0,
            });
          }
        });

      fechasKeys.map((item: any) => {
        for (let i = 1; i <= 12; i++) {
          if (objTemp.find((e: any) => e.date === item + '-' + ((i) < 10 ? '0' + (i) : (i)) + '-' + '02') === undefined) {
            objTemp.push({
              date: item + '-' + ((i) < 10 ? '0' + (i) : (i)) + '-' + '02',
              ventas: 0,
              compras: 0,
              diferencia: 0,
              'cod.91': 0,
              fechaPago: '',
              'cod.524': 0,
              posterga: 0
            });
          }
        }
      });
      return objTemp.sort((a, b) => (a.date > b.date ? 1 : -1));
    }
    return [];
  }

  crearInformacionBalanceEstadoResultadoM(): any[] { // debe devolver un array de dos arrays de obejtos [[{}], [{}]]
    // array 1
    const array1: any[] = [];
    //Indicador de liquidez
    const indicadorLiquidez = {
      title: 'Indicador de liquidez',
      value: Number(this.validaNumber(this.obtenerValorRulesEngine('IndicadorLiquidez'))),
      type: 'decNumber'
    };
    array1.push(indicadorLiquidez);
    //Periodo de cobro clientes
    const periodoCobroClientes = {
      title: 'Periodo de cobro clientes',
      value: this.obtenerValorRulesEngine('PeriododeCobroClientes') || '',
      type: 'text'
    };
    array1.push(periodoCobroClientes);
    //Pago de IVA en fecha // esta definido como cero y con "por definir"
    const pagoIVA = {
      title: 'Pago de IVA en fecha',
      value: 0,
      type: 'text'
    };
    array1.push(pagoIVA);
    //Gastos intereses/EBITDA
    const gastosInteresesEBITDA = {
      title: 'Gastos intereses/EBITDA',
      value: Number(this.validaNumber(this.obtenerValorRulesEngine('GastosInteresesEBITDA'))),
      type: 'decNumber'
    };
    array1.push(gastosInteresesEBITDA);

    // array 2
    const array2: any[] = [];
    //Indicador de endeudamiento
    const indicadorEndeudamiento = {
      title: 'Indicador de endeudamiento',
      value: Number(this.validaNumber(this.obtenerValorRulesEngine('IndicadordeEndeudamiento'))),
      type: 'decNumber'
    };
    array2.push(indicadorEndeudamiento);
    //EBITDA M$
    const EBITDA = {
      title: 'EBITDA M$',
      value: (Number(this.validaNumber(this.obtenerValorRulesEngine('ebitda')))/1000),
      type: 'intNumber'
    };
    array2.push(EBITDA);
    //Margen EBITDA %
    const margenEBITDA = {
      title: 'Margen EBITDA %',
      value: Number(this.validaNumber(this.obtenerValorRulesEngine('ebitdAporcentaje'))),
      type: 'decNumber'
    };
    array2.push(margenEBITDA);
    //% Flujo pago (leasing y otros) sobre Venta
    const flujoPagoLeasingVenta = {
      title: '% Flujo pago (leasing y otros) sobre Venta',
      value: Number(this.validaNumber(this.obtenerValorRulesEngine('flujoPagoLeasingOtrosSobreVentas'))),
      type: 'decNumber'
    };
    array2.push(flujoPagoLeasingVenta);
    //Línea de crédito / sobre Patrimonio(%)
    const lineaCreditoPatrimonio = {
      title: 'Línea de crédito / sobre Patrimonio(%)',
      value: Number(this.validaNumber(this.obtenerValorRulesEngine('lineaCreditoSobrePatrimonio'))),
      type: 'decNumber'
    };
    array2.push(lineaCreditoPatrimonio);

    return [array1, array2];
  }

  obtenerFechaFlujosFuturos() : any {
    let objetoFlujoFuturoReduce: any[] = [];
    const titlesFlujosFuturos: any[] = [];
    const objetoTempFlujoFuturoReduce: any[] = [];
    const objTemp: any[] = [];
    const objResponse = this.obtenerValorCamposPersonalizadosAutomatic('item');
    const keyValue = 'Saldo_Actual';
    const keysValues = ['Importe ML', 'Periodo_Vencimiento', 'Unidad Negocio'];
    // se envia 'Importe ML' y se obtiene el array de valores
    const objImporte = this.convertirMiles(this.obtenerValorCamposPersonalizadosAutomatic(keysValues[0]));
     // se envia 'Periodo_Vencimiento' y se obtiene el array de valores
    const objPeriodoVencimiento = this.obtenerValorCamposPersonalizadosAutomatic(keysValues[1]);
     // se envia 'Unidad Negocio' y se obtiene el array de valores
    const objUnidadNegocio = this.obtenerValorCamposPersonalizadosAutomatic(keysValues[2]);
    const keysObjs = [objImporte, objPeriodoVencimiento, objUnidadNegocio]; // se crea un array con los arrays de valores
    const earlyPeriodoVencimiento = new Date(new Date().getFullYear(), new Date().getMonth(), 2); // se crea una fecha con el primer dia del mes actual
    
    if (objResponse.length > 0) {
      //Obtener posicion de la key Saldo Actual

      let idx = objResponse.indexOf(keyValue);
      while (idx != -1) {
        objTemp.push({ position: idx }); // [{position: 1}, {position: 2}, {position: 3] // son las posiciones de los saldos actuales
        idx = objResponse.indexOf(keyValue, idx + 1); // es como un i++ que luego se evalua en el while
      }      
      //Obtener valores de la key

      if (objTemp.length > 0) {
        keysValues.forEach((e: any, i: number) => { // ['Importe ML', 'Periodo_Vencimiento', 'Unidad Negocio'] e es el valor de la key, i es el indice
          objTemp.forEach((f: any) => { // {position: 1}, {position: 2}, {position: 3] // son las posiciones de los saldos actuales
            f[e.replace(' ', '')] = keysObjs[i][f.position] || ''; // f[e.replace(' ', '')] es el valor de la key, keysObjs[i][f.position] es el valor de la key en el array donde i es el indice de keysValues y f.position es la posicion del valor en el array objResponse
            if (e.replace(' ', '') === 'UnidadNegocio') { // unidad de negocio es donde se almacena el valor nombre del campo por eso se usa en el titulo
              if (titlesFlujosFuturos.indexOf(f.UnidadNegocio) === -1) {
                titlesFlujosFuturos.push(f.UnidadNegocio);
              }
            }
          });
        });
      }

      if (objTemp.length > 0) {

        objetoFlujoFuturoReduce = Object.values(objTemp.reduce((acc, curr) => { // acc es el acumulador, curr es el valor actual
          acc[curr.Periodo_Vencimiento] = acc[curr.Periodo_Vencimiento] || { // se agrupa por periodo de vencimiento
            Periodo_Vencimiento: this.setFechaPeriodo(curr.Periodo_Vencimiento),
            [this.quitarEspacios(curr.UnidadNegocio)]: 0  // se crea una propiedad con el nombre de la unidad de negocio y se le asigna 0
          };

          if (acc[curr.Periodo_Vencimiento][this.quitarEspacios(curr.UnidadNegocio)]) { // si la propiedad ya existe se le suma el importe
            acc[curr.Periodo_Vencimiento][this.quitarEspacios(curr.UnidadNegocio)] += +Number(curr.ImporteML) || 0;
          } else { // si no existe se crea y se le asigna el importe
            acc[curr.Periodo_Vencimiento][this.quitarEspacios(curr.UnidadNegocio)] = 0;
            acc[curr.Periodo_Vencimiento][this.quitarEspacios(curr.UnidadNegocio)] += +Number(curr.ImporteML);
          }

          return acc;
        }, {}));        

        if (objetoFlujoFuturoReduce.length > 0) {
          let sum = 0;

          for (let i = 0; i < 12; i++) { // se recorre los 12 meses

            const periodoVencimiento = new Date(new Date(earlyPeriodoVencimiento).setMonth(new Date(earlyPeriodoVencimiento).getMonth() + i)); // se crea una fecha con el primer dia del mes actual y se le suma i meses          
            const resp = objetoFlujoFuturoReduce.find((e: any) => new Date(e.Periodo_Vencimiento).getTime() === new Date(periodoVencimiento).getTime()); // se busca si el periodo de vencimiento existe en el array de flujos futuros
            
            if (!resp) { // si no existe se crea un objeto con el periodo de vencimiento
              objetoTempFlujoFuturoReduce.push({
                Periodo_Vencimiento: periodoVencimiento,
              });
            } else { // si existe se agrega al array
              objetoTempFlujoFuturoReduce.push(resp)
            }
            sum = 1;
          }
        }
      }
    }
    return {
      titlesFlujosFuturos: titlesFlujosFuturos,
      objFlujosFuturos: objetoTempFlujoFuturoReduce
    }
  }

  obtenerFlujosHistoricos() : any{
    const titlesFlujosHistoricos: any[] = [];
    let objFlujosHistoricos: any[] = [];

    let objetoFlujoHistoricoReduce: any[] = [];
    const tempoFlujoFuturo: any[] = [];
    const objTemp: any[] = [];
    const objResponse = this.obtenerValorCamposPersonalizadosAutomatic('item');
    const keyValue = 'Fact';
    const keysValues = ['Importe ML', 'Periodo', 'Unidad Negocio'];
    const objImporte = this.convertirMiles(this.obtenerValorCamposPersonalizadosAutomatic(keysValues[0]));
    const objPeriodo = this.obtenerValorCamposPersonalizadosAutomatic(keysValues[1]);
    const objUnidadNegocio = this.obtenerValorCamposPersonalizadosAutomatic(keysValues[2]);
    const keysObjs = [objImporte, objPeriodo, objUnidadNegocio];
    const actualDate = new Date().getFullYear();
    const periodosDate = [actualDate - 1, actualDate];    

    if (objResponse.length > 0) {
      //Obtener posicion de la key Fact
      let idx = objResponse.indexOf(keyValue);
      while (idx != -1) {
        objTemp.push({ position: idx });
        idx = objResponse.indexOf(keyValue, idx + 1);
      }

      //Obtener valores de la key
      if (objTemp.length > 0) {
        keysValues.forEach((e: any, i: number) => {
          objTemp.forEach((f: any) => {
            f[e.replace(' ', '')] = e.replace(' ', '') !== 'UnidadNegocio' ? (keysObjs[i][f.position] || '') : keysObjs[i][f.position] || '';
            if (e.replace(' ', '') === 'UnidadNegocio') {
              if (titlesFlujosHistoricos.indexOf(f.UnidadNegocio) === -1) {
                titlesFlujosHistoricos.push(f.UnidadNegocio); // final
              }
            }
          });
        });
      }

      // Agrupar por periodo
      objetoFlujoHistoricoReduce = Object.values(objTemp.reduce((acc, curr) => {
        acc[curr.Periodo] = acc[curr.Periodo] || {
          date: this.setFechaPeriodo(curr.Periodo),
          [this.quitarEspacios(curr.UnidadNegocio)]: 0
        };

        if (acc[curr.Periodo][this.quitarEspacios(curr.UnidadNegocio)]) {
          acc[curr.Periodo][this.quitarEspacios(curr.UnidadNegocio)] += +Number(curr.ImporteML) || 0;
        } else {
          acc[curr.Periodo][this.quitarEspacios(curr.UnidadNegocio)] = 0;
          acc[curr.Periodo][this.quitarEspacios(curr.UnidadNegocio)] += +Number(curr.ImporteML);
        }
        return acc;
      }, {}));

      // Eliminar periodos vacios
      objetoFlujoHistoricoReduce = objetoFlujoHistoricoReduce.filter((e: any) => Object.keys(e).length > 1);
      // Agregar peridos faltantes
      periodosDate.forEach((e: any) => {

        for (let i = 1; i <= 12; i++) {
          const periodo = this.setFechaPeriodo(e + '' + (i));
          const resp = objetoFlujoHistoricoReduce.find((e: any) => e.date.getTime() === periodo.getTime());
          if (!resp) {
            tempoFlujoFuturo.push({
              date: periodo.getFullYear() + '-' + (periodo.getMonth() + 1) + '-' + periodo.getDate(),
            });
          } else {
            tempoFlujoFuturo.push(resp);
          }
        }
      });
    }

    if (tempoFlujoFuturo.length > 0) {
      // Agregar propiedad fecha al principio del arreglo
      titlesFlujosHistoricos.unshift('date');

      // Agregar propiedades falntantes al objeto
      objFlujosHistoricos = tempoFlujoFuturo.map((e: any) => { // final

        const newsPropertys: any = {};
        for (const key of titlesFlujosHistoricos) {
          if (!e.hasOwnProperty(this.quitarEspacios(key)) && key !== 'date') {
            newsPropertys[this.quitarEspacios(key)] = 0;
          }
        }

        const newObject = {
          ...e,
          date: new Date(e.date).getFullYear() + '-' + ((new Date(e.date).getMonth() + 1 <= 9 ?
            '0' + (new Date(e.date).getMonth() + 1) : new Date(e.date).getMonth() + 1)) + '-' + '02',
          ...newsPropertys
        }
        return newObject;
      });
    }

    return {
      titlesFlujosHistoricos: titlesFlujosHistoricos,
      objFlujosHistoricos: objFlujosHistoricos
    }

  }

  crearMallaSocietariaTable(): any[] {
    const respuesta: any[] = [];

    const listaSocios = this.entidadesDerivadas.find((e: any) => e.entidad === 'socios')?.items || [];

    if(listaSocios && listaSocios.length > 0) {
      for(const socio of listaSocios) {
        const reporteBasico = this.reportesDerivadosObj?.socios?.[socio?.rut]?.basicReport?.reporte || {};
        respuesta.push({
          rut: socio.rut,
          date: reporteBasico?.FechaReporte || '',
          nombre: reporteBasico?.InformacionBasica?.NombreORazonSocial || (socio?.atributos && socio?.atributos.length > 0 ? socio?.atributos.find((e: any) => e.name === 'nombre')?.value || '' : '')
        });
      }
    }

    return respuesta || [];
  }

  crearDicomMTable(): any[] {
    const reporteBasico = this.reportesPrincipalesObj?.basicReport?.reporte || null;
    const info: any[] = [];
    let representante: any[] = [];

    if (reporteBasico && Object.keys(reporteBasico).length > 0) {
      representante = this.getAntecedentesComercialesEmpresa(reporteBasico?.AntecedentesComerciales?.Resumen,
        reporteBasico?.InformacionBasica?.NombreORazonSocial + ' ' + reporteBasico?.InformacionBasica?.Rut,
        reporteBasico?.Riesgo?.Clasificacion,
        reporteBasico?.InformacionBasica?.FechaReporte) || [];
    }

    const listaSocios = this.entidadesDerivadas.find((e: any) => e.entidad === 'socios')?.items || [];
    const socios: any[] = this.getAntecedentesComercialesSocios(listaSocios) || [];

    if (representante.length > 0) {
      info.push(...representante);
    }

    if (socios.length > 0) {
      socios.forEach(element => {
        info.push(element)
      });
    }

    return info;
  }

  formatPeriodoSbif(periodo: string): string {
    if(!periodo) {
      return periodo;
    }
    const [mes, anio] = periodo.split('-');
    const meses = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'];
    return `${meses[Number(mes) - 1]}-${anio}`;
  }

  mapeoReporteSbifDerivados(): any[] {
    const response: any[] = [];
    if(this.reportesDerivadosObj && Object.keys(this.reportesDerivadosObj).length > 0 && this.entidadesDerivadas && this.entidadesDerivadas.length > 0) {
      for(const [entidad, ruts] of Object.entries(this.reportesDerivadosObj)) {
        if(ruts && Object.keys(ruts).length > 0) {
          for(const [rut, reporte] of Object.entries(ruts)) {
            if(reporte?.deudaSbif?.reporte?.Sbif?.Reporte?.rowsMeses?.item && reporte?.deudaSbif?.reporte?.Sbif?.Reporte?.rowsMeses?.item.length > 0) {
              const periodoMasActual = reporte.deudaSbif.reporte.Sbif.Reporte.rowsMeses.item.reduce((acc: any, curr: any) => {
                const accPeriodo = acc?.periodo;
                const currPeriodo = curr?.periodo;
                
                // Si accPeriodo es null/undefined, usar el currPeriodo
                if (!accPeriodo) return curr;
                // Si currPeriodo es null/undefined, mantener accPeriodo
                if (!currPeriodo) return acc;
              
                const accDate = new Date(accPeriodo.split('-').reverse().join('-'));
                const currDate = new Date(currPeriodo.split('-').reverse().join('-'));
              
                return accDate > currDate ? acc : curr;
              });
              if(periodoMasActual && Object.keys(periodoMasActual).length > 0) {
                const entidadDerivada = this.entidadesDerivadas.find(e => e.entidad === entidad);
                if(entidadDerivada?.items && entidadDerivada?.items.length > 0) {
                  const rutDerivado = entidadDerivada?.items.find((e: any) => e.rut === rut);
                  if(rutDerivado?.atributos && rutDerivado?.atributos.length > 0) {
                    const nombre = rutDerivado.atributos.find((e: any) => e.name === 'nombre')?.value || '';
                    let morosidad: any = 0;
                    let deudaTotal: any = 0;
                    if(periodoMasActual){
                      morosidad = Number(periodoMasActual?.dirvencida) + Number(periodoMasActual?.dirven180mayor3year) + Number(periodoMasActual?.dirmorosa);
                      deudaTotal = Number(periodoMasActual?.dirvigente);
                    }
                    else{
                      morosidad = 0;
                      deudaTotal = 0;
                    }
                    
                    const obj = {
                      rut: rut,
                      nombre: nombre,
                      periodo: periodoMasActual?.periodo || '',
                      periodoFormatted: this.formatPeriodoSbif(periodoMasActual?.periodo || ''),
                      vigente: deudaTotal,
                      deteriorada: morosidad
                    };
                    const index = response.findIndex((e: any) => e.rut === rut);
                    if(index === -1) {
                      response.push(obj);
                    } else {
                      if(obj.periodo) {
                        const periodoDate = new Date(obj.periodo.split('-').reverse().join('-'));
                        const responseDate = new Date(response[index].periodo.split('-').reverse().join('-'));
                        if(periodoDate > responseDate) {
                          response[index] = obj;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    return response;
  }

  procesarStringFecha(input: string) {
    if (input === undefined || input === null) {
      return "";
    }
  
    if (typeof input !== "string") {
      return "";
    }
  
    // Validar si es una fecha en formato "yyyy-mm-dd"
    const fechaRegex = /^\d{4}-\d{2}-\d{2}$/;
    if (fechaRegex.test(input)) {
      const [year, month, day] = input.split("-");
      return `${day}-${month}-${year}`;
    }
  
    // Validar si es una fecha en formato "yyyy-mm-ddThh:mm:ss"
    const fechaHoraRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/;
    if (fechaHoraRegex.test(input)) {
      const [datePart] = input.split("T");
      const [year, month, day] = datePart.split("-");
      return `${day}-${month}-${year}`;
    }
  
    return input;
  }

  crearMallaSocietariaGraph(): any[] {
    if(!this.entidadesDerivadas || this.entidadesDerivadas.length <= 0) {
      return [];
    }

    const respuesta: any[] = [];

    const sociedadDisueltaPorAbsorcion = this.reportesPrincipalesObj?.basicReport?.reporte?.InformacionBasica?.sociedadDisueltaPorAbsorcion || '';
    const fechaInicio = this.procesarStringFecha(this.reportesPrincipalesObj?.basicReport?.reporte?.InformacionBasica?.AnoInicioActividades) || '';
    const fechaTermino = 
      this.reportesPrincipalesObj?.basicReport?.reporte?.InformacionBasica?.TerminoGiro === true ? 
      (this.procesarStringFecha(this.reportesPrincipalesObj?.basicReport?.reporte?.InformacionBasica?.FechaTerminoGiro)) : '';
    const objTooltipPrincipal = {
      sociedadDisueltaPorAbsorcion: sociedadDisueltaPorAbsorcion || '',
      fechaInicio: fechaInicio || '',
      fechaTermino: fechaTermino || ''
    }
    respuesta.push(this.utilsService.getElementMalla(
      'Rut'+'Principal'+'0'+this.rut,
      null, // padre
      false, // expanded
      'Principal', // tipo
      this.reportesPrincipalesObj?.basicReport?.reporte?.InformacionBasica?.NombreORazonSocial || '', // nombre
      this.rutFormatPipe.transform(this.rut), // rut formateado // falta
      undefined,
      undefined,
      undefined,
      undefined,
      false,
      objTooltipPrincipal
    ));

    const clasesHTML: string[] = [
      'bg-entidad-0',
      'bg-entidad-1',
      'bg-entidad-2',
      'bg-entidad-3',
      'bg-entidad-4'
    ];
    const clasesRutHTML: string[] = [
      'bg-rut-entidad-0',
      'bg-rut-entidad-1',
      'bg-rut-entidad-2',
      'bg-rut-entidad-3',
      'bg-rut-entidad-4'
    ];
    for(const [index, entidad] of this.entidadesDerivadas.entries()) {
      if(entidad.entidad && entidad.items && entidad.items.length > 0) {
        const idGraphEntidad = entidad.entidad+'Entidad'+'1'+this.rut;
        const contratoEntidad = this.contratoEntidades.find(e => e.key === entidad.entidad);//key	
        respuesta.push(this.utilsService.getElementMalla(
          idGraphEntidad,
          'Rut'+'Principal'+'0'+this.rut, // id padre
          true, // expanded
          'Entidad', // tipo
          contratoEntidad? contratoEntidad.name : entidad.entidad, // nombre
          '', // rut formateado
          undefined,
          undefined,
          undefined,
          (clasesHTML[index % clasesHTML.length])
        ));
        for(const rutEntidad of entidad.items) {
          // agregar rut entidad
          const nameRut = rutEntidad.atributos.find((e: any) => e.name === 'nombre')?.value || '';
          const idGraphRutEntidad = entidad.entidad+'Rut'+'2'+rutEntidad.rut;

          const sociedadDisueltaPorAbsorcionRutEntidad = this.reportesDerivadosObj?.[entidad.entidad]?.[rutEntidad.rut]?.basicReport?.reporte?.InformacionBasica?.sociedadDisueltaPorAbsorcion || '';
          const fechaInicioRutEntidad = this.procesarStringFecha(this.reportesDerivadosObj?.[entidad.entidad]?.[rutEntidad.rut]?.basicReport?.reporte?.InformacionBasica?.AnoInicioActividades) || '';
          const fechaTerminoRutEntidad = 
            this.reportesDerivadosObj?.[entidad.entidad]?.[rutEntidad.rut]?.basicReport?.reporte?.InformacionBasica?.TerminoGiro === true ? 
            (this.procesarStringFecha(this.reportesDerivadosObj?.[entidad.entidad]?.[rutEntidad.rut]?.basicReport?.reporte?.InformacionBasica?.FechaTerminoGiro)) : '';
          const objTooltipRutEntidad = {
            sociedadDisueltaPorAbsorcion: sociedadDisueltaPorAbsorcionRutEntidad || '',
            fechaInicio: fechaInicioRutEntidad || '',
            fechaTermino: fechaTerminoRutEntidad || ''
          }

          respuesta.push(this.utilsService.getElementMalla(
            idGraphRutEntidad,
            idGraphEntidad, // id padre
            true, // expanded
            contratoEntidad.name, // tipo
            nameRut, // nombre
            this.rutFormatPipe.transform(rutEntidad.rut), // rut formateado
            entidad.entidad, // entidad
            'rutDerivado', // tipo de rut
            'checkboxfila-' + rutEntidad.rut + '-' +  entidad.entidad,
            (clasesRutHTML[index % clasesRutHTML.length]),
            false,
            objTooltipRutEntidad
          ));

          const socios = this.reportesDerivadosObj?.[entidad.entidad]?.[rutEntidad.rut]?.mallaSocietaria?.reporte?.MallaSocietariaReporte?.Reporte?.mallaSocietaria?.respuestaMalla?.listadoSocios || []; // {socio:{}}
          const participaciones = this.reportesDerivadosObj?.[entidad.entidad]?.[rutEntidad.rut]?.mallaSocietaria?.reporte?.MallaSocietariaReporte?.Reporte?.mallaSocietaria?.respuestaMalla?.listadoParticipaciones || []; // {participacion: {}}
          
          if((socios && socios.length > 0)){
            for(const socio of socios) {
              // agregar socio
              const nameSocio = socio.socio.nombreRazonSocial || '';
              const rutSocio = socio.socio.rut+'-'+socio.socio.dv;
              const idGraphRutSocioNivel2 = idGraphRutEntidad + '-' + entidad.entidad+'socio'+3+rutSocio;

              const sociedadDisueltaPorAbsorcionSocio1 = this.reportesDerivadosObj?.[entidad.entidad]?.[rutSocio]?.basicReport?.reporte?.InformacionBasica?.sociedadDisueltaPorAbsorcion || '';
              const fechaInicioSocio1 = this.procesarStringFecha(this.reportesDerivadosObj?.[entidad.entidad]?.[rutSocio]?.basicReport?.reporte?.InformacionBasica?.AnoInicioActividades) || '';
              const fechaTerminoSocio1 = 
                this.reportesDerivadosObj?.[entidad.entidad]?.[rutSocio]?.basicReport?.reporte?.InformacionBasica?.TerminoGiro === true ? 
                (this.procesarStringFecha(this.reportesDerivadosObj?.[entidad.entidad]?.[rutSocio]?.basicReport?.reporte?.InformacionBasica?.FechaTerminoGiro)) : '';
              const objTooltipSocio1 = {
                sociedadDisueltaPorAbsorcion: sociedadDisueltaPorAbsorcionSocio1 || '',
                fechaInicio: fechaInicioSocio1 || '',
                fechaTermino: fechaTerminoSocio1 || ''
              }

              respuesta.push(this.utilsService.getElementMalla(
                idGraphRutSocioNivel2 || '',
                idGraphRutEntidad || '', // id padre
                true, // expanded
                'Socio', // tipo
                nameSocio, // nombre
                this.rutFormatPipe.transform(rutSocio), // rut formateado
                entidad.entidad, // entidad
                'rutMalla', // tipo de rut
                '',
                undefined,
                false,
                objTooltipSocio1
              ));

              const sociosNivel2 = this.reportesDerivadosObj?.[entidad.entidad]?.[rutSocio]?.mallaSocietaria?.reporte?.MallaSocietariaReporte?.Reporte?.mallaSocietaria?.respuestaMalla?.listadoSocios || []; // {socio:{}}
              const participacionesNivel2 = this.reportesDerivadosObj?.[entidad.entidad]?.[rutSocio]?.mallaSocietaria?.reporte?.MallaSocietariaReporte?.Reporte?.mallaSocietaria?.respuestaMalla?.listadoParticipaciones || []; // {participacion: {}}

              if((sociosNivel2 && sociosNivel2.length > 0)){
                for(const socioNivel2 of sociosNivel2) {
                  const nameSocioNivel2 = socioNivel2.socio.nombreRazonSocial || '';
                  const rutSocioNivel2 = socioNivel2.socio.rut+'-'+socioNivel2.socio.dv;
                  const idGraphRutSocioNivel3 = idGraphRutSocioNivel2 + '-' + entidad.entidad+'socio'+3+rutSocioNivel2;

                  const sociedadDisueltaPorAbsorcionSocio2 = this.reportesDerivadosObj?.[entidad.entidad]?.[rutSocioNivel2]?.basicReport?.reporte?.InformacionBasica?.sociedadDisueltaPorAbsorcion || '';
                  const fechaInicioSocio2 = this.procesarStringFecha(this.reportesDerivadosObj?.[entidad.entidad]?.[rutSocioNivel2]?.basicReport?.reporte?.InformacionBasica?.AnoInicioActividades) || '';
                  const fechaTerminoSocio2 = 
                    this.reportesDerivadosObj?.[entidad.entidad]?.[rutSocioNivel2]?.basicReport?.reporte?.InformacionBasica?.TerminoGiro === true ? 
                    (this.procesarStringFecha(this.reportesDerivadosObj?.[entidad.entidad]?.[rutSocioNivel2]?.basicReport?.reporte?.InformacionBasica?.FechaTerminoGiro)) : '';
                  const objTooltipSocio2 = {
                    sociedadDisueltaPorAbsorcion: sociedadDisueltaPorAbsorcionSocio2 || '',
                    fechaInicio: fechaInicioSocio2 || '',
                    fechaTermino: fechaTerminoSocio2 || ''
                  }

                  respuesta.push(this.utilsService.getElementMalla(
                    idGraphRutSocioNivel3 || '',
                    idGraphRutSocioNivel2 || '', // id padre
                    true, // expanded
                    'Socio', // tipo
                    nameSocioNivel2, // nombre
                    this.rutFormatPipe.transform(rutSocioNivel2), // rut formateado
                    entidad.entidad, // entidad
                    'rutMalla', // tipo de rut
                    '',
                    undefined,
                    false,
                    objTooltipSocio2
                  ));
                }
              }

              if((participacionesNivel2 && participacionesNivel2.length > 0)){
                for(const participacionNivel2 of participacionesNivel2) {
                  const nameParticipacionNivel2 = participacionNivel2.participacion.nombreRazonSocial || '';
                  const rutParticipacionNivel2 = participacionNivel2.participacion.rut+'-'+participacionNivel2.participacion.dv;
                  const idGraphRutParticipacionNivel3 = idGraphRutSocioNivel2 + '-' + entidad.entidad+'participacion'+3+rutParticipacionNivel2;

                  const sociedadDisueltaPorAbsorcionPart2 = this.reportesDerivadosObj?.[entidad.entidad]?.[rutParticipacionNivel2]?.basicReport?.reporte?.InformacionBasica?.sociedadDisueltaPorAbsorcion || '';
                  const fechaInicioPart2 = this.procesarStringFecha(this.reportesDerivadosObj?.[entidad.entidad]?.[rutParticipacionNivel2]?.basicReport?.reporte?.InformacionBasica?.AnoInicioActividades) || '';
                  const fechaTerminoPart2 = 
                    this.reportesDerivadosObj?.[entidad.entidad]?.[rutParticipacionNivel2]?.basicReport?.reporte?.InformacionBasica?.TerminoGiro === true ? 
                    (this.procesarStringFecha(this.reportesDerivadosObj?.[entidad.entidad]?.[rutParticipacionNivel2]?.basicReport?.reporte?.InformacionBasica?.FechaTerminoGiro)) : '';
                  const objTooltipPart2 = {
                    sociedadDisueltaPorAbsorcion: sociedadDisueltaPorAbsorcionPart2 || '',
                    fechaInicio: fechaInicioPart2 || '',
                    fechaTermino: fechaTerminoPart2 || ''
                  }

                  respuesta.push(this.utilsService.getElementMalla(
                    idGraphRutParticipacionNivel3 || '',
                    idGraphRutSocioNivel2 || '', // id padre
                    true, // expanded
                    'Sociedad', // tipo
                    nameParticipacionNivel2, // nombre
                    this.rutFormatPipe.transform(rutParticipacionNivel2), // rut formateado
                    entidad.entidad, // entidad
                    'rutMalla', // tipo de rut
                    '',
                    undefined,
                    false,
                    objTooltipPart2
                  ));
                }
              }
            }
          }

          if(participaciones && participaciones.length > 0){
            for(const participacion of participaciones) {
              // agregar participacion
              const nameParticipacion = participacion.participacion.nombreRazonSocial || '';
              const rutParticipacion = participacion.participacion.rut+'-'+participacion.participacion.dv;
              const idGraphRutParticipacionNivel2 = idGraphRutEntidad + '-' + entidad.entidad+'participacion'+3+rutParticipacion;

              const sociedadDisueltaPorAbsorcionPart1 = this.reportesDerivadosObj?.[entidad.entidad]?.[rutParticipacion]?.basicReport?.reporte?.InformacionBasica?.sociedadDisueltaPorAbsorcion || '';
              const fechaInicioPart1 = this.procesarStringFecha(this.reportesDerivadosObj?.[entidad.entidad]?.[rutParticipacion]?.basicReport?.reporte?.InformacionBasica?.AnoInicioActividades) || '';
              const fechaTerminoPart1 = 
                this.reportesDerivadosObj?.[entidad.entidad]?.[rutParticipacion]?.basicReport?.reporte?.InformacionBasica?.TerminoGiro === true ? 
                (this.procesarStringFecha(this.reportesDerivadosObj?.[entidad.entidad]?.[rutParticipacion]?.basicReport?.reporte?.InformacionBasica?.FechaTerminoGiro)) : '';
              const objTooltipPart1 = {
                sociedadDisueltaPorAbsorcion: sociedadDisueltaPorAbsorcionPart1 || '',
                fechaInicio: fechaInicioPart1 || '',
                fechaTermino: fechaTerminoPart1 || ''
              }

              respuesta.push(this.utilsService.getElementMalla(
                idGraphRutParticipacionNivel2 || '',
                idGraphRutEntidad || '', // id padre
                true, // expanded
                'Sociedad', // tipo
                nameParticipacion, // nombre
                this.rutFormatPipe.transform(participacion.participacion.rut), // rut formateado
                entidad.entidad, // entidad
                'rutMalla', // tipo de rut
                '',
                undefined,
                false,
                objTooltipPart1
              ));

              const sociosNivel2 = this.reportesDerivadosObj?.[entidad.entidad]?.[rutParticipacion]?.mallaSocietaria?.reporte?.MallaSocietariaReporte?.Reporte?.mallaSocietaria?.respuestaMalla?.listadoSocios || []; // {socio:{}}
              const participacionesNivel2 = this.reportesDerivadosObj?.[entidad.entidad]?.[rutParticipacion]?.mallaSocietaria?.reporte?.MallaSocietariaReporte?.Reporte?.mallaSocietaria?.respuestaMalla?.listadoParticipaciones || []; // {participacion: {}}

              if((sociosNivel2 && sociosNivel2.length > 0)){
                for(const socioNivel2 of sociosNivel2) {
                  const nameSocioNivel2 = socioNivel2.socio.nombreRazonSocial || '';
                  const rutSocioNivel2 = socioNivel2.socio.rut+'-'+socioNivel2.socio.dv;
                  const idGraphRutSocioNivel3 = idGraphRutParticipacionNivel2 + '-' + entidad.entidad+'socio'+3+rutSocioNivel2;

                  const sociedadDisueltaPorAbsorcionSocio2 = this.reportesDerivadosObj?.[entidad.entidad]?.[rutSocioNivel2]?.basicReport?.reporte?.InformacionBasica?.sociedadDisueltaPorAbsorcion || '';
                  const fechaInicioSocio2 = this.procesarStringFecha(this.reportesDerivadosObj?.[entidad.entidad]?.[rutSocioNivel2]?.basicReport?.reporte?.InformacionBasica?.AnoInicioActividades) || '';
                  const fechaTerminoSocio2 = 
                    this.reportesDerivadosObj?.[entidad.entidad]?.[rutSocioNivel2]?.basicReport?.reporte?.InformacionBasica?.TerminoGiro === true ? 
                    (this.procesarStringFecha(this.reportesDerivadosObj?.[entidad.entidad]?.[rutSocioNivel2]?.basicReport?.reporte?.InformacionBasica?.FechaTerminoGiro)) : '';
                  const objTooltipSocio2 = {
                    sociedadDisueltaPorAbsorcion: sociedadDisueltaPorAbsorcionSocio2 || '',
                    fechaInicio: fechaInicioSocio2 || '',
                    fechaTermino: fechaTerminoSocio2 || ''
                  }

                  respuesta.push(this.utilsService.getElementMalla(
                    idGraphRutSocioNivel3 || '',
                    idGraphRutParticipacionNivel2 || '', // id padre
                    true, // expanded
                    'Socio', // tipo
                    nameSocioNivel2, // nombre
                    this.rutFormatPipe.transform(rutSocioNivel2), // rut formateado
                    entidad.entidad, // entidad
                    'rutMalla', // tipo de rut
                    '',
                    undefined,
                    false,
                    objTooltipSocio2
                  ));
                }
              }

              if((participacionesNivel2 && participacionesNivel2.length > 0)){
                for(const participacionNivel2 of participacionesNivel2) {
                  const nameParticipacionNivel2 = participacionNivel2.participacion.nombreRazonSocial || '';
                  const rutParticipacionNivel2 = participacionNivel2.participacion.rut+'-'+participacionNivel2.participacion.dv;
                  const idGraphRutParticipacionNivel3 = idGraphRutParticipacionNivel2 + '-' + entidad.entidad+'participacion'+3+rutParticipacionNivel2;

                  const sociedadDisueltaPorAbsorcionPart2 = this.reportesDerivadosObj?.[entidad.entidad]?.[rutParticipacionNivel2]?.basicReport?.reporte?.InformacionBasica?.sociedadDisueltaPorAbsorcion || '';
                  const fechaInicioPart2 = this.procesarStringFecha(this.reportesDerivadosObj?.[entidad.entidad]?.[rutParticipacionNivel2]?.basicReport?.reporte?.InformacionBasica?.AnoInicioActividades) || '';
                  const fechaTerminoPart2 = 
                    this.reportesDerivadosObj?.[entidad.entidad]?.[rutParticipacionNivel2]?.basicReport?.reporte?.InformacionBasica?.TerminoGiro === true ? 
                    (this.procesarStringFecha(this.reportesDerivadosObj?.[entidad.entidad]?.[rutParticipacionNivel2]?.basicReport?.reporte?.InformacionBasica?.FechaTerminoGiro)) : '';
                  const objTooltipSocio2 = {
                    sociedadDisueltaPorAbsorcion: sociedadDisueltaPorAbsorcionPart2 || '',
                    fechaInicio: fechaInicioPart2 || '',
                    fechaTermino: fechaTerminoPart2 || ''
                  }

                  respuesta.push(this.utilsService.getElementMalla(
                    idGraphRutParticipacionNivel3 || '',
                    idGraphRutParticipacionNivel2 || '', // id padre
                    true, // expanded
                    'Sociedad', // tipo
                    nameParticipacionNivel2, // nombre
                    this.rutFormatPipe.transform(rutParticipacionNivel2), // rut formateado
                    entidad.entidad, // entidad
                    'rutMalla', // tipo de rut
                    '',
                    undefined,
                    false,
                    objTooltipSocio2
                  ));
                }
              }
            }
          }

        }
      }
    }

    return respuesta || [];
  }

  crearMallaSocietariaGraphPrint(): any[] {
    if(!this.entidadesDerivadas || this.entidadesDerivadas.length <= 0) {
      return [];
    }

    let respuesta: any = {};

    respuesta = {
      nivel: "1",
      name: this.reportesPrincipalesObj?.basicReport?.reporte?.InformacionBasica?.NombreORazonSocial || '',
      tipo: "Principal",
      rutDisplay: this.rutFormatPipe.transform(this.rut),
      children: []
    }

    for(const [index, entidad] of this.entidadesDerivadas.entries()) {
      if(entidad.entidad && entidad.items && entidad.items.length > 0) {
        for(const rutEntidad of entidad.items) {
          // agregar rut entidad
          const nameRut = rutEntidad.atributos.find((e: any) => e.name === 'nombre')?.value || '';
          const contratoEntidad = this.contratoEntidades.find(e => e.key === entidad.entidad);
          const objEntidad: any = {
            nivel: "2",
            name: nameRut,
            tipo: contratoEntidad? contratoEntidad.name : entidad.entidad,
            rutDisplay: this.rutFormatPipe.transform(rutEntidad.rut),
            children: []
          }

          const socios = this.reportesDerivadosObj?.[entidad.entidad]?.[rutEntidad.rut]?.mallaSocietaria?.reporte?.MallaSocietariaReporte?.Reporte?.mallaSocietaria?.respuestaMalla?.listadoSocios || []; // {socio:{}}
          const participaciones = this.reportesDerivadosObj?.[entidad.entidad]?.[rutEntidad.rut]?.mallaSocietaria?.reporte?.MallaSocietariaReporte?.Reporte?.mallaSocietaria?.respuestaMalla?.listadoParticipaciones || []; // {participacion: {}}
          
          if((socios && socios.length > 0)){
            for(const socio of socios) {
              // agregar socio
              const nameSocio = socio.socio.nombreRazonSocial || '';
              const rutSocio = socio.socio.rut+'-'+socio.socio.dv;
              const objSocio: any = {
                nivel: "3",
                name: nameSocio,
                tipo: "Socio",
                rutDisplay: this.rutFormatPipe.transform(rutSocio),
                children: []
              }

              const sociosNivel2 = this.reportesDerivadosObj?.[entidad.entidad]?.[rutSocio]?.mallaSocietaria?.reporte?.MallaSocietariaReporte?.Reporte?.mallaSocietaria?.respuestaMalla?.listadoSocios || []; // {socio:{}}
              const participacionesNivel2 = this.reportesDerivadosObj?.[entidad.entidad]?.[rutSocio]?.mallaSocietaria?.reporte?.MallaSocietariaReporte?.Reporte?.mallaSocietaria?.respuestaMalla?.listadoParticipaciones || []; // {participacion: {}}

              if((sociosNivel2 && sociosNivel2.length > 0)){
                for(const socioNivel2 of sociosNivel2) {
                  const nameSocioNivel2 = socioNivel2.socio.nombreRazonSocial || '';
                  const rutSocioNivel2 = socioNivel2.socio.rut+'-'+socioNivel2.socio.dv;
                  const objSocio2: any = {
                    nivel: "4",
                    name: nameSocioNivel2,
                    tipo: "Socio",
                    rutDisplay: this.rutFormatPipe.transform(rutSocioNivel2),
                    children: []
                  }

                  objSocio.children.push(objSocio2);
                }
              }

              if((participacionesNivel2 && participacionesNivel2.length > 0)){
                for(const participacionNivel2 of participacionesNivel2) {
                  const nameParticipacionNivel2 = participacionNivel2.participacion.nombreRazonSocial || '';
                  const rutParticipacionNivel2 = participacionNivel2.participacion.rut+'-'+participacionNivel2.participacion.dv;
                  const objParticipacion2: any = {
                    nivel: "4",
                    name: nameParticipacionNivel2,
                    tipo: "Sociedad",
                    rutDisplay: this.rutFormatPipe.transform(rutParticipacionNivel2),
                    children: []
                  }
                  objSocio.children.push(objParticipacion2);
                }
              }

              objEntidad.children.push(objSocio);
            }
          }

          if(participaciones && participaciones.length > 0){
            for(const participacion of participaciones) {
              // agregar participacion
              const nameParticipacion = participacion.participacion.nombreRazonSocial || '';
              const rutParticipacion = participacion.participacion.rut+'-'+participacion.participacion.dv;
              const objParticipacion: any = {
                nivel: "3",
                name: nameParticipacion,
                tipo: "Sociedad",
                rutDisplay: this.rutFormatPipe.transform(participacion.participacion.rut),
                children: []
              }

              const sociosNivel2 = this.reportesDerivadosObj?.[entidad.entidad]?.[rutParticipacion]?.mallaSocietaria?.reporte?.MallaSocietariaReporte?.Reporte?.mallaSocietaria?.respuestaMalla?.listadoSocios || []; // {socio:{}}
              const participacionesNivel2 = this.reportesDerivadosObj?.[entidad.entidad]?.[rutParticipacion]?.mallaSocietaria?.reporte?.MallaSocietariaReporte?.Reporte?.mallaSocietaria?.respuestaMalla?.listadoParticipaciones || []; // {participacion: {}}

              if((sociosNivel2 && sociosNivel2.length > 0)){
                for(const socioNivel2 of sociosNivel2) {
                  const nameSocioNivel2 = socioNivel2.socio.nombreRazonSocial || '';
                  const rutSocioNivel2 = socioNivel2.socio.rut+'-'+socioNivel2.socio.dv;
                  const objSocio2: any = {
                    nivel: "4",
                    name: nameSocioNivel2,
                    tipo: "Socio",
                    rutDisplay: this.rutFormatPipe.transform(rutSocioNivel2),
                    children: []
                  }

                  objParticipacion.children.push(objSocio2);
                }
              }

              if((participacionesNivel2 && participacionesNivel2.length > 0)){
                for(const participacionNivel2 of participacionesNivel2) {
                  const nameParticipacionNivel2 = participacionNivel2.participacion.nombreRazonSocial || '';
                  const rutParticipacionNivel2 = participacionNivel2.participacion.rut+'-'+participacionNivel2.participacion.dv;
                  const objParticipacion2: any = {
                    nivel: "4",
                    name: nameParticipacionNivel2,
                    tipo: "Sociedad",
                    rutDisplay: this.rutFormatPipe.transform(rutParticipacionNivel2),
                    children: []
                  }

                  objParticipacion.children.push(objParticipacion2);
                }
              }

              objEntidad.children.push(objParticipacion);
            }
          }

          respuesta.children.push(objEntidad);
        }
      }
    }

    return respuesta || [];
  }

  setFacturacionSaldo(): void {
    let objetoFlujoFuturoReduce: any[] = [];
    const objTemp: any[] = [];
    const objTemp2: any[] = [];
    const objResponse = this.obtenerValorCamposPersonalizadosAutomatic('item');
    const keyValue = ['Saldo_Acum', 'Fact'];
    const keysValues = ['Importe ML', 'Periodo'];
    const objImporte = this.convertirMiles(this.obtenerValorCamposPersonalizadosAutomatic(keysValues[0]));
    const objPeriodo = this.obtenerValorCamposPersonalizadosAutomatic(keysValues[1]);
    const keysObjs = [objImporte, objPeriodo];

    if (objResponse.length > 0) {
      //Obtener posicion de la key Saldo Actual

      let idx = objResponse.indexOf(keyValue[0]);
      while (idx != -1) {
        objTemp.push({ position: idx });
        idx = objResponse.indexOf(keyValue[0], idx + 1);
      }

      let idx2 = objResponse.indexOf(keyValue[1]);
      while (idx2 != -1) {
        objTemp2.push({ position: idx2 });
        idx2 = objResponse.indexOf(keyValue[1], idx2 + 1);
      }

      //Obtener valores de la key Saldo Acumulado
      if (objTemp.length > 0) {
        keysValues.forEach((e: any, i: number) => {
          objTemp.forEach((f: any) => {
            const name = e === 'Importe ML' ? 'Saldo_Acum' : e;
            f[name.replace(' ', '')] = keysObjs[i][f.position] || '';
          });
        });
      }

      if (objTemp2.length > 0) {
        keysValues.forEach((e: any, i: number) => {
          objTemp2.forEach((f: any) => {
            const name = e === 'Importe ML' ? 'Fact' : e;
            f[name.replace(' ', '')] = keysObjs[i][f.position] || '';
          });
        });
      }

      objetoFlujoFuturoReduce = Object.values(objTemp.concat(objTemp2).reduce((acc, curr) => {
        acc[curr.Periodo] = acc[curr.Periodo] || {
          Periodo: curr.Periodo,
          Fact: 0,
          Saldo_Acum: 0,
        };

        if (acc[curr.Periodo]) {
          acc[curr.Periodo].Fact += +Number(curr.Fact) || 0;
          acc[curr.Periodo].Saldo_Acum += +Number(curr.Saldo_Acum) || 0;
        } else {
          acc[curr.Periodo].Fact = 0;
          acc[curr.Periodo].Saldo_Acum = 0;
          acc[curr.Periodo].Fact += +Number(curr.Fact);
          acc[curr.Periodo].Saldo_Acum += +Number(curr.Saldo_Acum);
        }

        return acc;
      }, {}));
    }
    this.setGrafica(objetoFlujoFuturoReduce);
  }

  // Sub Funciones

  setGrafica(obj: any) {
    const objTemp: any = [];
    const valuesKeys = ['Periodo', 'Fact', 'Saldo_Acum'];
    valuesKeys.forEach((e: any) => {
      objTemp[e] = obj.map((f: any) => {
        return e !== 'Periodo'? Number(f[e].toFixed(0)) : f[e];
      });
    });

    this.categoria.push({
      categories: objTemp.Periodo,
      crosshair: true
    });

    this.serie = [{
      type: 'column',
      name: this.colunmTitles[0],
      color: '#4271c3',
      data: objTemp.Fact
    },
    {
      type: 'spline',
      name: this.colunmTitles[1],
      yAxis: 1,
      color: '#ec8944',
      data: objTemp.Saldo_Acum,
    },
    ]
  }

  sumDataLeasing(data: any, periodo: string, property: string): number {
    const keyValues = ['TotalDeudaDirecta', 'TotalDeudaIndirecta'];
    let sum = 0;
    keyValues.forEach((el: string) => {
      sum += Number(data[el].find((e: any) => e.Periodo === periodo)?.[property]) || 0;
    })
    return sum;
  }

  filterDataLeasingClp(data: any, field: string, periodo: string): string {
    return data[field].find((e: any) => e.Periodo === periodo && e.Item === field).valorMiles || '';
  }

  setNumber(num: string): number {
    return Number(num);
  }

  filterDataLeasing(data: any, field: string, periodo: string): string {
    return data[field].find((e: any) => e.Periodo === periodo && e.Item === field).Valor || '';
  }

  agruparObjetosByKey(obj: any[], keyValue: string) {
    return obj.reduce(
      (objectsByKeyValue, obj) => ({
        ...objectsByKeyValue,
        [obj[keyValue]]: (objectsByKeyValue[obj[keyValue]] || []).concat(obj)
      }),
      {});
  }

  getAntecedentesComercialesSocios(obj: any[]) {
    const result: any[] = [];
    if (obj) {
      if (obj.length > 0) {
        for(const socio of obj) {
          const nombre = socio?.atributos && socio?.atributos.length > 0 ? socio?.atributos.find((e: any) => e.name === 'nombre')?.value || '' : '';
          const name = nombre || '' + ' ' + socio.rut || ''; 
          const reporteBasico = this.reportesDerivadosObj?.socios?.[socio?.rut]?.basicReport?.reporte || {};
          result.push({
            name: name,
            fecha: this.utilsService.formatDate(reporteBasico?.InformacionBasica?.FechaReporte) || '',
            score: reporteBasico?.Riesgo?.Clasificacion || 0,
            morosidades: [
              { morosidad: reporteBasico?.AntecedentesComerciales?.Resumen?.MorosidadesCantidad || 0 },
              { morosidad: reporteBasico?.AntecedentesComerciales?.Resumen?.MorosidadesMonto || 0 }],
            protestos: [
              { protesto: reporteBasico?.AntecedentesComerciales?.Resumen?.ProtestoCantidad || 0 },
              { protesto: reporteBasico?.AntecedentesComerciales?.Resumen?.ProtestosMonto || 0 }],
            multas: [
              { multa: reporteBasico?.AntecedentesComerciales?.Resumen?.InfraccionesLaboralesCantidad || 0 },
              { multa: reporteBasico?.AntecedentesComerciales?.Resumen?.InfraccionesLaboralesMonto || 0 }]
          })

        }
      }
    }
    return result
  }

  getAntecedentesComercialesEmpresa(resumen: any, nombre: string, score: string, fecha: string) {
    const result: any[] = [];

    if (resumen) {
      result.push({
        name: nombre || '',
        fecha: this.utilsService.formatDate(fecha) || '',
        score: score || 0,
        morosidades: [
          { morosidad: resumen.MorosidadesCantidad || 0 },
          { morosidad: resumen.MorosidadesMonto || 0 }],
        protestos: [
          { protesto: resumen.ProtestoCantidad || 0 },
          { protesto: resumen.ProtestosMonto || 0 }],
        multas: [
          { multa: resumen.InfraccionesLaboralesCantidad || 0 },
          { multa: resumen.InfraccionesLaboralesMonto || 0 }]
      });
    }
    return result
  }

  utilRepresentantesSocios(detailsCarpetaTributaria: any): any[] {

    return [detailsCarpetaTributaria].map((item: any) => {
      return {
        ...item,
        sociosRepresentante:
          item.sociosRepresentante.map((element: any) => {
            return {
              ...element,
              data: element.data.map((item: any) => {
                return {
                  ...item,
                  rut: item.hasOwnProperty('rut') ? (item.rut).toString() + '-' + item.dv : item.Rut,
                  date: item.hasOwnProperty('fecha') ? this.utilsService.formatDate(item.fecha) : this.utilsService.formatDate(item.Fecha),
                  nombre: item.hasOwnProperty('Nombre') ? item.Nombre : item.nombreRazonSocial
                }
              })
            }
          })
      }
    });
  }

  updateDateBalances(balances: any) {
    if (balances && balances.length > 0) {
      return balances.map((item: any) => {
        return {
          ...item,
          balance: Number(item.periodo) - 1,
          check: 1
        }
      });
    }
    return [];
  }

  updateDataVentas(ventas: any) {
    let result: any[] = [];
    if (ventas && ventas.length > 0) {
      var groups = new Set(ventas.filter((item: any) => item && Object.keys(item).length > 0).map((item: any) => item.periodo));
      let count = 0;
      groups.forEach((e: any) => {
        const obj = ventas.filter((i: any) => i && Object.keys(i).length > 0 && i.periodo === e).map((f: any) => {
          let date = new Date(`${f.periodo}-${f.mes}-3`);
          const nameMonth = f.mes !== 9 ? date.toLocaleString('default', { month: 'short' }).replace('.', '')
            : 'sep'
          return {
            [nameMonth]: f.hasOwnProperty('sinDeclaracion') ? 0 : 1
          };
        });

        const newObj: any = {};
        newObj.ventas = e;
        obj.forEach((element: any) => {
          newObj[Object.keys(element)[0]] = element[Object.keys(element)[0]]
        });
        result.push(newObj);
        count++
      });
    }
    return result;
  }

  obtenerValorRulesEngine(key: string): any {
    if(this.motorCalculos?.ResponseReglas?.resultado && Object.keys(this.motorCalculos?.ResponseReglas?.resultado).length > 0){
      return this.motorCalculos?.ResponseReglas?.resultado[key] || '';
    } else {
      return '';
    }
  }

  agruparObjetos(obj: any[]) {
    return obj.reduce((result: any, currentValue: any) => {
      if (currentValue['Item'] === undefined) return result;
      (result[currentValue['Item']] = result[currentValue['Item']] || []).push(currentValue);
      return result;
    }, {});
  }

  obtenerPeriodo(periodo: string): string {
    const periodoArray: string[] = periodo.split("-");
    if(periodoArray.length > 0){
      const date = new Date(Number(periodoArray[0]), (Number(periodoArray[1]) - 1));
      date.setUTCHours(0,0,0,0);
      return date.toJSON();
    } else {
      return '';
    }
  }

  getTotal(civil: string | number, cobranza: string | number, laboral: string | number): number {
    return Number(civil) + Number(cobranza) + Number(laboral);
  }

  getDateByFormat(dateInput: string, format: string): string {
    /*format = 
    'DD-MM-YYYY'
    'DD-MM-YY'
    'MM-DD-YY'
    'MM-DD-YYYY'
    'YYYY-MM-DD'*/
    if (dateInput && format) {
      const [year, month, day] = dateInput?.split("-");
      if (format === 'DD-MM-YYYY') {
        return `${day}-${month}-${year}`;
      } else if (format === 'DD-MM-YY') {
        return `${day}-${month}-${year.substr(-2)}`;
      } else if (format === 'MM-DD-YY') {
        return `${month}-${day}-${year.substr(-2)}`;
      } else if (format === 'MM-DD-YYYY') {
        return `${month}-${day}-${year}`;
      } else if (format === 'YYYY-MM-DD') {
        return `${year}-${month}-${day}`;
      }
    }
    return dateInput;
  }

  guardarCamposPersonalizados() { // validar que funcione ok cuando este la api nueva - validar*
    const form: any[] = [];
    if (!this.camposPersonalizadosForm.valid) {
      this.solicitudCreditoService.validateAllFormFields(this.camposPersonalizadosForm);
      this.actualizaLayout();
      return;
    }
    const formTemp = this.camposPersonalizadosForm.getRawValue();

    Object.keys(formTemp).forEach((control: any) => {
      const item = this.atributos
        .filter(e => e.nameAttribute === control)[0];

      if (item) {
        if(item.typeAttibute.toUpperCase() === 'DATE'){
          formTemp[control] = this.getDateByFormat(formTemp[control], item.formatAttribute);
        }
        form.push({
          nameAttribute: item.nameAttribute,
          value: formTemp[control]
        })
      }
    });

    this.updateFieldsByService(form);
  }

  haveDependientes(): boolean {
    const dependientes = this.atributos.filter(e => e.creditRequestActive && e.enabled &&
      ((e.expression && e.expression.length > 0) || (e.validation && e.validation.length > 0) || (typeof(e.editable) == 'string' && e.editable && e.editable.length > 0))
    );

    if(dependientes.length > 0){
      return true;
    }
    return false;
  }

  updateFieldsByService(form: any): void {
    this.spinner.show();
    this.showReportes = false;
    this.solicitudCreditoService.updateCamposPersonalizados(
      form, 
      this.rut, 
      this.idTransaccion, 
      8,
      '',
      '',
      this.haveDependientes() ? this.bodyModelos?.workflowModel || '' : ''
    ).subscribe(resp => {
      if(resp?.SolicitudDeCredito?.Stage2Response?.errors?.hassError){
        this.alertService.error(resp?.SolicitudDeCredito?.Stage2Response?.errors?.details?.message || 'Solicitud de Crédito - Ocurrio un error con la respuesta del servicio');
        this.spinner.hide();
        this.showReportes = true;
      } else {
        if(resp.SolicitudDeCredito?.Stage2Response?.Atributos){
          this.atributos = resp.SolicitudDeCredito?.Stage2Response?.Atributos;
        }
        this.spinner.hide();
        this.mapeoReportes();
      }
    }, error => {
      const msg = (error?.error?.SolicitudDeCredito?.Stage2Response?.errors?.details?.message || error?.error?.message || ('Solicitud de Crédito - Ocurrio un error al guardar los cambios'));
      this.alertService.error(msg);
      this.spinner.hide();
    })
  }

  // Funcion que valida el llenado de los datos y guarda los datos de la ficha

  guardarFicha(): void{

    if (!this.camposPersonalizadosForm.valid) { // Valida que la cabecera inicial tenga todos los datos
      this.solicitudCreditoService.validateAllFormFields(this.camposPersonalizadosForm);
      this.alertService.error('Solicitud de Crédito - Debe completar los datos necesarios para guardar correctamente.');
      return;
    }

    if(this.bodyModelos?.workflowModel === 'lineaDeCredito') { // modeloDemo
      const elementoHTML = document.getElementById('montoPreAprobado');
      if(elementoHTML){
        elementoHTML.className = elementoHTML.className.replace(" error-input", "");
        elementoHTML.title = '';
      }

      if(!this.montoPreAprobado && this.montoPreAprobado != 0){
        this.alertService.error('Solicitud de Crédito - Debe completar el monto pre aprobado.');
        if(elementoHTML){
          elementoHTML.className = elementoHTML.className + " error-input";
          elementoHTML.title = 'Completar dato';
        }
        return;
      }

    }

    if(this.rut === ''){
      this.alertService.error('Solicitud de Crédito - Ocurrio un error al recuperar el rut.');
      return;
    }

    const ficha: any = {
      ficha: {
        rut: this.rut,
        informacionCliente: this.informacionCliente,
        antecedentesSalfa: this.antecedentesSalfa,
        lineaCreditoMTickets: this.lineaCreditoMTickets,
        titlesFlujosFuturos: this.titlesFlujosFuturos,
        objFlujosFuturos: this.objFlujosFuturos,
        titlesFlujosHistoricos: this.titlesFlujosHistoricos,
        objFlujosHistoricos: this.objFlujosHistoricos,
        mallaSocietariaTable: this.mallaSocietariaTable,
        dicomMTable: this.dicomMTable,
        mallaSocietariaGraph: this.mallaSocietariaGraph,
        mallaSocietariaGraphPrint: this.mallaSocietariaGraphPrint,
        categoria: this.categoria,
        serie: this.serie,
        graphReady: this.graphReady,
        deudaSbifDerivados: this.deudaSbifDerivados
      }
    }

    if(this.bodyModelos?.workflowModel === 'lineaDeCredito'){ // modeloDemo
      ficha.ficha.objetoLineaCredito = this.objetoLineaCredito;
      ficha.ficha.montoPreAprobado = this.montoPreAprobado;
    }

    this.spinner.show();
    this.subscriptions.push(this.solicitudCreditoService.guardarFichaDeCredito(this.rut, this.idTransaccion, ficha).subscribe(resp => {
      this.fichaGuardada = ficha;
      this.alertService.success(resp.message)
      this.spinner.hide();
    }, (error: any) => {
      this.alertService.error(error?.error?.message || 'Solicitud de Crédito - Ocurrio un error con el servicio de guardado de la ficha');
      this.spinner.hide();
    }));
  }

  // Boton Continuar Primera etapa

  avanzarFlujo(): void{
    this.spinner.show();
    this.subscriptions.push(this.solicitudCreditoService.obtenerFlujo(this.idTransaccion, this.rut).subscribe(resp => {
      this.bodyModelos = resp;
      if(this.bodyModelos?.stages[8]?.optional === false){
        if(this.bodyModelos?.stages[8]?.completed){

          this.subscriptions.push(this.solicitudCreditoService.calcularScore(this.idTransaccion, this.rut).subscribe(resp => {
            if(resp?.MotorDeCalculos?.errors?.hassError === false){
              if(resp?.DatosBasicosSolicitud?.Automatico === true){

                this.bloqueaPasos6.emit(true);
                this.solicitudCreditoService.setStep(6);
                this.spinner.hide();

              } else {
                
                this.solicitudCreditoService.setStep('next');
                // bloquea pasos 5
                if(!this.validaUsuarioAdmin()){
                  this.bloqueaPasos5.emit(true);
                }
                this.spinner.hide();
              }
            } else {
              this.alertService.error(resp?.MotorDeCalculos?.details?.message || 'Solicitud de Crédito - Ocurrio un error al recuperar los datos del motor de calculos');
              this.spinner.hide();
            }
          }, (error: any) => {
            this.alertService.error(error?.error?.message || 'Solicitud de Crédito - Ocurrio un error al consultar servicio del motor de calculos');
            this.spinner.hide();
          }));

        } else {
          this.spinner.hide();
          this.alertService.error('Solicitud de Crédito - Debe completar la etapa actual para poder avanzar');
        }
      } else {
        this.solicitudCreditoService.setStep('next');
        this.spinner.hide();
      }
    }, (error: any) => {
      this.alertService.error(error?.error?.message || 'Solicitud de Crédito - Ocurrio un error al recuperar los datos del flujo de credito');
      this.spinner.hide();
    }));
  }

  // Funciones para navegacion

  mapeoReporteSbif(): any {
    let respuesta: any = null;
    if(this.reportesPrincipalesObj?.deudaSbif?.reporte){
      if (this.reportesPrincipalesObj?.deudaSbif?.reporte?.Sbif?.rowsMeses?.item && this.reportesPrincipalesObj?.deudaSbif?.reporte?.Sbif?.rowsMeses?.item.length > 0) {
        respuesta = {
          Documentos: {
            SbifOnline: {
              Reporte: {
                rowsMeses: this.reportesPrincipalesObj?.deudaSbif?.reporte?.Sbif?.rowsMeses
              }
            }
          }
        }
      }
    }
    return respuesta;

  }

  // Scrollea automaticamente la vista a la seccion seleccionada

  scroll(el: string, event: any) {
    let button = event.target as HTMLElement | null;
    const elementHTML = document.getElementById(el) as HTMLElement | null;
    if(button?.classList.contains('fa')){
      button = button?.parentElement as HTMLElement | null;
    }
    elementHTML?.classList.toggle('container-desplegables');
    if ( !elementHTML?.classList.contains('container-desplegables') ){
      elementHTML?.scrollIntoView(true);
      button?.classList.add('button-selected');
      button?.classList.remove('border');
      button?.classList.remove('border-buttons');
      button?.classList.add('border-0'); 

    } else {
      button?.classList.remove('button-selected');
      button?.classList.add('border');
      button?.classList.add('border-buttons');
      button?.classList.remove('border-0'); 
    }
  }

  // Muestra u oculta seccion dependiendo de seleccion del usuario

  show(el: string, buttonId?: string) {
    const elementHTML = document.getElementById(el) as HTMLElement;
    elementHTML.classList.toggle('container-desplegables');
    if(buttonId){
      const button = document.getElementById(buttonId) as HTMLElement;
      if ( !elementHTML.classList.contains('container-desplegables') ){
        button.classList.add('button-selected');
        button.classList.remove('border');
        button.classList.remove('border-buttons');
        button.classList.add('border-0'); 
      } else {
        button.classList.remove('button-selected');
        button.classList.add('border');
        button.classList.add('border-buttons');
        button.classList.remove('border-0'); 
      }
    }
  }

  stringToFloat(number: any): number {
    return parseFloat(number.toString().replace(',', '.')) || 0;
  }

  finalizar(): void {
    this.finalizarFlujo.emit();
  }

  setToday(): void {
    this.today = `${new Date().getFullYear()}${(new Date().getMonth() + 1 < 10?  '0'+(new Date().getMonth() + 1) : 
      new Date().getMonth() + 1)}${new Date().getDate()}`;        
  }

  agregarSaltosDeLinea(texto: string): string {
    return texto.replace(new RegExp('\n', 'g'), '<br />');
  }

  validaUsuarioAdmin(): boolean {
    return this._sessionService.getUserAccess().includes(this.access[3]);
  }

  volverEtapaPrevia(): void {
    this.bloqueaPasos5.emit(false);
    this.solicitudCreditoService.setStep(Number(this.stepper - 1));
    this.spinner.hide();
  }

  dividirValor(numero: number | string, divisor: number): string {
    if (numero === undefined || numero === null) {
      return "";
    }
    const valorNumerico = Number(numero);
    if (isNaN(valorNumerico)) {
      return "";
    }
    const valorDividido = valorNumerico / divisor;
    return valorDividido.toString();
  }

  // Boton continuar etapa final

  continuar(): void{
    this.spinner.show();
    this.subscriptions.push(this.solicitudCreditoService.obtenerFlujo(this.idTransaccion, this.rut).subscribe(resp => {
      this.bodyModelos = resp;
      if(this.bodyModelos?.stages[9]?.optional === false){
        if(this.bodyModelos?.stages[9]?.completed){
          
          // Llamada a servicio para inciar aprobacion
          this.subscriptions.push(this.solicitudCreditoService.iniciarFlujoAprobacional(this.idTransaccion, this.rut).subscribe(resp => {
            const error = resp?.ServicioAprobacional?.errors || null;
            if(error?.hassError === true){
              this.alertService.error(error?.details?.message || 'Solicitud de Crédito - Ocurrio un error al actualizar el flujo');
              this.spinner.hide();
            } else {
              this.solicitudCreditoService.setStep('next');
              this.bloqueaPasos6.emit(true);
              this.spinner.hide();
            }
          }, (error: any) => {
            this.alertService.error(error?.error?.message || 'Solicitud de Crédito - Ocurrio un error al consultar con el servicio para iniciar el flujo aprobacional');
            this.spinner.hide();
          }));

        } else {
          this.spinner.hide();
          this.alertService.error('Solicitud de Crédito - Debe completar la etapa actual para poder avanzar');
        }
      } else {
        // Llamada a servicio para inciar aprobacion
        this.subscriptions.push(this.solicitudCreditoService.iniciarFlujoAprobacional(this.idTransaccion, this.rut).subscribe(resp => {
          const error = resp?.ServicioAprobacional?.errors || null;
          if(error?.hassError === true){
            this.alertService.error(error?.details?.message || 'Solicitud de Crédito - Ocurrio un error al actualizar el flujo');
            this.spinner.hide();
          } else {
            this.solicitudCreditoService.setStep('next');
            this.bloqueaPasos6.emit(true);
            this.spinner.hide();
          }
        }, (error: any) => {
          this.alertService.error(error?.error?.message || 'Solicitud de Crédito - Ocurrio un error al consultar con el servicio para iniciar el flujo aprobacional');
          this.spinner.hide();
        }));
      }
    }, (error: any) => {
      this.alertService.error(error?.error?.message || 'Solicitud de Crédito - Ocurrio un error al recuperar los datos del flujo de credito');
      this.spinner.hide();
    }));
  }

  convertirMiles(obj: number[]): number[] {
    if (obj.length > 0) {
      return obj.map((e: number) => {
        return e / 1000;
      });
    }
    return [];
  }

  quitarEspacios(str: any): any {
    if (str && typeof str === 'string') {
      return str.replace(/\s/g, '_');
    } else if(str && Array.isArray(str)) {
      return str.map((e: any) => e.replace(/\s/g, '_'));
    } else {
      return '';
    }
  }

  setFechaPeriodo(fecha: string): Date {
    const year = fecha.substring(0, 4);
    const month = fecha.substring(4, 6);
    const day = '02';
    return new Date(Number(year), (Number(month) - 1), Number(day));
  }

  // probablemente el mapeo de la siguiente funcion cambie - validar*
  obtenerValorCamposPersonalizadosAutomatic(key: string): any[] {
    if (this.atributosCargaMasiva) {
      if (this.atributosCargaMasiva.length > 0) {
        return this.atributosCargaMasiva
          .find((e: any) => e.nameAttribute === key)?.values || [];
      }
      return [];
    }
    return [];
  }



}
