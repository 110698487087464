import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AlertService } from 'src/app/components/_alert';

@Component({
  selector: 'app-reporte-tesoreria-view',
  templateUrl: './reporte-tesoreria-view.component.html',
  styleUrls: ['./reporte-tesoreria-view.component.scss']
})
export class ReporteTesoreriaViewComponent implements OnInit {
  @Input() reporteFullContent!: any | null;
  @Input() rut!: string | null;
  @Input() isResumida = false;
  @Input() printClick: Observable<void> = of();

  public camposEnteros: string[] = [
    'cantidad', 'vencidas',
    'noVencidas', 'noLiquidables',
    'montoLiquidado', 'deudaNeta',
    'reajuste', 'interes',
    'multa', 'total',
    'montoVoluntario', 'cantidadVoluntario',
    'montoNoVoluntario', 'cantidadNoVoluntario',
    'monto', 'montoRenta',
    'cantidadRenta', 'montoDevoluciones',
    'cantidadDevoluciones', 'montoBonificaciones',
    'cantidadBonificaciones', 'montoSubvenciones',
    'cantidadSubvenciones', 'montoAdministrativa',
    'cantidadAdministrativa', 'montoJudicial',
    'cantidadJudicial', 'montoQuiebra',
    'cantidadQuiebra', 'montoVigentes',
    'cantidadVigentes', 'montoCaducados',
    'cantidadCaducados', 'montoPagados',
    'cantidadPagados',
    'monotCuotaFija', 'montoCuotaContado', 'montoInicial'
  ];
  //public camposDecimales: string[] = [];
  public camposFechas: string[] = [
    'fechaVencimiento', 'fechaPago', 'fechaUltimoPago', 'fechaResolucion', 'fechaActivacion'
  ];
  //public camposPorc: string[] = []; // Porcentajes

  public headerContribuciones: any[] = [
    {key: 'cantidad', value: 'Cantidad'},
    {key: 'vencidas', value: 'Vencidas'},
    {key: 'noVencidas', value: 'No Vencidas'},
    {key: 'noLiquidables', value: 'No Liquidables'},
    {key: 'montoLiquidado', value: 'Monto Liquidado'},
  ]

  public headerFiscalesTotales: any[] = [
    {key: 'cantidad', value: 'Cantidad'},
    {key: 'vencidas', value: 'Vencidas'},
    {key: 'noVencidas', value: 'No Vencidas'},
    {key: 'noLiquidables', value: 'No Liquidables'},
    {key: 'montoLiquidado', value: 'Monto Liquidado'},
  ]

  public headerFiscales: any[] = [
    {key: 'tipoDeuda', value: 'Tipo Deuda'},
    {key: 'formulario', value: 'Formulario'},
    {key: 'tipo', value: 'Tipo'},
    {key: 'folio', value: 'Folio'},
    {key: 'fechaVencimiento', value: 'Fecha Vencimiento'},
    {key: 'deudaNeta', value: 'Deuda Neta'},
    {key: 'reajuste', value: 'Reajuste'},
    {key: 'interes', value: 'Interes'},
    {key: 'multa', value: 'Multa'},
    {key: 'total', value: 'Total'},
  ]

  public headerPagosTotales: any[] = [
    {key: 'montoVoluntario', value: 'Monto'},
    {key: 'cantidadVoluntario', value: 'Cantidad'},
    {key: 'montoNoVoluntario', value: 'Monto'},
    {key: 'cantidadNoVoluntario', value: 'Cantidad'},
  ]

  public headerPagosVoluntarios: any[] = [
    {key: 'formulario', value: 'Formulario'},
    {key: 'rol', value: 'Rol'},
    {key: 'folio', value: 'Folio'},
    {key: 'fechaVencimiento', value: 'Fecha Vencimiento'},
    {key: 'fechaPago', value: 'Fecha Pago'},
    {key: 'monto', value: 'Monto'},
  ]

  public headerPagosNoVoluntarios: any[] = [
    {key: 'formulario', value: 'Formulario'},
    {key: 'folio', value: 'Folio'},
    {key: 'fechaVencimiento', value: 'Fecha Vencimiento'},
    {key: 'fechaPago', value: 'Fecha Pago'},
    {key: 'monto', value: 'Monto'},
  ]

  public headerPagosRecTotales: any[] = [
    {key: 'montoRenta', value: 'Monto'},
    {key: 'cantidadRenta', value: 'Cantidad'},
    {key: 'montoDevoluciones', value: 'Monto'},
    {key: 'cantidadDevoluciones', value: 'Cantidad'},
    {key: 'montoBonificaciones', value: 'Monto'},
    {key: 'cantidadBonificaciones', value: 'Cantidad'},
    {key: 'montoSubvenciones', value: 'Monto'},
    {key: 'cantidadSubvenciones', value: 'Cantidad'},
  ]

  public headerPagosRenta: any[] = [
    {key: 'formulario', value: 'Formulario'},
    {key: 'folio', value: 'Folio'},
    {key: 'fechaVencimiento', value: 'Fecha Vencimiento'},
    {key: 'fechaPago', value: 'Fecha Pago'},
    {key: 'monto', value: 'Monto'},
  ]

  public headerPagosDevoluciones: any[] = [
    {key: 'formulario', value: 'Formulario'},
    {key: 'folio', value: 'Folio'},
    {key: 'fechaVencimiento', value: 'Fecha Vencimiento'},
    {key: 'fechaPago', value: 'Fecha Pago'},
    {key: 'monto', value: 'Monto'},
  ]

  public headerPagosBonificaciones: any[] = [
    {key: 'formulario', value: 'Formulario'},
    {key: 'folio', value: 'Folio'},
    {key: 'fechaVencimiento', value: 'Fecha Vencimiento'},
    {key: 'fechaPago', value: 'Fecha Pago'},
    {key: 'monto', value: 'Monto'},
  ]

  public headerPagosSubvenciones: any[] = [
    {key: 'formulario', value: 'Formulario'},
    {key: 'folio', value: 'Folio'},
    {key: 'fechaVencimiento', value: 'Fecha Vencimiento'},
    {key: 'fechaPago', value: 'Fecha Pago'},
    {key: 'monto', value: 'Monto'},
  ]

  public headerCobranzasTotales: any[] = [
    {key: 'montoAdministrativa', value: 'Monto'},
    {key: 'cantidadAdministrativa', value: 'Cantidad'},
    {key: 'montoJudicial', value: 'Monto'},
    {key: 'cantidadJudicial', value: 'Cantidad'},
    {key: 'montoQuiebra', value: 'Monto'},
    {key: 'cantidadQuiebra', value: 'Cantidad'},
  ]

  public headerCuentasCobranzasAdministrativa: any[] = [
    {key: 'expediente', value: 'Expediente'},
    {key: 'año', value: 'Año'},
    {key: 'comuna', value: 'Comuna'},
    {key: 'etapa', value: 'Etapa'},
    {key: 'montoLiquidado', value: 'Monto Liquidado'},
  ]

  public headerCuentasCobranzasJudiciales: any[] = [
    {key: 'expediente', value: 'Expediente'},
    {key: 'año', value: 'Año'},
    {key: 'comuna', value: 'Comuna'},
    {key: 'etapa', value: 'Etapa'},
    {key: 'montoLiquidado', value: 'Monto Liquidado'},
    // {key: 'fechaUltimoPago', value: 'Fecha Último Pago'},
  ]

  public headerCuentasCobranzasQuiebra: any[] = [
    {key: 'expediente', value: 'Expediente'},
    {key: 'año', value: 'Año'},
    {key: 'comuna', value: 'Comuna'},
    {key: 'etapa', value: 'Etapa'},
    {key: 'montoLiquidado', value: 'Monto Liquidado'},
  ]

  public headerConveniosTotales: any[] = [
    {key: 'montoVigentes', value: 'Monto'},
    {key: 'cantidadVigentes', value: 'Cantidad'},
    {key: 'montoCaducados', value: 'Monto'},
    {key: 'cantidadCaducados', value: 'Cantidad'},
    {key: 'montoPagados', value: 'Monto'},
    {key: 'cantidadPagados', value: 'Cantidad'},
  ]

  public headerConveniosVigentes: any[] = [
    {key: 'resolucion', value: 'Resolucion'},
    {key: 'formulario', value: 'Formulario'},
    {key: 'folio', value: 'Folio'},
    {key: 'fechaVencimiento', value: 'Fecha Vencimiento'},
    {key: 'montoLiquidado', value: 'Monto Liquidado'},
  ]

  public headerConveniosCaducados: any[] = [
    {key: 'resolucion', value: 'Resolucion'},
    {key: 'formulario', value: 'Formulario'},
    {key: 'folio', value: 'Folio'},
    {key: 'fechaVencimiento', value: 'Fecha Vencimiento'},
    {key: 'montoLiquidado', value: 'Monto Liquidado'},
  ]

  public headerConveniosPagados: any[] = [
    {key: 'resolucion', value: 'Resolucion'},
    {key: 'formulario', value: 'Formulario'},
    {key: 'folio', value: 'Folio'},
    {key: 'fechaVencimiento', value: 'Fecha Vencimiento'},
    {key: 'montoLiquidado', value: 'Monto Liquidado'},
  ]

  public headerRegistroMercadoPublicoDeudas: any[] = [
    {key: 'formulario', value: 'Formulario'},
    {key: 'detalle', value: 'Detalle'},
  ]

  public headerDetalleCobranza: any[] = [
    {key: 'formulario', value: 'Formulario'},
    {key: 'folio', value: 'Folio'},
    {key: 'fechaVencimiento', value: 'Fecha Vencimiento'},
    {key: 'montoLiquidado', value: 'Monto Liquidado'},
  ]

  public headerDetalleCobranzaJudicial: any[] = [
    {key: 'formulario', value: 'Formulario'},
    {key: 'folio', value: 'Folio'},
    {key: 'fechaVencimiento', value: 'Fecha Vencimiento'},
    {key: 'montoLiquidado', value: 'Monto Liquidado'},
    {key: 'nConvenio', value: 'Número de convenio'},
    {key: 'fechaUltimoPago', value: 'Fecha Último Pago'},
  ]

  public headerDatosConvenio: any[] = [
    {key: 'tipoDeuda', value: 'Tipo de Deuda'},
    {key: 'cuotasOtorgadas', value: 'Cuotas Otorgadas'},
    {key: 'cuotasPagadas', value: 'Cuotas Pagadas'},
    {key: 'cuotasPorVencer', value: 'Cuotas Por Vencer'},
    {key: 'cuotasVencidas', value: 'Cuotas Vencidas'},
    {key: 'etapaActual', value: 'Etapa Actual'},
    {key: 'fechaActivacion', value: 'Fecha de Activación'},
    {key: 'fechaResolucion', value: 'Fecha de Resolución'},
    {key: 'monotCuotaFija', value: 'Monto Cuota Fija'},
    {key: 'montoCuotaContado', value: 'Monto Cuota al Contado'},
    {key: 'montoInicial', value: 'Monto Inicial'},
    {key: 'rutContribuyente', value: 'Rut Contribuyente'},
    {key: 'nombreContribuyente', value: 'Nombre Contribuyente'},
    // {key: 'resolucion', value: ''},
    {key: 'tipoConvenio', value: 'Tipo Convenio'},
    {key: 'tipoPago', value: 'Tipo Pago'},
  ]

  public headerDeudasConvenio: any[] = [
    {key: 'numero', value: 'No.'},
    {key: 'rutRol', value: 'Rut/Rol'},
    {key: 'formulario', value: 'Form.'},
    {key: 'folio', value: 'Folio'},
    {key: 'fechaVencimiento', value: 'F. Venc.'},
    {key: 'condMultas', value: 'Cond. otorgada Multas (%)'},
    {key: 'condInteres', value: 'Cond. otorgada Intereses (%)'},
    {key: 'saldo', value: 'Saldo(CLP)'},
  ]

  public headerCuotasConvenio: any[] = [ 
    {key: 'numero', value: 'Numero'},
    {key: 'monto', value: 'Monto'},
    {key: 'fechaVencimiento', value: 'Fecha Vencimiento'},
    {key: 'estadoPago', value: 'Estado Pago'},

  ]

  public contribuciones: any = null;
  public fiscalesTotal: any = null;
  public fiscales: any[] = [];
  public pagosTotal: any = null;
  public pagosVoluntarios: any[] = [];
  public pagosNoVoluntarios: any[] = [];
  public pagosRecTotal: any = null;
  public pagosRecRenta: any[] = [];
  public pagosRecDevoluciones: any[] = [];
  public pagosRecBonificaciones: any[] = [];
  public pagosRecSubvenciones: any[] = [];
  public cobranzasTotales: any = null;
  public cuentasCobranzasAdministrativa: any[] = [];
  public cuentasCobranzasJudiciales: any[] = [];
  public cuentasCobranzasQuiebra: any[] = [];
  public conveniosTotales: any = null;
  public conveniosVigentes: any[] = [];
  public conveniosCaducados: any[] = [];
  public conveniosPagados: any[] = [];
  public registroMercadoPublicoDeudas: any[] = [];
  public errores: {title: string, message: string}[] = [];

  public objectKeys = Object.keys;

  constructor(public alertService: AlertService,) { }

  ngOnInit(): void {
    this.mapeosTablas();
    console.log(this.reporteFullContent);
    this.validateReport(this.reporteFullContent);
    this.errores = this.getErrores(this.reporteFullContent);
    this.printClick.subscribe(() => this.expandAllView());
  }

  getErrores(report: any): any[] {
    const response: {title: string, message: string}[] = [];
    if(report?.TesoreriaReporte?.Reporte?.deudas?.fiscales?.detalle?.error === true) {
      response.push({
        title: 'Deudas Fiscales',
        message: report?.TesoreriaReporte?.Reporte?.deudas?.fiscales?.detalle?.message || 'Error al obtener deudas fiscales'
      });
    }
    return response;
  }

  validateReport(report: any) {
    if(!report || !report.TesoreriaReporte || !report.TesoreriaReporte.Reporte) {
      this.alertService.error("Error en Al Obtener Reporte");
    }
    if(!report.TesoreriaReporte.Reporte.deudas || !report.TesoreriaReporte.Reporte.deudas.fiscales) {
      this.alertService.error("Error en Al Obtener Deudas");
    }
    if(!report.TesoreriaReporte.Reporte.deudas.fiscales.detalle) {
      
    } else if(report.TesoreriaReporte.Reporte.deudas.fiscales.detalle.error) {
      this.alertService.error(report.TesoreriaReporte.Reporte.deudas.fiscales.detalle.message || "Error al obtener deudas fiscales");
    }
  }

  expandAllView() {
    this.expandAllConvenios(this.conveniosVigentes);
    this.expandAllConvenios(this.conveniosCaducados);
    this.expandAllConvenios(this.conveniosCaducados);
  }

  validaCobranzasJudiciales(): boolean {
    if((this.reporteFullContent?.TesoreriaReporte?.Reporte?.cobranzas?.judicial?.cuentas &&
      this.reporteFullContent?.TesoreriaReporte?.Reporte?.cobranzas?.judicial?.cuentas.length > 0)
      || (this.reporteFullContent?.TesoreriaReporte?.Reporte?.cobranzas?.judicial?.monto && 
        this.reporteFullContent?.TesoreriaReporte?.Reporte?.cobranzas?.judicial?.monto > 0)
      || (this.reporteFullContent?.TesoreriaReporte?.Reporte?.cobranzas?.judicial?.cantidad && 
        this.reporteFullContent?.TesoreriaReporte?.Reporte?.cobranzas?.judicial?.cantidad > 0)) { 
          return true;
        }
    return false;
  }

  validaCobranzasAdministrativas(): boolean {
    if((this.reporteFullContent?.TesoreriaReporte?.Reporte?.cobranzas?.administrativa?.cuentas &&
      this.reporteFullContent?.TesoreriaReporte?.Reporte?.cobranzas?.administrativa?.cuentas.length > 0)
      || (this.reporteFullContent?.TesoreriaReporte?.Reporte?.cobranzas?.administrativa?.monto && 
        this.reporteFullContent?.TesoreriaReporte?.Reporte?.cobranzas?.administrativa?.monto > 0)
      || (this.reporteFullContent?.TesoreriaReporte?.Reporte?.cobranzas?.administrativa?.cantidad && 
        this.reporteFullContent?.TesoreriaReporte?.Reporte?.cobranzas?.administrativa?.cantidad > 0)) { 
          return true;
        }
    return false;
  }

  validaCobranzasQuiebra(): boolean {
    if((this.reporteFullContent?.TesoreriaReporte?.Reporte?.cobranzas?.quiebra?.cuentas &&
      this.reporteFullContent?.TesoreriaReporte?.Reporte?.cobranzas?.quiebra?.cuentas.length > 0)
      || (this.reporteFullContent?.TesoreriaReporte?.Reporte?.cobranzas?.quiebra?.monto && 
        this.reporteFullContent?.TesoreriaReporte?.Reporte?.cobranzas?.quiebra?.monto > 0)
      || (this.reporteFullContent?.TesoreriaReporte?.Reporte?.cobranzas?.quiebra?.cantidad && 
        this.reporteFullContent?.TesoreriaReporte?.Reporte?.cobranzas?.quiebra?.cantidad > 0)) { 
          return true;
        }
    return false;
  }

  validarCobranzasTotales(key: string): boolean {
    if(!key) return false;
    if ((key == 'montoJudicial' || key == 'cantidadJudicial') && this.validaCobranzasJudiciales()) { 
      return true;
    } else if((key == 'montoAdministrativa' || key == 'cantidadAdministrativa') && this.validaCobranzasAdministrativas()) {
      return true;
    } else if((key == 'montoQuiebra' || key == 'cantidadQuiebra') && this.validaCobranzasQuiebra()) {
      return true;
    } else {
      return false;
    }
  }

  mapeosTablas(): void {
    if(this.reporteFullContent !== null){

      // Deudas
      this.contribuciones = this.reporteFullContent?.TesoreriaReporte?.Reporte?.deudas?.contribuciones || null;

      this.fiscalesTotal = {
        cantidad: this.reporteFullContent?.TesoreriaReporte?.Reporte?.deudas?.fiscales?.cantidad,
        vencidas: this.reporteFullContent?.TesoreriaReporte?.Reporte?.deudas?.fiscales?.detalle?.totalDeudaLiquidadaMorosa,
        noVencidas: this.reporteFullContent?.TesoreriaReporte?.Reporte?.deudas?.fiscales?.detalle?.totalDeudaNoVencidaLiquidada,
        noLiquidables: this.reporteFullContent?.TesoreriaReporte?.Reporte?.deudas?.fiscales?.detalle?.acogidosArt196y197,
        montoLiquidado: null,
      }

      if((this.fiscalesTotal.vencidas || this.fiscalesTotal.vencidas == 0) ||
        (this.fiscalesTotal.noVencidas || this.fiscalesTotal.noVencidas == 0) ||
        (this.fiscalesTotal.noLiquidables || this.fiscalesTotal.noLiquidables == 0) ) {
          this.fiscalesTotal.montoLiquidado = Number(this.fiscalesTotal.vencidas || 0) + Number(this.fiscalesTotal.noVencidas || 0) + Number(this.fiscalesTotal.noLiquidables || 0);
        }

      this.fiscales = this.reporteFullContent?.TesoreriaReporte?.Reporte?.deudas?.fiscales?.detalle?.deudas || [];

      // Pagos
      this.pagosTotal = {
        montoVoluntario: this.reporteFullContent?.TesoreriaReporte?.Reporte?.pagos?.realizados?.voluntarios?.monto,
        cantidadVoluntario: this.reporteFullContent?.TesoreriaReporte?.Reporte?.pagos?.realizados?.voluntarios?.cantidad,
        montoNoVoluntario: this.reporteFullContent?.TesoreriaReporte?.Reporte?.pagos?.realizados?.noVoluntarios?.monto,
        cantidadNoVoluntario: this.reporteFullContent?.TesoreriaReporte?.Reporte?.pagos?.realizados?.noVoluntarios?.cantidad,
      }

      this.pagosVoluntarios = this.reporteFullContent?.TesoreriaReporte?.Reporte?.pagos?.realizados?.voluntarios?.detalle || [];
      this.pagosNoVoluntarios = this.reporteFullContent?.TesoreriaReporte?.Reporte?.pagos?.realizados?.noVoluntarios?.detalle || [];

      this.pagosRecTotal = {
        montoRenta: this.reporteFullContent?.TesoreriaReporte?.Reporte?.pagos?.recibidos?.renta?.monto,
        cantidadRenta: this.reporteFullContent?.TesoreriaReporte?.Reporte?.pagos?.recibidos?.renta?.cantidad,
        montoDevoluciones: this.reporteFullContent?.TesoreriaReporte?.Reporte?.pagos?.recibidos?.devoluciones?.monto,
        cantidadDevoluciones: this.reporteFullContent?.TesoreriaReporte?.Reporte?.pagos?.recibidos?.devoluciones?.cantidad,
        montoBonificaciones: this.reporteFullContent?.TesoreriaReporte?.Reporte?.pagos?.recibidos?.bonificaciones?.monto,
        cantidadBonificaciones: this.reporteFullContent?.TesoreriaReporte?.Reporte?.pagos?.recibidos?.bonificaciones?.cantidad,
        montoSubvenciones: this.reporteFullContent?.TesoreriaReporte?.Reporte?.pagos?.recibidos?.subvenciones?.monto,
        cantidadSubvenciones: this.reporteFullContent?.TesoreriaReporte?.Reporte?.pagos?.recibidos?.subvenciones?.cantidad,
      }

      this.pagosRecRenta = this.reporteFullContent?.TesoreriaReporte?.Reporte?.pagos?.recibidos?.renta?.detalle || [];
      this.pagosRecDevoluciones = this.reporteFullContent?.TesoreriaReporte?.Reporte?.pagos?.recibidos?.devoluciones?.detalle || [];
      this.pagosRecBonificaciones = this.reporteFullContent?.TesoreriaReporte?.Reporte?.pagos?.recibidos?.bonificaciones?.detalle || [];
      this.pagosRecSubvenciones = this.reporteFullContent?.TesoreriaReporte?.Reporte?.pagos?.recibidos?.subvenciones?.detalle || [];

      // Cobranzas
      this.cobranzasTotales = {};

      if(this.validaCobranzasAdministrativas()) {
        this.cobranzasTotales.montoAdministrativa = this.reporteFullContent?.TesoreriaReporte?.Reporte?.cobranzas?.administrativa?.monto;
        this.cobranzasTotales.cantidadAdministrativa = this.reporteFullContent?.TesoreriaReporte?.Reporte?.cobranzas?.administrativa?.cantidad;
      }

      if(this.validaCobranzasJudiciales())  {
        this.cobranzasTotales.montoJudicial = this.reporteFullContent?.TesoreriaReporte?.Reporte?.cobranzas?.judicial?.monto;
        this.cobranzasTotales.cantidadJudicial = this.reporteFullContent?.TesoreriaReporte?.Reporte?.cobranzas?.judicial?.cantidad;
      }

      if(this.validaCobranzasQuiebra()) {
        this.cobranzasTotales.montoQuiebra = this.reporteFullContent?.TesoreriaReporte?.Reporte?.cobranzas?.quiebra?.monto;
        this.cobranzasTotales.cantidadQuiebra = this.reporteFullContent?.TesoreriaReporte?.Reporte?.cobranzas?.quiebra?.cantidad;
      }

      this.cuentasCobranzasAdministrativa = this.reporteFullContent?.TesoreriaReporte?.Reporte?.cobranzas?.administrativa?.cuentas || [];
      this.cuentasCobranzasAdministrativa.forEach((e) => {
        if(e && Object.keys(e).length > 0 && e?.detalle && e?.detalle.length > 0) {
          this.addDetailExtraData(e.detalle);
          e.isExpanded = false;
        }
      });
      this.cuentasCobranzasJudiciales = this.reporteFullContent?.TesoreriaReporte?.Reporte?.cobranzas?.judicial?.cuentas || [];
      this.cuentasCobranzasJudiciales.forEach((e) => {
        if(e && Object.keys(e).length > 0 && e?.detalle && e?.detalle.length > 0) {
          // Debo agregar al detalle el numero de convenio y fecha ultimo pago
          this.addDetailExtraData(e.detalle);
          console.log("with extra data:", e.detalle);
          e.isExpanded = false;
        }
      });
      this.cuentasCobranzasQuiebra = this.reporteFullContent?.TesoreriaReporte?.Reporte?.cobranzas?.quiebra?.cuentas || [];
      this.cuentasCobranzasQuiebra.forEach((e) => {
        if(e && Object.keys(e).length > 0 && e?.detalle && e?.detalle.length > 0) {
          this.addDetailExtraData(e.detalle);
          e.isExpanded = false;
        }
      });

      // Convenios
      this.conveniosTotales = {
        montoVigentes: this.reporteFullContent?.TesoreriaReporte?.Reporte?.convenios?.vigentes?.monto,
        cantidadVigentes: this.reporteFullContent?.TesoreriaReporte?.Reporte?.convenios?.vigentes?.cantidad,
        montoCaducados: this.reporteFullContent?.TesoreriaReporte?.Reporte?.convenios?.caducados?.monto,
        cantidadCaducados: this.reporteFullContent?.TesoreriaReporte?.Reporte?.convenios?.caducados?.cantidad,
        montoPagados: this.reporteFullContent?.TesoreriaReporte?.Reporte?.convenios?.pagados?.monto,
        cantidadPagados: this.reporteFullContent?.TesoreriaReporte?.Reporte?.convenios?.pagados?.cantidad,
      }

      this.conveniosVigentes = this.reporteFullContent?.TesoreriaReporte?.Reporte?.convenios?.vigentes?.detalle || [];
      this.conveniosCaducados = this.reporteFullContent?.TesoreriaReporte?.Reporte?.convenios?.caducados?.detalle || [];
      this.conveniosPagados = this.reporteFullContent?.TesoreriaReporte?.Reporte?.convenios?.pagados?.detalle || [];
      
      this.addDatosToConvenio(this.conveniosVigentes);
      this.addDatosToConvenio(this.conveniosCaducados);
      this.addDatosToConvenio(this.conveniosCaducados);

      // Registro Mercado Publico
      this.registroMercadoPublicoDeudas = this.reporteFullContent?.TesoreriaReporte?.Reporte?.registroMercadoPublico?.deudas || [];

    }
  }

  log(item: any) {
    console.log(item)
  }

  // Agrega el numero de convenio y fecha de ultimo pago
  addDetailExtraData(detalle: any) {
    detalle.forEach((element: any) => {
      if(element && Object.keys(element).length > 0) {
        const folio = element?.folio || ''
        // Busco el numero de convenio en la lista de convenios con el folio
        const nConvenio = this.searchConvenioByFolio(folio);
        element.nConvenio = nConvenio;
  
        // Busco la fecha de ultimo pago con este folio
        const fechaUltimoPago = this.searchFechaUltimoPago(folio);
        element.fechaUltimoPago = fechaUltimoPago
      }
    });
  }

  // Busca el convenio por numero de folio, en los convenios vigentes, caducados y pagados
  searchConvenioByFolio(folio: any) {
    if(!folio) return '';

    const convenioVigente = this.getConveniosVigentesDetalle().find(element => element?.folio === folio);
    if(convenioVigente) return convenioVigente?.resolucion || ''

    const convenioCaducado = this.getConveniosCaducadosDetalle().find(element => element?.folio === folio);
    if(convenioCaducado) return convenioCaducado?.resolucion || ''

    const convenioPagado = this.getConveniosPagadosDetalle().find(element => element?.folio === folio);
    if(convenioPagado) return convenioPagado?.resolucion || ''

    return '';
  }

  searchFechaUltimoPago(folio: string) {
    if(!folio) return '';

    const pagosVoluntarios = this.getPagosVoluntarios();
    const pagosNoVoluntarios = this.getPagosNoVoluntarios();

    const pagosTotales = pagosVoluntarios.concat(pagosNoVoluntarios)
    const pagosTotalesFolio = pagosTotales.filter(element => element?.folio === folio);
    console.log("pagosTotalesFolio:", pagosTotalesFolio)

    if(pagosTotalesFolio && pagosTotalesFolio.length === 0) return '';

    const latestFechaPagoObject = pagosTotalesFolio.reduce((latest, current) => {
      return new Date(current?.fechaPago) > new Date(latest?.fechaPago) ? current : latest;
    }, pagosTotalesFolio[0]);

    return latestFechaPagoObject?.fechaPago || '';
  }

  addDatosToConvenio(convenios: any[]) {
    convenios.forEach((convenio) => {
      this.searchDatosConvenioByResolucion(convenio)
    })
  }

  // Buscar convenio por resolucion
  searchDatosConvenioByResolucion(itemConvenio: any) {
    const resolucion = itemConvenio.resolucion.toString();
    const convenioVigenteDatos = this.getConveniosVigentesDatos().find(element => element.resolucion === resolucion);
    if(convenioVigenteDatos){
      itemConvenio.datos = convenioVigenteDatos.detalle.informacion
      itemConvenio.datos.cuotas = convenioVigenteDatos.cuotas
      itemConvenio.datos.deudas = convenioVigenteDatos.detalle.deudas
      itemConvenio.datos.tipoDeuda = convenioVigenteDatos.detalle.tipoDeuda
      itemConvenio.datos.tipoPage = convenioVigenteDatos.tipoPago
      itemConvenio.datos.seeCuotas = false;
      console.log(itemConvenio)
      return
    } 

    const convenioCaducado = this.getConveniosCaducadosDatos().find(element => element.resolucion === resolucion);
    if(convenioCaducado){
      itemConvenio.datos = convenioCaducado.detalle.informacion
      itemConvenio.datos.cuotas = convenioCaducado.cuotas
      itemConvenio.datos.deudas = convenioCaducado.detalle.deudas
      itemConvenio.datos.tipoDeuda = convenioCaducado.detalle.tipoDeuda
      itemConvenio.datos.tipoPage = convenioCaducado.tipoPago
      itemConvenio.datos.seeCuotas = false;
      return
    }

    const convenioPagado = this.getConveniosPagadosDatos().find(element => element.resolucion === resolucion);
    if(convenioPagado) {
      itemConvenio.datos = convenioPagado.detalle.informacion
      itemConvenio.datos.cuotas = convenioPagado.cuotas
      itemConvenio.datos.deudas = convenioPagado.detalle.deudas
      itemConvenio.datos.tipoDeuda = convenioPagado.detalle.tipoDeuda
      itemConvenio.datos.tipoPage = convenioPagado.tipoPago
      itemConvenio.datos.seeCuotas = false;
      return
    }
  }

  getConveniosVigentesDatos(): any[] {
    return this.reporteFullContent?.TesoreriaReporte?.Reporte?.convenios?.vigentes?.datos || []
  }

  getConveniosCaducadosDatos(): any[] {
    return this.reporteFullContent?.TesoreriaReporte?.Reporte?.convenios?.caducados?.datos || [];
  }

  getConveniosPagadosDatos(): any[] {
    return this.reporteFullContent?.TesoreriaReporte?.Reporte?.convenios?.pagados?.datos || [];
  }

  getConveniosVigentesDetalle(): any[] {
    return this.reporteFullContent?.TesoreriaReporte?.Reporte?.convenios?.vigentes?.detalle || []
  }

  getConveniosCaducadosDetalle(): any[] {
    return this.reporteFullContent?.TesoreriaReporte?.Reporte?.convenios?.caducados?.detalle || [];
  }

  getConveniosPagadosDetalle(): any[] {
    return this.reporteFullContent?.TesoreriaReporte?.Reporte?.convenios?.pagados?.detalle || [];
  }

  getPagosVoluntarios(): any[] {
    return this.reporteFullContent?.TesoreriaReporte?.Reporte?.pagos?.realizados?.voluntarios?.detalle || [];
  }

  getPagosNoVoluntarios(): any[] {
    return this.reporteFullContent?.TesoreriaReporte?.Reporte?.pagos?.realizados?.noVoluntarios?.detalle || [];
  }

  expandAllConvenios(convenios: any[]) {
    convenios.forEach(convenio => {
      convenio.isExpanded = true;
      if(convenio.datos) {
        convenio.datos.seeCuotas = true;
      }
    });
  }
}
