import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment as env } from 'src/environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { UsuariosService } from 'src/app/shared/services/usuarios.service';
import { AlertService } from 'src/app/components/_alert';
import { Title } from '@angular/platform-browser';
import { ESystemAccess, ESystemProfileList } from 'src/app/enum/EAccess';
import { SessionService } from 'src/app/shared/services/session.service';
import { CarteraRutService } from 'src/app/shared/services/cartera-rut.service';
import { catchError, map } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import Highcharts from 'highcharts';
import { Chart } from 'angular-highcharts';
import { CredencialesService } from 'src/app/shared/services/credenciales.service';

@Component({
  selector: 'app-dashboard-mantenedor-cartera',
  templateUrl: './dashboard-mantenedor-cartera.component.html',
  styleUrls: ['./dashboard-mantenedor-cartera.component.scss']
})
export class DashboardMantenedorCarteraComponent {
  private access: string[] = [ESystemAccess.ADMINISTRACION_SUJETO_CREDITO, ESystemProfileList.WEB];
  public hasUserAccess = false;
  
  public objectKeys = Object.keys;
  public usuarioSeleccionado = '';
  public showDashboard = true;
  public showAsignar = false;
  public currentItem = env.initItemPerPage;
  public currentPage = 0;
  public inputText: string = '';
  public users: any[] = [];
  public usersFiltrado: any[] = [];
  private totalizadoresUsuarios: any[] = [];
  private listadoErrores: any[] = [];
  public cards: any[] = [];
  public graph: any = {};
  public cantidadRutsSincronizados: number = 0;
  public isDetalleOpen: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private _usuariosService: UsuariosService,
    private _sessionService: SessionService,
    private credencialesService: CredencialesService,
    public alertService: AlertService,
    private readonly titleService: Title,
    private carteraRutService: CarteraRutService) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.route.snapshot.data['title']);
    if (this._sessionService.getUserAccess().includes(this.access[0]) && this._sessionService.getUserProfileList().includes(this.access[1])) {
      this.hasUserAccess = true;
      this.loadListUserDatasource();
    }
  }

  setResponse(service: string, resp: any): void {
    switch (service) {
      case 'totalizadoresUsuarios':
        this.totalizadoresUsuarios = resp || [];
        break;
      case 'getUsersForGroup':
        this.users = resp || [];
        break;
      case 'obtenerCantidadElementos':
        this.cantidadRutsSincronizados = resp?.NElementos ? Number(resp?.NElementos) || 0 : 0;
    }
  }

  setError(service: string, error: string): void {
    this.listadoErrores.push({ service, error });
  }

  getServices(service: string): any {
    const objeto: any = {
      'totalizadoresUsuarios': () => {
        return this.carteraRutService.obtenerUsuariosTotalizadores()
          .pipe(
            map(resp => {
              this.setResponse(service, resp);
            })
          )
          .pipe(
            catchError((error) => (this.setError(service, error?.error?.message || 'Ocurrió un error al obtener cantidad de ruts'), of(null))));
      },
      'getUsersForGroup': () => {
        return this._usuariosService.getUsersForGroup()
        .pipe(
          map(resp => {
            this.setResponse(service, resp);
          })
        )
        .pipe(
          catchError((error) => (this.setError(service, error?.error?.message || 'Ocurrió un error al obtener el listado de usuario'), of(null))));
      },
      'obtenerCantidadElementos': () => {
        return this.credencialesService.obtenerCantidadElementos()
          .pipe(
            map(resp => {
              this.setResponse(service, resp);
            })
          )
          .pipe(
            catchError((error) => (this.setError(service, error?.error?.message || 'Ocurrió un error al obtener cantidad de elementos'), of(null))));
      }
    };
    return objeto[service]();
  }

  loadListUserDatasource() {
    this.users = [];
    this.cards = [];
    this.graph = {};
    this.usersFiltrado = [];
    this.inputText = '';
    this.totalizadoresUsuarios = [];
    this.cantidadRutsSincronizados = 0;
    this.listadoErrores = [];

    const apiServices: any = [];
    apiServices.push(this.getServices('totalizadoresUsuarios'));
    apiServices.push(this.getServices('getUsersForGroup'));
    apiServices.push(this.getServices('obtenerCantidadElementos'));

    this.spinner.show();
    forkJoin(apiServices).subscribe(
      resp => {
        if(this.listadoErrores.length > 0) {
          for(const error of this.listadoErrores) {
            this.alertService.error(error.error);
          }
        }

        if(this.users.length > 0) {
          if(this.totalizadoresUsuarios.length > 0) {
            for(const user of this.users) {
              const totalizador = this.totalizadoresUsuarios.find((totalizador: any) => totalizador.idUsuario == user.userName);
              user.rutCount = totalizador?.rutCount || 0;
            }
          }

          this.filtrarObjeto();
          this.generarTotales();
          this.spinner.hide();
        }
        this.spinner.hide();
      },
      error => {
        this.alertService.error(error.message || 'Ocurrió un error al consultar por los usuarios');
        this.spinner.hide();
      }
    );
  }
  
  filtrarObjeto(){
    this.currentPage = 0;
    this.usersFiltrado = this.inputText != '' ? this.users.filter((item: any) => 
    item?.userName?.toUpperCase().includes(this.inputText.toUpperCase()) 
    || item?.name?.toUpperCase().includes(this.inputText.toUpperCase())
    || item?.givenName?.toUpperCase().includes(this.inputText.toUpperCase())
    || item?.email?.toUpperCase().includes(this.inputText.toUpperCase())
    ) : this.users;
  }

  generarTotales(){
    if(this.users && this.users.length > 0) {
      // cards
      this.cards = [
        {title: 'Usuarios', value: '', type: 'number', icon: 'fa fa-users'},
        {title: 'Ruts asignados', value: '', type: 'number', icon: 'fa fa-user'},
        {title: 'Ruts sin asignar', value: '', type: 'number', icon: 'fa fa-user-o'},
        {title: 'Usuario con mas ruts', value: '', type: 'string', icon: ''},
        {title: 'Usuario con menos ruts', value: '', type: 'string', icon: ''},
      ]

      const rutsAsignados = this.users.reduce((acc, user) => acc + user.rutCount, 0);
      this.cards[0].value = this.users.length;
      this.cards[1].value = rutsAsignados;
      this.cards[2].value = this.cantidadRutsSincronizados > 0 && (this.cantidadRutsSincronizados - 1) > 0 ? (this.cantidadRutsSincronizados - 1)  - rutsAsignados : 0;
      this.cards[3].value = this.users.reduce((acc, user) => acc.rutCount > user.rutCount ? acc : user).userName;
      this.cards[4].value = this.users.reduce((acc, user) => acc.rutCount < user.rutCount ? acc : user).userName;

      // grafico
      // ordenar por cantidad de ruts de mayor a menor
      let rutsConMasAsignaciones = this.users.sort((a, b) => b?.rutCount - a?.rutCount);
      // dejar solo userName y rutCount
      rutsConMasAsignaciones = rutsConMasAsignaciones.map((user) => ({userName: user.userName, rutCount: user.rutCount}));
      // tomar solo los 10 primeros
      rutsConMasAsignaciones = rutsConMasAsignaciones.slice(0, 10);

      if (rutsConMasAsignaciones && rutsConMasAsignaciones.length > 0) {
        const data: any = {
          categories: [],
          series: []
        };
        
        data.categories = rutsConMasAsignaciones.map((user) => user.userName);
        
        const colors = ['#007bff', '#28a745', '#dc3545', '#ffc107', '#17a2b8', '#6f42c1']; // Colores diferentes
        
        data.series = rutsConMasAsignaciones.map((user, index) => ({
          name: `${user.userName}`,
          type: 'column',
          data: [user.rutCount],
          color: colors[index % colors.length], // Asigna un color diferente a cada columna
        }));
        
        if (rutsConMasAsignaciones.length > 0 && data.categories.length > 0 && data.series.length > 0) {
          this.graph = new Chart({
            xAxis: [{
              categories: data.categories || [],
              labels: {
                enabled: false
              }
            }],
            title: {
              text: ''
            },
            yAxis: [{
              labels: {
                format: '{value}',
                style: {
                  color: '#000'
                }
              },
              title: {
                text: '',
                style: {
                  color: '#000'
                }
              }
            }],
            tooltip: {
              formatter: function () {
                return 'Usuario: ' + this.series.name+ '<br/>' +
                  'Cantidad de ruts' + ': ' + Highcharts.numberFormat(Number(this.y), 0);
              }
            },
            series: data.series || []
          });
        }
      }
    }
  }

  seleccionarUsuario(usuario: string): void {
    this.usuarioSeleccionado = usuario;
    this.showDashboard = false;
    this.isDetalleOpen = false;
    this.showAsignar = true;
    this.users = [];
    this.cards = [];
    this.graph = {};
    this.usersFiltrado = [];
    this.inputText = '';
    this.totalizadoresUsuarios = [];
    this.cantidadRutsSincronizados = 0;
    this.listadoErrores = [];
  }

  volver(): void {
    this.usuarioSeleccionado = '';
    this.showDashboard = true;
    this.showAsignar = false;
    this.isDetalleOpen = false;
    this.users = [];
    this.cards = [];
    this.graph = {};
    this.usersFiltrado = [];
    this.inputText = '';
    this.totalizadoresUsuarios = [];
    this.cantidadRutsSincronizados = 0;
    this.listadoErrores = [];
    this.loadListUserDatasource();
  }
}
