<div *ngIf="!hasUserAccess">
    <app-request-access-view></app-request-access-view>
</div>

<div class="row" *ngIf="hasUserAccess && showDashboard">
    <div class="col-12">
        <h1 class="ml-2">
            <strong>Administrar Sujetos de Crédito</strong>
        </h1>
    </div>
    <hr class="col-sm-12 col-md-10 col-lg-8 ml-3"/>
    
    <div class="col-sm-12 col-md-10 mx-auto">
        <div class="card card-rounded-list text-center my-4">
            <div class="card-header card-rounded-header-list">
                <div class="my-0 row d-flex px-4">
                    <div class="col-xs-3 col-sm-auto mr-auto p-2">
                        <app-paginator-by-items title="Cantidad de filas a mostrar en la tabla" [itemsPerPageInput]="currentItem" (itemsPerPage)="currentItem = $event"></app-paginator-by-items>
                    </div>
                    <div class="col-xs-3 col-sm-auto p-2">
                        <div class="input-group mb-3">
                            <input id="table-complete-search" type="text" class="form-control" name="searchTerm"
                                title="Ingrese texto para filtrar tabla"
                                placeholder="Filtrar tabla"
                                [(ngModel)]="inputText" 
                                (keyup)="filtrarObjeto()"
                            >
                        </div>
                    </div>
                </div>
            </div>
            <div class="table-responsive">
                <table id="tableUsuarios" class="table table-hover border my-0">
                    <thead>
                        <tr>
                            <th scope="col" class="text-center font-size bg-primary">Ejecutivo</th>
                            <th scope="col" class="text-center font-size bg-primary">Nombre</th>
                            <th scope="col" class="text-center font-size bg-primary">Apellido</th>
                            <th scope="col" class="text-center font-size bg-primary">Ruts Asignados</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of usersFiltrado | paginate:{itemsPerPage: currentItem, currentPage: currentPage}">
                            <td class="text-center cursor-pointer font-size" (click)="seleccionarUsuario(item?.userName)">{{item?.userName}}</td>
                            <td class="text-center cursor-pointer font-size" (click)="seleccionarUsuario(item?.userName)">{{item?.name}}</td>
                            <td class="text-center cursor-pointer font-size" (click)="seleccionarUsuario(item?.userName)">{{item?.givenName}}</td>
                            <td class="text-center cursor-pointer font-size" (click)="seleccionarUsuario(item?.userName)">{{item?.rutCount | number: '1.0-0'}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="card-footer card-rounded-bottom-list text-muted">
                <div class="p-2">
                    <div id="pagination">
                        <div class="col-sm-12 col-md-8 col-lg-5 float-right">
                            <pagination-controls (pageChange)="currentPage=$event"></pagination-controls>
                        </div>
                    </div>
                </div>
            </div>
        </div>  
    </div>

    <div class="col-sm-12 col-md-10 mt-3 mx-auto">
        <button type="button" class="btn" [ngClass]="isDetalleOpen ? 'btn-danger' : 'btn-primary'" (click)="isDetalleOpen = !isDetalleOpen">
            <i class="px-2 mr-2 fa" [ngClass]="isDetalleOpen ? 'fa-angle-down' : 'fa-angle-right'" aria-hidden="true"></i>
            {{ isDetalleOpen ? 'Ocultar' : 'Mostrar' }} Indicadores
        </button>
    </div>

    <div class="col-12" *ngIf="isDetalleOpen">
        <div class="row">
            <div class="col-12 mt-3">
                <div class="row">
        
                    <ng-container *ngFor="let item of cards">
                        <div class="col-sm-12 col-md-6 col-lg-4 mx-auto my-3">
                            <div class="card card-rounded w-50 mx-auto border-0 shadow">
                                <div class="card-header bg-primary card-rounded-header text-center font-size-card-header">
                                    {{item?.title || ''}}
                                </div>
                                <div class="card-body d-flex justify-content-between px-5">
                                    <i *ngIf="item?.icon" class="text-primary my-auto font-size-card" [ngClass]="item.icon" aria-hidden="true"></i>
                                    <span class="text-primary my-auto font-weight-bold" [ngClass]="item?.type == 'number' ? 'font-size-card' : 'text-break text-center'">
                                        <ng-container *ngIf="item?.type == 'number'; else elseTemplateType">
                                            {{item?.value || item?.value == 0 ? (item?.value | number: '1.0-0') : ''}}
                                        </ng-container>
                                        <ng-template #elseTemplateType>
                                            {{item?.value}}
                                        </ng-template>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    
                </div>
            </div>
        
            <div class="col-sm-12 mx-auto my-5" *ngIf="graph && objectKeys(graph).length > 0">
                <div class="card card-rounded w-75 mx-auto border-0 shadow">
                    <div class="card-header bg-primary card-rounded-header text-center font-size-card-header">
                        Ruts / Usuario
                    </div>
                    <div class="card-body d-flex justify-content-between">
                        <div class="row w-100 mx-auto">
                            <div class="col-12">
                                <ng-container *ngIf="graph && objectKeys(graph).length > 0">
                                    <div [chart]="graph" class="graphContent mx-auto"></div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>

<app-asignador-rut *ngIf="hasUserAccess && showAsignar" [usuario]="usuarioSeleccionado" (backAsignar)="volver()"></app-asignador-rut>


