import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Subscription, forkJoin, from, of } from 'rxjs';
import { ESystemAccess } from 'src/app/enum/EAccess';
import { AlertService } from 'src/app/components/_alert';
import { SolicitudCreditoServiceWorkflow } from '../../../../shared/services/solicitud-credito-workflow.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { catchError, filter, map, mergeMap, toArray } from 'rxjs/operators';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { ModalBasicoConsolidadoService } from 'src/app/shared/services/modal-basico-consolidado.service';
import { Router } from '@angular/router';
import { FormularioF29Service } from 'src/app/shared/services/formulario-f29.service';
import { Chart } from 'angular-highcharts';
import { AccordionComponent } from 'ngx-bootstrap/accordion';
import { deudaSbifLeasing } from 'src/app/models/utils/utils.model';
import { SessionService } from 'src/app/shared/services/session.service';
import { environment as env } from 'src/environments/environment';
import Highcharts from 'highcharts';
import { MatDialog } from '@angular/material/dialog';
import { ModalF29Component } from '../modal-f29/modal-f29.component';

@Component({
  selector: 'app-panel-riesgo-primus',
  templateUrl: './panel-riesgo-primus.component.html',
  styleUrls: ['./panel-riesgo-primus.component.scss']
})
export class PanelRiesgoPrimusComponent {
  @Input() plantillaNoEditable: boolean = false;
  @Input() objEntradaFicha: any = {};
  @Input() objEntradaMotor: any = {};

  @Output() operacionNuevo = new EventEmitter<boolean>();
  @Output() finalizarFlujo = new EventEmitter<void>();
  @Output() bloqueaPasos5 = new EventEmitter<boolean>();
  @Output() bloqueaPasos6 = new EventEmitter<boolean>();
  @Input() idTransaccion: string = '';
  @Input() stepper!: number;
  @Input() rut: string = '';
  @Input() closed: boolean = true;

  public objectKeys = Object.keys;
  private access: string[] = [
    ESystemAccess.FLUJO_CREDITO_BUSCAR, 
    ESystemAccess.FLUJO_CREDITO_BUSCAR_ADMIN,
    ESystemAccess.FLUJO_CREDITO,
    ESystemAccess.FLUJO_CREDITO_ADMIN
  ];

  public subscriptions: Subscription[] = [];
  public data: any;
  public today = '';
  public bodyModelos: any = {};
  
  public groupNameConsulting: string[] = ['buscarReportePrincipal', 'buscarReportesDerivados'];
  public groupNameConsultingFicha: string[] = ['buscarFichaDeCredito', 'buscarReportesDerivados', 'buscarReportePrincipal'];
  public groupNameConsultingScore: string[] = ['buscarCalculoScore', 'buscarReportesDerivados', 'buscarReportePrincipal'];

  public errores: any[] = [];
  public contratoServicios: any[] = [];
  public atributos: any[] = [];
  public listaReportesPrincipales: any[] = [];
  public listaReportesDerivados: any = {};
  public entidadesDerivadas: any = {};
  public reportesPrincipalesObj: any = {};
  public reportesDerivadosObj: any = {};

  public factoring: any[] = [];
  public tablaSocios: any[] = [];
  public tablaBalance: any = {};
  public tablaIvaSobreVentas: any = {};
  public evolucionGraphDicom: any = {};
  public graphEvolutivoCV: any = {};
  public f29Tablas: any[] = [];

  public consolidadoTitular: any = {};
  public plantillaAntecedentes = 'cesceDemo';
  public modalDefault = true;
  public reportesSeleccionado: any = {};
  public rutSeleccionado: string = '';
  public informeComercial: string = '';
  public fechaTx: string = '';
  public antecedentesComerciales: any = {};
  public evolucionDeudaCMF: any[] = [];

  public reportePoderJudicial: any = null;
  public reporteDeudaSbif: any = null;
  public reporteBoletinConcursal: any = null;
  public reporteCteVentasBalancesRentas: any = null;
  public reporteBasicReport: any = null;
  public reporteBienesRaices: any = null;
  public reporteVehiculos: any = null;

  public headRow: any = [
    'periodo',
    'dirvigente', 
    'dirmorosa', 
    'dirvencida', 
    'diracreedor', 
    'dirhipotecaria', 
    'dirinversion', 
    'diroperacionpacto', 
    'dircomercial', 
    'credcontingente', 
    'monexvigente',
    'monexvencida', 
    'indirectavig', 
    'indirectaven', 
    'castigadadirecta', 
    'castigadaindirecta', 
    'leasingvig', 
    'leasingmor', 
    'dirven180mayor3year', 
    'lineacredito', 
  ];
  public headNames: any = deudaSbifLeasing;

  // campos para tablas primus
  public informacionBasicaCliente: any[] = 
  [
    {text: 'Actividad económica', value: '', type: 'text'},
    {text: 'Tamaño de la empresa', value: '', type: 'text'},
    {text: 'Fecha inicio de actividades', value: '', type: 'date'},
    {text: 'Dirección', value: '', type: 'text'},
    {text: 'Cantidad de empleados', value: '', type: 'number'},
    {text: 'Rango facturación en UF', value: '', type: 'text'},
    {text: 'Tramo', value: '', type: 'text'}
  ];

  public datosFichas: any = {
    fechaReporte: '',
    razonSocial: '',
    rutEmpresa: '', 
    boletinConcursal: '',
    clienteDificilFiscalicion: '',
    montoTotalVentaMorosaTGR: '', 
    promVentasEvolucionCompraVenta: '',
    porcCompraSobreVentas: ''
  };
  public deudaFiscal: any[] = [];
  public convenioVigentes: any = {
    body: [],
    totales: {}
  };
  public detalleDocumentos: any = {
    body: [],
    totales: {}
  };
  public statusIva12Meses: any = {
    body: [],
    totales: {}
  }

  public showReportes: boolean = false;
  public showArchivos: boolean = false;

  public currentPage = 1;
  public currentItem = env.initItemPerPage;

  public fichaGuardada: any = {}; // es para validar la existencia de una ficha guardada y habilitar el boton continuar

  @ViewChild(AccordionComponent) accordion: AccordionComponent | any;

  constructor(
    public alertService: AlertService,
    private solicitudCreditoService: SolicitudCreditoServiceWorkflow,
    private spinner: NgxSpinnerService,
    private utilsService: UtilsService,
    private modalService: ModalBasicoConsolidadoService,
    private formularioF29Service: FormularioF29Service,
    private dialog: MatDialog,
    private _sessionService: SessionService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.operacionNuevo.emit(false);
    this.setToday();
    if(this.plantillaNoEditable === false){
      this.obtenerFlujo();
    }
  }

  setToday(): void {
    this.today = `${new Date().getFullYear()}${(new Date().getMonth() + 1 < 10?  '0'+(new Date().getMonth() + 1) : 
      new Date().getMonth() + 1)}${new Date().getDate()}`;        
  }

  // Logica de revision de paso y llamada a servicios

  obtenerFlujo(): void{
    this.spinner.show();
    this.subscriptions.push(this.solicitudCreditoService.obtenerFlujo(this.idTransaccion, this.rut).subscribe(resp => {
      this.bodyModelos = resp;
      let paso: any;
      let pasoAnterior: any;
      const pasosCompleted = resp.stages.filter((e: any) => e.completed);
      const cantidad = pasosCompleted.length;
      const ultimoPasoCompletado = Number(pasosCompleted[cantidad - 1].stageID);
      this.closed = (resp.closed === null || resp.closed === undefined) ? true : resp.closed;
      this.showArchivos = true;

      if(this.stepper === 5){
        resp.stages.forEach((e: any) => {
          if(e.stageID === '10'){
            paso = e;
          }
        });
        // Se valida si el paso anterior fue completado y si no se envia al ultimo completado
        if(paso?.stageID === '10' && (paso?.completed === false && paso?.optional === false)){ //pasoAnterior && !pasoAnterior?.completed
          let pasoAnteriorCompleted = ultimoPasoCompletado;
          if(ultimoPasoCompletado === 9)
            pasoAnteriorCompleted = 8;

          this.redireccionPasoAnterior(pasoAnteriorCompleted);
        } else {
          if (paso?.stageID === '10' && paso?.completed === true) { // Se realizo el calculo del score
            this.callServices(true);
          } else {
            this.redireccionPasoAnterior(ultimoPasoCompletado);
          }
        }
      } else {
        resp.stages.forEach((e: any) => {
          if(e.stageID === '9'){
            paso = e;
          } else if (e.stageID === '8'){
            pasoAnterior = e;
          }
        });
        // Se valida si el paso anterior fue completado y si no se envia al ultimo completado
        if(pasoAnterior && (!pasoAnterior?.completed && pasoAnterior?.optional === false)){
          this.redireccionPasoAnterior(ultimoPasoCompletado);
        } else {
          if (paso?.stageID === '9' && paso?.completed === false) { // primera vez en esta pantalla -> cambiar para pruebas
            this.callServices();
          }
          else if(paso?.stageID === '9' && paso?.completed === true){ // ya se habia llegado antes a esta pantalla -> cambiar para pruebas
            //this.callServices();
            this.callServices(true); // --> solo si se avanza en el flujo
          }
        }
      }
    }, (error: any) => {
      this.alertService.error(error?.error?.message || 'Solicitud de Crédito - Ocurrio un error al recuperar los datos del flujo de credito');
      this.spinner.hide();
    }));

  }

  async callServices(fichaGuardada: boolean = false): Promise<void> {
    const apiServices: any = [];

    if(!fichaGuardada){
      if (this.groupNameConsulting.length > 0) {
        for await (const value of this.groupNameConsulting) {
          apiServices.push(this.getServices(value))
        }
      }
    } else {
      if (this.groupNameConsultingFicha.length > 0) {
        for await (const value of this.groupNameConsultingFicha) {
          apiServices.push(this.getServices(value))
        }
      }
    }

    this.spinner.show();
    this.subscriptions.push(forkJoin(apiServices).subscribe((resp) => {
      this.spinner.hide();
      if (this.errores.length > 0){
        this.errores.forEach((element: any) => {
          this.alertService.error(element.msg);
        });
        this.spinner.hide();
      } else {
        if(!fichaGuardada) {
          this.buscarReportes("callServices");
        } else {
          this.buscarReportes("callServicesFicha");
        }
      }
    },
      (error) => {
        this.alertService.error(error.message);
        this.spinner.hide();
      }
    ));
  }

  setError(reporte: string, error: string): void {
    this.errores.push({
      id: reporte,
      msg: error
    })
  }

  setResponse(service: string, response: any): void {
    if (service === 'buscarReportePrincipal'){

      this.contratoServicios = response?.Stage3Response?.ContratoServicios || [];
      this.atributos = response?.Stage2Response?.Atributos || [];
      this.listaReportesPrincipales = response?.ReportKeys || [];
      this.fechaTx = response?.DatosBasicosSolicitud?.FechaReporte || '';

    }
    else if (service === 'buscarReportesDerivados'){
      this.listaReportesDerivados = response?.ReportKeys || {};
      this.entidadesDerivadas = response?.Stage5Response?.Entidades;

    } else if (service === 'buscarFichaDeCredito' || service === 'buscarCalculoScore'){
      this.fichaGuardada = response?.FichaDeCredito;
      const ficha = JSON.parse(JSON.stringify(response?.FichaDeCredito?.ficha));
      
      (ficha && Object.keys(ficha).length > 0) ? this.showReportes = true : this.showReportes = false;
    }
  }

  mapeoFromFicha(ficha: any): void {
    this.consolidadoTitular = ficha?.consolidadoTitular || {};
    this.datosFichas = ficha?.datosFichas || {};
    this.informacionBasicaCliente = ficha?.informacionBasicaCliente || [];
    this.tablaSocios = ficha?.tablaSocios || [];
    this.tablaBalance = ficha?.tablaBalance || {};
    this.tablaIvaSobreVentas = ficha?.tablaIvaSobreVentas || {};
    this.antecedentesComerciales = ficha?.antecedentesComerciales || {};
    this.evolucionDeudaCMF = ficha?.evolucionDeudaCMF || [];
    this.factoring = ficha?.factoring || [];
    this.deudaFiscal = ficha?.deudaFiscal || [];
    this.f29Tablas = ficha?.f29Tablas || [];
    this.convenioVigentes = ficha?.convenioVigentes || {
      body: [],
      totales: {}
    };
    this.detalleDocumentos = ficha?.detalleDocumentos || {
      body: [],
      totales: {}
    };
    this.statusIva12Meses = ficha?.statusIva12Meses || {
      body: [],
      totales: {}
    };
    
    if(ficha?.dataEvolucionGraphDicom && Object.keys(ficha?.dataEvolucionGraphDicom).length > 0){
      this.evolucionGraphDicom = new Chart({
        xAxis: ficha?.dataEvolucionGraphDicom?.xAxis,
        title: ficha?.dataEvolucionGraphDicom?.title,
        yAxis: ficha?.dataEvolucionGraphDicom?.yAxis,
        series: ficha?.dataEvolucionGraphDicom?.series
      });
    }

    if(ficha?.dataGraphEvolutivoCV && Object.keys(ficha?.dataGraphEvolutivoCV).length > 0){
      this.graphEvolutivoCV = new Chart({
        xAxis: [{
          categories: ficha?.dataGraphEvolutivoCV?.categories || []
        }],
        title: {
          text: ''
        },
        yAxis: [{
          labels: {
            format: '${value} K',
            style: {
              color: '#000'
            }
          },
          title: {
            text: '',
            style: {
              color: '#000'
            }
          }
        }],
        tooltip: {
          formatter: function() {
            return ''+ this.x + '<br/>' +
              this.series.name +': ' + '$' + Highcharts.numberFormat(Number(this.y), 2,',') + ' K';
          }
        },
        series: ficha?.dataGraphEvolutivoCV?.series || []
      });
    }

    this.generarReporteBasicoConsolidado();
  }

  //Definicion llamada a servicios asincronos por primera vez en la vista

  getServices(service: string): any {
    const objeto: any = {
      'buscarReportePrincipal': () => {
        return this.solicitudCreditoService.buscarReportePrincipal(this.idTransaccion, this.rut)
          .pipe(
            map(resp => {
              this.setResponse(service, resp);
            })
          )
          .pipe(
            catchError((error) => (this.setError(service, error?.error?.message || 'Solicitud de Crédito - Error Inesperado en servicio de reportes principales'), of(null))));
      },
      'buscarReportesDerivados': () => {
        return this.solicitudCreditoService.buscarReportesDerivados(this.idTransaccion, this.rut)
        .pipe(
          map(resp => {
            this.setResponse(service, resp);
          })
        )
        .pipe(
          catchError((error) => (this.setError(service, error?.error?.message || 'Solicitud de Crédito - Error Inesperado en servicio de reportes derivados'), of(null))));
      },
      'buscarFichaDeCredito': () => {
        return this.solicitudCreditoService.buscarFichaDeCredito(this.idTransaccion, this.rut)
        .pipe(
          map(resp => {
            this.setResponse(service, resp);
          })
        )
        .pipe(
          catchError((error) => (this.setError(service, error?.error?.message || 'Solicitud de Crédito - Error Inesperado en servicio de carga de ficha'), of(null))));
      },
      'buscarCalculoScore': () => {
        return this.solicitudCreditoService.buscarCalculoScore(this.rut, this.idTransaccion)
        .pipe(
          map(resp => {
            this.setResponse(service, resp);
          })
        )
        .pipe(
          catchError((error) => (this.setError(service, error?.error?.message || 'Solicitud de Crédito - Error Inesperado en servicio de carga de ficha con Score'), of(null))));
      }
    };
    return objeto[service]();
  }

  buscarReportes(etapa: string): void {
    const apiServicesReportes: any = this.utilsService.obtenerServicesReportes(this.listaReportesPrincipales, this.listaReportesDerivados) || [];
    let count = 0;

    this.spinner.show();
    from(apiServicesReportes).pipe( 
      mergeMap(
        (service: any) => 
          this.solicitudCreditoService.buscarReportes(
            this.idTransaccion, 
            this.rut, 
            service.principal, 
            service.reportKey, 
            service.entidad, 
            service.rutDerivado
          )
          .pipe(
            catchError(error => {
              //console.error(`Error en la consulta del servicio: ${service.principal}, ${service.reportKey}, ${service.entidad}, ${service.rutDerivado}`);
              return of(null); // Retorna un observable que emite un valor nulo
            }),
            map(response => {
              if (response !== null) {
                count++;
              }
              return {
                service: service,
                response: response
              };
            }),
          ),
          20
        ), 
        filter(response => response !== null),
      toArray()
    ).subscribe(responses => {
      if (count === 0) { // Si no hubo respuestas válidas
        this.alertService.error('Solicitud de Crédito - Ha ocurrido un error al consultar la información de todos los reportes');
        this.spinner.hide();
      } else if (count < apiServicesReportes.length) { // Si hubo respuestas válidas pero no para todos los servicios
        this.alertService.error('Solicitud de Crédito - Ha ocurrido un error al consultar la información de algunos de los reportes');
      }
      if(count > 0){
        this.reportesPrincipalesObj = this.utilsService.mapeoReportesPrincipales(responses);
        this.reportesDerivadosObj = this.utilsService.mapeoReportesDerivados(responses);
        if (etapa === 'callServices'){
          this.mapeoReportes();
          this.informeComercial = this.utilsService.identificarInformeComercial(this.reportesSeleccionado?.basicReport?.reporte);
          this.spinner.hide();
        } else if (etapa === 'callServicesFicha'){
          this.informeComercial = this.utilsService.identificarInformeComercial(this.reportesSeleccionado?.basicReport?.reporte);
          this.mapeoFromFicha(this.fichaGuardada?.ficha);
          this.showReportes = true;
          this.spinner.hide();
        } else {
          this.spinner.hide();
        }
      }
    }, error => {
      if (count > 0) {
        this.alertService.error('Solicitud de Crédito - Ha ocurrido un error al consultar la información de algunos de los reportes');
      } else {
        this.alertService.error('Solicitud de Crédito - Ha ocurrido un error al consultar la información de todos los reportes');
        this.spinner.hide();
      }
    });
  }

  redireccionPasoAnterior(ultimoPasoCompletado: number): void {
    let step = this.solicitudCreditoService.stepsOptions.filter((e: any) => e.stageID == (ultimoPasoCompletado + 1));
    if (!step[0]?.step){
      step = this.solicitudCreditoService.stepsOptions.filter((e: any) => e.stageID == (ultimoPasoCompletado));
    }
    this.alertService.error(`Solicitud de Crédito - Debe completar el paso N° ${Number(step[0]?.step)} para poder continuar`);
    this.solicitudCreditoService.setStep(Number(step[0]?.step));
  }

  obtenerDatosF22(): any[] {
    const carpetaF22 = this.reportesPrincipalesObj?.cteVentasBalancesRentas?.reporte?.CarpetaTributariaElectronicaReporte?.Reporte?.f22 || [];
    const historicoF22 = this.reportesPrincipalesObj?.f22?.reporte?.ListadoF22Reportes || [];
    const calculadoF22 = this.reportesPrincipalesObj?.f22BalancesRentas?.reporte?.Formulario22Reporte || {};
    const f22Manual = this.reportesPrincipalesObj?.balanceManual?.reporte?.ListadoF22Reportes || [];

    const F22: any[] = [];
    carpetaF22.forEach((element: any) => {
      if(element) {
        element.FechaReporte = this.reportesPrincipalesObj?.cteVentasBalancesRentas?.reporte?.CarpetaTributariaElectronicaReporte?.DatosBasicosSolicitud?.FechaReporte;
        F22.push(element);
      }
    });
    historicoF22.forEach((element: any) => {
      if(element) {
        const reporte: any = element?.Formulario22Reporte?.Reporte;
        reporte.FechaReporte = element?.Formulario22Reporte?.DatosBasicosSolicitud?.FechaReporte;
        F22.push(reporte);
      }
    });
    if(calculadoF22 && Object.keys(calculadoF22).length > 0){
      const elemento: any = calculadoF22?.Reporte;
      if(elemento) {
        elemento.FechaReporte = calculadoF22?.DatosBasicosSolicitud?.FechaReporte;
        F22.push(elemento);
      }
    }
    f22Manual.forEach((element: any) => {
      if(element) {
        const reporte: any = element?.Formulario22Reporte?.Reporte;
        reporte.FechaReporte = element?.Formulario22Reporte?.DatosBasicosSolicitud?.FechaReporte;
        F22.push(reporte);
      }
    });

    const ordenadoF22 = F22.sort((a: any,b: any) => Number(b?.calculado?.periodo) - Number(a?.calculado?.periodo)); // mayor a menor en año
    const F22Limpio: any[] = [];

    ordenadoF22.forEach((element: any) => {
      if(element?.calculado?.periodo){
        const indexRepetido = F22Limpio.findIndex(e => Number(e?.calculado?.periodo) === Number(element?.calculado?.periodo));
        if(indexRepetido >= 0){
          if(F22Limpio[indexRepetido]?.FechaReporte < element.FechaReporte){
            F22Limpio[indexRepetido] = element;
          }
        } else {
          F22Limpio.push(element);
        }
      }
    });
    return F22Limpio;
  }

  obtenerDatosF29(): any[] {
    const carpetaF29 = this.reportesPrincipalesObj?.cteVentasBalancesRentas?.reporte?.CarpetaTributariaElectronicaReporte?.Reporte?.f29 || [];
    const historicoF29 = this.reportesPrincipalesObj?.f29?.reporte?.ListadoF29Reportes || [];
    const calculadoF29 = this.reportesPrincipalesObj?.f29Ventas?.reporte?.Formulario29Reporte?.Reporte || {};
    
    const F29: any[] = [];
    carpetaF29.forEach((element: any) => {
      if(!element?.message){
        F29.push(element);
      }
    });
    historicoF29.forEach((element: any) => {
      if(!element?.Formulario29Reporte?.Reporte.message){
        F29.push(element?.Formulario29Reporte?.Reporte);
      }
    });
    if(calculadoF29 && Object.keys(calculadoF29).length > 0 && !calculadoF29?.message){
      F29.push(calculadoF29);
    }

    const ordenadoF29 = F29.sort((a: any,b: any) => { // mayor a menor en año y menor a mayor en mes
      if (!a?.calculado) return 1;
      if (!b?.calculado) return -1;
      const periodoDiff = Number(b.calculado.periodo) - Number(a.calculado.periodo);
      if (periodoDiff !== 0) return periodoDiff;
      return Number(a.calculado.mes) - Number(b.calculado.mes);
    });
    const F29Limpio: any[] = [];

    ordenadoF29.forEach((element: any) => {
      const indexRepetido = F29Limpio.findIndex(e => Number(e?.calculado?.periodo) === Number(element?.calculado?.periodo) && Number(e?.calculado?.mes) === Number(element?.calculado?.mes));
      if(indexRepetido >= 0){
        if(F29Limpio[indexRepetido]?.fecha_ingreso < element.fecha_ingreso){
          F29Limpio[indexRepetido] = element;
        }
      } else {
        F29Limpio.push(element);
      }
    })

    return F29Limpio;
  }

  formateoFecha(fechaEntrada: string): string {
     if (fechaEntrada.includes("T")) {
      const fecha = new Date(fechaEntrada);
      const dia = fecha.getDate().toString().padStart(2, '0');
      const mes = (fecha.getMonth() + 1).toString().padStart(2, '0');
      const anio = fecha.getFullYear().toString();
      return `${dia}-${mes}-${anio}`;
    } else  {
      return fechaEntrada;
    }
  }

  mapeoReportes(): void {
    const F22Limpio = this.obtenerDatosF22();
    const F29Limpio = this.obtenerDatosF29();
    const fechaActual = new Date();
    const anio = fechaActual.getFullYear();

    let aniosF29 = [];
    if (F29Limpio.length === 0) {
      aniosF29 = [anio, anio - 1];
    } else if (F29Limpio.length === 1) {
      const anioUnico = F29Limpio[0].periodo ? F29Limpio[0].periodo : anio;
      aniosF29 = [anioUnico, anioUnico - 1];
    } else {
      const aniosUnicos = [...new Set(F29Limpio.map(obj => obj?.periodo))];
      aniosUnicos.sort((a, b) => b - a);

      const anioActual: any = aniosUnicos[0] ? aniosUnicos[0] : (aniosUnicos[1] ? (aniosUnicos[1] + 1) : anio);
      const anioAnterior: any = aniosUnicos[1] ? aniosUnicos[1] : (aniosUnicos[0] ? (aniosUnicos[0] - 1) : anio - 1);

      aniosF29 = [anioActual, anioAnterior];
    }

    let aniosF22 = [];
    if (F22Limpio.length === 0) {
      aniosF22 = [anio, anio - 1];
    } else if (F22Limpio.length === 1) {
      const anioUnicoF22 = F22Limpio[0]?.calculado?.periodo;
      if(anioUnicoF22){
        aniosF22 = [anioUnicoF22, anioUnicoF22 - 1];
      } else {
        aniosF22 = [anio, anio - 1];
      }
    } else {
      const aniosUnicosF22 = [...new Set(F22Limpio.map(obj => obj?.calculado?.periodo))];
      aniosUnicosF22.sort((a, b) => b - a);
      aniosF22 = [aniosUnicosF22[0], aniosUnicosF22[1]];
    }

    this.consolidadoTitular = this.utilsService.crearConsolidadoTitular(F22Limpio, F29Limpio, this.reportesPrincipalesObj);
    this.generarReporteBasicoConsolidado();

    this.tablaIvaSobreVentas = this.crearTablaIvaSobreVentas(aniosF29[0], aniosF29[1], F29Limpio);
    const indexSocios = this.entidadesDerivadas.findIndex((e: any) => e?.entidad === 'socios');
    if(indexSocios >= 0){
      this.tablaSocios = this.crearTablaSocios(this.entidadesDerivadas[indexSocios]?.items || [], this.reportesPrincipalesObj?.basicReport?.reporte?.AntecedentesSocietarios || [], 'socios', this.reportesDerivadosObj);
    }

    this.tablaBalance = this.crearTablaBalance(aniosF22[0], aniosF22[1], F22Limpio);

    const datosGrafico = this.utilsService.ifReporteBasico(this.reportesPrincipalesObj?.basicReport?.reporte);
    if(datosGrafico?.graph) {
      this.evolucionGraphDicom = new Chart({
        xAxis: datosGrafico?.graph?.xAxis,
        title: datosGrafico?.graph?.title,
        yAxis: datosGrafico?.graph?.yAxis,
        series: datosGrafico?.graph?.series
      });
    }

    const datosGraficoEvCV: any = this.crearGraficoEvolutivoCompraVenta();
    if(datosGraficoEvCV && Object.keys(datosGraficoEvCV).length > 0 && datosGraficoEvCV?.categories && datosGraficoEvCV?.categories.length > 0 && datosGraficoEvCV?.series && datosGraficoEvCV?.series.length > 0) {
      this.graphEvolutivoCV = new Chart({
        xAxis: [{
          categories: datosGraficoEvCV?.categories || []
        }],
        title: {
          text: ''
        },
        yAxis: [{
          labels: {
            format: '${value} K',
            style: {
              color: '#000'
            }
          },
          title: {
            text: '',
            style: {
              color: '#000'
            }
          }
        }],
        tooltip: {
          formatter: function() {
            return ''+ this.x + '<br/>' +
              this.series.name +': ' + '$' + Highcharts.numberFormat(Number(this.y), 2,',') + ' K';
          }
        },
        series: datosGraficoEvCV?.series || []
      });
    }

    this.crearAntecedentesComerciales();
    this.crearEvolucionDeudaCMF();
    this.factoring = this.utilsService.crearFactoring(this.reportesPrincipalesObj?.cesionFacturas);
    if(this.informacionBasicaCliente && this.informacionBasicaCliente?.length > 0) {
      this.llenarInformacionBasicaCliente();
    }
    this.putInformacionFicha();
    this.putVentasInformacionFicha(F29Limpio);
    this.setDeudaFiscal();
    this.setConveniosVigentes();
    this.setDetalleDocumentos();
    this.setStatusIva12Meses(F29Limpio);

    const anioActualVentas = new Date().getFullYear();
    const anioAnteriorVentas = Number(anioActualVentas - 1);
    this.mapeoAnalisisDeclaracionIva(anioActualVentas, anioAnteriorVentas, F29Limpio);

  
    this.showReportes = true;
  }

  mapeoAnalisisDeclaracionIva(anioActualVentas: number, anioAnteriorVentas: number, F29Limpio: any[]): void {
    const aniosF29Tabla = [anioActualVentas.toString(), anioAnteriorVentas.toString()];
    const rutCut = this.rut.substring(0, this.rut.length - 1);
    const lastChar = this.rut[this.rut.length - 1];
    const rutF29 = rutCut + '-' + lastChar;
    const reporteF29 = this.formularioF29Service.obtenerReporteFiltrado(F29Limpio, aniosF29Tabla, true, rutF29);
    this.f29Tablas = this.formularioF29Service.revisionMeses(reporteF29);
  }

  crearGraficoEvolutivoCompraVenta(): any {
    const data: any = {
      categories: [],
      series: []
    }

    const libros: any = this.utilsService.getLibrosMapeados(this.reportesPrincipalesObj?.libroCompraVenta?.reporte?.Libros || [], this.reportesPrincipalesObj?.libroCompraVentaObtenerReporte?.reporte?.Libros || []);

    if(libros && libros.length > 0) {
      const librosOrdenados: any[] = libros.sort((a: any, b: any) => {
        if(a?.periodo === b?.periodo) {
          return a?.mes - b?.mes;
        }
        return a?.periodo - b?.periodo;
      });

      const valuesVentas: any[] = [];
      const valuesCompras: any[] = [];
      for(const libro of librosOrdenados) {
        data.categories.push(`${libro?.mes}-${libro?.periodo}`);
        valuesVentas.push(libro?.totalizadores?.montoTotalVenta || libro?.totalizadores?.montoTotalVenta == 0 ? 
          Number(libro?.totalizadores?.montoTotalVenta) / 1000 
          : '');
        valuesCompras.push(libro?.totalizadores?.montoTotalCompra || libro?.totalizadores?.montoTotalCompra == 0 ? 
          Number(libro?.totalizadores?.montoTotalCompra) / 1000 
          : '');
      }

      data.series.push({
        name: `Ventas`,
        type: 'column',
        data: valuesVentas,
        color:  '#007bff',
      });
      data.series.push({
        name: `Compras`,
        type: 'column',
        data: valuesCompras,
        color:  '#c51f20',
      });

    }

    return data || {};
  }

  getMes(mes: number): string {
    switch (mes) {
      case 1:
        return 'ENE';
      case 2:
        return 'FEB';
      case 3:
        return 'MAR';
      case 4:
        return 'ABR';
      case 5:
        return 'MAY';
      case 6:
        return 'JUN';
      case 7:
        return 'JUL';
      case 8:
        return 'AGO';
      case 9:
        return 'SEP';
      case 10:
        return 'OCT';
      case 11:
        return 'NOV';
      case 12:
        return 'DIC';
      default:
        return '';
    }
  }

  setStatusIva12Meses(F29: any[]): void {
    if(F29 && F29.length > 0){
      const fechaActual = new Date();
      // el mesActual y anioActual depende de que debe ser el mes anterior si estamos en enero debe ser diciembre del año anterior
      const mesActualReal = fechaActual.getMonth() + 1;
      const anioActualReal = fechaActual.getFullYear();
      const diaActual = fechaActual.getDate();
      const mesActual = mesActualReal === 1 ? 12 : mesActualReal - 1;
      const anioActual = mesActualReal === 1 ? anioActualReal - 1 : anioActualReal;

      const meses = [];
      for(let i = 0; i < 12; i++){
        let mes = mesActual - i;
        let anio = anioActual;
        if(mes <= 0){
          mes = 12 + mes;
          anio = anio - 1;
        }
        meses.push({
          mes: mes,
          anio: anio
        });
      }
      for(const item of meses){
        const obj: any = {
          mes: item.mes,
          mesName: this.getMes(item.mes),
          periodo: item.anio,
          estado: ''
        };
        const elementF29 = F29.find((e: any) => Number(e?.calculado?.mes) == item.mes && Number(e?.calculado?.periodo) == item.anio);
        if(!elementF29){
          obj.estado = 'noPagado';
          if(item.anio === anioActual && item.mes === mesActual && diaActual <= 20) {
            obj.estado = 'noPublicado';
          }
        } else {
          obj.estado = 'pagado';
          if(elementF29?.calculado?.postergacionIVA && elementF29?.calculado?.postergacionIVA > 0) {
            obj.estado = 'postergado';
          }
        }
        this.statusIva12Meses.body.push(obj);
      }
    }
    this.setStatusIva12MesesTotales();
  }

  setStatusIva12MesesTotales(): void {
    this.statusIva12Meses.totales = {
      noPublicado: 0,
      postergado: 0,
      pagado: 0,
      noPagado: 0
    }
    this.statusIva12Meses.body.forEach((item: any) => {
      if(item.estado === 'noPublicado'){
        this.statusIva12Meses.totales.noPublicado++;
      } else if(item.estado === 'postergado'){
        this.statusIva12Meses.totales.postergado++;
      } else if(item.estado === 'pagado'){
        this.statusIva12Meses.totales.pagado++;
      } else if(item.estado === 'noPagado'){
        this.statusIva12Meses.totales.noPagado++;
      }
    });
  }

  setDetalleDocumentos(): void {
    const entidad: string = 'cyv' // debe ser deudores despues - validar*
    const deudores = this.entidadesDerivadas.find((e: any) => e?.entidad === entidad); // debe ser deudores // validar*
    if(deudores?.items && deudores?.items.length > 0/* && this.reportesDerivadosObj[entidad]*/){
      for(const item of deudores.items){
        //if(this.reportesDerivadosObj?.[entidad]?.[item?.rut] && Object.keys(this.reportesDerivadosObj?.[entidad]?.[item?.rut]).length > 0) {
          const montoTotalVenta = item.atributos.find((e: any) => e?.name === 'montoTotalVenta')?.value;
          if(montoTotalVenta && montoTotalVenta > 0) {
            const rutSplit = item.rut.split('-');
            const nombre = item.atributos.find((e: any) => e?.name === 'nombre')?.value || '';
            const montoTotalCompra = item.atributos.find((e: any) => e?.name === 'montoTotalCompra')?.value;
    
            this.detalleDocumentos.body.push({
              nombre: nombre || "",
              rut: rutSplit[0] || '',
              dv: rutSplit[1] || '',
              ventas: montoTotalVenta,
              compras: montoTotalCompra
            });
          }
        //}
      }
      if(this.detalleDocumentos?.body.length > 0){
        this.calcularTotalesDetalleDocumentos();
      }
    }
  }

  calcularTotalesDetalleDocumentos(): void {
    this.detalleDocumentos.totales = {
      totalVentas: 0,
      totalCompras: 0
    };
    this.detalleDocumentos?.body.forEach((item: any) => {
      this.detalleDocumentos.totales.totalVentas += Number(item?.ventas) || 0;
      this.detalleDocumentos.totales.totalCompras += Number(item?.compras) || 0;
    });
  }

  setDeudaFiscal(): any {
    const deudaFiscal = this.reportesPrincipalesObj?.tesoreria?.reporte?.reporteTesoreria?.deudas?.fiscales?.detalle?.deudas || [];
    if(deudaFiscal && deudaFiscal.length > 0){
      this.deudaFiscal = deudaFiscal.map((deuda: any) => {
        return {
          ...deuda,
          rut: this.rut,
          girador: deuda?.formulario == '21' ? 'SII' : ''
        };
      });
    }
  }

  setConveniosVigentes(): any {
    const conveniosVigentes = this.reportesPrincipalesObj?.tesoreria?.reporte?.reporteTesoreria?.convenios?.vigentes?.detalle || [];
    if(conveniosVigentes && conveniosVigentes.length > 0){
      this.convenioVigentes.body = conveniosVigentes;
      this.addDatosToConvenio(this.convenioVigentes.body);
      if(this.convenioVigentes.body.length > 0){
        this.convenioVigentes.body.forEach((convenio: any) => {
          convenio.montoMoroso = ((Number(convenio?.datos?.monotCuotaFija) || 0) * (Number(convenio?.datos?.cuotasVencidas) || 0)); // / 1000
        });
        this.calcularTotalesConveniosVigentes();
      }
    }
  }

  calcularTotalesConveniosVigentes(): void {
    this.convenioVigentes.totales = {
      totalMontoMoroso: 0,
      totalCuotasVencidas: 0,
      totalSaldo: 0
    };
    this.convenioVigentes.body.forEach((convenio: any) => {
      this.convenioVigentes.totales.totalMontoMoroso += Number(convenio?.montoMoroso) || 0;
      this.convenioVigentes.totales.totalCuotasVencidas += Number(convenio?.datos?.cuotasVencidas) || 0;
      this.convenioVigentes.totales.totalSaldo += Number(convenio?.montoLiquidado) || 0;
    });
  }

  addDatosToConvenio(convenios: any[]) {
    convenios.forEach((convenio) => {
      this.searchDatosConvenioByResolucion(convenio)
    })
  }

  // Buscar convenio por resolucion
  searchDatosConvenioByResolucion(itemConvenio: any) {
    const resolucion = itemConvenio.resolucion.toString();
    const convenioVigenteDatos = this.getConveniosVigentesDatos().find(element => element.resolucion === resolucion);
    if(convenioVigenteDatos){
      itemConvenio.datos = convenioVigenteDatos.detalle.informacion
      itemConvenio.datos.cuotas = convenioVigenteDatos.cuotas
      itemConvenio.datos.deudas = convenioVigenteDatos.detalle.deudas
      itemConvenio.datos.tipoDeuda = convenioVigenteDatos.detalle.tipoDeuda
      itemConvenio.datos.tipoPage = convenioVigenteDatos.tipoPago
      itemConvenio.datos.seeCuotas = false;
      return
    } 

    const convenioCaducado = this.getConveniosCaducadosDatos().find(element => element.resolucion === resolucion);
    if(convenioCaducado){
      itemConvenio.datos = convenioCaducado.detalle.informacion
      itemConvenio.datos.cuotas = convenioCaducado.cuotas
      itemConvenio.datos.deudas = convenioCaducado.detalle.deudas
      itemConvenio.datos.tipoDeuda = convenioCaducado.detalle.tipoDeuda
      itemConvenio.datos.tipoPage = convenioCaducado.tipoPago
      itemConvenio.datos.seeCuotas = false;
      return
    }

    const convenioPagado = this.getConveniosPagadosDatos().find(element => element.resolucion === resolucion);
    if(convenioPagado) {
      itemConvenio.datos = convenioPagado.detalle.informacion
      itemConvenio.datos.cuotas = convenioPagado.cuotas
      itemConvenio.datos.deudas = convenioPagado.detalle.deudas
      itemConvenio.datos.tipoDeuda = convenioPagado.detalle.tipoDeuda
      itemConvenio.datos.tipoPage = convenioPagado.tipoPago
      itemConvenio.datos.seeCuotas = false;
      return
    }
  }

  getConveniosVigentesDatos(): any[] {
    return this.reportesPrincipalesObj?.tesoreria?.reporte?.reporteTesoreria?.convenios?.vigentes?.datos || []
  }

  getConveniosCaducadosDatos(): any[] {
    return this.reportesPrincipalesObj?.tesoreria?.reporte?.reporteTesoreria?.convenios?.caducados?.datos || [];
  }

  getConveniosPagadosDatos(): any[] {
    return this.reportesPrincipalesObj?.tesoreria?.reporte?.reporteTesoreria?.convenios?.pagados?.datos || [];
  }

  generarReporteBasicoConsolidado(): void {
    if(this.reportesPrincipalesObj){

      this.mapeoReporteTitular();
      this.mapeoBalance();
      this.mapeoPoderJudicial();
      this.mapeoSbif();
      this.validaObjetos();

      if(this.reportesSeleccionado?.poderJudicial?.ReporteFull) {
        this.reportePoderJudicial = this.reportesSeleccionado.poderJudicial.ReporteFull;
      }
      if(this.reportesSeleccionado?.deudaSbif?.reporte) {
        this.reporteDeudaSbif = this.reportesSeleccionado.deudaSbif.reporte;
      }
      if(this.reportesSeleccionado?.boletinConcursal?.reporte && 
        this.reportesSeleccionado?.boletinConcursal?.reporte?.boletinConcursalReporte?.principal?.encabezado && 
        Object.keys(this.reportesSeleccionado?.boletinConcursal?.reporte?.boletinConcursalReporte?.principal?.encabezado).length > 0) {
        this.reporteBoletinConcursal = this.reportesSeleccionado.boletinConcursal.reporte;
      }
      if(this.reportesSeleccionado?.cteVentasBalancesRentas?.reporte) {
        this.reporteCteVentasBalancesRentas = this.reportesSeleccionado.cteVentasBalancesRentas.reporte;
      }
      if(this.reportesSeleccionado?.basicReport?.reporte) {
        this.reporteBasicReport = this.reportesSeleccionado.basicReport.reporte;
      }
      if(this.reportesSeleccionado?.objReporteBienesRaices) {
        this.reporteBienesRaices = this.reportesSeleccionado?.objReporteBienesRaices;
      }
      if(this.reportesSeleccionado?.objReporteVehiculos) {
        this.reporteVehiculos = this.reportesSeleccionado.objReporteVehiculos;
      }

    }
  }

  formatterRut(rut: string): string {
    if (!rut) {
      return '';
    }
    rut = rut.replace(/\./g, ''); // Eliminar puntos
    if (!rut.includes('-')) {
      // Agregar guion antes del último dígito si no tiene guion.
      rut = rut.slice(0, -1) + '-' + rut.slice(-1);
    }
    return rut;
  }

  llenarInformacionBasicaCliente() {
    const actividadEconomica = this.informacionBasicaCliente.findIndex((e: any) => e?.text === 'Actividad económica');
    if(actividadEconomica >= 0) {
      this.informacionBasicaCliente[actividadEconomica].value = this.reportesPrincipalesObj?.basicReport?.reporte?.Riesgo?.ActividadEconomica || '-';
    }
    const tamanoEmpresa = this.informacionBasicaCliente.findIndex((e: any) => e?.text === 'Tamaño de la empresa');
    if(tamanoEmpresa >= 0) {
      this.informacionBasicaCliente[tamanoEmpresa].value = this.reportesPrincipalesObj?.basicReport?.reporte?.AntecedentesFinancieros?.TamanoEmpresaDescripcion || '-';
    }
    const fechaInicioActividades = this.informacionBasicaCliente.findIndex((e: any) => e?.text === 'Fecha inicio de actividades');
    if(fechaInicioActividades >= 0) {
      this.informacionBasicaCliente[fechaInicioActividades].value = this.reportesPrincipalesObj?.basicReport?.reporte?.InformacionBasica?.AnoInicioActividades || '-';
    }
    const direccion = this.informacionBasicaCliente.findIndex((e: any) => e?.text === 'Dirección');
    if(direccion >= 0) {
      this.informacionBasicaCliente[direccion].value = this.getDireccion() || '-';
    }
    const cantEmpleados = this.informacionBasicaCliente.findIndex((e: any) => e?.text === 'Cantidad de empleados');
    if(cantEmpleados >= 0) {
      this.informacionBasicaCliente[cantEmpleados].value = this.reportesPrincipalesObj?.basicReport?.reporte?.AntecedentesFinancieros?.NumeroEmpleados || '-';
    }
    const rangoFacturacionUF = this.informacionBasicaCliente.findIndex((e: any) => e?.text === 'Rango facturación en UF');
    if(rangoFacturacionUF >= 0) {
      this.informacionBasicaCliente[rangoFacturacionUF].value = this.reportesPrincipalesObj?.basicReport?.reporte?.AntecedentesFinancieros?.TamanoEmpresaRango || '-';
    }
    const tramo = this.informacionBasicaCliente.findIndex((e: any) => e?.text === 'Tramo');
    if(tramo >= 0) {
      this.informacionBasicaCliente[tramo].value = this.reportesPrincipalesObj?.basicReport?.reporte?.AntecedentesFinancieros?.TramoSegunVentas || '-';
    }
  }

  getTotalTGR(tesoreria: any): any {
    let respuesta: any = null;

    if(tesoreria?.deudas?.fiscales?.detalle && Object.keys(tesoreria?.deudas?.fiscales?.detalle).length > 0){
      //const totalDeudaNoVencidaLiquidada: any = Number(tesoreria?.deudas?.fiscales?.detalle?.totalDeudaNoVencidaLiquidada || 0);
      const totalDeudaLiquidadaMorosa: any = Number(tesoreria?.deudas?.fiscales?.detalle?.totalDeudaLiquidadaMorosa || 0);
      const acogidosArt196y197: any = Number(tesoreria?.deudas?.fiscales?.detalle?.acogidosArt196y197 || 0);
      respuesta = /*totalDeudaNoVencidaLiquidada + */totalDeudaLiquidadaMorosa + acogidosArt196y197;
    }

    return respuesta;
  }

  putInformacionFicha(): void {
    this.datosFichas.fechaReporte = this.formateoFecha(this.fechaTx) || '';
    this.datosFichas.razonSocial = this.reportesPrincipalesObj?.basicReport?.reporte?.InformacionBasica?.NombreORazonSocial || '';
    this.datosFichas.rutEmpresa = this.formatterRut(this.rut);
    if(this.reportesPrincipalesObj?.boletinConcursal?.reporte && 
      this.reportesPrincipalesObj?.boletinConcursal?.reporte?.boletinConcursalReporte?.principal?.encabezado && 
      Object.keys(this.reportesPrincipalesObj?.boletinConcursal?.reporte?.boletinConcursalReporte?.principal?.encabezado).length > 0) {
      this.datosFichas.boletinConcursal = 'Si';
    } else {
      this.datosFichas.boletinConcursal = 'No';
    }
    this.datosFichas.montoTotalVentaMorosaTGR = this.getTotalTGR(this.reportesPrincipalesObj?.tesoreria?.reporte?.reporteTesoreria);

    this.datosFichas.clienteDificilFiscalicion =  'No';
    if(this.reportesPrincipalesObj?.basicReport?.reporte?.ObservacionesTributarias?.Observaciones && this.reportesPrincipalesObj?.basicReport?.reporte?.ObservacionesTributarias?.Observaciones.length > 0) {
      if(this.reportesPrincipalesObj?.basicReport?.reporte?.ObservacionesTributarias?.Observaciones.findIndex((e: any) => e?.nombre === 'Contribuyente incluido en Nómina de Difícil Fiscalización (NDF)') >= 0) {
        this.datosFichas.clienteDificilFiscalicion = 'Si';
      }
    }
  }

  getRutNumber(rut: string): number {
    return Number(rut.replace(/\./g, '').split('-')[0]);
  }

  getDireccion(): any {
    if(Number(this.getRutNumber(this.rut)) < 50000000) {
      if(this.reportesPrincipalesObj?.basicReport?.reporte?.InformacionPersonal?.Contactabilidad?.Direcciones && this.reportesPrincipalesObj?.basicReport?.reporte?.InformacionPersonal?.Contactabilidad?.Direcciones.length > 0) {
        const direccionesParticulares: any[] = this.reportesPrincipalesObj?.basicReport?.reporte?.InformacionPersonal?.Contactabilidad?.Direcciones.filter((direccion: any) => direccion.TipoDireccion === 'PARTICULAR');
        if(direccionesParticulares && direccionesParticulares.length > 0) {
          const direccionPReciente = direccionesParticulares.sort((a: any, b: any) => (a.FechaDireccion < b.FechaDireccion ? 1 : -1))[0];
          if(direccionPReciente) {
            return `${direccionPReciente.CalleyNumero}${direccionPReciente.Comuna ? ', ' + direccionPReciente.Comuna : ''}${direccionPReciente.Ciudad ? ', ' + direccionPReciente.Ciudad : ''}`;
          }
        }
      }
      return this.reportesPrincipalesObj?.basicReport?.reporte?.InformacionBasica?.Direccion || '';
    } else {
      if(this.reportesPrincipalesObj?.basicReport?.reporte?.InformacionBasica?.Direccion) {
        return this.reportesPrincipalesObj?.basicReport?.reporte?.InformacionBasica?.Direccion || '';
      }
    }
    return '';
  }

  crearAntecedentesComerciales(): void {
    if(this.reportesPrincipalesObj?.basicReport?.reporte && this.reportesPrincipalesObj?.basicReport?.reporte?.InformacionBasica?.TipoReporte !== 'Reporte Basico - Sin Informe Comercial') {
      const objReporteBasico = this.reportesPrincipalesObj?.basicReport?.reporte;
      this.antecedentesComerciales.fecha = objReporteBasico?.InformacionBasica?.FechaReporte;
      this.antecedentesComerciales.protestosMonto = objReporteBasico?.AntecedentesComerciales?.Resumen?.ProtestosMonto;
      this.antecedentesComerciales.protestosCantidad = objReporteBasico?.AntecedentesComerciales?.Resumen?.ProtestoCantidad;
      this.antecedentesComerciales.morosidadesMontos = objReporteBasico?.AntecedentesComerciales?.Resumen?.MorosidadesMonto;
      this.antecedentesComerciales.morosidadesCantidad = objReporteBasico?.AntecedentesComerciales?.Resumen?.MorosidadesCantidad;
      this.antecedentesComerciales.infraccionesLaboralesMonto = objReporteBasico?.AntecedentesComerciales?.Resumen?.InfraccionesLaboralesMonto;
      this.antecedentesComerciales.infracionesLaboralesCantidad = objReporteBasico?.AntecedentesComerciales?.Resumen?.InfraccionesLaboralesCantidad;
      this.antecedentesComerciales.totalesImpagosMonto = objReporteBasico?.AntecedentesComerciales?.Resumen?.TotalImpagosMonto;
      this.antecedentesComerciales.totalesImpagosCantidad = objReporteBasico?.AntecedentesComerciales?.Resumen?.TotalImpagosCantidad;
    }
  }

  crearEvolucionDeudaCMF(): void {
    const deudaSbif = this.reportesPrincipalesObj.deudaSbif?.reporte?.Documentos?.SbifOnline?.Reporte?.rowsMeses?.item || this.reportesPrincipalesObj.deudaSbif?.reporte?.Sbif?.rowsMeses?.item || [];
    if(deudaSbif && deudaSbif.length > 0) {
      this.evolucionDeudaCMF = deudaSbif.map((e: any) => {
        return {
          castigadadirecta: e.castigadadirecta,
          castigadaindirecta: e.castigadaindirecta,
          credcontingente: e.credcontingente,
          diracreedor: [e.dirconsumo, e.diracreedor],
          dircomercial: e.dircomercial,
          dirhipotecaria: e.dirhipotecaria,
          dirinversion: e.dirinversion,
          dirmorosa: e.dirmorosa,
          diroperacionpacto: e.diroperacionpacto,
          dirven180mayor3year: e.dirven180mayor3year,
          dirvencida: e.dirvencida,
          dirvigente: e.dirvigente,
          indirectaven: e.indirectaven,
          indirectavig: e.indirectavig,
          leasingmor: e.leasingmor,
          leasingvig: e.leasingvig,
          lineacredito: e.lineacredito,
          monexvencida: e.monexvencida,
          monexvigente: e.monexvigente,
          periodo: this.formatDate(e.periodo)
        }
      }).sort((a: any,b: any)=> new Date(b.periodo).getTime() - new Date(a.periodo).getTime()) || [];
    }
  }

  formatDate(date: string): string {
    return date.split('-').reverse().join('-');
  }

  putVentasInformacionFicha(listaF29: any[]): void {
    const listaF29Ord: any[] = [...listaF29];
    
    listaF29Ord.sort((a: any,b: any) => { // mayor a menor en año y en mes
      if (!a?.calculado) return 1;
      if (!b?.calculado) return -1;
      const periodoDiff = Number(b?.calculado?.periodo) - Number(a?.calculado?.periodo);
      if (periodoDiff !== 0) return periodoDiff;
      return Number(b?.calculado?.mes) - Number(a?.calculado?.mes);
    });

    let periodo: number = 0;
    let mes: number = 0;
    
    if(listaF29Ord.length > 0 && listaF29Ord[0] && Object.keys(listaF29Ord[0]).length > 0){
      periodo = Number(listaF29Ord[0]?.calculado?.periodo) || 0;
      mes = Number(listaF29Ord[0]?.calculado?.mes) || 0;
    }

    let contador: number = 0;
    let sumaVentas: number = 0;
    let sumaCompras: number = 0;

    for (let i = 0; i < 12; i++) {
      if(contador < 12) {
        let periodoEntrada = periodo;
        let mesEntrada = mes - i;
        
        if (mesEntrada <= 0) {
          mesEntrada += 12;
          periodoEntrada--;
        }

        const elemento = listaF29Ord.find((obj: any) => obj?.calculado?.periodo == periodoEntrada && obj?.calculado?.mes == mesEntrada);
        if(elemento){
          sumaVentas = sumaVentas + (Number(elemento?.calculado?.ventasNetasMilesPesos) || 0);
          sumaCompras = sumaCompras + (Number(elemento?.calculado?.comprasNetasMilesPesos) || 0);
        }
        
      }

    }

    let promVentas: number = 0;
    if(sumaVentas != 0){
      promVentas = sumaVentas / 12;
    }

    let comprasVentas: number = 0;
    if(sumaVentas != 0){
      comprasVentas = (Number(sumaCompras) / Number(sumaVentas)) * 100;
    }

    this.datosFichas.promVentasEvolucionCompraVenta = promVentas;
    this.datosFichas.porcCompraSobreVentas = comprasVentas;
  }

  obtenerMesAbreviado(numeroMes: number, anio: number): string {
    const meses = ["ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep", "oct", "nov", "dic"];
    const anioAbreviado = anio.toString().slice(-2);
    return meses[numeroMes - 1] + '-' + anioAbreviado;
  }

  crearTablaIvaSobreVentas(anioActual: number, anioAnterior: number, listaF29: any[]): any {
    let respuesta: any = {};
    const listaF29Ord: any[] = [...listaF29];

    listaF29Ord.sort((a: any,b: any) => { // mayor a menor en año y en mes
      if (!a?.calculado) return 1;
      if (!b?.calculado) return -1;
      const periodoDiff = Number(b?.calculado?.periodo) - Number(a?.calculado?.periodo);
      if (periodoDiff !== 0) return periodoDiff;
      return Number(b?.calculado?.mes) - Number(a?.calculado?.mes);
    });

    if(anioActual || anioAnterior){
      const ultimoElemento = listaF29Ord[0];

      respuesta = {
        afectasMeses12: 0,
        meses12: 0,
        afectasMeses6: 0,
        meses6: 0,
        listaMeses: [],
        ivaMeses12: 0,
        ivaMeses6: 0,

        sumaNotas12: 0,
        sumaNotas6: 0,
        sumaVentas12: 0,
        sumaVentas6: 0,
        
        notasMeses12: 0,
        notasMeses6: 0
      }
      
      let contador: number = 0;
      if(ultimoElemento){
        for (let i = 0; i < 12; i++) {
          if(contador < 12) {
            let periodo = ultimoElemento?.calculado?.periodo || 0;
            let mes = ultimoElemento?.calculado?.mes - i || 0;
            
            if (mes <= 0) {
              mes += 12;
              periodo--;
            }
  
            const objeto: any = {
              mes: this.obtenerMesAbreviado(mes, periodo),
              valueIva: 0,
              valueNotas: 0
            };
  
            const elemento = listaF29Ord.find((obj: any) => obj?.calculado?.periodo == periodo && obj?.calculado?.mes == mes);
            if(elemento){
              const elementoF29 = elemento;
  
              let valueCodigos: number = 0;
              let codigo89: number = 0;
              let codigo755: number = 0;
              if(elementoF29?.codigos?.['89']) {
                codigo89 = Number(elementoF29?.codigos?.['89']);
              } 
              if (elementoF29?.codigos?.['755']) {
                codigo755 = Number(elementoF29?.codigos?.['755']);
              }
  
              valueCodigos = ((Number(codigo89) + Number(codigo755)) / 1000);
  
              const ventasNetasAfectas = Number(elementoF29?.calculado?.ventasNetasMilesPesos);
             
              const valorNotas = elementoF29?.calculado?.notasCredito || 0;
              const valorVentas = elementoF29?.calculado?.ventasNetas || 0;
  
              if(contador < 6){
                objeto.mes = this.obtenerMesAbreviado(mes, periodo);
                objeto.valueIva = (Number(valueCodigos) / Number(ventasNetasAfectas)) * 100;
                objeto.valueNotas = elementoF29?.calculado?.porc_notascred_ventas || 0;
  
                respuesta.afectasMeses6 = Number(respuesta.afectasMeses6) + Number(ventasNetasAfectas);
                respuesta.meses6 = Number(respuesta.meses6) + Number(valueCodigos);
                respuesta.sumaNotas6 = Number(respuesta.sumaNotas6) + Number(valorNotas);
                respuesta.sumaVentas6 = Number(respuesta.sumaVentas6) + Number(valorVentas);
              }
              respuesta.afectasMeses12 = Number(respuesta.afectasMeses12) + Number(ventasNetasAfectas);
              respuesta.meses12 = Number(respuesta.meses12) + Number(valueCodigos);
              respuesta.sumaNotas12 = Number(respuesta.sumaNotas12) + Number(valorNotas);
              respuesta.sumaVentas12 = Number(respuesta.sumaVentas12) + Number(valorVentas);
            }
  
            if(contador < 6){
              respuesta.listaMeses.push(objeto);
              contador++;
            }
          }
        }
  
        respuesta.ivaMeses12 = (Number(respuesta.meses12) / Number(respuesta.afectasMeses12)) * 100;
        respuesta.ivaMeses6 = (Number(respuesta.meses6) / Number(respuesta.afectasMeses6)) * 100;
        respuesta.notasMeses12 = (Number(respuesta.sumaNotas12) / Number(respuesta.sumaVentas12) * 100); // validar * 100
        respuesta.notasMeses6 = (Number(respuesta.sumaNotas6) / Number(respuesta.sumaVentas6) * 100); // validar * 100
      }
    }

    return respuesta;
  }

  crearTablaSocios(entidadSocios: any[], antecedentesSocietarios: any[], entidad: string, reportesDerivadosObj: any): any[] {
    const tablaSocios: any[] = [];

    if(entidadSocios && entidadSocios.length > 0 && reportesDerivadosObj[entidad]) {
      entidadSocios.forEach(element => {
        if(reportesDerivadosObj?.[entidad]?.[element?.rut] && Object.keys(reportesDerivadosObj?.[entidad]?.[element?.rut]).length > 0) {
          const objeto: any = {
            rut: element?.rut || '',
            fechaVinculacion: '',
            nombre: '',
            entidad: entidad || '',
            participacion: '',
            montoMoroso: ''
          };
  
          const indexAntecedentes = antecedentesSocietarios.findIndex(e => e.rut === element?.rut);
          if(indexAntecedentes >= 0){
            objeto.fechaVinculacion = antecedentesSocietarios[indexAntecedentes]?.fechaVinculacion || '';
          }
  
          if(element?.atributos && element?.atributos.length > 0) {
            const indexAtributo = element?.atributos.findIndex((e: any) => e.name === 'nombre');
            if(indexAtributo >= 0){
              objeto.nombre = element?.atributos[indexAtributo]?.value || '';
            }
          }
  
          const reportes: any = this.reportesDerivadosObj?.[entidad]?.[element?.rut];
          if(reportes && reportes?.deudaSbif?.reporte?.Sbif?.rowsMeses?.item && reportes?.deudaSbif?.reporte?.Sbif?.rowsMeses?.item.length > 0) {
            const mesesDeuda: any[] = reportes?.deudaSbif?.reporte?.Sbif?.rowsMeses?.item || [];
            mesesDeuda.sort(function (a, b) {
              const [mesA, añoA] = a?.periodo?.split('-').map(Number);
              const [mesB, añoB] = b?.periodo?.split('-').map(Number);
            
              const dateA: any = new Date(añoA, mesA - 1);
              const dateB: any = new Date(añoB, mesB - 1);
            
              // Verifica si las fechas son válidas
              const isValidDateA = !isNaN(dateA.getTime());
              const isValidDateB = !isNaN(dateB.getTime());
            
              // Si dateA no es válida, colócala al final
              if (!isValidDateA && isValidDateB) return 1;
              // Si dateB no es válida, colócala al final
              if (isValidDateA && !isValidDateB) return -1;
              // Si ambas fechas no son válidas, mantén el orden original
              if (!isValidDateA && !isValidDateB) return 0;
            
              // Orden descendente
              return dateB - dateA;
            });
            const deudaReciente: any = mesesDeuda[0];
  
            let morosidad: any = 0;
            if(deudaReciente){
              morosidad = Number(deudaReciente?.dirvencida) + Number(deudaReciente?.dirven180mayor3year) + Number(deudaReciente?.dirmorosa);
  
            }
            else{
              morosidad = 0;
            }
            objeto.montoMoroso = morosidad;
          }
  
          tablaSocios.push(objeto);
        }
      })
    }

    return tablaSocios;
  }

  crearTablaBalance(anioActual: number, anioAnterior: number, listaF22: any[]): any {
    const tablaBalance: any = {};

    listaF22.forEach(e => {
      if(anioAnterior){
        if(e?.calculado?.periodo == anioAnterior){
          tablaBalance.anioAnterior = {
            anio: anioAnterior,
            ventas: Number(e?.calculado?.ingresosdelaExplotacion) !== 0 ? (Number(e?.calculado?.ingresosdelaExplotacion) / 1000) : (e?.calculado?.ingresosdelaExplotacion),
            utilidad: Number(e?.calculado?.utilidadneta) !== 0 ? (Number(e?.calculado?.utilidadneta) / 1000) : (e?.calculado?.utilidadneta),
            patrimonio: Number(e?.calculado?.totalpatrimonio) !== 0 ? (Number(e?.calculado?.totalpatrimonio) / 1000) : (e?.calculado?.totalpatrimonio),
            actFijo: Number(e?.calculado?.totalactivosfijos) !== 0 ? (Number(e?.calculado?.totalactivosfijos) / 1000) : (e?.calculado?.totalactivosfijos),
          }
        }
      }
      if(anioActual){
        if(e?.calculado?.periodo == anioActual){
          tablaBalance.anioActual = {
            anio: anioActual,
            ventas: Number(e?.calculado?.ingresosdelaExplotacion) !== 0 ? (Number(e?.calculado?.ingresosdelaExplotacion) / 1000) : (e?.calculado?.ingresosdelaExplotacion),
            utilidad: Number(e?.calculado?.utilidadneta) !== 0 ? (Number(e?.calculado?.utilidadneta) / 1000) : (e?.calculado?.utilidadneta),
            patrimonio: Number(e?.calculado?.totalpatrimonio) !== 0 ? (Number(e?.calculado?.totalpatrimonio) / 1000) : (e?.calculado?.totalpatrimonio),
            actFijo: Number(e?.calculado?.totalactivosfijos) !== 0 ? (Number(e?.calculado?.totalactivosfijos) / 1000) : (e?.calculado?.totalactivosfijos),
          }
        }
      }
    });

    return tablaBalance;
  }

  validarValor(valor: any): boolean {
    if(valor || valor == 0){
      return true;
    }
    return false;
  }

  mapeoSbif(): void {
    if(this.reportesSeleccionado?.deudaSbif?.reporte){
      /*if(this.reportesSeleccionado?.deudaSbif?.reporte?.DeudaSistemaFinancieroReporte?.Documentos?.SbifOnline?.Reporte?.rowsMeses?.item){

      } else */
      if (this.reportesSeleccionado?.deudaSbif?.reporte?.Sbif?.rowsMeses?.item) {
        this.reportesSeleccionado.deudaSbif.reporte = {
          Documentos: {
            SbifOnline: {
              Reporte: {
                rowsMeses: this.reportesSeleccionado?.deudaSbif?.reporte?.Sbif?.rowsMeses
              }
            }
          }
        }
      }
    }

  }

  mapeoPoderJudicial(): void{
    if(this.reportesSeleccionado?.poderJudicial?.reporte){
      const datos = this.reportesSeleccionado?.poderJudicial?.reporte["Totalizadores Poder Judicial"];
      const reporte = {
        poderJudicialReporte: {
          poderJudicial: {
            Totales: datos
          }
        }
      };
      this.reportesSeleccionado.poderJudicial.reporteMapeado = reporte;
    }
    if(this.reportesSeleccionado?.poderJudicial?.reporte){
      const datosCivil = this.reportesSeleccionado?.poderJudicial?.reporte?.["Causas Civiles"] || [];
      const datosCobranza = this.reportesSeleccionado?.poderJudicial?.reporte?.["Causas Cobranzas"] || [];
      const datosLaboral = this.reportesSeleccionado?.poderJudicial?.reporte?.["Causas Laborales"] || [];
      const reporte = {
        poderJudicialReporte: {
          poderJudicial: {
            Civil: datosCivil,
            Cobranza: datosCobranza,
            Laboral: datosLaboral
          }
        }
      };

      this.reportesSeleccionado.poderJudicial.ReporteFull = reporte;
    }
  }

  mapeoReporteTitular(): void {
    this.reportesSeleccionado = {
      deudaSbif: this.reportesPrincipalesObj?.deudaSbif,
      poderJudicial: this.reportesPrincipalesObj?.poderJudicial,
      boletinConcursal: this.reportesPrincipalesObj?.boletinConcursal,
      objReporteVehiculos: this.reportesPrincipalesObj?.vehiculos?.reporte,
      objReporteBienesRaices: this.reportesPrincipalesObj?.bienesRaices?.reporte,
      cteVentasBalancesRentas: this.reportesPrincipalesObj?.cteVentasBalancesRentas
    } 

  }

  validaObjetos(): void{
    if(this.reportesSeleccionado?.cteVentasBalancesRentas?.reporte && Object.keys(this.reportesSeleccionado?.cteVentasBalancesRentas?.reporte).length === 0){
      this.reportesSeleccionado.cteVentasBalancesRentas.reporte = null;
    }
    if(this.reportesSeleccionado?.basicReport?.reporte && Object.keys(this.reportesSeleccionado?.basicReport?.reporte).length === 0){
      this.reportesSeleccionado.basicReport.reporte = null;
    }
    if(this.reportesSeleccionado?.deudaSbif?.reporte && Object.keys(this.reportesSeleccionado?.deudaSbif?.reporte).length === 0){
      this.reportesSeleccionado.deudaSbif.reporte = null;
    }
    if(this.reportesSeleccionado?.boletinConcursal?.reporte && Object.keys(this.reportesSeleccionado?.boletinConcursal?.reporte).length === 0){
      this.reportesSeleccionado.boletinConcursal.reporte = null;
    }
    if(this.reportesSeleccionado?.reporteEmpresaDia?.reporte && Object.keys(this.reportesSeleccionado?.reporteEmpresaDia?.reporte).length === 0){
      this.reportesSeleccionado.reporteEmpresaDia.reporte = null;
    }
  }

  mapeoVehiculos(): void{
    if(this.reportesSeleccionado?.vehiculos?.reporte){
      this.reportesSeleccionado.objReporteVehiculos = this.reportesSeleccionado?.vehiculos?.reporte;
    }
  }

  mapeoBienesRaices(): void{
    if(this.reportesSeleccionado?.bienesRaices?.reporte){
      this.reportesSeleccionado.objReporteBienesRaices = this.reportesSeleccionado?.bienesRaices?.reporte;
    }
  }

  obtenerSocios(): any[] {
    if(this.reportesPrincipalesObj?.cte?.reporte?.fechaReporte && this.reportesPrincipalesObj?.cteVentasBalancesRentas?.reporte?.fechaReporte) {
      if(this.reportesPrincipalesObj?.cte?.reporte?.fechaReporte >= this.reportesPrincipalesObj?.cteVentasBalancesRentas?.reporte?.fechaReporte) {
        return this.reportesPrincipalesObj?.cte?.reporte?.CarpetaTributariaElectronicaReporte?.Reporte?.socios || [];
      } else {
        return this.reportesPrincipalesObj?.cteVentasBalancesRentas?.reporte?.CarpetaTributariaElectronicaReporte?.Reporte?.socios || [];
      }
    } else {
      if(this.reportesPrincipalesObj?.cte?.reporte?.fechaReporte) {
        return this.reportesPrincipalesObj?.cte?.reporte?.CarpetaTributariaElectronicaReporte?.Reporte?.socios || [];
      } else if (this.reportesPrincipalesObj?.cteVentasBalancesRentas?.reporte?.fechaReporte) {
        return this.reportesPrincipalesObj?.cteVentasBalancesRentas?.reporte?.CarpetaTributariaElectronicaReporte?.Reporte?.socios || [];
      }
    }
    return [];
  }

  mapeoBalance(): void {
    const datosF22: any[] = this.obtenerDatosF22();
    const datosF29: any[] = this.obtenerDatosF29();
    if(datosF22.length > 0 || datosF29.length > 0){
      const respuesta: any = {CarpetaTributariaElectronicaReporte: {
        DatosBasicosSolicitud: {Rut: this.rut.slice(0, -1), DV: this.rut.slice(-1)},
        DatosSolicitante: {},
        Reporte: {
          f22: datosF22 || [],
          f29: datosF29 || [],
          socios: this.obtenerSocios || [],
          representantes: []
        }
      }};

      this.reportesSeleccionado.cteVentasBalancesRentas = {reporte: respuesta};
    }
  }

  abrirModalTitular(): void{
    if(this.reportesPrincipalesObj){
      this.mapeoReporteTitular();
      this.mapeoBalance();
      this.mapeoPoderJudicial();
      this.mapeoSbif();
      this.validaObjetos();
    }

    this.reportesSeleccionado.consolidadoTitular = this.consolidadoTitular;
  
    this.rutSeleccionado = this.rut;
    this.plantillaAntecedentes = 'cesceDemo';
    this.modalDefault = false;

    this.modalService.open();
  }

  // Funcion para abrir modal con datos basicos en tabla de socios

  abrirModal(entidad: string, rut: string): void{
    if(rut && entidad){
      if(this.reportesDerivadosObj[entidad]?.[rut]){
        this.reportesSeleccionado = this.reportesDerivadosObj[entidad][rut];
        this.rutSeleccionado = rut;
        this.informeComercial = this.utilsService.identificarInformeComercial(this.reportesSeleccionado?.basicReport?.reporte);
        this.mapeoPoderJudicial();
        this.mapeoVehiculos();
        this.mapeoBienesRaices();
        this.mapeoSbif();
        this.validaObjetos();
        this.plantillaAntecedentes = '';
        this.modalDefault = true;
  
        this.modalService.open();
      }
    }
    
  }

  abrirModalCompraVenta(): void {
    const url = this.router.createUrlTree([`compra-venta/buscar`], { queryParams: {rut: this.rut} }).toString();
    window.open(url, '_blank');
  }

  abrirModalCompraVentaCesion(): void {
    const url = this.router.createUrlTree([`compra-venta/buscar`], { queryParams: {rut: this.rut, seccion: 'cesion'} }).toString();
    window.open(url, '_blank');
  }

  show(el: string, buttonId?: string) {
    const elementHTML = document.getElementById(el) as HTMLElement;
    elementHTML.classList.toggle('container-desplegables');
    if(buttonId){
      const button = document.getElementById(buttonId) as HTMLElement;
      if ( !elementHTML.classList.contains('container-desplegables') ){
        button.classList.add('button-selected');
        button.classList.remove('border');
        button.classList.remove('border-buttons');
        button.classList.add('border-0'); 
      } else {
        button.classList.remove('button-selected');
        button.classList.add('border');
        button.classList.add('border-buttons');
        button.classList.remove('border-0'); 
      }
    }
  }

  descargarPDF(): void {
    // se abren todos los acordeones
    const element = document.getElementById('printButton');
    if(this.accordion?.groups && this.accordion?.groups.length > 0){
      for (const group of this.accordion.groups){
        group.isOpen = true;
      }
    }
    setTimeout(() => {
      if(element){
        element.click();
      } else {
        console.error('Elemento no encontrado');
      }
    }, 500);
  }

  showF29(): void {
    if(this.f29Tablas && this.f29Tablas.length > 0){
      const dialogRef = this.dialog.open(ModalF29Component, {
        data: {
          title: "Reporte F29",
          f29Tablas: this.f29Tablas
        }
      });
    
      dialogRef.afterClosed().subscribe(resp => { 
      });
    }
  }

  validaUsuarioAdmin(): boolean {
    return this._sessionService.getUserAccess().includes(this.access[3]);
  }

  volverEtapaPrevia(): void {
    this.bloqueaPasos5.emit(false);
    this.solicitudCreditoService.setStep(Number(this.stepper - 1));
    this.spinner.hide();
  }

  validarNumber(value: any): boolean {
    return !isNaN(Number(value)) && value !== '' && value !== null;
  }

  guardarFicha(): void {
    if(this.tablaSocios && this.tablaSocios.length > 0) {
      for(let i = 0; i < this.tablaSocios.length; i++){
        const elementoHTML = document.getElementById('socio-participacion'+i);
        if(elementoHTML){
          elementoHTML.className = elementoHTML.className.replace(" error-input", "");
          elementoHTML.title = '';
        }
      }
      const index = this.tablaSocios.findIndex((e: any) => e.participacion == undefined || e.participacion == null || e.participacion == '' || this.validarNumber(e.participacion) == false); // validar*
      if(index >= 0) {
        const elementoHTML = document.getElementById('socio-participacion'+index);
        if(elementoHTML){
          elementoHTML.className = elementoHTML.className + " error-input";
          elementoHTML.title = 'Completar dato';
        }
        this.alertService.error('Solicitud de Crédito - Debe completar la participación de los socios');
        return;
      }
    }

    let datosGrafico: any = null;
    if(this.evolucionGraphDicom && Object.keys(this.evolucionGraphDicom).length > 0) {
      datosGrafico = this.utilsService.ifReporteBasico(this.reportesPrincipalesObj?.basicReport?.reporte);
    }

    let datosGraficoCV: any = null;
    if(this.graphEvolutivoCV && Object.keys(this.graphEvolutivoCV).length > 0) {
      datosGraficoCV = this.crearGraficoEvolutivoCompraVenta();
    }

    const ficha: any = {
      ficha: {
        consolidadoTitular: this.consolidadoTitular,
        datosFichas: this.datosFichas,
        informacionBasicaCliente: this.informacionBasicaCliente,
        tablaSocios: this.tablaSocios,
        tablaBalance: this.tablaBalance,
        tablaIvaSobreVentas: this.tablaIvaSobreVentas,
        dataEvolucionGraphDicom: datosGrafico?.graph || null,
        antecedentesComerciales: this.antecedentesComerciales,
        evolucionDeudaCMF: this.evolucionDeudaCMF,
        factoring: this.factoring,
        deudaFiscal: this.deudaFiscal,
        convenioVigentes: this.convenioVigentes,
        detalleDocumentos: this.detalleDocumentos,
        statusIva12Meses: this.statusIva12Meses,
        dataGraphEvolutivoCV: datosGraficoCV || null,
        f29Tablas: this.f29Tablas
      }
    };    
    this.spinner.show();
    this.subscriptions.push(this.solicitudCreditoService.guardarFichaDeCredito(this.rut, this.idTransaccion, ficha).subscribe(resp => {
      this.fichaGuardada = ficha;
      this.alertService.success(resp.message)
      this.spinner.hide();
    }, (error: any) => {
      this.alertService.error(error?.error?.message || 'Solicitud de Crédito - Ocurrio un error con el servicio de guardado de la ficha');
      this.spinner.hide();
    }));
  }

  avanzarFlujo(): void{
    this.spinner.show();
    this.subscriptions.push(this.solicitudCreditoService.obtenerFlujo(this.idTransaccion, this.rut).subscribe(resp => {
      this.bodyModelos = resp;
      if(this.bodyModelos?.stages[8]?.optional === false){
        if(this.bodyModelos?.stages[8]?.completed){

          this.subscriptions.push(this.solicitudCreditoService.calcularScore(this.idTransaccion, this.rut).subscribe(resp => {
            if(resp?.MotorDeCalculos?.errors?.hassError === false){
              if(resp?.DatosBasicosSolicitud?.Automatico === true){

                this.bloqueaPasos6.emit(true);
                this.solicitudCreditoService.setStep(6);
                this.spinner.hide();

              } else {
                
                this.solicitudCreditoService.setStep('next');
                // bloquea pasos 5
                if(!this.validaUsuarioAdmin()){
                  this.bloqueaPasos5.emit(true);
                }
                this.spinner.hide();
              }
            } else {
              this.alertService.error(resp?.MotorDeCalculos?.details?.message || 'Solicitud de Crédito - Ocurrio un error al recuperar los datos del motor de calculos');
              this.spinner.hide();
            }
          }, (error: any) => {
            this.alertService.error(error?.error?.message || 'Solicitud de Crédito - Ocurrio un error al consultar servicio del motor de calculos');
            this.spinner.hide();
          }));

        } else {
          this.spinner.hide();
          this.alertService.error('Solicitud de Crédito - Debe completar la etapa actual para poder avanzar');
        }
      } else {
        this.solicitudCreditoService.setStep('next');
        this.spinner.hide();
      }
    }, (error: any) => {
      this.alertService.error(error?.error?.message || 'Solicitud de Crédito - Ocurrio un error al recuperar los datos del flujo de credito');
      this.spinner.hide();
    }));
  }

}
