import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { IBoletinConcursalCreateReportResponse } from 'src/app/interface/IBoletinConcursalCreateReportResponse';
import { IEmpresaEnUnDiaCreateReportResponse } from 'src/app/interface/IEmpresaEnUnDiaCreateReportResponse';
import { IPoderJudicialGetReportReponse } from 'src/app/interface/IPoderJudicialGetReportReponse';
import { IReportBienesRaices } from 'src/app/models/bienes-raices/bienes-raices.model';
import { IReporteBasico } from 'src/app/models/reporte-buscar/reporte-buscar.model';
import { ICarpetaTributariaElectronicaReporteModel } from 'src/app/models/reporte-full/reporte-full.model';
import { IReporteVehiculos } from 'src/app/models/vehiculos/vehiculos.model';
import { IReporteConsolidado } from 'src/app/pages/reporte-consolidado/reporte-consolidado.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { ReporteService } from 'src/app/shared/services/reporte.service';
import { ReporteJudicialService } from 'src/app/shared/services/reporte-judicial.service';
import { BoletinConcursalService } from 'src/app/shared/services/boletin-concursal.service';
import { IItem, SbifLeasingService } from 'src/app/shared/services/sbif-leasing.service';
import { ReporteFullService } from 'src/app/shared/services/reporte-full.service';
import { BienesRaicesService } from 'src/app/shared/services/bienes-raices.service';
import { VehiculosService } from 'src/app/shared/services/vehiculos.service';
import { EmpresaEnUnDiaService } from 'src/app/shared/services/empresa-en-un-dia.service';
import { AlertService } from '../../_alert';
import { Chart } from 'angular-highcharts';
import { balanceReporteFullResumenFinanciero } from 'src/app/models/utils/utils.model';
import { F22, Totales } from 'src/app/models/solicitud-credito/solicitud-credito-request.model';
import { ChartType } from 'angular-google-charts';
import { Subscription } from 'rxjs';

interface IChart {
  title: string;
  type: ChartType;
  data: any[][];
  columns: string[];
  options: any[];
  width: number;
  height: number;
}

@Component({
  selector: 'app-antecedentes-consolidado',
  templateUrl: './antecedentes-consolidado.component.html',
  styleUrls: ['./antecedentes-consolidado.component.scss', '../../reporte/reporte-viewer/reporte-viewer.component.scss']
})

export class AntecedentesConsolidadoComponent implements OnInit, AfterViewInit, OnDestroy {

  @Output() actualizarOtherReports = new EventEmitter<boolean>();

  @Input() rut: string = '';
  @Input() informeComercial: string = '';
  // Variable para el reporte financiero
  @Input() objReporteFinanciero: ICarpetaTributariaElectronicaReporteModel | null = null;
  // Variable para el reporte deuda sbif
  @Input() objReporteDeudaSbif: any | null = null;
  // Variable para el reporte basico
  @Input() objReporteBasico: IReporteBasico | null = null;
  // Variable para el reporte judicial
  @Input() objReporteJudicial: IPoderJudicialGetReportReponse | null = null;
  // Variable para el reporte boletin concursal
  @Input() objReporteBoletinConcursal: IBoletinConcursalCreateReportResponse | null = null;
  // Variable para el reporte empresa en un dia
  @Input() objReporteEmpresaDia: IEmpresaEnUnDiaCreateReportResponse | null = null;
  @Input() updateReportes: boolean = true;
  public chart: IChart;
  public reporteConsolidado: IReporteConsolidado[] | any = [];
  public mallaSocietaria: string = '';
  public totalJudicial: number = 0;
  // Variable para el resumen deuda sbif
  public objResumenDeudaSbif: any = {};
  // Variable para el reporte boletin concursal

  // Variable para el reporte bienes raices
  public objReporteBienesRaices: IReportBienesRaices | null = null;

  // Variable para el reporte vehiculos
  public objReporteVehiculos: IReporteVehiculos | null = null;

  public sinInformeText: string = 'Reporte Basico - Sin Informe Comercial';
  public showInformacionBoletinComercialLaboral: boolean = false;
  public showDetalleMorosidadesYProtestos: boolean = false;
  public showInformacionSectorialyDeMercado: boolean = false;
  public showConsultaRut: boolean = false;
  public periodoDeuda: string = '';
  public graficaSinInformacion: boolean = false;
  public balanceReporteFullResumenFinanciero: typeof balanceReporteFullResumenFinanciero | any = balanceReporteFullResumenFinanciero;
  public informacionBalance: any;
  public headerResumenFinanciero: string[] = [
    'periodo',
    'ingresosdelaExplotacion',
    'utilidadneta',
    'utilidadVentas',
    'razonCorriente',
    'permanenciaCtasporCobrar',
    'permanenciadeInventarios',
    'totalactivos',
    'totalactivosfijos',
    'totalpasivoexigible',
    'totalpatrimonio',
    'leverage',
    'ebitda',
    'ebitdagf'
  ];

  public objectKeys = Object.keys;
  public riesgoIndustriaCalculado = "";
  public riesgoIndustriaCalculadoValue = 0;
  public graph: any = {};
  private subscriptions: Subscription[] = [];

  public isPersonaNatural: boolean = false;
  public telefonos: any[] = [];
  public direccion: string = '';
  public reporteConInformeComercial: boolean = false;

  constructor(
    private reporteService: ReporteService,
    private alertService: AlertService,
    private reporteFullService: ReporteFullService
  ) {
    this.chart = {
      title: "Ventas expresadas en millones de UF",
      type: ChartType.ColumnChart,
      data: [],
      columns: ['Topping', 'Ventas'],
      options: [],
      width: 490,
      height: 250
    }
    if(this.updateReportes){
      this.subscriptions.push(this.reporteFullService.reporteBasicoUpdateObs$.subscribe((resp) => {
        this.updateReports(resp.report, resp.obj);
        this.getReportes();      
      }));
    }
  }

  ngOnInit(): void {
    this.getReportes();
  }

  getReportes(): void {
    this.getReporteBasico();
    this.calcularMallaSocietaria();
    if (this.objReporteFinanciero) {
      this.setInformacionBalance();
    }
    if (this.objReporteDeudaSbif) {
      this.obtenerLastDeudaSbif();
    }
    if (this.objReporteJudicial) {
      this.getTotalJudicial();
    }
  }

  getReporteBasico(): void {
    this.ifReporteBasico();
  }


  ifReporteBasico(): void {
    this.setRiesgoIndustrialCalculado();
    this.setChartData();

    if (this.objReporteBasico?.Riesgo?.ClasificacionHistorica && this.objReporteBasico?.Riesgo?.ClasificacionHistorica.length > 0) {
      this.setGrafico();
    }

    if (
      this.objReporteBasico?.InformacionSectorialyDeMercado
      && this.objReporteBasico?.InformacionSectorialyDeMercado.TotalVentasHistoricoUF
      && this.objReporteBasico?.InformacionSectorialyDeMercado.TotalVentasHistoricoUF.ano1) {
      this.showInformacionSectorialyDeMercado = true;
    }

    if(this.objReporteBasico?.InformacionBasica?.TipoReporte !== this.sinInformeText) {
      this.reporteConInformeComercial = true;
    } else {
      this.reporteConInformeComercial = false;
    }

    if(this.objReporteBasico?.InformacionBasica?.Rut) {
      let rut = this.objReporteBasico?.InformacionBasica?.Rut || '';
      if(rut.includes('.')) {
        rut = rut.replace(/\./g, '');
      }
      if(rut.includes('-')) {
        rut = rut.split('-')[0];
      }
      this.isPersonaNatural = Number(rut) > 50000000 ? false : true;
      this.getTelefonos();
      this.getDireccion();
    }

  }

  public getDireccion(): void {
    if(this.isPersonaNatural) {
      if(this.objReporteBasico?.InformacionPersonal?.Contactabilidad?.Direcciones && this.objReporteBasico?.InformacionPersonal?.Contactabilidad?.Direcciones.length > 0) {
        const direccionesParticulares: any[] = this.objReporteBasico?.InformacionPersonal?.Contactabilidad?.Direcciones.filter((direccion: any) => direccion.TipoDireccion === 'PARTICULAR');
        if(direccionesParticulares && direccionesParticulares.length > 0) {
          const direccionPReciente = direccionesParticulares.sort((a: any, b: any) => (a.FechaDireccion < b.FechaDireccion ? 1 : -1))[0];
          if(direccionPReciente) {
            this.direccion = `${direccionPReciente.CalleyNumero}${direccionPReciente.Comuna ? ', ' + direccionPReciente.Comuna : ''}${direccionPReciente.Ciudad ? ', ' + direccionPReciente.Ciudad : ''}`;
            return;
          }
        }
      }
      this.direccion = this.objReporteBasico?.InformacionBasica?.Direccion || '';
    } else {
      if(this.objReporteBasico?.InformacionBasica?.Direccion) {
        this.direccion = this.objReporteBasico?.InformacionBasica?.Direccion || '';
      }
    }
  }

  public getTelefonos(): void {
    if(this.isPersonaNatural) {
      if(this.objReporteBasico?.InformacionPersonal?.Contactabilidad?.Telefonos && this.objReporteBasico?.InformacionPersonal?.Contactabilidad?.Telefonos.length > 0) {
        if(this.objReporteBasico?.InformacionPersonal?.Contactabilidad?.Telefonos.length <= 2) {
          this.telefonos = this.objReporteBasico?.InformacionPersonal?.Contactabilidad?.Telefonos || [];
        } else {
          this.telefonos = this.objReporteBasico?.InformacionPersonal?.Contactabilidad?.Telefonos
          .sort((a: any, b: any) => (new Date(a.FechaTelefono).getTime() < new Date(b.FechaTelefono).getTime() ? 1 : -1)).slice(0, 2);
        }
      }
    } else {
      if(this.objReporteBasico?.ContactabilidadComercial?.Telefonos && this.objReporteBasico?.ContactabilidadComercial?.Telefonos.length > 0) {
        if(this.objReporteBasico?.ContactabilidadComercial?.Telefonos.length <= 2) {
          this.telefonos = this.objReporteBasico?.ContactabilidadComercial?.Telefonos || [];
        } else {
          this.telefonos = this.objReporteBasico?.ContactabilidadComercial?.Telefonos
          .sort((a: any, b: any) => (new Date(a.FechaTelefono).getTime() < new Date(b.FechaTelefono).getTime() ? 1 : -1)).slice(0, 2);
        }
      }
    }
  }

  public setRiesgoIndustrialCalculado(): void {
    if (!this.objReporteBasico?.Riesgo) {
      this.riesgoIndustriaCalculado = "";
      this.riesgoIndustriaCalculadoValue = 100;
      return;
    }

    switch (this.objReporteBasico?.Riesgo.RiesgoIndustria) {
      case "Alto":
        this.riesgoIndustriaCalculado = "Alto";
        this.riesgoIndustriaCalculadoValue = 10;
        break;
      case "Medio":
        this.riesgoIndustriaCalculado = "Medio";
        this.riesgoIndustriaCalculadoValue = 30;
        break;
      case "Medio Alto":
        this.riesgoIndustriaCalculado = "Medio Alto";
        this.riesgoIndustriaCalculadoValue = 50;
        break;
      case "Medio Bajo":
        this.riesgoIndustriaCalculado = "Medio Bajo";
        this.riesgoIndustriaCalculadoValue = 70;
        break;
      case "Bajo":
        this.riesgoIndustriaCalculado = "Bajo";
        this.riesgoIndustriaCalculadoValue = 90;
        break;
      default:
        this.riesgoIndustriaCalculado = "";
        this.riesgoIndustriaCalculadoValue = 100;
    }
  }

  setClasificacionPosition(value: string): number {
    if (!value) return 0;
    return Math.round((Number(value) * 100) / 1000);
  }

  setGrafico(): void {
    const objTemp = this.objReporteBasico?.Riesgo?.ClasificacionHistorica
      .sort((a: any, b: any) => (new Date(a.Fecha).getTime() > new Date(b.Fecha).getTime() ? 1 : -1))
      .slice(-12);

    const categorias = objTemp?.map((element: any) => {
      const fechaStr = element.Fecha;
      let fecha;

      fecha = new Date(fechaStr);

      if (isNaN(fecha.getTime())) {
        const [year, month, day] = fechaStr.split('-').map(Number);
        fecha = new Date(Date.UTC(year, month - 1, day));
      }

      if (isNaN(fecha.getTime())) {
        //console.error(`Fecha inválida: ${fechaStr}`);
        return '';
      }

      const mes = fecha.getUTCMonth() + 1;
      return `${fecha.getUTCFullYear()}/${mes}`;
    });

    const values = objTemp?.map((element: any) => {
      return Number(element.Valor)
    });

    this.graph = new Chart({
      xAxis: [{
        categories: categorias
      }],
      title: {
        text: ''
      },
      yAxis: [{
        labels: {
          format: '{value}',
          style: {
            color: '#000'
          }
        },
        title: {
          text: '',
          style: {
            color: '#000'
          }
        }
      }],
      series: [{
        name: 'Puntajes',
        type: 'line',
        data: values,
        color: '#f00'
      }]
    });
  }

  setInformacionBalance(): void {
    const objF22 = this.objReporteFinanciero?.CarpetaTributariaElectronicaReporte
      ?.Reporte?.f22 || [];

    if (objF22.length > 0) {
      this.informacionBalance = objF22.sort((a: F22, b: F22) => {
        return b.periodo - a.periodo;
      }).slice(0, 3);
    }
  }

  getPercents(title: string): boolean {
    const valuesKeys = [
      'razonCorriente',
      'testAcido',
      'variacionenActivos',
      'variacionenPasivoExigible',
      'utilidadVentas',
      'ventaTotalIngresos',
      'roe',
      'roa',
      'dfebitda',
      'ebitdagf',
      'leverage',
      'dfPatrimonio',
      'variacionenVentas',
      'variacionenPatrimonio'
    ];
    return valuesKeys.includes(title);
  }

  public setChartData(): void {
    if (
      !this.objReporteBasico?.InformacionSectorialyDeMercado
      || !this.objReporteBasico?.InformacionSectorialyDeMercado.TotalVentasHistoricoUF
      || !this.objReporteBasico?.InformacionSectorialyDeMercado.TotalVentasHistoricoUF.ano1
      || !this.objReporteBasico?.InformacionSectorialyDeMercado.TotalVentasHistoricoUF.periodo_1
    ) {
      return;
    }
    this.graficaSinInformacion = true;
    this.chart.data = [
      [String(this.objReporteBasico?.InformacionSectorialyDeMercado.TotalVentasHistoricoUF.ano1), (this.objReporteBasico?.InformacionSectorialyDeMercado.TotalVentasHistoricoUF.periodo_1) / 1000000],
      [String(this.objReporteBasico?.InformacionSectorialyDeMercado.TotalVentasHistoricoUF.ano2), (this.objReporteBasico?.InformacionSectorialyDeMercado.TotalVentasHistoricoUF.periodo_2) / 1000000],
      [String(this.objReporteBasico?.InformacionSectorialyDeMercado.TotalVentasHistoricoUF.ano3), (this.objReporteBasico?.InformacionSectorialyDeMercado.TotalVentasHistoricoUF.periodo_3) / 1000000],
      [String(this.objReporteBasico?.InformacionSectorialyDeMercado.TotalVentasHistoricoUF.ano4), (this.objReporteBasico?.InformacionSectorialyDeMercado.TotalVentasHistoricoUF.periodo_4) / 1000000],
      [String(this.objReporteBasico?.InformacionSectorialyDeMercado.TotalVentasHistoricoUF.ano5), (this.objReporteBasico?.InformacionSectorialyDeMercado.TotalVentasHistoricoUF.periodo_5) / 1000000],
    ]
  }

  getTotalJudicial(): void {
    const causas = this.objReporteJudicial?.poderJudicialReporte?.poderJudicial?.Totales;
    if (!causas) { this.totalJudicial = 0; return; }
    const civil = causas.nCausasCivil || 0;
    const cobranza = causas.nCausasCobranza || 0;
    const laboral = causas.nCausasLaboral || 0;
    this.totalJudicial = Number(civil) + Number(cobranza) + Number(laboral);
  }

  calcularMallaSocietaria(): void {
    this.mallaSocietaria = this.objReporteFinanciero && this.objReporteFinanciero.CarpetaTributariaElectronicaReporte.Reporte.socios.length > 0 ?
      'CTE' : this.objReporteFinanciero && this.objReporteFinanciero.CarpetaTributariaElectronicaReporte.Reporte.socios.length > 0 &&
        this.objReporteBasico && this.informeComercial === '11' ? 'CTE' : this.objReporteBasico && this.informeComercial === '11' ?
        'Informe comercial' : 'Sin información';
  }

  obtenerLastDeudaSbif(): void {
    if (this.objReporteDeudaSbif && this.objReporteDeudaSbif?.Documentos?.SbifOnline) {
      const objTemp = this.getResponse(this.objReporteDeudaSbif.Documentos.SbifOnline.Reporte.rowsMeses.item);
      this.reducirDeudaSbif(objTemp);
    }
  }

  getResponse(response: IItem[]): any[] {
    if (response && response.length > 0) {
      return response.map((e: any) => {
        return {
          castigadadirecta: e.castigadadirecta,
          castigadaindirecta: e.castigadaindirecta,
          credcontingente: e.credcontingente,
          diracreedor: [e.dirconsumo, e.diracreedor],
          dircomercial: e.dircomercial,
          dirhipotecaria: e.dirhipotecaria,
          dirinversion: e.dirinversion,
          dirmorosa: e.dirmorosa,
          diroperacionpacto: e.diroperacionpacto,
          dirven180mayor3year: e.dirven180mayor3year,
          dirvencida: e.dirvencida,
          dirvigente: e.dirvigente,
          indirectaven: e.indirectaven,
          indirectavig: e.indirectavig,
          leasingmor: e.leasingmor,
          leasingvig: e.leasingvig,
          lineacredito: e.lineacredito,
          monexvencida: e.monexvencida,
          monexvigente: e.monexvigente,
          periodo: this.formatDate(e.periodo)
        }
      }).sort((a, b) => new Date(b.periodo).getTime() - new Date(a.periodo).getTime()) || []
    }
    return [];
  }

  reducirDeudaSbif(obj: any): void {
    const lastPeriodo = obj[0].periodo;
    this.periodoDeuda = !isNaN(Number(lastPeriodo.substr(0, 3))) ? lastPeriodo : '';

    this.objResumenDeudaSbif = {};
    if(obj[0]){
      this.objResumenDeudaSbif = {
        castigadadirecta: (obj[0]['castigadadirecta']? Number(obj[0]['castigadadirecta']) : 0),
        credcontingente: (obj[0]['credcontingente']? Number(obj[0]['credcontingente']) : 0),
        dirmorosa: (obj[0]['dirmorosa']? Number(obj[0]['dirmorosa']) : 0),
        dirven180mayor3year: (obj[0]['dirven180mayor3year']? Number(obj[0]['dirven180mayor3year']) : 0),
        dirvencida: (obj[0]['dirvencida']? Number(obj[0]['dirvencida']) : 0),
        dirvigente: (obj[0]['dirvigente']? Number(obj[0]['dirvigente']) : 0),
        leasingmor: (obj[0]['leasingmor']? Number(obj[0]['leasingmor']) : 0),
        leasingvig: (obj[0]['leasingvig']? Number(obj[0]['leasingvig']) : 0),
        lineacredito: (obj[0]['lineacredito']? Number(obj[0]['lineacredito']) : 0),
        periodo: lastPeriodo
      }
    }
  }

  sumarTotalesDeudas([...arr]): number | string {
    return this.objResumenDeudaSbif ? arr.reduce((acc, curr) => acc + this.objResumenDeudaSbif[curr], 0) : 'Sin información';
  }

  formatDate(date: string): string {
    return date.split('-').reverse().join('-');
  }

  validarIfInformation(obj: any): boolean {
    return obj && Object.keys(obj).length > 0;
  }

 async updateReports(report: string, obj: any): Promise<void> {
    const objeto: any = {
      'reporteBasico': (obj: IReporteBasico) => {
        this.objReporteBasico = obj;
      },
      'boletinConcursal': (obj: IBoletinConcursalCreateReportResponse) => {
        this.objReporteBoletinConcursal = obj;
      },
      'deudaSbif': (obj: any) => {
        this.objReporteDeudaSbif = obj?.DeudaSistemaFinancieroReporte;            
      },
      'reporteJudicial': (obj: IPoderJudicialGetReportReponse) => {
        this.objReporteJudicial = obj;
      }
    };
    objeto[report](obj);
  }

  ngAfterViewInit(): void {
    this.actualizarOtherReports.emit(true);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.objReporteDeudaSbif) {
      this.obtenerLastDeudaSbif();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  validaObjetoSocios(): boolean{
    if(this.objReporteFinanciero && this.objReporteFinanciero?.CarpetaTributariaElectronicaReporte?.Reporte?.socios?.length > 0){
      return true;
    } else {
      return false;
    }
  }

  validaObjetoAntecedentes(): boolean{
    if(this.objReporteFinanciero && this.objReporteFinanciero?.CarpetaTributariaElectronicaReporte?.Reporte?.nombre){
      return true;
    } else {
      return false;
    }
  }

  validaObjetoSociosFecha(): any{
    if(this.objReporteFinanciero && this.objReporteFinanciero?.CarpetaTributariaElectronicaReporte?.Reporte?.socios?.length > 0){
      return (this.objReporteFinanciero?.CarpetaTributariaElectronicaReporte?.DatosBasicosSolicitud?.FechaReporte || '');
    } else {
      return (this.objReporteBasico?.InformacionBasica?.FechaReporte || '');
    }
  }

  formatFechaEmailPN(dateNumber: any): string {
    // Convertir el número a una cadena
    if(!dateNumber) {
      return '';
    }
    const dateString = dateNumber.toString();
  
    // Verificar si la cadena tiene exactamente 8 caracteres y solo contiene dígitos
    if (/^\d{8}$/.test(dateString)) { // formato es YYYYMMDD
      const year = dateString.substring(0, 4);
      const month = dateString.substring(4, 6);
      const day = dateString.substring(6, 8);
      return `${day}-${month}-${year}`;
    } else {
      return '';
    }
  }

}
