import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { forkJoin, from, of, Subscription } from 'rxjs';
import { AlertService } from 'src/app/components/_alert';
import { NgxSpinnerService } from 'ngx-spinner';
import { SolicitudCreditoServiceWorkflow } from '../../../../shared/services/solicitud-credito-workflow.service';
import { catchError, filter, last, map, mergeMap, toArray } from 'rxjs/operators';
import { UntypedFormBuilder, FormControl, UntypedFormGroup, Validators, FormGroup, FormBuilder, FormArray, AbstractControl } from '@angular/forms';
import { gtpRutValidator } from 'src/app/shared/validators/rut-validator';
import { rutValidate } from 'rut-helpers';
import { ModalBasicoConsolidadoService } from '../../../../shared/services/modal-basico-consolidado.service';
import { ESystemAccess } from 'src/app/enum/EAccess';
import { SessionService } from 'src/app/shared/services/session.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { Chart } from 'highcharts';
import Highcharts from 'highcharts';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { DetalleReporteModalComponent } from './detalle-reporte-modal/detalle-reporte-modal.component'
const MOCK_CF = [
  {
      "VENDEDOR": "76262643-8",
      "ESTADO_CESION": "Cesion Vigente",
      "DEUDOR": "76640933-4",
      "MAIL_DEUDOR": "miguelgomez@cesce.cl",
      "TIPO_DOC": "33",
      "NOMBRE_DOC": "Factura Electronica",
      "FOLIO_DOC": "739",
      "FCH_EMIS_DTE": "2022-01-31",
      "MNT_TOTAL": "29750",
      "CEDENTE": "76262643-8",
      "RZ_CEDENTE": "DESARROLLO E INTEGRACION DE SISTEMAS SPA.",
      "MAIL_CEDENTE": "jurzua@gestionatupyme.cl",
      "CESIONARIO": "77378155-9",
      "RZ_CESIONARIO": "SISTEMAS Y APLICACIONES SPA",
      "MAIL_CESIONARIO": "contacto@reportesonline.cl",
      "FCH_CESION": "2022-02-17 11:02",
      "MNT_CESION": "29750",
      "FCH_VENCIMIENTO": "2022-02-27"
  },
  {
    "VENDEDOR": "76262643-8",
    "ESTADO_CESION": "Cesion Vigente",
    "DEUDOR": "76640933-4",
    "MAIL_DEUDOR": "miguelgomez@cesce.cl",
    "TIPO_DOC": "33",
    "NOMBRE_DOC": "Factura Electronica",
    "FOLIO_DOC": "739",
    "FCH_EMIS_DTE": "2022-01-31",
    "MNT_TOTAL": "29750",
    "CEDENTE": "76262643-8",
    "RZ_CEDENTE": "DESARROLLO E INTEGRACION DE SISTEMAS SPA.",
    "MAIL_CEDENTE": "jurzua@gestionatupyme.cl",
    "CESIONARIO": "77378155-9",
    "RZ_CESIONARIO": "SISTEMAS Y APLICACIONES SPA",
    "MAIL_CESIONARIO": "contacto@reportesonline.cl",
    "FCH_CESION": "2022-02-17 11:02",
    "MNT_CESION": "29750",
    "FCH_VENCIMIENTO": "2022-02-27"
},
{
  "VENDEDOR": "76262643-8",
  "ESTADO_CESION": "Cesion Vigente",
  "DEUDOR": "76640933-4",
  "MAIL_DEUDOR": "miguelgomez@cesce.cl",
  "TIPO_DOC": "33",
  "NOMBRE_DOC": "Factura Electronica",
  "FOLIO_DOC": "739",
  "FCH_EMIS_DTE": "2022-01-31",
  "MNT_TOTAL": "29750",
  "CEDENTE": "76262643-8",
  "RZ_CEDENTE": "DESARROLLO E INTEGRACION DE SISTEMAS SPA.",
  "MAIL_CEDENTE": "jurzua@gestionatupyme.cl",
  "CESIONARIO": "77378155-9",
  "RZ_CESIONARIO": "SISTEMAS Y APLICACIONES SPA",
  "MAIL_CESIONARIO": "contacto@reportesonline.cl",
  "FCH_CESION": "2022-02-17 11:02",
  "MNT_CESION": "29750",
  "FCH_VENCIMIENTO": "2022-02-27"
},
{
  "VENDEDOR": "76262643-8",
  "ESTADO_CESION": "Cesion Vigente",
  "DEUDOR": "76640933-4",
  "MAIL_DEUDOR": "miguelgomez@cesce.cl",
  "TIPO_DOC": "33",
  "NOMBRE_DOC": "Factura Electronica",
  "FOLIO_DOC": "739",
  "FCH_EMIS_DTE": "2022-01-31",
  "MNT_TOTAL": "29750",
  "CEDENTE": "76262643-8",
  "RZ_CEDENTE": "DESARROLLO E INTEGRACION DE SISTEMAS SPA.",
  "MAIL_CEDENTE": "jurzua@gestionatupyme.cl",
  "CESIONARIO": "77378155-9",
  "RZ_CESIONARIO": "SISTEMAS Y APLICACIONES SPA",
  "MAIL_CESIONARIO": "contacto@reportesonline.cl",
  "FCH_CESION": "2022-02-17 11:02",
  "MNT_CESION": "29750",
  "FCH_VENCIMIENTO": "2022-02-27"
},
{
  "VENDEDOR": "76262643-8",
  "ESTADO_CESION": "Cesion Vigente",
  "DEUDOR": "76640933-4",
  "MAIL_DEUDOR": "miguelgomez@cesce.cl",
  "TIPO_DOC": "33",
  "NOMBRE_DOC": "Factura Electronica",
  "FOLIO_DOC": "739",
  "FCH_EMIS_DTE": "2022-01-31",
  "MNT_TOTAL": "29750",
  "CEDENTE": "76262643-8",
  "RZ_CEDENTE": "DESARROLLO E INTEGRACION DE SISTEMAS SPA.",
  "MAIL_CEDENTE": "jurzua@gestionatupyme.cl",
  "CESIONARIO": "77378155-9",
  "RZ_CESIONARIO": "SISTEMAS Y APLICACIONES SPA",
  "MAIL_CESIONARIO": "contacto@reportesonline.cl",
  "FCH_CESION": "2022-02-17 11:02",
  "MNT_CESION": "29750",
  "FCH_VENCIMIENTO": "2022-02-27"
},
{
  "VENDEDOR": "76262643-8",
  "ESTADO_CESION": "Cesion Vigente",
  "DEUDOR": "76640933-4",
  "MAIL_DEUDOR": "miguelgomez@cesce.cl",
  "TIPO_DOC": "33",
  "NOMBRE_DOC": "Factura Electronica",
  "FOLIO_DOC": "739",
  "FCH_EMIS_DTE": "2022-01-31",
  "MNT_TOTAL": "29750",
  "CEDENTE": "76262643-8",
  "RZ_CEDENTE": "DESARROLLO E INTEGRACION DE SISTEMAS SPA.",
  "MAIL_CEDENTE": "jurzua@gestionatupyme.cl",
  "CESIONARIO": "77378155-9",
  "RZ_CESIONARIO": "SISTEMAS Y APLICACIONES SPA",
  "MAIL_CESIONARIO": "contacto@reportesonline.cl",
  "FCH_CESION": "2022-02-17 11:02",
  "MNT_CESION": "29750",
  "FCH_VENCIMIENTO": "2022-02-27"
},
{
  "VENDEDOR": "76262643-8",
  "ESTADO_CESION": "Cesion Vigente",
  "DEUDOR": "76640933-4",
  "MAIL_DEUDOR": "miguelgomez@cesce.cl",
  "TIPO_DOC": "33",
  "NOMBRE_DOC": "Factura Electronica",
  "FOLIO_DOC": "739",
  "FCH_EMIS_DTE": "2022-01-31",
  "MNT_TOTAL": "29750",
  "CEDENTE": "76262643-8",
  "RZ_CEDENTE": "DESARROLLO E INTEGRACION DE SISTEMAS SPA.",
  "MAIL_CEDENTE": "jurzua@gestionatupyme.cl",
  "CESIONARIO": "77378155-9",
  "RZ_CESIONARIO": "SISTEMAS Y APLICACIONES SPA",
  "MAIL_CESIONARIO": "contacto@reportesonline.cl",
  "FCH_CESION": "2022-02-17 11:02",
  "MNT_CESION": "29750",
  "FCH_VENCIMIENTO": "2022-02-27"
},
{
  "VENDEDOR": "76262643-8",
  "ESTADO_CESION": "Cesion Vigente",
  "DEUDOR": "76640933-4",
  "MAIL_DEUDOR": "miguelgomez@cesce.cl",
  "TIPO_DOC": "33",
  "NOMBRE_DOC": "Factura Electronica",
  "FOLIO_DOC": "739",
  "FCH_EMIS_DTE": "2022-01-31",
  "MNT_TOTAL": "29750",
  "CEDENTE": "76262643-8",
  "RZ_CEDENTE": "DESARROLLO E INTEGRACION DE SISTEMAS SPA.",
  "MAIL_CEDENTE": "jurzua@gestionatupyme.cl",
  "CESIONARIO": "77378155-9",
  "RZ_CESIONARIO": "SISTEMAS Y APLICACIONES SPA",
  "MAIL_CESIONARIO": "contacto@reportesonline.cl",
  "FCH_CESION": "2022-02-17 11:02",
  "MNT_CESION": "29750",
  "FCH_VENCIMIENTO": "2022-02-27"
},
]
interface CargaFinanciera {
  fechaCurse: string,
  institucion: string,
  tipoCredito: string,
  creditoOriginal: number | string,
  deudaActual: number | string,
  cuota: number | string,
  nCuotasPorPagar: number | string,
  nCuotasMorosas: number | string,
  ultimoVencimiento: string,
  garantiasVigentes: string
}

interface DicomRow {
  nombre: string,
  rut: string,
  numero: string,
  monto: string,
  motivo: string
}

@Component({
  selector: 'app-plantilla-puente-financiero-ct',
  templateUrl: './plantilla-puente-financiero-ct.component.html',
  styleUrls: ['./plantilla-puente-financiero-ct.component.scss']
})
export class PlantillaPuenteFinancieroCtComponent implements OnInit {
  @Input() type: string = 'ct'
  
  @Input() plantillaNoEditable: boolean = false;
  @Input() objEntradaFicha: any = {};
  @Input() objEntradaMotor: any = {};

  @Output() operacionNuevo = new EventEmitter<boolean>();
  @Output() finalizarFlujo = new EventEmitter<void>();
  @Output() bloqueaPasos5 = new EventEmitter<boolean>();
  @Output() bloqueaPasos6 = new EventEmitter<boolean>();
  @Input() idTransaccion: string = '';
  @Input() stepper!: any;
  @Input() rut: string = '';
  @Input() closed: boolean = true;
  public objectKeys = Object.keys;
  public servicioFichaForm: FormGroup = this.fb.group({
    nombreComite: ['', Validators.required],
    giroComite: ['', Validators.required],
    ejecutivoComite: ['', Validators.required],
    rutComite: ['', Validators.required],
    direccion: ['', Validators.required],
    fechaComite: ['', Validators.required]
  });
  public servicioDeudoresForm!: UntypedFormGroup;

  private access: string[] = [
    ESystemAccess.FLUJO_CREDITO_BUSCAR, 
    ESystemAccess.FLUJO_CREDITO_BUSCAR_ADMIN,
    ESystemAccess.FLUJO_CREDITO,
    ESystemAccess.FLUJO_CREDITO_ADMIN
  ];

  public activateRutComiteHelper: boolean = false;
  public activateRutClienteComiteHelper: boolean = false;

  public subscriptions: Subscription[] = [];
  public data: any;
  public today = '';
  public dataFiltrada: any;
  public dataCargada: boolean = false;
  public groupNameConsulting: string[] = ['buscarReportePrincipal', 'buscarReportesDerivados'];
  public groupNameConsultingFicha: string[] = ['buscarFichaDeCredito', 'buscarReportesDerivados', 'buscarReportePrincipal'];
  public groupNameConsultingScore: string[] = ['buscarCalculoScore', 'buscarReportesDerivados', 'buscarReportePrincipal'];
  public groupNameConsultingAprobacion: string[] = ['buscarReportesDerivados', 'buscarReportePrincipal'];
  public contratoServicios: any[] = [];
  public atributos: any[] = [];
  public reportesPrincipalesObj: any = {};
  public reportesDerivadosObj: any = {};
  public reportesPrincipales: any = {};
  public reportesDerivados: any = {};
  public entidadesDerivadas: any = {};
  public errores: any[] = [];
  public bodyModelos: any = {};
  public fichaGuardada: any = {}; // es para validar la existencia de una ficha guardada y habilitar el boton continuar
  public fichaComiteCredito: any = {};
  public cabeceraDeudores: any = {};
  public totalesInformacionFinanciera: any = {};
  public informacionFinancieraCabecera: any = {};
  public informacionFinancieraTable: any[] = [];
  public deudores: any[] = [];
  public sociosComposicion: any[] = [];
  public sociosInformes: any[] = [];
  public empresasRelacionadasComposicion: any[] = [];
  public empresasRelacionadasInformes: any[] = [];
  public showReportes: boolean = false;
  public showArchivos: boolean = false;
  public consolidadoTitular: any = {};
  public plantillaAntecedentes = 'cesceDemo';
  public modalDefault = true; 
  public observaciones: any = {
    text: ''
  };
  public servicioFichaFormSaved: any = {};
  public servicioDeudoresFormSaved: any = {};
  public cargaFinanciera: CargaFinanciera[] = [];
  public nombreClienteCargaFinanciera: string = '';

  public reportesSeleccionado: any = {};
  public rutSeleccionado: string = '';
  public informeComercial: string = '';
  public totalesCargaFinanciera: any = {
    creditoOriginal: 0,
    deudaActual: 0,
    cuota: 0,
    nCuotasPorPagar: 0,
    nCuotasMorosas: 0,
  }
  public factoring: any[] = [];
  public lineaOperacionPropuesta: any = {};
  public lineaOperacionPropuestaTotales: any = {};
  public motorCalculos: any;
  public motorConDatos: boolean = false;
  public balanceManual: any[] = [];
  public dataManualCargada: boolean = false;
  public tablasCompraVenta: any = {}; 

  public listaReportesPrincipales: any[] = [];
  public listaReportesDerivados: any = {};

  public fechaTx: string = '';

  public formatos: any = {
    // deudoress
    porcentaje: 'decimal',
    facturaFinanciar: 'decimal',
    cheques: 'decimal',
    histCancelado: 'decimal',
    diasPromedio: 'decimal',
    fs: 'si-no',
    web: 'text',
    // carga financiera
    fechaCurse: 'text',
    institucion: 'text',
    tipoCredito: 'text',
    ultimoVencimiento: 'text',
    garantiasVigentes: 'text',
    creditoOriginal: 'decimal',
    deudaActual: 'decimal',
    cuota: 'decimal',
    nCuotasPorPagar: 'numero',
    nCuotasMorosas: 'numero',
  }

  public formatosLinea: any = {
    // linea / operacion propuesta
    lineaVigentefacturas: 'decimal',
    lineaPropuestafacturas: 'decimal',
    opPuntualVigentefacturas: 'decimal',
    opPuntualPropuestafacturas: 'decimal',

    lineaVigentecheques: 'decimal',
    lineaPropuestacheques: 'decimal',
    opPuntualVigentecheques: 'decimal',
    opPuntualPropuestacheques: 'decimal',

    lineaVigenteconfirming: 'decimal',
    lineaPropuestaconfirming: 'decimal',
    opPuntualVigenteconfirming: 'decimal',
    opPuntualPropuestaconfirming: 'decimal',

    lineaVigentecontratoServiu: 'decimal',
    lineaPropuestacontratoServiu: 'decimal',
    opPuntualVigentecontratoServiu: 'decimal',
    opPuntualPropuestacontratoServiu: 'decimal',

    lineaVigentecreditoDirecto: 'decimal',
    lineaPropuestacreditoDirecto: 'decimal',
    opPuntualVigentecreditoDirecto: 'decimal',
    opPuntualPropuestacreditoDirecto: 'decimal',

    vigentefacturas: 'decimal',
    propuestafacturas: 'decimal',
    canceladafacturas: 'decimal',

    vigentecheques: 'decimal',
    propuestacheques: 'decimal',
    canceladacheques: 'decimal',

    vigenteconfirming: 'decimal',
    propuestaconfirming: 'decimal',
    canceladaconfirming: 'decimal',

    vigentecontratoServiu: 'decimal',
    propuestacontratoServiu: 'decimal',
    canceladacontratoServiu: 'decimal',

    vigentecreditoDirecto: 'decimal',
    propuestacreditoDirecto: 'decimal',
    canceladacreditoDirecto: 'decimal',

    porcentajefacturas: 'decimal',
    porcentajecheques: 'decimal',
    porcentajeconfirming: 'decimal',
    porcentajecontratoServiu: 'decimal',
    porcentajecreditoDirecto: 'decimal',

    plazoDiasfacturas: 'numero',
    plazoDiascheques: 'numero',
    plazoDiasconfirming: 'numero',
    plazoDiascontratoServiu: 'numero',
    plazoDiascreditoDirecto: 'numero',

    vencimiento: 'text',
    opPuntualM: 'decimal',
    riesgoTotalM: 'decimal',
    totalLineaM: 'decimal',
    presentacionCliente: 'text'
  }

  isDisabled = false;
  motivoOptions: string[] = ['Renovacion', 'Inversion', 'Endeudamiento', 'Puesta en Marcha', 'Laboral', 'Emergencia']
  tipoOptions: string[] = ['Renovacion', 'OC', 'Contrato']; 

  public datosRiesgoForm: FormGroup = this.fb.group({
    inicioActividades: ['', Validators.required],
    anosMeses: ['', Validators.required],
    promedioVentas12M: ['', Validators.required],
    margenMensual: ['', Validators.required],
    lagunas: ['', Validators.required],
    alzaBaja: ['', Validators.required],
    vehiculos: ['0', Validators.required],
    propiedades: ['0', Validators.required],
    morosidad: ['', Validators.required],
    notificacionEmbargo: ['', Validators.required],
    deudaConvenio: ['0', Validators.required],
    cuotasMorosas: ['', Validators.required],
  });

  public descripcionesForm: FormGroup = this.fb.group({
    relCliEjec: ['', Validators.required],
    detalleNegocio: ['', Validators.required],
    expCliente: ['', Validators.required],
  });

  public dicomForm: FormGroup = this.fb.group({
    empresa: ['', Validators.required],
    socios: this.fb.array([]),
    deudores: this.fb.array([])
  });

  public operadoEnPuente: FormGroup = this.fb.group({
    factoringTradicional: ['', Validators.required],
    pagadoFact: ['', Validators.required],
    kt: ['', Validators.required],
    pagadoKt: ['', Validators.required],
    morosidadInterna: ['', Validators.required],
    dias: ['', Validators.required],
    reclamos: ['', Validators.required],
    promedioMora: ['', Validators.required],
    ncTotales: ['', Validators.required],
    moraMaxima: ['', Validators.required],
  });

  public proyeccionForm: FormGroup = this.fb.group({
    totalDeudaB1: ['', Validators.required],
    totalDeudaB2: ['', Validators.required],
    totalDeudaB3: ['', Validators.required],
    totalDeudaB4: ['', Validators.required],
    totalDeudaB5: ['', Validators.required],
    
    totalDeudaL1: ['', Validators.required],
    totalDeudaL2: ['', Validators.required],
    totalDeudaL3: ['', Validators.required],
    totalDeudaL4: ['', Validators.required],
    totalDeudaL5: ['', Validators.required],

    margen1: ['', Validators.required],
    margen2: ['', Validators.required],
    margen3: ['', Validators.required],
    margen4: ['', Validators.required],
    margen5: ['', Validators.required],

    cuota1: ['', Validators.required],
    cuota2: ['', Validators.required],
    cuota3: ['', Validators.required],
    cuota4: ['', Validators.required],
    cuota5: ['', Validators.required],

    flujo1: ['', Validators.required],
    flujo2: ['', Validators.required],
    flujo3: ['', Validators.required],
    flujo4: ['', Validators.required],
    flujo5: ['', Validators.required],
  });

  public operacionKtForm: FormGroup = this.fb.group({
    valor: ['', Validators.required],
    tasa: ['', Validators.required],
    cuotas: ['', Validators.required],
    cuota: ['', Validators.required],
  });

  // DICOM
  public dicomEmpresa: DicomRow = {
    nombre : '',
    rut: '',
    numero: '',
    monto: '',
    motivo: ''
  }
  public dicomSocios: DicomRow[] = [];
  public dicomDeudores: DicomRow[] = [];

  // TODO: Pasar a FormArray
  public detalleDeudaArray = this.fb.group({
    myFormArray: this.fb.array([this.createFormGroup()])
  });
  lcvPrincipal: any;

  get myFormArray(): FormArray {
    return this.detalleDeudaArray.get('myFormArray') as FormArray;
  }

  addFormGroup() {
    this.myFormArray.push(this.createFormGroup());
  }

  removeFormGroup(index: number) {
    if (this.myFormArray.length >= 0) {
      this.myFormArray.removeAt(index);
    }
  }

  createFormGroup(data: any = {}): FormGroup {
    return this.fb.group({
      entidad: [data.entidad || ''],
      cxp: [data.cxp || ''],
      vcm: [data.vcm || ''],
      vca: [data.vca || '']
    });
  }

  public resolucionControl: FormControl = new FormControl([''])
  reporteTesoreriaPrincipal: any;
  rutPrincipal: any;

  public juiciosSocios = 0;
  public juiciosEmpresa = 0;

  constructor(
    public alertService: AlertService,
    private solicitudCreditoService: SolicitudCreditoServiceWorkflow,
    private spinner: NgxSpinnerService,
    private utilsService: UtilsService,
    private modalService: ModalBasicoConsolidadoService,
    //private simpleModalService: SimpleModalService,
    public dialog: MatDialog,
    private router: Router,
    private fb: FormBuilder,
    private _sessionService: SessionService
  ) { }

  ngOnInit() {
    // this.servicioFichaForm.disable();
    this.operacionNuevo.emit(false);
    this.setToday();
    if(this.plantillaNoEditable === false){
      this.obtenerFlujo();
    } else {
      this.mapeoNoEditable();
      this.showArchivos = true;
    }
    this.checkDisabled();
  }

  mapeoNoEditable(): void {
    this.callServicesFichaAprobacion();    
  }

  async callServicesFichaAprobacion(): Promise<void> {
    const apiServices: any = [];

    if (this.groupNameConsultingAprobacion.length > 0) {
      for await (const value of this.groupNameConsultingAprobacion) {
        apiServices.push(this.getServices(value))
      }
    }

    this.spinner.show();
    this.subscriptions.push(forkJoin(apiServices).subscribe((resp) => {
      this.spinner.hide();
      if (this.errores.length > 0){
        this.errores.forEach((element: any) => {
          this.alertService.error(element.msg);
        });
        this.spinner.hide();
      } else {
        this.buscarReportes("callServicesFichaAprobacion");
      }
    },
      (error) => {
        this.alertService.error(error?.message);
        this.spinner.hide();
      }
    ));
  }

  openDialogTesoreriaPrincipal(): void {
    const dialogRef = this.dialog.open(DetalleReporteModalComponent, {
      // width: '250px',
      data: { 
        reporte: this.reporteTesoreriaPrincipal,
        rut: this.rutPrincipal,
        reportType: 'tesoreria'
       }
    });
  }

  openDialogLcvPrincipal(): void {
    const url = this.router.createUrlTree([`compra-venta/buscar`], { queryParams: {rut: this.rutPrincipal} }).toString();
    window.open(url, '_blank');
  }

  checkDisabled() {
    const isDisabled = (this.stepper != 4 || this.plantillaNoEditable)
    this.servicioFichaForm.disable();
  }

  obtenerFlujo(): void{
    this.spinner.show();
    this.subscriptions.push(this.solicitudCreditoService.obtenerFlujo(this.idTransaccion, this.rut).subscribe(resp => {
      this.bodyModelos = resp;
      let paso: any;
      let pasoAnterior: any;
      const pasosCompleted = resp.stages.filter((e: any) => e.completed);
      const cantidad = pasosCompleted.length;
      const ultimoPasoCompletado = Number(pasosCompleted[cantidad - 1].stageID);
      this.closed = (resp.closed === null || resp.closed === undefined) ? true : resp.closed;
      this.showArchivos = true;

      if(this.stepper === 5){
        resp.stages.forEach((e: any) => {
          if(e.stageID === '10'){
            paso = e;
          }
        });
        // Se valida si el paso anterior fue completado y si no se envia al ultimo completado
        if(paso?.stageID === '10' && (paso?.completed === false && paso?.optional === false)){ //pasoAnterior && !pasoAnterior?.completed
          let pasoAnteriorCompleted = ultimoPasoCompletado;
          if(ultimoPasoCompletado === 9)
            pasoAnteriorCompleted = 8;

          this.redireccionPasoAnterior(pasoAnteriorCompleted);
        } else {
          if (paso?.stageID === '10' && paso?.completed === true) { // Se realizo el calculo del score
            this.callServicesFichaScore();
          } else {
            this.redireccionPasoAnterior(ultimoPasoCompletado);
          }
        }
      } else {
        resp.stages.forEach((e: any) => {
          if(e.stageID === '9'){
            paso = e;
          } else if (e.stageID === '8'){
            pasoAnterior = e;
          }
        });
        // Se valida si el paso anterior fue completado y si no se envia al ultimo completado
        if(pasoAnterior && (!pasoAnterior?.completed && pasoAnterior?.optional === false)){
          this.redireccionPasoAnterior(ultimoPasoCompletado);
        } else {
          if (paso?.stageID === '9' && paso?.completed === false) { // primera vez en esta pantalla -> cambiar para pruebas
            this.callServices();
          }
          else if(paso?.stageID === '9' && paso?.completed === true){ // ya se habia llegado antes a esta pantalla -> cambiar para pruebas
            this.callServicesFicha();
          }
        }
      }
    }, (error: any) => {
      this.alertService.error(error?.error?.message || 'Solicitud de Crédito - Ocurrio un error al recuperar los datos del flujo de credito');
      this.spinner.hide();
    }));

  }

  async callServicesFichaScore(): Promise<void> {
    const apiServices: any = [];

    if (this.groupNameConsultingScore.length > 0) {
      for await (const value of this.groupNameConsultingScore) {
        apiServices.push(this.getServices(value))
      }
    }

    this.spinner.show();
    this.subscriptions.push(forkJoin(apiServices).subscribe((resp) => {
      this.spinner.hide();
      if (this.errores.length > 0){
        this.errores.forEach((element: any) => {
          this.alertService.error(element.msg);
        });
        this.spinner.hide();
      } else {
        this.buscarReportes("callServicesFichaScore");
      }
    },
      (error) => {
        this.alertService.error(error?.message);
        this.spinner.hide();
      }
    ));
  }

  async callServices(): Promise<void> {
    const apiServices: any = [];

    if (this.groupNameConsulting.length > 0) {
      for await (const value of this.groupNameConsulting) {
        apiServices.push(this.getServices(value))
      }
    }

    this.spinner.show();
    // Aca devuelve undefined, consultar con Jose
    this.subscriptions.push(forkJoin(apiServices).subscribe((resp) => {
      this.spinner.hide();
      if (this.errores.length > 0){
        this.errores.forEach((element: any) => {
          this.alertService.error(element.msg);
        });
        this.spinner.hide();
      } else {
        this.buscarReportes("callServices");
      }
    },
      (error) => {
        this.alertService.error(error.message);
        this.spinner.hide();
      }
    ));
  }

  async callServicesFicha(): Promise<void> {
    const apiServices: any = [];

    if (this.groupNameConsulting.length > 0) {
      for await (const value of this.groupNameConsultingFicha) {
        apiServices.push(this.getServices(value))
      }
    }

    this.spinner.show();
    this.subscriptions.push(forkJoin(apiServices).subscribe((resp) => {
      this.spinner.hide();
      if (this.errores.length > 0){
        this.errores.forEach((element: any) => {
          this.alertService.error(element.msg);
        });
        this.spinner.hide();
      } else {
        this.buscarReportes("callServicesFicha");
      }
    },
      (error) => {
        this.alertService.error(error?.message);
        this.spinner.hide();
      }
    ));
  }

  //Definicion llamada a servicios asincronos por primera vez en la vista

  getServices(service: string): any {
    const objeto: any = {
      'buscarReportePrincipal': () => {
        return this.solicitudCreditoService.buscarReportePrincipal(this.idTransaccion, this.rut)
          .pipe(
            map(resp => {
              this.setResponse(service, resp);
            })
          )
          .pipe(
            catchError((error) => (this.setError(service, error?.error?.message || 'Solicitud de Crédito - Error Inesperado en servicio de reportes principales'), of(null))));
      },
      'buscarReportesDerivados': () => {
        return this.solicitudCreditoService.buscarReportesDerivados(this.idTransaccion, this.rut)
        .pipe(
          map(resp => {
            this.setResponse(service, resp);
          })
        )
        .pipe(
          catchError((error) => (this.setError(service, error?.error?.message || 'Solicitud de Crédito - Error Inesperado en servicio de reportes derivados'), of(null))));
      },
      'buscarFichaDeCredito': () => {
        return this.solicitudCreditoService.buscarFichaDeCredito(this.idTransaccion, this.rut)
        .pipe(
          map(resp => {
            this.setResponse(service, resp);
          })
        )
        .pipe(
          catchError((error) => (this.setError(service, error?.error?.message || 'Solicitud de Crédito - Error Inesperado en servicio de carga de ficha'), of(null))));
      },
      'buscarCalculoScore': () => {
        return this.solicitudCreditoService.buscarCalculoScore(this.rut, this.idTransaccion)
        .pipe(
          map(resp => {
            this.setResponse(service, resp);
          })
        )
        .pipe(
          catchError((error) => (this.setError(service, error?.error?.message || 'Solicitud de Crédito - Error Inesperado en servicio de carga de ficha con Score'), of(null))));
      }
    };
    return objeto[service]();
  }

  buscarReportes(etapa: string): void {
    const apiServicesReportes: any = this.utilsService.obtenerServicesReportes(this.listaReportesPrincipales, this.listaReportesDerivados) || [];
    let count = 0;

    this.spinner.show();
    from(apiServicesReportes).pipe( 
      mergeMap(
        (service: any) => 
          this.solicitudCreditoService.buscarReportes(
            this.idTransaccion, 
            this.rut, 
            service.principal, 
            service.reportKey, 
            service.entidad, 
            service.rutDerivado
          )
          .pipe(
            catchError(error => {
              //console.error(`Error en la consulta del servicio: ${service.principal}, ${service.reportKey}, ${service.entidad}, ${service.rutDerivado}`);
              return of(null); // Retorna un observable que emite un valor nulo
            }),
            map(response => {
              if (response !== null) {
                count++;
              }
              return {
                service: service,
                response: response
              };
            }),
          ),
          20
        ), 
        filter(response => response !== null),
      toArray()
    ).subscribe(responses => {
      if (count === 0) { // Si no hubo respuestas válidas
        this.alertService.error('Solicitud de Crédito - Ha ocurrido un error al consultar la información de todos los reportes');
        this.spinner.hide();
      } else if (count < apiServicesReportes.length) { // Si hubo respuestas válidas pero no para todos los servicios
        this.alertService.error('Solicitud de Crédito - Ha ocurrido un error al consultar la información de algunos de los reportes');
      }
      if(count > 0){
        this.reportesPrincipalesObj = this.utilsService.mapeoReportesPrincipales(responses);
        this.reportesDerivadosObj = this.utilsService.mapeoReportesDerivados(responses);
        if (etapa === 'callServices'){
          this.mapeoReportes();
          this.informeComercial = this.utilsService.identificarInformeComercial(this.reportesSeleccionado?.basicReport?.reporte);
          this.spinner.hide();
        } else if (etapa === 'callServicesFicha'){
          // this.initForm();
          this.showReportes = true;
          this.dataFiltrada && Object.keys(this.dataFiltrada).length > 0 ? this.dataCargada = true : this.dataCargada = false;
          this.mapeoReportes();
          this.spinner.hide();
        } else if (etapa === 'callServicesFichaAprobacion'){
          // this.initForm();
          this.mapeoFichaGuardada();
          this.spinner.hide();
        } else {
          // this.initForm();
          this.mapeoReportes();
          this.spinner.hide();
        }
      }
    }, error => {
      if (count > 0) {
        this.alertService.error('Solicitud de Crédito - Ha ocurrido un error al consultar la información de algunos de los reportes');
      } else {
        this.alertService.error('Solicitud de Crédito - Ha ocurrido un error al consultar la información de todos los reportes');
        this.spinner.hide();
      }
    });
  }

  mapeoFichaGuardada() {
    this.fichaGuardada = this.objEntradaFicha;
    this.setSavedValues(this.objEntradaFicha.ficha);
    this.setSavedExtraValues(this.objEntradaFicha.ficha);
    this.disableAllForms();
  }

  setSavedExtraValues(ficha:any) {
    if(ficha?.dicomEmpresa) this.dicomEmpresa = ficha?.dicomEmpresa;
    if(ficha?.dicomSocios) this.dicomSocios = ficha?.dicomSocios;
    if(ficha?.dicomDeudores) this.dicomDeudores = ficha?.dicomDeudores;
    if(ficha?.sbifEmpresa) this.sbifEmpresa = ficha?.sbifEmpresa;
    if(ficha?.sbifSocios) this.sbifSocios = ficha?.sbifSocios;
    if(ficha?.sbifSocios) this.sbifSocios = ficha?.sbifSocios;
    if(ficha?.juiciosSocios) this.juiciosSocios = ficha?.juiciosSocios;
    if(ficha?.juiciosEmpresa) this.juiciosEmpresa = ficha?.juiciosEmpresa;
    if(ficha?.periodosSbifDetalle) this.periodosSbifDetalle = ficha?.periodosSbifDetalle;
    if(ficha?.valoresSbifDetalleEmpresa) this.valoresSbifDetalleEmpresa = ficha?.valoresSbifDetalleEmpresa;
    if(ficha?.sbifDetalleSocios) this.sbifDetalleSocios = ficha?.sbifDetalleSocios;
    if(ficha?.factoring) this.factoring = ficha?.factoring;
  }

  setError(reporte: string, error: string): void {
    this.errores.push({
      id: reporte,
      msg: error
    })
  }

  setResponse(service: string, response: any): void {
    if (service === 'buscarReportePrincipal'){

      this.contratoServicios = response?.Stage3Response?.ContratoServicios || [];
      this.atributos = response?.Stage2Response?.Atributos || [];
      this.listaReportesPrincipales = response?.ReportKeys || [];
      this.fechaTx = response?.DatosBasicosSolicitud?.FechaReporte || '';

    }
    else if (service === 'buscarReportesDerivados'){

      this.listaReportesDerivados = response?.ReportKeys || {};
      this.entidadesDerivadas = response?.Stage5Response?.Entidades;

      const deudores = this.mapDeudores(this.listaReportesDerivados, this.entidadesDerivadas);

      this.setTablaDeudores(deudores);
      this.deudores

    } else if (service === 'buscarFichaDeCredito'){
      this.setSavedValues(response?.FichaDeCredito?.ficha);
    } else if (service === 'buscarCalculoScore'){
      this.setSavedValues(response?.FichaDeCredito?.ficha);
      this.disableAllForms();
    }
  }

  setSavedValues(response: any) {
    if(!response) return;

    if(response?.servicioFichaForm) this.servicioFichaForm.setValue(response?.servicioFichaForm);
    if(response?.deudoresForm) this.setDeudoresFormArrayValues(response?.deudoresForm?.elements);
    if(response?.sociosForm) this.setSociosFormArrayValues(response?.sociosForm?.elements);
    if(response?.descripcionesForm) this.descripcionesForm.setValue(response?.descripcionesForm);
    if(response?.datosRiesgoForm) this.datosRiesgoForm.setValue(response?.datosRiesgoForm);
    if(response?.operadoEnPuente) this.operadoEnPuente.setValue(response?.operadoEnPuente);
    if(response?.proyeccionForm) this.proyeccionForm.setValue(response?.proyeccionForm);
    if(response?.operacionKtForm) this.operacionKtForm.setValue(response?.operacionKtForm);
    if(response?.resolucionControl) this.resolucionControl.setValue(response?.resolucionControl);
    if(response?.detalleDeudaArray?.myFormArray) this.setFormArrayValues(response?.detalleDeudaArray?.myFormArray);
    if(response?.clasificacionDeudoresForm) this.setcalsificacionArrayValues(response?.clasificacionDeudoresForm)
  }

  disableAllForms() {
    this.servicioFichaForm.disable();
    this.deudoresForm.disable();
    this.sociosForm.disable();
    this.descripcionesForm.disable();
    this.datosRiesgoForm.disable();
    this.operadoEnPuente.disable();
    this.proyeccionForm.disable();
    this.operacionKtForm.disable();
    this.resolucionControl.disable();
    this.detalleDeudaArray.disable();
    this.clasificacionDeudoresForm.disable();
    // this.setFormArrayValues(response.detalleDeudaArray.myFormArray);
  }

  setFormArrayValues(values: any[]) {
    this.removeFormGroup(0);
    values.forEach(value => {
      this.myFormArray.push(this.createFormGroup(value));
    });
    if(this.stepper == 4) {
      this.addFormGroup();
    }
  }

  deudoresSet = false;
  setDeudoresFormArrayValues(values: any[]) {
    this.deudoresSet = true;
    this.deudoresFormArray.clear()
    values.forEach(value => {
      this.deudoresFormArray.push(this.createDeudoresFormGroup(value));
    });
  }

  sociosSet = false;
  setSociosFormArrayValues(values: any[]) {
    this.sociosSet = true;
    this.sociosFormArray.clear()
    values.forEach(value => {
      const socioGroup = this.fb.group({
        nombre: [value.nombre || '', []],
        rut: [value.rut || '', []],
        participacion: [ value.participacion || '', []], // Add this to capture %Participación
      });

      this.addSocioFormGroup(socioGroup);
    });
  }

  clasificacionDeudoresSet = false;
  setcalsificacionArrayValues(values: any) {
    if(values?.elements?.length == 0) return
    this.clasificacionDeudoresSet = true;
    this.calsificacionDeudoresFormArray.clear()
    values.elements?.forEach((value: any) => {
      const clasificacionGroup = this.fb.group({
        nombreDeudor: [value.nombreDeudor || '', Validators.required],
        clasificacionAcordada: [value.clasificacionAcordada || '', Validators.required],
        dupla12M: [value.dupla12M || '', Validators.required],
        monto12M: [value.monto12M || '', Validators.required],
        morosidadPD: [value.morosidadPD || '', Validators.required],
        morosidadDias: [value.morosidadDias || '', Validators.required],
        facturacionDupla: [value.facturacionDupla || '', Validators.required],
        ncDupla: [value.ncDupla || '', Validators.required],
        nFacturas: [value.nFacturas || '', Validators.required],
        montoOperado: [value.montoOperado || '', Validators.required],
      });

      this.addClasificacionDeudoresFormGroup(clasificacionGroup);
    });
  }
  

  mapDeudores(deudoresToShow: any, deudoresInfo: any[]) {
    if(!deudoresToShow || !deudoresInfo || !deudoresToShow?.deudores) return [];
    
    const deudoresKeys = Object.keys(deudoresToShow.deudores);

    // Step 3: Filter the items based on the rut and extract names
    const deudoresData = deudoresInfo
        .find(entry => entry.entidad === "deudores")?.items // Get the items under the 'deudores' entity
        .filter((item: any) => deudoresKeys.includes(item.rut)) // Filter items by matching rut
        .map((item: any) => {
            const name = item.atributos.find((attr: any) => attr.name === "nombre")?.value
            return { nombre: name, rut: item.rut }
        }); // Extract the 'nombre' attribute
    return deudoresData
    }

  redireccionPasoAnterior(ultimoPasoCompletado: number): void {
    let step = this.solicitudCreditoService.stepsOptions.filter((e: any) => e.stageID == (ultimoPasoCompletado + 1));
    if (!step[0]?.step){
      step = this.solicitudCreditoService.stepsOptions.filter((e: any) => e.stageID == (ultimoPasoCompletado));
    }
    this.alertService.error(`Solicitud de Crédito - Debe completar el paso N° ${Number(step[0]?.step)} para poder continuar`);
    this.solicitudCreditoService.setStep(Number(step[0]?.step));
  }

  setToday(): void {
    this.today = `${new Date().getFullYear()}${(new Date().getMonth() + 1 < 10?  '0'+(new Date().getMonth() + 1) : 
      new Date().getMonth() + 1)}${new Date().getDate()}`;        
  }

  // Scrollea automaticamente la vista a la seccion seleccionada
  scroll(el: string, event: any) {
    let button = event.target as HTMLElement | null;
    const elementHTML = document.getElementById(el) as HTMLElement | null;
    if(button?.classList.contains('fa')){
      button = button?.parentElement as HTMLElement | null;
    }
    elementHTML?.classList.toggle('container-desplegables');
    if ( !elementHTML?.classList.contains('container-desplegables') ){
      elementHTML?.scrollIntoView(true);
      button?.classList.add('button-selected');
      button?.classList.remove('border');
      button?.classList.remove('border-buttons');
      button?.classList.add('border-0'); 

    } else {
      button?.classList.remove('button-selected');
      button?.classList.add('border');
      button?.classList.add('border-buttons');
      button?.classList.remove('border-0'); 
    }
  }




  mapeoReportes(): void { 

    let basicoMapped = null;
    if(this.reportesPrincipalesObj?.basicReport){
        basicoMapped = this.mapeoReporteBasico(this.reportesPrincipalesObj.basicReport?.reporte);
        this.rutPrincipal = basicoMapped.rutDicom
    }

    let tesoreriaMapped = null;
    if(this.reportesPrincipalesObj?.tesoreria) {
        tesoreriaMapped = this.mapeoTesoreria(this.reportesPrincipalesObj?.tesoreria?.reporte?.reporteTesoreria);
        this.reporteTesoreriaPrincipal = this.reportesPrincipalesObj?.tesoreria
    }
    
    let sbifMapped = null;
    if(this.reportesPrincipalesObj?.deudaSbif){
      sbifMapped = this.mapeoSbif(this.reportesPrincipalesObj?.deudaSbif.reporte);
    }

    let sbifDetalleMapped = null;
    if(this.reportesPrincipalesObj?.deudaSbif){
      sbifDetalleMapped = this.mapeoSbifDetalle(this.reportesPrincipalesObj?.deudaSbif.reporte);
    }

    let f29Mapped = null;
    if(this.reportesPrincipalesObj?.f29 || 
      this.reportesPrincipalesObj?.cteVentasBalancesRentas?.reporte?.CarpetaTributariaElectronicaReporte?.Reporte?.f29 || 
      this.reportesPrincipalesObj?.f29Ventas?.reporte?.Formulario29Reporte?.Reporte
    ) {
      f29Mapped = this.mapeof29();
    }

    let poderJudicialMapped = null;
    if(this.reportesPrincipalesObj?.poderJudicial) {
      poderJudicialMapped = this.mapeoPJ(this.reportesPrincipalesObj?.poderJudicial.reporte);
      this.juiciosEmpresa = poderJudicialMapped;
    }

    let lcvMapped = null;
    if(this.reportesPrincipalesObj?.libroCompraVenta?.reporte) {
      this.lcvPrincipal = this.reportesPrincipalesObj?.libroCompraVenta?.reporte;
    }

    this.consolidadoTitular = this.utilsService.crearConsolidadoTitular(this.obtenerDatosF22(), this.obtenerDatosF29(), this.reportesPrincipalesObj);
    this.factoring = this.utilsService.crearFactoring(this.reportesPrincipalesObj?.cesionFacturas);
    this.factoring = this.utilsService.addNdocAndPrincipalDeudor(this.factoring, this.reportesPrincipalesObj?.cesionFacturas)

    this.completeHeaderFicha(basicoMapped);
    this.completeTablaSocios();
    this.completeDatosRiesgo(basicoMapped, f29Mapped, tesoreriaMapped);
    this.completeDicom(basicoMapped);
    this.completeDeudaSbif(sbifMapped, basicoMapped);
    this.completeSbifDetalle(sbifDetalleMapped, basicoMapped);
    this.completeJuiciosSocio();
    this.completeClasificacionDeudores();
  }

  completeJuiciosSocio() {
    let total = 0;
    if(this.reportesDerivadosObj?.socios){
      const socios = Object.keys(this.reportesDerivadosObj?.socios);

      for(let socioRut of socios) {
        const reportesSocio = this.reportesDerivadosObj?.socios[socioRut]
        const poderJudicial = reportesSocio?.poderJudicial;
        if(!poderJudicial) continue;

        const poderJudicialMapped = this.mapeoPJ(poderJudicial?.reporte);
        total += poderJudicialMapped;
      }
    }

    this.juiciosSocios = total;
  }

  mapeoPJ(reportePJ: any) {
    if(!reportePJ?.Reporte?.Totales) return  0;
    
    let total = 0;
    
    const civil = reportePJ?.Reporte?.Totales.nCausasCivil;
    const cobranza = reportePJ?.Reporte?.Totales.nCausasCobranza;
    const laboral = reportePJ?.Reporte?.Totales.nCausasLaboral;

    if(civil) total += civil;
    if(cobranza) total += cobranza;
    if(laboral) total += laboral;

    return total;
  }

  periodosSbifDetalle:any[] = []
  valoresSbifDetalleEmpresa: any[] = []
  sbifDetalleSocios: any[][] = [];

  completeSbifDetalle(sbifDetalleEmpresa: any, basicoMapped: any) {
    if(!sbifDetalleEmpresa || !sbifDetalleEmpresa.length) return
    this.valoresSbifDetalleEmpresa.push(basicoMapped?.nombreDicom);
    for(let element of sbifDetalleEmpresa) {
      this.periodosSbifDetalle.push(element.periodo);
      this.valoresSbifDetalleEmpresa.push(element.value);
    }

    // Completo el detalle para los socios
    // Socios
    if(this.reportesDerivadosObj?.socios){
      const socios = Object.keys(this.reportesDerivadosObj?.socios);

      for(let socioRut of socios) {
        const reportesSocio = this.reportesDerivadosObj?.socios[socioRut]
        const deudaSbif = reportesSocio?.deudaSbif;
        const basicReport = reportesSocio?.basicReport;
        if(!deudaSbif || !basicReport) continue;

        const basicoMapped = this.mapeoReporteBasico(basicReport?.reporte);
        const reporteMapped = this.mapeoSbifDetalle(deudaSbif?.reporte);

        let row: any[] = [{ name: basicoMapped.nombreDicom, rut: socioRut}]
        for(let periodo of this.periodosSbifDetalle){
          let itemFound = reporteMapped.find(item => item.periodo === periodo);
          if(itemFound) {
            row.push(itemFound.value);
          } else {
            row.push('0');
          }
        }
        this.sbifDetalleSocios.push(row);
      }
    }
  }

  

  mapeoSbifDetalle(sbifReport: any) {
    const sbifItems = sbifReport?.Sbif?.rowsMeses?.item
    if(!sbifItems) return [];

    // Function to sort by "periodo"
    const sortedData = sbifItems.sort((a: any, b: any) => {
      const [monthA, yearA] = a.periodo.split('-').map(Number);
      const [monthB, yearB] = b.periodo.split('-').map(Number);

      if (yearA !== yearB) {
          return yearB - yearA; // Sort by year descending
      }
      return monthB - monthA; // Sort by month descending
    });

    // Get the 12 most recent elements
    const mostRecent12 = sortedData.slice(0, 12);

    let detalles = []
    for(let element of mostRecent12) {
      const periodo = element.periodo;
      const value = Number(element.dirvigente) + Number(element.dirvencida) + Number(element.dirven180mayor3year) + Number(element.dirmorosa) + Number(element.castigadadirecta)
    
      detalles.push({periodo, value})
    }

    return detalles;
  }






  public sbifEmpresa = {
    nombre: '',
    vigente: '',
    morosa: '',
    indirecta: '',
    lc: ''
  };
  public sbifSocios: any[] = [];
  public completeDeudaSbif(sbifReport: any, basicReport: any) {
    this.sbifEmpresa.nombre = basicReport?.nombreDicom
    this.sbifEmpresa.vigente = sbifReport?.vigenteSbif
    this.sbifEmpresa.morosa = sbifReport?.morosa
    this.sbifEmpresa.indirecta = sbifReport?.indirecta
    this.sbifEmpresa.lc = sbifReport?.lc

    // Socios
    if(this.reportesDerivadosObj?.socios){
      const socios = Object.keys(this.reportesDerivadosObj.socios);

      for(let socioRut of socios) {
        const reportesSocio = this.reportesDerivadosObj.socios[socioRut]
        const deudaSbif = reportesSocio?.deudaSbif;
        const basicReport = reportesSocio?.basicReport;
        if(!deudaSbif || !basicReport) continue;

        const basicoMapped = this.mapeoReporteBasico(basicReport.reporte);
        const reporteMapped = this.mapeoSbif(deudaSbif.reporte);

        const sbifRowSocio: any = {
          rut: socioRut,
          nombre: basicoMapped.nombreDicom,
          vigente: reporteMapped.vigenteSbif,
          morosa: reporteMapped.morosa,
          indirecta: reporteMapped.indirecta,
          lc: reporteMapped.lc
        }

        this.sbifSocios.push(sbifRowSocio);
      }
    }
  }






  clasificacionDeudoresForm: FormGroup = this.fb.group({
    elements: this.fb.array([])
  })
  get calsificacionDeudoresFormArray(): FormArray {
    return this.clasificacionDeudoresForm.get('elements') as FormArray;
  }
  /*addClasificacionDeudoresFormGroup(group: FormGroup) {
    this.calsificacionDeudoresFormArray.push(group);
  }*/

  addClasificacionDeudoresFormGroup(group: FormGroup) {
    if (!(group instanceof FormGroup)) {
      return;
    }
  
    if (!group.value || !group.value.nombreDeudor) {
      return;
    }
  
    if (!(this.calsificacionDeudoresFormArray instanceof FormArray)) {
      return;
    }
  
    // Buscar índice del grupo existente en el FormArray
    const index = this.calsificacionDeudoresFormArray.controls.findIndex((control: AbstractControl) => {
      return control.value.nombreDeudor === group.value.nombreDeudor;
    });
  
    if (index === -1) {
      // Si no existe, agregar el nuevo grupo al FormArray
      this.calsificacionDeudoresFormArray.push(group);
    } else {
      // Si existe, obtener el grupo actual y comparar prioridades
      const existingGroup = this.calsificacionDeudoresFormArray.at(index) as FormGroup;
      const existingValues = existingGroup.value;
      const newValues = group.value;
  
      // Priorizar el grupo que tenga datos en las propiedades específicas
      const hasData = (values: any) =>
        values.montoOperado || values.morosidadDias || values.morosidadPD || values.clasificacionAcordada;
  
      const useNewGroup = hasData(newValues) && !hasData(existingValues);
  
      // Si el nuevo grupo tiene prioridad, reemplazar el existente
      if (useNewGroup) {
        this.calsificacionDeudoresFormArray.setControl(index, group);
      }
    }
  
    // Actualizar el estado del FormArray
    this.calsificacionDeudoresFormArray.updateValueAndValidity();
  }

  completeClasificacionDeudores() {
    if(this.reportesDerivadosObj?.deudores){
      const deudores = Object.keys(this.reportesDerivadosObj?.deudores);

      for(let deudorRut of deudores) {
        const reportesDeudor = this.reportesDerivadosObj?.deudores[deudorRut]
        
        let basicoMapped = this.mapeoReporteBasico(reportesDeudor?.basicReport?.reporte);
        
        const dupla12M = this.getDupla12M(deudorRut, this.lcvPrincipal);
        const monto12M = this.getMonto12M(deudorRut);
        const facturacionDupla = this.getFacturacionDupla(this.lcvPrincipal, monto12M);
        const nFacturas = this.getNFacturas(deudorRut, this.lcvPrincipal);
        const ncDupla = reportesDeudor?.lcvData?.porcentajeNcSobreVentas;

        const formGroup = this.fb.group({
         nombreDeudor: [basicoMapped.nombreDicom || '', Validators.required],
         clasificacionAcordada: ['', Validators.required], // Manual
         dupla12M: [dupla12M || '', Validators.required],
         monto12M: [monto12M || '', Validators.required],
         morosidadPD: ['', Validators.required], // Manual
         morosidadDias: ['', Validators.required], // Manual
         facturacionDupla: [facturacionDupla || '', Validators.required],
         ncDupla: [ncDupla || '0', Validators.required],
         nFacturas: [nFacturas || '', Validators.required],
         montoOperado: ['', Validators.required], // Manual
        });
        this.addClasificacionDeudoresFormGroup(formGroup);
      }
    }
  }

  getNFacturas(deudorRut:any, lcvPrincipal: any): number { 
    let count = 0;
    const ventasLcv = this.mapVentasLcv(lcvPrincipal?.Libros);

    for(let venta of ventasLcv) {
      if(venta?.Rutcliente == deudorRut) {
        count++
      }
    }
    return count;
  }

  getFacturacionDupla(lcvPrincipal:any, monto12M: number): number {
    if(monto12M == 0) return 0;

    let montoTotalVentasAllPeriodos = 0;
    if(!lcvPrincipal?.Libros) return 0;
    for(let libro of lcvPrincipal?.Libros) {
      let montoTotal = libro?.Reporte?.totalizadores?.totalMontoVentas;
      if(!montoTotal) continue;
      montoTotalVentasAllPeriodos += montoTotal
    }

    if(montoTotalVentasAllPeriodos == 0) return 0;

    return monto12M / montoTotalVentasAllPeriodos;
  }

  /*getMonto12M(deudorRut: string, lcvPrincipal:any): number {
    let monto = 0;
    const ventasLcv = this.mapVentasLcv(lcvPrincipal?.Libros);

    for(let venta of ventasLcv) {
      let montoVenta = venta.Montototal;
      if(!montoVenta) continue;

      if(venta.Rutcliente == deudorRut) {
        montoVenta = Number(montoVenta)
        monto += montoVenta
      }
    }

    return monto;
  }*/

  getMonto12M(deudorRut: string): number {
    let monto = 0;

    const entidadDeudores = this.entidadesDerivadas.find((e: any) => e.entidad === 'deudores');
    if(entidadDeudores && entidadDeudores?.items && entidadDeudores?.items.length > 0) {
      const rutElement = entidadDeudores.items.find((e: any) => e.rut === deudorRut);
      if(rutElement && rutElement?.atributos && rutElement?.atributos.length > 0) {
        const atributoMonto = rutElement.atributos.find((e: any) => e.name === 'montoTotal')
        if(atributoMonto && atributoMonto?.value) {
          monto = atributoMonto?.value
        }
      }
    }

    return monto;
  }

  // Cantidad de veces que el deudor aparece en las ventas del titular
  getDupla12M(deudorRut: string, lcvPrincipal:any): number {
    let count = 0;

    if(!lcvPrincipal?.Libros) return count;
    for(let libro of lcvPrincipal?.Libros) {
      const ventasLibro = libro?.LibroCompraVentaReporte?.Reporte?.registros?.venta;
      if(!ventasLibro) continue;

      for(let venta of ventasLibro) {
        if(venta.Rutcliente == deudorRut) {
          count++
          break;
        }
      }
      
    }
    return count;
  }

  mapVentasLcv(libros: any[]): any[] {
    if(!libros) return [];
    let ventasTotales: any[] = [];
    for(let libro of libros) {
      const ventasLibro = libro?.LibroCompraVentaReporte?.Reporte?.registros?.venta;
      if(!ventasLibro) continue;

      ventasTotales = ventasTotales.concat(ventasLibro);
    }
    return ventasTotales;
  }

  sociosForm: FormGroup = this.fb.group({
    elements: this.fb.array([])
  });
  get sociosFormArray(): FormArray {
    return this.sociosForm.get('elements') as FormArray;
  }
  addSocioFormGroup(group: FormGroup) {
    this.sociosFormArray.push(group);
  }
  completeTablaSocios() {
    if (this.reportesDerivadosObj?.socios && !this.sociosSet) {
      const socios = Object.keys(this.reportesDerivadosObj.socios);
  
      for (let socioRut of socios) {
        const reportesDeudor = this.reportesDerivadosObj.socios[socioRut];
        const basicReport = reportesDeudor?.basicReport;
        if (!basicReport) continue;
  
        const reporteMapped = this.mapeoReporteBasico(basicReport.reporte);
  
        const socioGroup = this.fb.group({
          nombre: [reporteMapped.nombreDicom || '', []],
          rut: [reporteMapped.rutDicom || '', []],
          participacion: ['', []], // Add this to capture %Participación
        });
  
        this.addSocioFormGroup(socioGroup);
      }
    }
  }

  completeDicom(reporteBasicoEmpresa: any) {
    // Empresa
    this.dicomEmpresa.motivo = reporteBasicoEmpresa?.motivo;
    this.dicomEmpresa.monto = reporteBasicoEmpresa?.montoDicom
    this.dicomEmpresa.nombre = reporteBasicoEmpresa?.nombreDicom;
    this.dicomEmpresa.numero = reporteBasicoEmpresa?.numeroDicom;
    this.dicomEmpresa.rut = reporteBasicoEmpresa?.rutDicom;

    // Socios
    if(this.reportesDerivadosObj?.socios){
      const socios = Object.keys(this.reportesDerivadosObj.socios);

      for(let socioRut of socios) {
        const reportesDeudor = this.reportesDerivadosObj.socios[socioRut]
        const basicReport = reportesDeudor?.basicReport;
        if(!basicReport) continue;

        const reporteMapped = this.mapeoReporteBasico(basicReport.reporte);

        const dicomRowSocio: DicomRow = {
          motivo: reporteMapped.motivo,
          monto: reporteMapped.montoDicom,
          nombre: reporteMapped.nombreDicom,
          numero: reporteMapped.numeroDicom,
          rut: socioRut
        }

        this.dicomSocios.push(dicomRowSocio);
      }
    }

    // Deudores
    if(this.reportesDerivadosObj?.deudores){
      const deudores = Object.keys(this.reportesDerivadosObj.deudores);

      for(let deudorRut of deudores) {
        const reportesDeudor = this.reportesDerivadosObj.deudores[deudorRut]
        const basicReport = reportesDeudor?.basicReport;
        if(!basicReport) continue;

        const reporteMapped = this.mapeoReporteBasico(basicReport.reporte);

        const dicomRowDeudor: DicomRow = {
          motivo: reporteMapped.motivo,
          monto: reporteMapped.montoDicom,
          nombre: reporteMapped.nombreDicom,
          numero: reporteMapped.numeroDicom,
          rut: deudorRut
        }

        this.dicomDeudores.push(dicomRowDeudor);
      }
    }

  }

  completeDatosRiesgo(reporteBasico: any, f29: any, tesoreria: any) {

    let difMeses = 0;
    if(reporteBasico) {  
      const currentDate = new Date();
      const inicioActividades = new Date(reporteBasico.inicioActividades);
      difMeses = this.getMonthDifference(inicioActividades, currentDate);
    } 

    let deudaConvenio = 0;
    if(tesoreria?.deudaConvenio) {
      deudaConvenio = tesoreria?.deudaConvenio/1000
    }

    // const fechaActual = 
    this.datosRiesgoForm.setValue({
      inicioActividades: reporteBasico?.inicioActividades || '',
      anosMeses: difMeses || '',
      promedioVentas12M: f29?.f29Promedio12M || '',
      margenMensual: f29?.margenMensual || '',
      lagunas: f29?.lagunas || '',
      alzaBaja: f29?.alzaBaja || '',
      vehiculos: reporteBasico?.vehiculos || '0',
      propiedades: reporteBasico?.propiedades || '0',
      morosidad: tesoreria?.morosidadTGR || '',
      notificacionEmbargo: tesoreria?.notificacionEmbargo || '',
      deudaConvenio: deudaConvenio || '0',
      cuotasMorosas: tesoreria?.cuotasMorosas || ''
    });
  }

  getMonthDifference(startDate: Date, endDate: Date): number {
    const yearsDifference = endDate.getFullYear() - startDate.getFullYear();
    const monthsDifference = endDate.getMonth() - startDate.getMonth();

    return yearsDifference * 12 + monthsDifference;
  }

  completeHeaderFicha(basicoMapped: any) {
    const fecha = this.formatFechaTx(this.fechaTx)
    const ejec = this.getCampoPersonalizado('Ejecutivo Broke')

    const formValues = {
        nombreComite: basicoMapped?.nombre ? basicoMapped?.nombre : '',
        giroComite: basicoMapped?.giro ? basicoMapped?.giro : '',
        ejecutivoComite: ejec ? ejec : '',
        rutComite: basicoMapped?.rut ? basicoMapped?.rut : '',
        direccion: basicoMapped?.direccion ? basicoMapped?.direccion : '',
        fechaComite: fecha ? fecha : ''
    };
    
    this.servicioFichaForm.patchValue(formValues);
  }

  formatFechaTx(fecha: string) {
    const date = new Date(fecha);

    // Extract day, month, and year
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed, so add 1
    const year = date.getFullYear();

    // Format the date as "DD-MM-YYYY"
    const formattedDate = `${day}-${month}-${year}`;

    return formattedDate
  }

  mapeoReporteBasico(reporteResponse: any) {
    // const reporteResponse: any = {};

    // Ficha Puente Financiero
    const nombre = reporteResponse?.InformacionBasica?.NombreORazonSocial;
    const giro = reporteResponse?.Riesgo?.ActividadEconomica;
    const rut = reporteResponse?.InformacionBasica?.Rut;
    const direccion = reporteResponse?.InformacionBasica?.Direccion;

    // Riesgo
    const inicioActividades = reporteResponse?.InformacionBasica?.AnoInicioActividades;
    const vehiculos = reporteResponse?.AntecedentesFinancieros?.ValorVehiculos;
    const propiedades = reporteResponse?.AntecedentesFinancieros?.ValorBienesRaices;

    // DICOM
    const nombreDicom = nombre;
    const rutDicom = rut;
    const numeroDicom = reporteResponse?.AntecedentesComerciales?.Resumen?.TotalImpagosCantidad;
    const montoDicom = reporteResponse?.AntecedentesComerciales?.Resumen?.TotalImpagosMonto;
    
    let motivo = "";
    if(reporteResponse?.AntecedentesComerciales?.Resumen?.ProtestosMonto > 0) {
      motivo = "Protesto"
    }
    if(reporteResponse?.AntecedentesComerciales?.Resumen?.MorosidadesMonto > 0) {
      motivo = "Morosidad"
    }
    if(reporteResponse?.AntecedentesComerciales?.Resumen?.InfraccionesLaboralesMonto > 0) {
      motivo = "Infracciones Laborales"
    }

    return {
        nombre,
        giro,
        rut,
        direccion,
        inicioActividades,
        vehiculos,
        propiedades,
        nombreDicom,
        rutDicom,
        numeroDicom,
        montoDicom,
        motivo
    }
    
  }

  mapeof29() {
    const f29Clean = this.obtenerDatosF29();
    const f29Last12 = f29Clean.slice(0, 12);

    const f29Promedio12M = this.getAvgF29(f29Last12, 'ventasNetasMilesPesos');
    const margenMensual = this.getAvgF29(f29Last12, 'margenMiles');
    const lagunas = this.checkLagunas(f29Clean);
    const alzaBaja = this.checkAlzaBaja(f29Clean);

    return {
      f29Promedio12M,
      margenMensual,
      lagunas,
      alzaBaja
    }
  }

  checkAlzaBaja(f29Clean: any[]) {
    if(f29Clean.length < 1) return ''

    let i = 0;
    let sumCurrentYear = 0; // A comparar
    const currentPeriodo = f29Clean[0]?.periodo;
    let biggestNumberMonth = 0;
    let previousPeriodo = 0;

    while(f29Clean[i]) {
      const currentItem =  f29Clean[i];
      if(currentItem.periodo != currentPeriodo){
        previousPeriodo = currentItem?.periodo;
        break;
      }

      biggestNumberMonth = currentItem?.mes;
      sumCurrentYear += currentItem?.calculado?.ventasNetasMilesPesos;
      i += 1;

    }

    let sumPreviousYear = 0 // A comparar

    for(let item of f29Clean) {
      if(item?.mes <= biggestNumberMonth && item?.periodo == previousPeriodo){
        sumPreviousYear += item?.calculado?.ventasNetasMilesPesos || 0;
      }
    }

    return this.compareNumbers(sumCurrentYear, sumPreviousYear);
  }

  compareNumbers(num1: number, num2: number): string {
    const difference = num1 - num2;
    const percentage = (difference / num2) * 100;
    const roundedPercentage = Math.round(percentage);
  
    if (roundedPercentage > 0) {
      return `Alza ${roundedPercentage}%`;
    } else if (roundedPercentage < 0) {
      return `Baja ${Math.abs(roundedPercentage)}%`;
    } else {
      return 'Igual';
    }
  }
  

  checkLagunas(f29Clean: any[]) {
    let response = "NO"
    if(f29Clean.length < 1) return response;

    let first = true;
    let mesAnterior = f29Clean[0].mes;
    let periodoAnterior = f29Clean[0].periodo;
    for(let item of f29Clean) {
      if(first) {
        first = false;
        continue;
      }
      
      const mesActual = item.mes;
      const periodoActual = item.periodo;
      if(mesActual != mesAnterior + 1 && periodoAnterior == periodoActual ) {
        return "SI"
      }

      mesAnterior = mesActual
      periodoAnterior = periodoActual
    }

    return response
  }

  mapeoSbif(reporteResponse: any) {
    const lastPeriodo = this.getLastPeriodo(reporteResponse);
    
    // Detalle Deuda SBIF
    const vigenteSbif = lastPeriodo?.dirvigente;
    const morosa = this.getMorosa(lastPeriodo);
    const indirecta = this.getIndirecta(lastPeriodo);
    const lc = lastPeriodo?.lineacredito;

    return {
      vigenteSbif,
      morosa,
      indirecta,
      lc
    }
  }

  mapeoTesoreria(reporteResponse: any) {
    // Riesgo
    const morosidadTGR = reporteResponse?.deudas?.contribuciones?.vencidas + reporteResponse?.deudas?.fiscales?.vencidas
    const deudaConvenio = reporteResponse?.convenios?.vigentes?.monto;
    const notificacionEmbargo = this.getNotificacion(reporteResponse);
    const cuotasMorosas = this.getCuotasMorosasCount(reporteResponse);

    return {
      morosidadTGR,
      deudaConvenio,
      notificacionEmbargo,
      cuotasMorosas
    }
  }

  getCuotasMorosasCount(reporteTesoreria: any) {
    const datosArray = reporteTesoreria?.convenios?.vigentes?.datos;
    if(!datosArray) return "";

    let sumCuotasMorosas = 0;
    for(let dato of datosArray) {
        const valorSuma = dato.detalle?.informacion?.cuotasVencidas
        if(valorSuma) sumCuotasMorosas += valorSuma
    }
    
    return sumCuotasMorosas;
  }

  getNotificacion(reporteTesoreria: any) {
    const cuentas = reporteTesoreria?.cobranzas?.judicial?.cuentas;
    if(!cuentas) return "Sin Información";

    let response = ""
    for(let cuenta of cuentas) {
        const exp = cuenta.expediente
        const etapa = cuenta.etapa
        const thisCuenta = "{ " + exp + ": " + etapa + " } "

        response += thisCuenta;
    }

    if(response == "") response = "Sin Información"

    return response;
  }

  getLastPeriodo(reporteSbif: any) {

        const periodos = reporteSbif?.Sbif?.rowsMeses?.item;
        if(!periodos) return {};

        const latestPeriodObject = periodos.reduce((latest: any, current: any) => {
            const [currentMonth, currentYear] = current.periodo.split('-').map(Number);
            const [latestMonth, latestYear] = latest.periodo.split('-').map(Number);
        
            if (currentYear > latestYear || (currentYear === latestYear && currentMonth > latestMonth)) {
                return current;
            } else {
                return latest;
            }
        });
        if(!latestPeriodObject) return {};

        return latestPeriodObject;
  }

  getMorosa(lastPeriodo: any) {
    const dirvencida = Number(lastPeriodo?.dirvencida);
    const dirven180mayor3year = Number(lastPeriodo?.dirven180mayor3year);
    const dirmorosa = Number(lastPeriodo?.dirmorosa);
    const castigadadirecta = Number(lastPeriodo?.castigadadirecta);

    return dirvencida + dirven180mayor3year + dirmorosa + castigadadirecta;
  }

  getIndirecta(lastPeriodo: any) {
    const indirectavig = Number(lastPeriodo?.indirectavig);
    const indirectaven = Number(lastPeriodo?.indirectaven);
    const castigadaindirecta = Number(lastPeriodo?.castigadaindirecta);

    return indirectavig + indirectaven + castigadaindirecta;
  }

  obtenerDatosF29(): any[] {
    const carpetaF29 = this.reportesPrincipalesObj?.cteVentasBalancesRentas?.reporte?.CarpetaTributariaElectronicaReporte?.Reporte?.f29 || [];
    const historicoF29 = this.reportesPrincipalesObj?.f29?.reporte?.ListadoF29Reportes || [];
    const calculadoF29 = this.reportesPrincipalesObj?.f29Ventas?.reporte?.Formulario29Reporte?.Reporte || {};
    
    const F29: any[] = [];
    carpetaF29.forEach((element: any) => {
      if(!element?.message){
        F29.push(element);
      }
    });
    historicoF29.forEach((element: any) => {
      if(!element?.Formulario29Reporte?.Reporte.message){
        F29.push(element?.Formulario29Reporte?.Reporte);
      }
    });
    if(calculadoF29 && Object.keys(calculadoF29).length > 0 && !calculadoF29?.message){
      F29.push(calculadoF29);
    }

    const ordenadoF29 = F29.sort((a: any,b: any) => { // mayor a menor en año y menor a mayor en mes
      if (!a?.calculado) return 1;
      if (!b?.calculado) return -1;
      const periodoDiff = Number(b.calculado.periodo) - Number(a.calculado.periodo);
      if (periodoDiff !== 0) return periodoDiff;
      return Number(a.calculado.mes) - Number(b.calculado.mes);
    });
    const F29Limpio: any[] = [];

    ordenadoF29.forEach((element: any) => {
      const indexRepetido = F29Limpio.findIndex(e => Number(e?.calculado?.periodo) === Number(element?.calculado?.periodo) && Number(e?.calculado?.mes) === Number(element?.calculado?.mes));
      if(indexRepetido >= 0){
        if(F29Limpio[indexRepetido]?.fecha_ingreso < element.fecha_ingreso){
          F29Limpio[indexRepetido] = element;
        }
      } else {
        F29Limpio.push(element);
      }
    })

    return F29Limpio;
  }


  getAvgF29(f29Last12: any[], atr: string) {
    const sum = f29Last12.reduce((accumulator, item) => {
        return accumulator + item?.calculado[atr];
    }, 0);
    
    // Step 2: Calculate the average
    const average = sum / f29Last12.length;

    return average;
  }

  getCampoPersonalizado(name: string) {
    const ejecutivoBrokeObj = this.atributos.find(item => item.nameAttribute === name);

    if (ejecutivoBrokeObj) {
        // Get the value from allowedValues using the value attribute
        const ejecutivoBrokeValue = ejecutivoBrokeObj.allowedValues[ejecutivoBrokeObj.value];
        return ejecutivoBrokeValue
    }
  }

  deudoresForm: FormGroup = this.fb.group({
    elements: this.fb.array([])
  });
  get deudoresFormArray(): FormArray {
    return this.deudoresForm.get('elements') as FormArray;
  }
  setTablaDeudores(deudores: any[]){
    if(this.deudoresSet) return;
    let deudoresMapped = [];
    for(let deudor of deudores) {
        deudoresMapped.push({
            montoSolicitado: '', 
            plazo: '', 
            cuotas: '', 
            deudor: deudor.nombre, 
            rut: deudor.rut, 
            tipo: '', 
            motivo: ''
        })
    }

    this.deudoresForm = this.fb.group({
        elements: this.fb.array(deudoresMapped.map(item => this.createDeudoresFormGroup(item)))
    });
  }

  createDeudoresFormGroup(item: any): FormGroup {
    return this.fb.group({
      montoSolicitado: [item.montoSolicitado, Validators.required],
      plazo: [item.plazo, Validators.required],
      cuotas: [item.cuotas, Validators.required],
      deudor: [item.deudor], // Display only
      rut: [item.rut],       // Display only
      tipo: [item.tipo, Validators.required],
      motivo: [item.motivo, Validators.required]
    });
  }


  abrirModalTitular(): void{
    if(this.reportesPrincipalesObj){
      this.mapeoReporteTitularModal();
      this.mapeoBalanceModal();
      this.mapeoPoderJudicialModal();
      this.mapeoSbifModal();
      this.validaObjetosModal();
    }

    this.reportesSeleccionado.consolidadoTitular = this.consolidadoTitular;
  
    this.rutSeleccionado = this.rutPrincipal;
    this.plantillaAntecedentes = 'cesceDemo';
    this.modalDefault = false;

    this.modalService.open();
  }

  abrirModalSocio(rut: string): void{
    if(rut && this.reportesDerivadosObj.socios){
      if(this.reportesDerivadosObj.socios?.[rut]){
        this.reportesSeleccionado = this.reportesDerivadosObj.socios[rut];
        this.rutSeleccionado = rut;
        this.informeComercial = this.utilsService.identificarInformeComercial(this.reportesSeleccionado?.basicReport?.reporte);
        this.mapeoPoderJudicialModal();
        this.mapeoVehiculosModal();
        this.mapeoBienesRaicesModal();
        this.mapeoSbifModal();
        this.validaObjetosModal();
        this.plantillaAntecedentes = '';
        this.modalDefault = true;
  
        this.modalService.open();
      }
    }
    
  }

  abrirModalDeudor(rut: string): void{
    if(rut && this.reportesDerivadosObj.deudores){
      if(this.reportesDerivadosObj.deudores?.[rut]){
        this.reportesSeleccionado = this.reportesDerivadosObj.deudores[rut];
        this.rutSeleccionado = rut;
        this.informeComercial = this.utilsService.identificarInformeComercial(this.reportesSeleccionado?.basicReport?.reporte);
        this.mapeoPoderJudicialModal();
        this.mapeoVehiculosModal();
        this.mapeoBienesRaicesModal();
        this.mapeoSbifModal();
        this.validaObjetosModal();
        this.plantillaAntecedentes = '';
        this.modalDefault = true;
  
        this.modalService.open();
      }
    }
    
  }

  validaObjetosModal(): void{
    if(this.reportesSeleccionado?.cteVentasBalancesRentas?.reporte && Object.keys(this.reportesSeleccionado?.cteVentasBalancesRentas?.reporte).length === 0){
      this.reportesSeleccionado.cteVentasBalancesRentas.reporte = null;
    }
    if(this.reportesSeleccionado?.basicReport?.reporte && Object.keys(this.reportesSeleccionado?.basicReport?.reporte).length === 0){
      this.reportesSeleccionado.basicReport.reporte = null;
    }
    if(this.reportesSeleccionado?.deudaSbif?.reporte && Object.keys(this.reportesSeleccionado?.deudaSbif?.reporte).length === 0){
      this.reportesSeleccionado.deudaSbif.reporte = null;
    }
    if(this.reportesSeleccionado?.boletinConcursal?.reporte && Object.keys(this.reportesSeleccionado?.boletinConcursal?.reporte).length === 0){
      this.reportesSeleccionado.boletinConcursal.reporte = null;
    }
    if(this.reportesSeleccionado?.reporteEmpresaDia?.reporte && Object.keys(this.reportesSeleccionado?.reporteEmpresaDia?.reporte).length === 0){
      this.reportesSeleccionado.reporteEmpresaDia.reporte = null;
    }
  }

  mapeoVehiculosModal(): void{
    if(this.reportesSeleccionado?.vehiculos?.reporte){
      this.reportesSeleccionado.objReporteVehiculos = this.reportesSeleccionado?.vehiculos?.reporte;
    }
  }

  mapeoBienesRaicesModal(): void{
    if(this.reportesSeleccionado?.bienesRaices?.reporte){
      this.reportesSeleccionado.objReporteBienesRaices = this.reportesSeleccionado?.bienesRaices?.reporte;
    }
  }

  mapeoPoderJudicialModal(): void{
    if(this.reportesSeleccionado?.poderJudicial?.reporte){
      const datos = this.reportesSeleccionado?.poderJudicial?.reporte["Totalizadores Poder Judicial"];
      const reporte = {
        poderJudicialReporte: {
          poderJudicial: {
            Totales: datos
          }
        }
      };
      this.reportesSeleccionado.poderJudicial.reporteMapeado = reporte;
    }
    if(this.reportesSeleccionado?.poderJudicial?.reporte?.Reporte){
      const datos = this.reportesSeleccionado?.poderJudicial?.reporte?.Reporte;
      const reporte = {
        poderJudicialReporte: {
          poderJudicial: datos
        }
      };

      this.reportesSeleccionado.poderJudicial.ReporteFull = reporte;
    }
  }

  mapeoBalanceModal(): void {
    const datosF22: any[] = this.obtenerDatosF22();
    const datosF29: any[] = this.obtenerDatosF29();
    if(datosF22.length > 0 || datosF29.length > 0){
      const respuesta: any = {CarpetaTributariaElectronicaReporte: {
        DatosBasicosSolicitud: {Rut: this.rut.slice(0, -1), DV: this.rut.slice(-1)},
        DatosSolicitante: {},
        Reporte: {
          f22: datosF22 || [],
          f29: datosF29 || [],
          socios: this.obtenerSocios || [],
          representantes: []
        }
      }};

      this.reportesSeleccionado.cteVentasBalancesRentas = {reporte: respuesta};
    }
  }

  mapeoReporteTitularModal(): void {
    this.reportesSeleccionado = {
      deudaSbif: this.reportesPrincipalesObj?.deudaSbif,
      poderJudicial: this.reportesPrincipalesObj?.poderJudicial,
      boletinConcursal: this.reportesPrincipalesObj?.boletinConcursal,
      objReporteVehiculos: this.reportesPrincipalesObj?.vehiculos?.reporte,
      objReporteBienesRaices: this.reportesPrincipalesObj?.bienesRaices?.reporte
    } 
  }

  obtenerDatosF22(): any[] {
    const carpetaF22 = this.reportesPrincipalesObj?.cteVentasBalancesRentas?.reporte?.CarpetaTributariaElectronicaReporte?.Reporte?.f22 || [];
    const historicoF22 = this.reportesPrincipalesObj?.f22?.reporte?.ListadoF22Reportes || [];
    const calculadoF22 = this.reportesPrincipalesObj?.f22BalancesRentas?.reporte?.Formulario22Reporte || {};
    const f22Manual = this.reportesPrincipalesObj?.balanceManual?.reporte?.ListadoF22Reportes || [];

    const F22: any[] = [];
    carpetaF22.forEach((element: any) => {
      if(element) {
        element.FechaReporte = this.reportesPrincipalesObj?.cteVentasBalancesRentas?.reporte?.CarpetaTributariaElectronicaReporte?.DatosBasicosSolicitud?.FechaReporte;
        F22.push(element);
      }
    });
    historicoF22.forEach((element: any) => {
      if(element) {
        const reporte: any = element?.Formulario22Reporte?.Reporte;
        reporte.FechaReporte = element?.Formulario22Reporte?.DatosBasicosSolicitud?.FechaReporte;
        F22.push(reporte);
      }
    });
    if(calculadoF22 && Object.keys(calculadoF22).length > 0){
      const elemento: any = calculadoF22?.Reporte;
      if(elemento) {
        elemento.FechaReporte = calculadoF22?.DatosBasicosSolicitud?.FechaReporte;
        F22.push(elemento);
      }
    }
    f22Manual.forEach((element: any) => {
      if(element) {
        const reporte: any = element?.Formulario22Reporte?.Reporte;
        reporte.FechaReporte = element?.Formulario22Reporte?.DatosBasicosSolicitud?.FechaReporte;
        F22.push(reporte);
      }
    });

    const ordenadoF22 = F22.sort((a: any,b: any) => Number(b?.calculado?.periodo) - Number(a?.calculado?.periodo)); // mayor a menor en año
    const F22Limpio: any[] = [];

    ordenadoF22.forEach((element: any) => {
      if(element?.calculado?.periodo){
        const indexRepetido = F22Limpio.findIndex(e => Number(e?.calculado?.periodo) === Number(element?.calculado?.periodo));
        if(indexRepetido >= 0){
          if(F22Limpio[indexRepetido]?.FechaReporte < element.FechaReporte){
            F22Limpio[indexRepetido] = element;
          }
        } else {
          F22Limpio.push(element);
        }
      }
    });
    return F22Limpio;
  }

  obtenerSocios(): any[] {
    if(this.reportesPrincipalesObj?.cte?.reporte?.fechaReporte && this.reportesPrincipalesObj?.cteVentasBalancesRentas?.reporte?.fechaReporte) {
      if(this.reportesPrincipalesObj?.cte?.reporte?.fechaReporte >= this.reportesPrincipalesObj?.cteVentasBalancesRentas?.reporte?.fechaReporte) {
        return this.reportesPrincipalesObj?.cte?.reporte?.CarpetaTributariaElectronicaReporte?.Reporte?.socios || [];
      } else {
        return this.reportesPrincipalesObj?.cteVentasBalancesRentas?.reporte?.CarpetaTributariaElectronicaReporte?.Reporte?.socios || [];
      }
    } else {
      if(this.reportesPrincipalesObj?.cte?.reporte?.fechaReporte) {
        return this.reportesPrincipalesObj?.cte?.reporte?.CarpetaTributariaElectronicaReporte?.Reporte?.socios || [];
      } else if (this.reportesPrincipalesObj?.cteVentasBalancesRentas?.reporte?.fechaReporte) {
        return this.reportesPrincipalesObj?.cteVentasBalancesRentas?.reporte?.CarpetaTributariaElectronicaReporte?.Reporte?.socios || [];
      }
    }
    return [];
  }

  mapeoSbifModal(): void {
    if(this.reportesSeleccionado?.deudaSbif?.reporte){
      /*if(this.reportesSeleccionado?.deudaSbif?.reporte?.DeudaSistemaFinancieroReporte?.Documentos?.SbifOnline?.Reporte?.rowsMeses?.item){

      } else */
      if (this.reportesSeleccionado?.deudaSbif?.reporte?.Sbif?.rowsMeses?.item) {
        this.reportesSeleccionado.deudaSbif.reporte = {
          Documentos: {
            SbifOnline: {
              Reporte: {
                rowsMeses: this.reportesSeleccionado?.deudaSbif?.reporte?.Sbif?.rowsMeses
              }
            }
          }
        }
      }
    }
  }

  guardarFicha() {

    const ficha = {
      ficha: this.getFichaValues()
    }

    this.spinner.show();

    this.subscriptions.push(this.solicitudCreditoService.guardarFichaDeCredito(this.rut, this.idTransaccion, ficha).subscribe(resp => {
      this.fichaGuardada = ficha;
      this.alertService.success(resp.message)
      this.spinner.hide();
    }, (error: any) => {
      this.alertService.error(error?.error?.message || 'Solicitud de Crédito - Ocurrio un error con el servicio de guardado de la ficha');
      this.spinner.hide();
    }));
  }

  getFichaValues() {
    // Form values
    const servicioFichaForm = this.servicioFichaForm.value;
    const deudoresForm = this.deudoresForm.value;
    const sociosForm = this.sociosForm.value
    const descripcionesForm = this.descripcionesForm.value;
    const datosRiesgoForm = this.datosRiesgoForm.value;
    const operadoEnPuente = this.operadoEnPuente.value;
    const proyeccionForm = this.proyeccionForm.value;
    const operacionKtForm = this.operacionKtForm.value;
    const resolucionControl = this.resolucionControl.value;
    const detalleDeudaArray = this.detalleDeudaArray.value;
    const clasificacionDeudoresForm = this.clasificacionDeudoresForm.value

    // Extra values
    const dicomEmpresa = this.dicomEmpresa;
    const dicomSocios = this.dicomSocios; 
    const dicomDeudores = this.dicomDeudores;
    const sbifEmpresa = this.sbifEmpresa;
    const sbifSocios = this.sbifSocios;
    const juiciosSocios = this.juiciosSocios;
    const juiciosEmpresa = this.juiciosEmpresa;
    const periodosSbifDetalle = this.periodosSbifDetalle;
    const valoresSbifDetalleEmpresa = this.valoresSbifDetalleEmpresa;
    const sbifDetalleSocios = this.sbifDetalleSocios;
    const factoring = this.factoring;

    let monto = 0;
    this.deudoresFormArray.controls.forEach((group: AbstractControl) => {
      const montoSolicitado = group.get('montoSolicitado')?.value;
      const value = Number(montoSolicitado);

      monto += value;
    });

    let lineaPropuesta: any ={};
    let producto: any = {};

    if(this.type == 'pre-factoring') {
      lineaPropuesta = {
        "preFactoring": monto
      }
  
      producto = [
        {
          "id": "preFactoring",
          "texto": "Prefactoring"
        }
      ]
    } else {
      lineaPropuesta = {
        "capitalTrabajo": monto
      }
  
      producto = [
        {
          "id": "capitalTrabajo",
          "texto": "Capital de Trabajo"
        }
      ]
    }

    const lineaOperacionPropuestaTotales = {
      "totalesLineaPropuesta": monto,
    }
    
    let lineaOperacionPropuesta = {
      lineaPropuesta: lineaPropuesta,
      producto: producto
    }

    return { servicioFichaForm, deudoresForm, sociosForm, descripcionesForm, datosRiesgoForm, operadoEnPuente, proyeccionForm, operacionKtForm, resolucionControl, detalleDeudaArray, clasificacionDeudoresForm,
      lineaOperacionPropuesta, lineaOperacionPropuestaTotales,
      dicomEmpresa, dicomSocios, dicomDeudores, sbifEmpresa, sbifSocios, juiciosSocios, juiciosEmpresa, periodosSbifDetalle, valoresSbifDetalleEmpresa,
      sbifDetalleSocios, factoring
      }
  }

  validaUsuarioAdmin(): boolean {
    return this._sessionService.getUserAccess().includes(this.access[3]);
  }

  volverEtapaPrevia(): void {
    this.bloqueaPasos5.emit(false);
    this.solicitudCreditoService.setStep(Number(this.stepper - 1));
    this.spinner.hide();
  }

  continuar() {
    this.spinner.show();
    this.subscriptions.push(this.solicitudCreditoService.obtenerFlujo(this.idTransaccion, this.rut).subscribe(resp => {
      this.bodyModelos = resp;
      if(this.bodyModelos?.stages[9]?.optional === false){
        if(this.bodyModelos?.stages[9]?.completed){
          
          // Llamada a servicio para inciar aprobacion
          this.subscriptions.push(this.solicitudCreditoService.iniciarFlujoAprobacional(this.idTransaccion, this.rut).subscribe(resp => {
            const error = resp?.ServicioAprobacional?.errors || null;
            if(error?.hassError === true){
              this.alertService.error(error?.details?.message || 'Solicitud de Crédito - Ocurrio un error al actualizar el flujo');
              this.spinner.hide();
            } else {
              this.solicitudCreditoService.setStep('next');
              this.bloqueaPasos6.emit(true);
              this.spinner.hide();
            }
          }, (error: any) => {
            this.alertService.error(error?.error?.message || 'Solicitud de Crédito - Ocurrio un error al consultar con el servicio para iniciar el flujo aprobacional');
            this.spinner.hide();
          }));

        } else {
          this.spinner.hide();
          this.alertService.error('Solicitud de Crédito - Debe completar la etapa actual para poder avanzar');
        }
      } else {
        // Llamada a servicio para inciar aprobacion
        this.subscriptions.push(this.solicitudCreditoService.iniciarFlujoAprobacional(this.idTransaccion, this.rut).subscribe(resp => {
          const error = resp?.ServicioAprobacional?.errors || null;
          if(error?.hassError === true){
            this.alertService.error(error?.details?.message || 'Solicitud de Crédito - Ocurrio un error al actualizar el flujo');
            this.spinner.hide();
          } else {
            this.solicitudCreditoService.setStep('next');
            this.bloqueaPasos6.emit(true);
            this.spinner.hide();
          }
        }, (error: any) => {
          this.alertService.error(error?.error?.message || 'Solicitud de Crédito - Ocurrio un error al consultar con el servicio para iniciar el flujo aprobacional');
          this.spinner.hide();
        }));
      }
    }, (error: any) => {
      this.alertService.error(error?.error?.message || 'Solicitud de Crédito - Ocurrio un error al recuperar los datos del flujo de credito');
      this.spinner.hide();
    }));
  }

  avanzarFlujo() {
    this.spinner.show();
    this.subscriptions.push(this.solicitudCreditoService.obtenerFlujo(this.idTransaccion, this.rut).subscribe(resp => {
      this.bodyModelos = resp;
      if(this.bodyModelos?.stages[8]?.optional === false){
        if(this.bodyModelos?.stages[8]?.completed){

          this.subscriptions.push(this.solicitudCreditoService.calcularScore(this.idTransaccion, this.rut).subscribe(resp => {
            if(resp?.MotorDeCalculos?.errors?.hassError === false){
              if(resp?.DatosBasicosSolicitud?.Automatico === true){

                this.bloqueaPasos6.emit(true);
                this.solicitudCreditoService.setStep(6);
                this.spinner.hide();

              } else {
                
                this.solicitudCreditoService.setStep('next');
                // bloquea pasos 5
                if(!this.validaUsuarioAdmin()){
                  this.bloqueaPasos5.emit(true);
                }
                this.spinner.hide();
              }
            } else {
              this.alertService.error(resp?.MotorDeCalculos?.details?.message || 'Solicitud de Crédito - Ocurrio un error al recuperar los datos del motor de calculos');
              this.spinner.hide();
            }
          }, (error: any) => {
            this.alertService.error(error?.error?.message || 'Solicitud de Crédito - Ocurrio un error al consultar servicio del motor de calculos');
            this.spinner.hide();
          }));

        } else {
          this.spinner.hide();
          this.alertService.error('Solicitud de Crédito - Debe completar la etapa actual para poder avanzar');
        }
      } else {
        this.solicitudCreditoService.setStep('next');
        this.spinner.hide();
      }
    }, (error: any) => {
      this.alertService.error(error?.error?.message || 'Solicitud de Crédito - Ocurrio un error al recuperar los datos del flujo de credito');
      this.spinner.hide();
    }));
  }

  show(el: string, buttonId?: string) {
    const elementHTML = document.getElementById(el) as HTMLElement;
    elementHTML.classList.toggle('container-desplegables');
    if(buttonId){
      const button = document.getElementById(buttonId) as HTMLElement;
      if ( !elementHTML.classList.contains('container-desplegables') ){
        button.classList.add('button-selected');
        button.classList.remove('border');
        button.classList.remove('border-buttons');
        button.classList.add('border-0'); 
      } else {
        button.classList.remove('button-selected');
        button.classList.add('border');
        button.classList.add('border-buttons');
        button.classList.remove('border-0'); 
      }
    }
  }

  validaNumber(valor: any): boolean {
    if (typeof valor === 'number' && !isNaN(valor)) {
      return true;
    } else if (typeof valor === 'string' && /^[0-9]+$/.test(valor)) {
      return true;
    } else if (valor === 0) {
      return true;
    }
    return false;
  }

  agregarSaltosDeLinea(texto: string): string {
    return texto.replace(new RegExp('\n', 'g'), '<br />');
  }

}
